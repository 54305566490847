import React from 'react';
import { Grid } from '@material-ui/core';
import { TitleField } from '../common';

const DbbFeatureFields = (props) => {
    let { presentedTitle, displayDate } = props;
    return (
        <Grid container>
            <TitleField label="Title" field="name"
                title={presentedTitle}
                warning="No Title found"
            />

            <TitleField label="Runtime" field="runtime"
                title={presentedTitle}
                warning="No Runtime found"
            />

            <TitleField label="Production Company" field="productions"
                title={presentedTitle}
                warning="Production Company not found"
            />

            <TitleField label="MPAA Rating" field="rating"
                title={presentedTitle}
                warning="No Rating found"
            />

            <TitleField label="ISAN" field="isan"
                title={presentedTitle}
                warning="ISAN not found"
            />

            <TitleField label="MPM" field="mpmWalkerId"
                title={presentedTitle}
                warning="MPM not found"
            />

            <TitleField label="AlphaId" field="alphaId"
                title={presentedTitle}
                warning="No Alpha ID found for this title"
            />

            <TitleField label="Theatrical Release Date" field="initialReleaseDate"
                title={presentedTitle}
                warning="Theatrical Release Date not found for this title"
                formatFunc={displayDate}
            />

            <TitleField label="Release Year" field="releaseYear"
                title={presentedTitle}
                warning="Release Year not found"
            />

            <TitleField label="Color" field="color"
                title={presentedTitle}
            />

            <TitleField label="Copyright Line" field="copyrightLine"
                title={presentedTitle}
            />

            <TitleField label="Original Language" field="language"
                title={presentedTitle}
                warning="No Original Language found for this title"
            />

            <TitleField label="Country of Origin" field="countryOfOrigin"
                title={presentedTitle}
            />

            <TitleField label="Genres" field="genres"
                title={presentedTitle}
                warning="No Genres found for this title"
            />

            <TitleField label="Actors" field="actors"
                subfield="name"
                title={presentedTitle}
                warning="No Actors found for this title"
            />

            <TitleField label="Voice" field="voice"
                subfield="name"
                title={presentedTitle}
                warning="No Voice found for this title"
            />  

            <TitleField label="Director" field="directors"
                subfield="name"
                title={presentedTitle}
                warning="No Directors found for this title"
            />

            <TitleField label="Producers" field="producers"
                subfield="name"
                title={presentedTitle}
                warning="No Producers found for this title"
            />

            <TitleField label="Screenplay" field="screenplay"
                subfield="name"
                title={presentedTitle}
                warning="No Screenplay found for this title"
            />

            <TitleField label="Teleplay" field="teleplay"
                subfield="name"
                title={presentedTitle}
                warning="No Teleplay found for this title"
            />

            <TitleField label="Writer" field="writer"
                subfield="name"
                title={presentedTitle}
                warning="No Writer found for this title"
            />

            <TitleField label="Composer" field="composers"
                subfield="name"
                title={presentedTitle}
                warning="No Composer found for this title"
            />

            <TitleField label="Synopsis" field="synopsis"
                subfield="text"
                title={presentedTitle}
                warning="No synopsis found for this title"
            />
        </Grid>
    )
}

export { DbbFeatureFields };