import { SidecarService } from "./sidecar.service";

const search = (query) => {
  return SidecarService.post("/titlesearch", {
    query: query,
  });
};

const getTitlesByParent = (parentExternalId) => {
  return SidecarService.get(`/titlesbyparent/${parentExternalId}`);
};

const getTitleForClient = (clientId, titleId, dataFilter) => {
  let body = {
    gpmsId: titleId,
    clientId,
    dataFilter,
  };

  return SidecarService.post("/clientspec", body);
};

const displayTitle = (title) => {
  if (!title) {
    return "";
  }

  if (title.alphaName) {
    return `${title.titleFullName} - ${title.alphaName} - ${title.alphaOwner}`;
  }

  if (title.alpha) {
    return `${title.alpha.titleFullName} - ${title.alpha.alphaName} - ${title.alpha.alphaOwner}`;
  }

  return title.titleFullName || title.primaryTitleName || title.name;
};

export const TitleService = {
  search: search,
  getTitlesByParent,
  displayTitle,
  getTitleForClient,
};
