import React, { Component } from 'react';
import { Grid, Typography } from '@material-ui/core';


class TitleHeader extends Component {
    render() {
        return (
            <Grid item>
                <Typography className="title-pane-header">TITLE DATA</Typography>
            </Grid>
        );
    }
}

export { TitleHeader };