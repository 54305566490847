import React from 'react';
import { Router, Route, Switch } from 'react-router-dom';
import { connect } from 'react-redux';
import { history } from '../_helpers';
import { alertActions } from '../_actions';
import { PrivateRoute } from '../_components';
import { HomePage } from '../HomePage';
import { LoginPage } from '../LoginPage';
import { DataEntry } from '../DataEntryPage';
import { DownloadPage } from '../DownloadPage';
import { TestingGround } from '../TestingGround';
import { ClientProfileView, ClientProfileEdit } from '../ClientProfileView';
import { SidecarAppBar } from '../components';
import { MuiThemeProvider } from '@material-ui/core/styles';
import { sidecarTheme } from '../ui/MainTheme';
import '../stylesheets/common.scss';

const TheApp = () => {
  return (
    <div className="sidecar-layout">
      <SidecarAppBar />
      <PrivateRoute exact path="/" component={HomePage} />
      <PrivateRoute exact path="/package-data" component={DataEntry} />
      <PrivateRoute exact path="/client-profiles" component={ClientProfileView} />
      <PrivateRoute exact path="/profile-edit" component={ClientProfileEdit} />
      <PrivateRoute exact path="/download" component={DownloadPage} />
      <Route exact path="/testing-ground" component={TestingGround} />
    </div>
  );
}
class App extends React.Component {
  constructor(props) {
    super(props);

    const { dispatch } = this.props;
    history.listen((location, action) => {
      dispatch(alertActions.clear());
    });
  }

  render() {
    return (
      <MuiThemeProvider theme={sidecarTheme}>
        <Router history={history}>
          <div>
            <Switch>
              <Route exact path="/login" component={LoginPage} />
              <PrivateRoute path="/" component={TheApp} />
            </Switch>
          </div>
        </Router>
      </MuiThemeProvider>
    );
  }
}

function mapStateToProps(state) {
  const { alert } = state;
  return {
    alert
  };
}

const connectedApp = connect(mapStateToProps)(App);
export { connectedApp as App };
