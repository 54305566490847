import config from 'config';

export const userService = {
  login,
  logout,
};

function login(username, password) {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ username, password }),
  };

  return fetch(`${config.gigyaUrl}/login`, requestOptions)
    .then(handleResponse)
    .then(user => {
      if (user) {
        localStorage.setItem('user', JSON.stringify(user));
      }

      return user;
    });
}

function logout() {
  localStorage.removeItem('user');
}

function handleResponse(response) {
  return response.json().then(json => {
    const data = json;

    if (!response.ok) {
      return Promise.reject(data);
    }

    return data;
  });
}
