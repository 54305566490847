import React, { Component } from 'react';
import { Grid, Table, TableHead, TableBody,
    TableCell, TableRow } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import { MultiSelect } from '../components/common';
import _ from 'lodash';

const MAX_PRIORITY_SIZE = 4;
const styles = (theme) => ({
    table: {
        border: "2px solid rgba(117,117,117, 0.5)",
    },
    tableHead: {
        borderRight: "2px solid rgba(117,117,117, 0.5)",
        backgroundColor: "rgba(94, 94, 219, 0.16)",
        borderBottom: "none",
        fontSize: 16,
    },
    title: {
        borderBottom: "2px solid rgba(117,117,117, 0.5)",
        borderRight: "2px solid rgba(117,117,117, 0.5)",
        backgroundColor: "rgba(94, 94, 219, 0.16)",
        fontSize: 16,
    },
    tableRow: {
        borderTop: "2px solid rgba(117,117,117, 0.5)",
        borderBottom: "2px solid rgba(117,117,117, 0.5)",
    },
    tableHeadCell: {
        borderRight: "2px solid rgba(117,117,117, 0.5)",
        borderBottom: "none",
        color: "#5e5edb",
        fontSize: 14,
    },
    cell: {
        borderRight: "2px solid rgba(117,117,117, 0.5)",
        borderBottom: "2px solid rgba(117,117,117, 0.5)",
    },
    tableHeading: {
        position: 'relative',
        top: 32,
    }
});

class DataComparisonPanel extends Component {
    constructor(props) {
        super(props);
        this.state = {
            languagesToCompare: props.languagesToCompare || [],
            territoriesToCompare: props.territoriesToCompare || {},
            comparisonData: props.comparisonData || [],
        }
    }

    getComparisonData = (languagesToCompare, territoriesToCompare) => {
        /* This function is for language selection handler and territory select handler */
        if(languagesToCompare.length && territoriesToCompare.length) {
            let result = languagesToCompare.map((language) => {
                return territoriesToCompare.map((territory) => {
                    let data = _.find(this.props.languageData, (item) => {
                        return (item.language === language) && item.territories.includes(territory);
                    });
                    if(!_.isEmpty(data)) {
                        return Object.assign({}, data, { languageTerritoryLabel: `${language} - ${territory}` });
                    }
                })
            })
            return _.compact(_.flattenDeep(result));
        }
    }


    handleSelection = (label, collection) => {
        let comparisonData = [];
        let collectionToCompareName = (label === 'languagesToCompare') ?
            'territoriesToCompare':
            'languagesToCompare';

        let collectionToCompare = this.state[collectionToCompareName];

        if(label === 'languagesToCompare' && collection.length && collectionToCompare.length) {
            comparisonData = this.getComparisonData(collection, collectionToCompare);
        } else {
            comparisonData = this.getComparisonData(collectionToCompare, collection);
        }

        this.setState({
            [label]: collection,
            comparisonData,
        }, () => {
            this.props.onChange('compare', this.state);
        });
        
    }

    getTitlePriorities = (title, classes) => {
        let priorities = [];
        for(let i = 0; i < MAX_PRIORITY_SIZE; i++) {
            if(!_.isEmpty(title[i])){
                priorities.push(
                    <TableCell align="center" key={i} className={classes.cell}>
                        {`${title[i].source} - ${title[i].type}`}
                    </TableCell>
                );
            } else {
                priorities.push(
                    <TableCell align="center" key={i} className={classes.cell}></TableCell>
                );
            }
        }
        return priorities;
    }

    render() {
        let { classes, selectedLanguages, selectedTerritories } = this.props;
        let { languagesToCompare, comparisonData, territoriesToCompare } = this.state;
        return (
            <Grid container direction="column" className="card-padding" style={{
                borderTop: "2px solid rgba(117,117,117, 0.5)"
            }}>
                <Grid container direction="row" justify="space-between"
                style={{
                    marginBottom: 24,
                }}>
                    <Grid item component={MultiSelect}
                    label="Language"
                    menuItems={selectedLanguages}
                    field="languagesToCompare"
                    value={languagesToCompare}
                    onChange={this.handleSelection}
                    required={true}/>

                    <Grid item component={MultiSelect}
                    label="Territories"
                    menuItems={selectedTerritories}
                    field="territoriesToCompare"
                    value={territoriesToCompare}
                    onChange={this.handleSelection}
                    required={true}/>

                </Grid>
                <Grid container style={{
                    overflowY: "auto",
                }}>
                    <Table className={classes.table}>
                        <TableHead>
                            <TableRow>
                                <TableCell align="center" className={classes.tableHead}>
                                    <span className={classes.tableHeading} style={{
                                        left: 16,
                                    }}>LANGUAGE - TERRITORY</span>
                                </TableCell>
                                <TableCell align="center" colSpan={4} className={classes.title}>
                                    <span>TITLE</span>
                                </TableCell>
                                <TableCell align="center" className={classes.tableHead}>
                                    <span className={classes.tableHeading}>EIDR</span>
                                </TableCell>
                                <TableCell align="center" className={classes.tableHead}>
                                    <span className={classes.tableHeading}>Alpha ID</span>
                                </TableCell>
                                <TableCell align="center" className={classes.tableHead}>
                                    <span className={classes.tableHeading}>MPM</span>
                                </TableCell>
                                <TableCell align="center" className={classes.tableHead}>
                                    <span className={classes.tableHeading}>ISAN</span>
                                </TableCell>
                                <TableCell align="center" className={classes.tableHead}>
                                    <span className={classes.tableHeading}>Copyright Line</span>
                                </TableCell>
                                <TableCell align="center" className={classes.tableHead}>
                                    <span className={classes.tableHeading}>Ratings Reason</span>
                                </TableCell>
                            </TableRow>
                            <TableRow style={{
                                borderBottom: "2px solid rgba(117,117,117, 0.5)",
                            }}>
                                <TableCell className={classes.tableHead}></TableCell>
                                <TableCell align="center" className={classes.tableHeadCell}>1st</TableCell>
                                <TableCell align="center" className={classes.tableHeadCell}>2nd</TableCell>
                                <TableCell align="center" className={classes.tableHeadCell}>3rd</TableCell>
                                <TableCell align="center" className={classes.tableHeadCell}>4th</TableCell>
                                <TableCell className={classes.tableHead}></TableCell>
                                <TableCell className={classes.tableHead}></TableCell>
                                <TableCell className={classes.tableHead}></TableCell>
                                <TableCell className={classes.tableHead}></TableCell>
                                <TableCell className={classes.tableHead}></TableCell>
                                <TableCell className={classes.tableHead}></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                           {
                               !_.isEmpty(comparisonData) &&
                               comparisonData.map((languageInfo, index) => {
                                    return (
                                        <TableRow key={index}>
                                            <TableCell align="center" className={classes.cell}>
                                                {languageInfo.languageTerritoryLabel || ''}
                                            </TableCell>
                                            {
                                                this.getTitlePriorities(languageInfo.title, classes)
                                            }
                                            <TableCell align="center" className={classes.cell}>{languageInfo.eidr}</TableCell>
                                            <TableCell align="center" className={classes.cell}>{languageInfo.alphaId}</TableCell>
                                            <TableCell align="center" className={classes.cell}>{languageInfo.mpmWalkerId}</TableCell>
                                            <TableCell align="center" className={classes.cell}>{languageInfo.isan}</TableCell>
                                            <TableCell align="center" className={classes.cell}>{languageInfo.copyrightLine}</TableCell>
                                            <TableCell align="center" className={classes.cell}>{languageInfo.mpaaRatingReason}</TableCell>
                                        </TableRow>
                                    )
                               })
                           }
                        </TableBody>
                    </Table>
                </Grid>
            </Grid>
        )
    }
}

const styledComponent = withStyles(styles)(DataComparisonPanel);
export { styledComponent as DataComparisonPanel };