import React, { Component } from 'react';
import { Grid, Paper, Typography } from '@material-ui/core';
import { FileData } from './FileData';
import { RokuLicensingData } from './RokuLicensingData';
import { TagsData } from './TagsData';
import { TitleData } from '../TitleDisplay/TitleData';
import { clientShow } from '../Clientshow/clientshow';
import { connect } from "react-redux";

const initialState = {
    license: {}
};
class Roku extends Component {
    constructor(props) {
        super(props);
        this.state = {
            ...initialState,
            ...props
        };
    }

    render() {
        return (
            <Grid container direction="column"
            justify="space-between"
            className="card-padding"
            component={Paper}
            elevation={8}>
                <Grid item>
                  <Typography variant="h5" component="h5">PACKAGE DATA</Typography>
                </Grid>
                <Grid item component={TitleData} {...this.props}
                        clientSpec={this.props.clientSpec}
                        warning={this.props.titleWarning} />

                <Grid item component={RokuLicensingData}
                    {...this.props}
                    onPackageDataChange={this.props.handleChildChange}
                    data={this.state.license}
                    validationRequired={true}
                    header={'licensing data'}
                />

                <Grid item component={FileData} {...this.props} data={this.state.fileAssets} />

                <Grid item component={TagsData} {...this.props} data={this.state.tags} />

            </Grid>
        )
    }
}

const mapStateToProps = (state) => {
    let { files: filesReducer } = state;
    let newfiles = filesReducer.files.concat([{filename: ''}]);
    return {
      files: newfiles
    };
}

const storeConnected = connect(mapStateToProps, null)(Roku);
const clientConnected = clientShow(storeConnected, 'Roku');
export { clientConnected as Roku };


