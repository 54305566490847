import React, { Component } from 'react';
import _ from 'lodash';
import { clientShow } from '../Clientshow/clientshow';
import { TextField, Fab, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';

const style = () => ({
    input: {
        position: 'relative',
        height: '28px !important',
    },
    text: {
        marginBottom: 8,
    },
    button: {
        height: 28,
        marginLeft: 8,
        border: "solid 2px",
        backgroundColor: "white",
        color: "#5e5edb",
        borderColor: "#5e5edb",
    }

})

class TextInputWithButton extends Component {
    constructor(props) {
        super(props);
        this.state = {
            inputValue: props.value || "",
            newTitle: {},
        }
    }

    handleInputValueChange = (e) => {
        let value = e.target.value;
        this.setState({
            inputValue: value,
        });
    }

    render() {
        let { classes, index } = this.props;
        let { inputValue } = this.state;
        return (
            <div>
                <Typography className={classes.text}>{this.props.label}</Typography>
                <TextField variant="outlined"
                    style={{ width: 60 }}
                    type="number"
                    value={this.state.inputValue}
                    onChange={e => this.handleInputValueChange(e)}
                    InputProps={{
                        classes : { root: classes.input }
                    }}
                />
                <Fab variant="extended"
                    onClick={e => this.props.handleSetClick(inputValue, index)}
                    className={classes.button}
                >Set</Fab>
            </div>
        );
    }
}

const clientConnected = clientShow(TextInputWithButton, 'TextInputWithButton');
const styledComponent = withStyles(style)(clientConnected);
export { styledComponent as TextInputWithButton };