import React, { Component } from 'react';
import { Grid, Paper, Fab, Typography } from '@material-ui/core';
import { clientShow } from '../Clientshow/clientshow';
import { SidecarButton } from '../common';
import { KitData, VideoData, SubtitleData, AudioData, TimecodeData } from './';
import { TitleData } from '../TitleDisplay/TitleData';
import AddIcon from "@material-ui/icons/Add";
import DeleteIcon from "@material-ui/icons/Delete";
import moment from 'moment';
import Clapboard from '../../assets/icons/Clapboard';
import Save from '../../assets/icons/Save';

class DbbBulkIngest extends Component {
  constructor(props) {
    super(props);

    let video = props.video || [{}];
    let audio = props.audio || [{}];
    let subtitle = props.subtitle || [{}];
    this.state = {
      video,
      audio,
      subtitle,
      changingTitleInProgress: false,
    };
  }

  displayDate = (dateStr) => {
    let momentDateObj = moment(dateStr);
    return momentDateObj.isValid() ? momentDateObj.format("MM/DD/YYYY") : dateStr;
  }

  handleDataChangeInPane = (label, index) => (value) => {
    let data = [].concat(this.state[label]);
    data[index] = value;
    this.setState({
      [label]: data
    }, () => {
      this.props.handleChildChange(label, this.state[label]);
    })
  }

  handleAddNewPane = (label) => {
    let newData = [].concat(this.state[label])
    newData.push({});
    this.setState({
      [label]: newData,
    })
  }

  handleDeletePane = (deletedIndex, label) => {
    let newData = this.state[label].filter((data, index) => {
      return index !== deletedIndex;
    });
    this.setState({
      [label]: newData,
    }, () => {
      this.props.handleChildChange(label, this.state[label]);
      this.props.updateErrors(`${label}_${deletedIndex}`, true);
    })
  }

  getFrameRateLimit = () => {
    let videoData = this.state.video;
    let videoDataLength = this.state.video.length;
    if(videoDataLength === 1 && videoData[0].profile) {
      return videoData[0].profile.frameRate;
    } else {
      return 0;
    }
  }

  renderPaneButtons = (index, label) => () => {
    let deletable = index > 0;
    return (
      <Grid item>
        <Fab
          variant="extended"
          size="small"
          color="primary"
          onClick={e => this.handleAddNewPane(label)}
          style={{
            marginRight: 8,
          }}
        >
          <AddIcon style={{
            marginRight: 8,
            fontSize: 16,
          }} />
          <Typography style={{
            marginRight: 8,
            marginTop: -2,
            color: '#ffffff',
          }}>New Pane</Typography>
        </Fab>
        {
          deletable ? (
            <Fab
              variant="extended"
              size="small"
              color="primary"
              onClick={e => this.handleDeletePane(index, label)}
              style={{
                marginRight: 8,
              }}
            >
              <DeleteIcon style={{
                marginRight: 8,
                fontSize: 16,
              }} />
              <Typography style={{
                marginRight: 8,
                marginTop: -2,
                color: '#ffffff',
              }}>Delete Pane</Typography>
            </Fab>
          ) : null
        }
      </Grid>
    )
  }

  setChangingTitleInProgress = (e, value) => {
    e.stopPropagation();
    this.setState({
      changingTitleInProgress: value
    });
  }

  renderTitleDataPanelButtons = () => () => {
    return (
      <div>
        {
          !this.state.changingTitleInProgress ?
          <SidecarButton key={'change-title-button'} buttonText="Change Title" icon={Clapboard} onClick={
            (e) => { this.setChangingTitleInProgress(e, true); }} />
          :
          (<div>
            <SidecarButton key={'cancel-change-title'} size="small" buttonText="Cancel" onClick={
              (e) => { this.setChangingTitleInProgress(e, false); }} />

            <SidecarButton key={'save-change-title'} size="small" buttonText="Save" icon={Save} onClick={
              (e) => { this.setChangingTitleInProgress(e, false); }} />
          </div>)
        }
      </div>
    );
  }

  render() {
    let { kitData = {}, timecode, title:presentedTitle } = this.props;
    return (
      <Grid container direction="column"
        justify="space-between"
        className="card-padding"
        component={Paper}
        elevation={8}>

        <Grid item component={TitleData}
          {...this.props}
          clientSpec={this.props.clientSpec}
          warning={this.props.titleWarning}
          renderButtons={this.renderTitleDataPanelButtons()}
          changingTitleInProgress={this.state.changingTitleInProgress}
        />

        <Grid item component={KitData}
          {...this.props}
          kitData={kitData}
          onChange={this.props.handleChildChange}
        />

        {
          this.state.video.map((data, index) => {
            return (
              <Grid item component={VideoData}
                {...this.props}
                videoData={data}
                onChange={this.handleDataChangeInPane('video', index)}
                key={`video-${index}`}
                updateErrorLabel={`video-${index}`}
                renderButtons={this.renderPaneButtons(index, 'video')}
                validate={{
                  profile: true,
                  textedLanguage: true,
                  burnedInSubtitle: true,
                  activePictureAspectRatio: true,
                  activeTopLeftX: true,
                  activeTopLeftY: true,
                  activeBottomRightX: true,
                  activeBottomRightY: true,
                  activePictureFormat: true,
                  forcedSubtitle: true,
                  isMlfCore: true,
                  runtime: true,
                  dynamicRange: true,
                }}
              />
            )
          })
        }

        {
          this.state.audio.map((data, index) => {
            return (
              <Grid item component={AudioData}
                {...this.props}
                audioData={data}
                onChange={this.handleDataChangeInPane('audio', index)}
                showNotification={this.props.showNotification}
                key={`audio-${index}`}
                updateErrorLabel={`audio-${index}`}
                renderButtons={this.renderPaneButtons(index, 'audio')}
              />
            )
          })
        }

        {
          this.state.subtitle.map((data, index) => {
            return (
              <Grid item component={SubtitleData}
                {...this.props}
                subtitleData={data}
                onChange={this.handleDataChangeInPane('subtitle', index)}
                key={`subtitle-${index}`}
                updateErrorLabel={`subtitle-${index}`}
                renderButtons={this.renderPaneButtons(index, 'subtitle')}
                validate={{
                  encodingProfile: true,
                  format: true,
                  contentType: true,
                  fileLayout: true,
                  subtitleLanguage: true,
                  imageFormat: true,
                  timingFormat: true,
                  textEncodingType: true,
                  horizontalResolution: true,
                  verticalResolution: true,
                  activePictureFormat: true,
                  ccStandard: true,
                  frameRate: true,
                  activePictureFormat: true,
                  activePictureAspectRatio: true,
                  screenAspectRatio: true,
                }}
              />
            )
          })
        }

        <Grid item component={TimecodeData}
          {...this.props}
          timecodeData={timecode}
          onChange={this.props.handleChildChange}
          updateErrors={this.props.updateErrors}
          showNotification={this.props.showNotification}
          focusErrorHandler={this.props.focusErrorHandler}
          frameRateLimit={this.getFrameRateLimit()}
        />

      </Grid>
    )
  }
}

let clientConnected = clientShow(DbbBulkIngest, 'DbbBulkIngest');
export { clientConnected as DbbBulkIngest };