import React from 'react';
import { Fab, Typography } from '@material-ui/core';

const buttonStyle = {
  marginRight: 8
}

const iconStyle = {
  marginRight: 8,
  fontSize: 16,
}

const typographyStyle = {
  marginRight: 8,
  marginTop: -2,
  color: '#ffffff',
}

export const SidecarButton = (props) => {
  let { icon:Icon } = props;
  return (
    <Fab variant="extended" style={buttonStyle}  onClick={props.onClick} color="primary">
      { props.icon ? <Icon style={iconStyle} /> : null }
      <Typography style={typographyStyle}>
        {props.buttonText}
      </Typography>
    </Fab>
  );
}