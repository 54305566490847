export default [
  "English",
  "Abkhaz",
  "Abkhazian",
  "Afar",
  "Afghan / Pashto",
  "Afrikaans",
  "Akan",
  "Albanian",
  "Amharic",
  "Arabic",
  "Aragonese",
  "Armenian",
  "Assamese",
  "AvailLanguage",
  "Avaric",
  "Avestan",
  "Aymara",
  "Azerbaijani",
  "Baka",
  "Bambara",
  "Bangla / Bengali",
  "Bashkir",
  "Basque",
  "Belarusian",
  "Belgium Dual",
  "Bhojpuri",
  "Bihari",
  "Bislama",
  "Bokmål, Norwegian;Norwegian Bokmål",
  "Bosnian",
  "BPort-LAS",
  "Breton",
  "Bulgarian",
  "Burmese",
  "Catalan",
  "CFrench - PFrench",
  "Chamorro",
  "Chechen",
  "Chichewa;Chewa;Nyanja",
  "Chinese - Simplified",
  "Chinese - Traditional",
  "Chinese (Cantonese)",
  "Chinese (Mandarin Simplified)[Text]",
  "Chinese (Mandarin Traditional)[Text]",
  "Chinese (Mandarin, PRC)",
  "Chinese (Mandarin, Singapore)",
  "Chinese (Mandarin, Taiwanese)",
  "Chuvash",
  "Corsican",
  "Cree",
  "Croatian",
  "Czech",
  "Danish",
  "Dari - Afghan Persian",
  "Divehi;Dhivehi;Maldivian;",
  "Dutch (Belgium)",
  "Dutch (Flemish)",
  "Dutch (Netherlands)",
  "Dzongkha",
  "English (UK)",
  "Esperanto",
  "Estonian",
  "Ewe",
  "Faroese",
  "Fijian",
  "Finnish",
  "Flemish",
  "French - Continental",
  "French (Belgium)",
  "French (Canadian)",
  "French (Parisian)",
  "Fula;Fulah;Pulaar;Pular",
  "Gaelic (Irish)",
  "Gaelic (Scots)",
  "Galician",
  "Ganda",
  "Georgian",
  "German (Austrian)",
  "German (Germany)",
  "German (Swiss)",
  "Greek",
  "Greenlandic, Kalaallisut",
  "Guaraní",
  "Gujarati",
  "Haitian;Haitian Creole",
  "Hausa",
  "Hebrew",
  "Herero",
  "Hindi",
  "Hiri Motu",
  "Hungarian",
  "Icelandic",
  "Igbo",
  "Indonesian",
  "Indonesian / Bahasa",
  "Inuktitut",
  "Irish",
  "Italian",
  "Japanese",
  "Javanese",
  "Kannada",
  "Kanuri",
  "Kashmiri",
  "Kazakh",
  "Khmer",
  "Kikuyu, Gikuyu",
  "Kinyarwanda",
  "Kirghiz, Kyrgyz",
  "Kirundi",
  "Komi",
  "Kongo",
  "Korean",
  "Korean - Rating Card",
  "Kurdish",
  "Kwanyama, Kuanyama",
  "Lao",
  "Latvia Dual",
  "Latvian",
  "Lebanon Dual",
  "Limburgish, Limburgan, Limburger",
  "Lingala",
  "Lithuanian",
  "Luba - Katanga",
  "Luxembourg - Luxembourgish",
  "Luxembourgish, Letzeburgesch",
  "Macedonian",
  "Malagasy",
  "Malay",
  "Malayalam",
  "Maltese",
  "Manx",
  "Maori",
  "Marathi",
  "Marshallese",
  "Mongolian",
  "Nauru",
  "Nauvhal",
  "Ndonga",
  "Nepali",
  "North Ndebele",
  "Northern Sami",
  "Northern Sotho",
  "Norwegian",
  "Not Texted",
  "Nuosu",
  "Oriya",
  "Oromo",
  "Ossetian, Ossetic",
  "Persian / Farsi",
  "Polish",
  "Portuguese (Brazil)",
  "Portuguese (Portugal)",
  "Punjabi",
  "Quechua",
  "Romanian",
  "Romansh",
  "Romany",
  "Russian (Russia)",
  "Russian (Ukraine)",
  "Samoan",
  "Sango",
  "Serbian",
  "Serbo - Croatian",
  "Shona",
  "Sindhi",
  "Sinhalese",
  "Slovak",
  "Slovene",
  "Somali",
  "Sotho, Southern",
  "South Ndebele",
  "Southern Sotho",
  "Spanish (Argentinean)",
  "Spanish (Castilian)",
  "Spanish (Chilean)",
  "Spanish (Colombian)",
  "Spanish (Cuban)",
  "Spanish (Latin Am)",
  "Spanish (Mexican)",
  "Spanish (Puerto Rican)",
  "Sundanese",
  "Swahili",
  "Swati",
  "Swedish",
  "Tagalog",
  "Tahitian",
  "Tajik",
  "Tamil",
  "Tatar",
  "Telugu",
  "Thai",
  "Tibetan",
  "Tigrinya",
  "Tonga (Tonga Islands)",
  "Tsonga",
  "Tswana",
  "Turkish",
  "Turkmen",
  "Twi",
  "Uighur, Uyghur",
  "Ukrainian",
  "Urdu",
  "Uzbek",
  "Venda",
  "Vietnamese",
  "Walloon",
  "Welsh",
  "Western Frisian",
  "Wolof",
  "Xhosa",
  "Yakut",
  "Yiddish",
  "Yoruba",
  "Yugoslavian",
  "Zhuang, Chuang",
  "Zulu",
];
