import React from 'react';
import { clientShow } from '../Clientshow/clientshow';
import { LanguageData } from './';
import { TitleData } from '../TitleDisplay';
import { TerritorySelect } from './TerritorySelect';
import './TitlePackage.styles.scss';
import { Grid, Paper, Typography } from '@material-ui/core';

class Dbb extends React.Component {
  constructor(props) {
    super(props);

    this.state = { //here since movielabstitlemetadata uses this
      subMPAA: true
    }
  }

  setSubMPAA = (subMPAA) => {
    this.setState({
        subMPAA
    }, () => {
        this.props.handleChildChange('substituteMPAA',  { subMPAA });
    });
}

  render() {
    let { titleWarning, ...rest } = this.props;
    return (
      <Grid container
        direction="column"
        justify="space-between"
        className="card-padding"
        component={Paper}
        elevation={8}>
        <Grid item>
          <Typography variant="h5" component="h5">PACKAGE DATA</Typography>
        </Grid>
        <Grid item
          component={TitleData}
          {...this.props}
          warning={this.props.titleWarning}
          errorsEnabled={true}
          onSubstituteMPAAClick={this.setSubMPAA}
          subMPAA={this.state.subMPAA}
          locales={this.props.locales}
        >
          <TerritorySelect {...this.props} />
        </Grid>
        <Grid item component={LanguageData} {...rest} languageData={this.props.languageData} />
      </Grid>
    );
  }
}

let clientConnected = clientShow(Dbb, 'Dbb');
export { clientConnected as Dbb };