export default {
  "5.1 MXF": {
    encodingProfile: "5.1 MXF",
    channels: {
      channel1: "Left",
      channel2: "Right",
      channel3: "Front Center",
      channel4: "LFE",
      channel5: "Rear Left",
      channel6: "Rear Right",
    },
    audioConfiguration: "5.1",
  },
  "5.1 WAV": {
    encodingProfile: "5.1 WAV",
    channels: {
      channel1: "Left",
      channel2: "Right",
      channel3: "Front Center",
      channel4: "LFE",
      channel5: "Rear Left",
      channel6: "Rear Right",
    },
    audioConfiguration: "5.1",
  },
  "7.1 MXF": {
    encodingProfile: "7.1 MXF",
    channels: {
      channel1: "Left",
      channel2: "Right",
      channel3: "Front Center",
      channel4: "LFE",
      channel5: "Rear Left",
      channel6: "Rear Right",
      channel7: "Side Left",
      channel8: "Side Right",
    },
    audioConfiguration: "7.1",
  },
  "7.1 WAV": {
    encodingProfile: "7.1 WAV",
    channels: {
      channel1: "Left",
      channel2: "Right",
      channel3: "Front Center",
      channel4: "LFE",
      channel5: "Rear Left",
      channel6: "Rear Right",
      channel7: "Side Left",
      channel8: "Side Right",
    },
    audioConfiguration: "7.1",
  },
  "Discrete Multiple Tracks 5.1 MOV PCM": {
    encodingProfile: "Discrete Multiple Tracks 5.1 MOV PCM",
    channels: {
      channel1: "Left",
      channel2: "Right",
      channel3: "Front Center",
      channel4: "LFE",
      channel5: "Rear Left",
      channel6: "Rear Right",
    },
    audioConfiguration: "5.1",
  },
  "Discrete Multiple Tracks 7.1 MOV PCM": {
    encodingProfile: "Discrete Multiple Tracks 7.1 MOV PCM",
    channels: {
      channel1: "Left",
      channel2: "Right",
      channel3: "Front Center",
      channel4: "LFE",
      channel5: "Rear Left",
      channel6: "Rear Right",
      channel7: "Side Left",
      channel8: "Side Right",
    },
    audioConfiguration: "7.1",
  },
  "Discrete Multiple Tracks LT/RT MOV PCM": {
    encodingProfile: "Discrete Multiple Tracks LT/RT MOV PCM",
    channels: {
      channel1: "Left Total",
      channel2: "Right Total",
    },
    audioConfiguration: "LT/RT",
  },
  "Discrete Multiple Tracks Mono 2.0 MOV PCM": {
    encodingProfile: "Discrete Multiple Tracks Mono 2.0 MOV PCM",
    channels: {
      channel1: "Left",
      channel2: "Right",
    },
    audioConfiguration: "Mono 2.0",
  },
  "Discrete Multiple Tracks Standard Stereo MOV PCM": {
    encodingProfile: "Discrete Multiple Tracks Standard Stereo MOV PCM",
    channels: {
      channel1: "Left",
      channel2: "Right",
    },
    audioConfiguration: "Standard Stereo",
  },
  "Discrete Track 5.1 MOV PCM": {
    encodingProfile: "Discrete Track 5.1 MOV PCM",
    channels: {
      channel1: "Left",
      channel2: "Right",
      channel3: "Front Center",
      channel4: "LFE",
      channel5: "Rear Left",
      channel6: "Rear Right",
    },
    audioConfiguration: "5.1",
  },
  "Discrete Track 7.1 MOV PCM": {
    encodingProfile: "Discrete Track 7.1 MOV PCM",
    channels: {
      channel1: "Left",
      channel2: "Right",
      channel3: "Front Center",
      channel4: "LFE",
      channel5: "Rear Left",
      channel6: "Rear Right",
      channel7: "Side Left",
      channel8: "Side Right",
    },
    audioConfiguration: "7.1",
  },
  "Discrete Track LT/RT MOV PCM": {
    encodingProfile: "Discrete Track LT/RT MOV PCM",
    channels: {
      channel1: "Left Total",
      channel2: "Right Total",
    },
    audioConfiguration: "LT/RT",
  },
  "Discrete Track Stereo MOV PCM": {
    encodingProfile: "Discrete Track Stereo MOV PCM",
    channels: {
      channel1: "Left",
      channel2: "Right",
    },
    audioConfiguration: "Standard Stereo",
  },
  "Dolby E Muxed MOV": {
    encodingProfile: "Dolby E Muxed MOV",
    channels: {
      channel1: "Dolby E 1",
      channel2: "Dolby E 2",
    },
    audioConfiguration: "Dolby E (Unknown)",
  },
  "IMF Component Audio 5.1": {
    encodingProfile: "IMF Component Audio 5.1",
    channels: {
      channel1: "Left",
      channel2: "Right",
      channel3: "Front Center",
      channel4: "LFE",
      channel5: "Rear Left",
      channel6: "Rear Right",
    },
    audioConfiguration: "5.1",
  },
  "IMF Component Audio LtRt": {
    encodingProfile: "IMF Component Audio LtRt",
    channels: {
      channel1: "Left Total",
      channel2: "Right Total",
    },
    audioConfiguration: "LT/RT",
  },
  "IMF Component Audio Standard Stereo": {
    encodingProfile: "IMF Component Audio Standard Stereo",
    channels: {
      channel1: "Left",
      channel2: "Right",
    },
    audioConfiguration: "Standard Stereo",
  },
  "IMF Mono 2.0": {
    encodingProfile: "IMF Mono 2.0",
    channels: {
      channel1: "Left",
      channel2: "Right",
    },
    audioConfiguration: "Mono 2.0",
  },
  "IMF Zipped 5.1": {
    encodingProfile: "IMF Zipped 5.1",
    channels: {
      channel1: "Left",
      channel2: "Right",
      channel3: "Front Center",
      channel4: "LFE",
      channel5: "Rear Left",
      channel6: "Rear Right",
    },
    audioConfiguration: "5.1",
  },
  "IMF Zipped LT/RT": {
    encodingProfile: "IMF Zipped LT/RT",
    channels: {
      channel1: "Left Total",
      channel2: "Right Total",
    },
    audioConfiguration: "LT/RT",
  },
  "IMF Zipped Mono 2.0": {
    encodingProfile: "IMF Zipped Mono 2.0",
    channels: {
      channel1: "Left",
      channel2: "Right",
    },
    audioConfiguration: "Mono 2.0",
  },
  "IMF Zipped Standard Stereo": {
    encodingProfile: "IMF Zipped Standard Stereo",
    channels: {
      channel1: "Left",
      channel2: "Right",
    },
    audioConfiguration: "Standard Stereo",
  },
  "LT/RT MXF": {
    encodingProfile: "LT/RT MXF",
    channels: {
      channel1: "Left Total",
      channel2: "Right Total",
    },
    audioConfiguration: "LT/RT",
  },
  "LT/RT WAV": {
    encodingProfile: "LT/RT WAV",
    channels: {
      channel1: "Left Total",
      channel2: "Right Total",
    },
    audioConfiguration: "LT/RT",
  },
  "Mono 2.0 MOV Discrete": {
    encodingProfile: "Mono 2.0 MOV Discrete",
    channels: {
      channel1: "Left",
      channel2: "Right",
    },
    audioConfiguration: "Mono 2.0",
  },
  "Mono 2.0 MXF": {
    encodingProfile: "Mono 2.0 MXF",
    channels: {
      channel1: "Left",
      channel2: "Right",
    },
    audioConfiguration: "Mono 2.0",
  },
  "Mono 2.0 WAV": {
    encodingProfile: "Mono 2.0 WAV",
    channels: {
      channel1: "Left",
      channel2: "Right",
    },
    audioConfiguration: "Mono 2.0",
  },
  "Muxed 5.1 MOV PCM": {
    encodingProfile: "Muxed 5.1 MOV PCM",
    channels: {
      channel1: "Left",
      channel2: "Right",
      channel3: "Front Center",
      channel4: "LFE",
      channel5: "Rear Left",
      channel6: "Rear Right",
    },
    audioConfiguration: "5.1",
  },
  "Muxed 7.1 MOV PCM": {
    encodingProfile: "Muxed 7.1 MOV PCM",
    channels: {
      channel1: "Left",
      channel2: "Right",
      channel3: "Front Center",
      channel4: "LFE",
      channel5: "Rear Left",
      channel6: "Rear Right",
      channel7: "Side Left",
      channel8: "Side Right",
    },
    audioConfiguration: "7.1",
  },
  "Muxed LT/RT MOV PCM": {
    encodingProfile: "Muxed LT/RT MOV PCM",
    channels: {
      channel1: "Left Total",
      channel2: "Right Total",
    },
    audioConfiguration: "LT/RT",
  },
  "Muxed Mono 2.0 MOV PCM": {
    encodingProfile: "Muxed Mono 2.0 MOV PCM",
    channels: {
      channel1: "Left",
      channel2: "Right",
    },
    audioConfiguration: "Mono 2.0",
  },
  "Muxed Standard Stereo MOV PCM": {
    encodingProfile: "Muxed Standard Stereo MOV PCM",
    channels: {
      channel1: "Left",
      channel2: "Right",
    },
    audioConfiguration: "Standard Stereo",
  },
  "Standard Stereo MXF": {
    encodingProfile: "Standard Stereo MXF",
    channels: {
      channel1: "Left",
      channel2: "Right",
    },
    audioConfiguration: "Standard Stereo",
  },
  "Standard Stereo WAV": {
    encodingProfile: "Standard Stereo WAV",
    channels: {
      channel1: "Left",
      channel2: "Right",
    },
    audioConfiguration: "Standard Stereo",
  },
};
