import React, { useRef } from 'react';
import { useDrag, useDrop } from 'react-dnd';
import PropTypes from 'prop-types';

const style = {
  cursor: 'move',
}

const DragDrop = (props) => {
  let ref = useRef(null);
  let { dragItem, type, index, moveItem } = props;
  let opacity = 1;

  let [, drop] = useDrop({
    accept: type,
    hover: (hoverItem, monitor) => {
      if (!ref.current) {
        //no dom reference
        return;
      }

      let draggedIndex = index;
      let hoverIndex = hoverItem.index;

      if (draggedIndex === hoverIndex) {
        //same item
        return;
      }

      //hover item position
      const hoverRect = ref.current.getBoundingClientRect();
      const hoverMiddleY = (hoverRect.bottom - hoverRect.top) / 2;

      //mouse position
      const clientOffset = monitor.getClientOffset();
      const hoverClientY = clientOffset.y - hoverRect.top;

      //dragging down
      if ((draggedIndex < hoverIndex) && (hoverClientY < hoverMiddleY)) {
        return;
      }

      //dragging up
      if ((draggedIndex > hoverIndex) && (hoverClientY > hoverMiddleY)) {
        return;
      }

      moveItem(draggedIndex, hoverIndex);
      hoverItem.index = index;
    }
  });

  let [{ isDragging }, drag] = useDrag({
    item: { ...dragItem, type, index },
    collect: (monitor) => (
      {
        isDragging: monitor.isDragging(),
      }
    )
  });

  opacity = isDragging ? 0 : 1;

  drop(drag(ref));

  return (
    <div ref={ref} style={{ ...style, opacity }}>
      {props.children}
    </div>
  );
}

DragDrop.propTypes = {
  moveItem: PropTypes.func.isRequired,
  index: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  type: PropTypes.string.isRequired,
  dragItem: PropTypes.object.isRequired,
}

export { DragDrop as DragDrop };