import { createStore, applyMiddleware } from 'redux';
import thunkMiddleware from 'redux-thunk';
import { createLogger } from 'redux-logger';
import rootReducer from '../_reducers';
import config from 'config';

let middlewares = [
  thunkMiddleware,
];

if (config.debug) {
  const loggerMiddleware = createLogger();
  middlewares.push(loggerMiddleware);
}

export const store = createStore(
  rootReducer,
  applyMiddleware(...middlewares),
);
