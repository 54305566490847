import React, { Component } from 'react';
import { Grid, FormControl, Select, MenuItem, Typography } from '@material-ui/core';

class TableSelect extends Component {

    constructor(props) {
        super(props);
        this.state = {
            value: props.value || ""
        }
    }

    handleChange = (e) => {
        let label = this.props.label;
        let value = e.target.value;
        this.setState({
            value,
        }, () => {
            this.props.onChange(label, value);
        })
    }

    render() {
        let { collection } = this.props;
        return (
            <Grid item component={FormControl} style={{
                width: '100%',
                height: '100%'
            }}>
                <Select
                    disableUnderline={true}
                    onChange={this.handleChange}
                    value={this.state.value}
                >
                    {
                        collection.map((item, index) => {
                            return (
                                <MenuItem value={item} key={index}>
                                    <Typography>{item}</Typography>
                                </MenuItem>
                            )
                        })
                    }
                </Select>
            </Grid>
        )
    }
}

export { TableSelect };