import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import _ from 'lodash';
import { TitleDetails } from '../components/common';

function getPriorityNum(index) {
    let num = index + 1;
    switch(num) {
        case 1:
            return `${num}ST`;
        
        case 2:
            return `${num}ND`;

        case 3:
            return `${num}RD`;
        
        default:
            return `${num}TH`;
    }
}

const TitlePriorityList = (props) => {
    let territoryData = props.territoryData;
    return (
        <Grid container direction="column" justify="space-between">
            
                {
                    _.map(territoryData, (item, index) => {
                        return (
                            <Grid item key={index}>
                                <Typography style={{ color: "#5e5edb" }}>{`${getPriorityNum(index)} PRIORITY`}</Typography>
                                <Grid container direction="row">
                                    <Grid item component={Typography} style={{
                                        fontWeight: 700,
                                        flexBasis: 160,
                                        color: '#333333'
                                    }}>Title Type/Source:</Grid>
                                    <Grid item component={Typography} style={{
                                        maxWidth: '60%'}}>{`${item.source} - ${item.type}`}</Grid>
                                </Grid>
                                <Grid container direction="row">
                                    <Grid item component={Typography} style={{
                                        fontWeight: 700,
                                        flexBasis: 160,
                                        color: '#333333'
                                    }}>Title Value:</Grid>
                                    <Grid item component={Typography} style={{
                                        maxWidth: '60%'}}>{item.name || <span className="no-data-found">No Data Found</span>}</Grid>
                                </Grid>
                            </Grid>
                        )
                    })
                }
        </Grid>
    )
}

export { TitlePriorityList };