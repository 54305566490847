import React, { Component } from 'react';
import _ from 'lodash';
import { Tabs, Tab, Grid, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core';
import { TabPanel } from '../common';
import { CmdgFeatureFields, CmdgTvFields } from './';
import { PXLFeatureFields, PXLBoxyFeatureFields } from '../PXL';
import { ExhibitionMetadataFeatureFields, ExhibitionMetadataTVFields } from '../ExhibitionMetadata';
import { MovielabsTitlePane } from '../MovielabsManifest/MovielabsTitlePane';

const style = () => ({
  scrollButtons: {
    width: 32,
    '&[type=button]': {
      width: 32,
      backgroundColor: '#5e5edb'
    },
    '& > svg': {
      color: '#ffffff'
    }
  },
  label: {
    fontSize: 14,
    color: '#333333',
    fontWeight: 700,
  },
  wrapper: {
    backgroundColor: '#f5f5f5',
    borderTop: "1px solid rgba(117,117,117, 0.5)"
  }
})

class TerritoriesPanel extends Component {

  getTitleForTerritory = (territory) => {
    let key = territory.territoryId || territory.locale;
    let territoryMap = this.props.titlesByTerritory[key];
    let title = territoryMap ? territoryMap.title : {};
    return title;
  }

  render() {
    let { classes, tabIndex, selectedTerritories, tvSynopsisLengths } = this.props;
    let scrollButtonsVariant = selectedTerritories.length > 5 ? 'auto' : 'off';

    return (
      <Grid container className={classes.wrapper}>
        <Tabs value={tabIndex}
          onChange={this.props.handleTabChange}
          indicatorColor="primary"
          variant="scrollable"
          textColor="primary"
          scrollButtons={scrollButtonsVariant}
          classes={{
            scrollButtons: classes.scrollButtons,
          }}
        >
          {
            selectedTerritories.map((territory, index) => {
              let name = territory.name || territory.territoryName;
              return (
                <Tab label={name} key={index}
                  classes={{
                    root: classes.label,
                  }} />
              )
            })
          }
        </Tabs>
        {
          selectedTerritories.map((territory, index) => {
            let title = this.getTitleForTerritory(territory);
            return (
              <TabPanel currentIndex={tabIndex} index={index} key={index}>
                <Grid container className="card-padding" style={{
                    backgroundColor: '#ffffff',
                    marginBottom: 0,
                }}>
                  <Typography component="h5" variant="h5" style={{
                    marginBottom: 16,
                  }}>Title Data</Typography>

                  {
                    this.props.isProductTypeFeature(title.productType) ? (
                      <React.Fragment>
                        <CmdgFeatureFields
                          {...this.props}
                          presentedTitle={title}
                          index={index}
                          handleSetClick={this.props.handleSetClick}
                          client={this.props.client}
                        />
                        <PXLFeatureFields
                          {...this.props}
                          handleSetClick={this.props.handleSetClick}
                          presentedTitle={title}
                          index={index}
                          client={this.props.client}
                        />

                        <PXLBoxyFeatureFields
                          {...this.props}
                          handleSetClick={this.props.handleSetClick}
                          presentedTitle={title}
                          index={index}
                          client={this.props.client}
                        />

                        <ExhibitionMetadataFeatureFields
                          {...this.props}
                          handleSetClick={this.props.handleSetClick}
                          presentedTitle={title}
                          index={index}
                          client={this.props.client}
                        />
                      </React.Fragment>
                    ) : (
                      <React.Fragment>
                        <CmdgTvFields
                        {...this.props}
                        tvSynopsisLengths={tvSynopsisLengths}
                        presentedTitle={title}
                        index={index}
                        handleSetClick={this.props.handleTVSetClicks}
                        client={this.props.client}
                      />

                      <ExhibitionMetadataTVFields
                          {...this.props}
                          handleSetClick={this.props.handleSetClick}
                          presentedTitle={title}
                          index={index}
                          client={this.props.client}
                        />
                      </React.Fragment>
                    )
                  }
                  <MovielabsTitlePane
                    {...this.props}
                    presentedTitle={title}
                    client={this.props.client}
                    index={index}
                  />
                </Grid>
              </TabPanel>
            )
          })
        }
      </Grid>
    );
  }
}

const styledComponent = withStyles(style)(TerritoriesPanel)
export { styledComponent as TerritoriesPanel };