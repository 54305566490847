import React, { Component } from 'react';
import { Grid } from '@material-ui/core';
import { TitleField } from '../common';
import { UtilityService } from '../../_services/utility.service';
import { clientShow } from '../Clientshow/clientshow';

const PXLBoxyFeatureFields = (props) => {
    let { presentedTitle, client, index, maxSynopsisLength } = props;
    return (
        <Grid container>
            <TitleField label="Title" field="name"
                title={presentedTitle}
                warning="No Title found"
            />

            <TitleField label="Runtime" field="runtime"
                title={presentedTitle}
                warning="No Runtime found"
            />

            <TitleField label="Production Company" field="productions"
                title={presentedTitle}
                warning="Production Company not found"
            />

            <TitleField label="Rating System" field="ratingSystem"
                title={presentedTitle}
                warning="Rating System not found"
            />

            <TitleField label="Rating" field="rating"
                title={presentedTitle}
                warning="No Rating found"
            />

            <TitleField label="Rating Reason" field="ratingReason"
                title={presentedTitle}
                warning="Rating Reason not found"
            />

            <TitleField label="ISAN" field="isan"
                title={presentedTitle}
                warning="ISAN not found"
            />

            <TitleField label="MPM" field="mpmWalkerId"
                title={presentedTitle}
                warning="MPM not found"
            />

            <TitleField label="AlphaId" field="alphaId"
                title={presentedTitle}
                warning="No Alpha ID found for this title"
            />

            <TitleField label="Theatrical Release Date" field="initialReleaseDate"
                title={presentedTitle}
                formatFunc={UtilityService.convertDateField}
                warning="Theatrical Release Date not found for this title"
            />

            <TitleField label="Local Release Date" field="localReleaseDate"
                title={presentedTitle}
                warning="Local Release Date not found for this title"
            />

            <TitleField label="Copyright Line" field="copyrightLine"
                title={presentedTitle}
            />

            <TitleField label="Summary Box Office" field="summaryBoxOffice"
                title={presentedTitle}
                warning="Summary Box office not found"
            />

            <TitleField label="Country of Origin" field="countryOfOrigin"
                title={presentedTitle}
            />

            <TitleField label="Genres" field="genres"
                title={presentedTitle}
                warning="No Genres found for this title"
            />

            <TitleField label="Actors" field="actors"
                subfield="name"
                title={presentedTitle}
                warning="No Actors found for this title"
            />

            <TitleField label="Voice" field="voice"
                subfield="name"
                title={presentedTitle}
                warning="No Voices found for this title"
            />

            <TitleField label="Director" field="directors"
                subfield="name"
                title={presentedTitle}
                warning="No Directors found for this title"
            />

            <TitleField label="Producers" field="producers"
                subfield="name"
                title={presentedTitle}
                warning="No Producers found for this title"
            />

            <TitleField label="Teleplay" field="teleplay"
                subfield="name"
                title={presentedTitle}
                warning="No Teleplay found for this title"
            />

            <TitleField label="Composer" field="composers"
                subfield="name"
                title={presentedTitle}
                warning="No Composers found for this title"
            />

            <TitleField label="Writer" field="writer"
                subfield="name"
                title={presentedTitle}
                warning="No Writers found for this title"
            />

            <TitleField label="Screenplay" field="screenplay"
                subfield="name"
                title={presentedTitle}
                warning="No Screenplay found for this title"
            />

            <TitleField label="Synopsis" field="synopsis"
                subfield="text"
                title={presentedTitle}
                handleSetClick={props.handleSetClick}
                index={index}
                client={client}
                maxSynopsisLength={maxSynopsisLength}
                warning="No synopsis found for this title"
            />

        </Grid>
    )
}

let clientConnected = clientShow(PXLBoxyFeatureFields, "PXLBoxyFeatureFields");
export { clientConnected as PXLBoxyFeatureFields };