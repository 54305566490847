import React from 'react';
// import { SaveProgressButton } from '../components/DbbBulkIngest/SaveProgressButton';
import { ReleaseDateField } from '../components/ExhibitionMetadata/ReleaseDatesField';

export const TestingGround = (props) => {
  let client= {
    components: [
      {
        "name": "SaveProgressButton"
      }
    ]
  }
  return (
    <div className="content">
      <ReleaseDateField releaseDates={[111]}></ReleaseDateField>
      {/* <SaveProgressButton client={client} onClick={() => {console.log('hi')}} /> */}
    </div>
  )
}