import React from 'react';
import _ from 'lodash';
import config from 'config';
import { connect } from 'react-redux';
import { Grid, Paper, Typography } from "@material-ui/core";

import { TitleData } from "../TitleDisplay/TitleData";
import { ProviderData, BillingData, BillingId,
  ClosedCaption, DataForm, ArtworkData } from '../DirectTV';
import { TagsData, RokuLicensingData } from '../Roku';
import { clientShow } from '../Clientshow/clientshow';
import SeasonPackage from '../SeasonPackage/SeasonPackage';
import { ClosedCaptionData } from './';
import { UtilityService } from '../../_services';
import { SupplementalAudio } from './SupplementalAudio';

class RokuCablelabs extends React.Component {

  constructor(props) {
    super(props);
      this.state = {...props};
  }

  shouldFieldDisplay = (fieldName, fields) => {
    if (!fieldName || !fields) {
      return true;
    }
    return fields.includes(fieldName);
  }

  render() {
    let isSeason = UtilityService.isSeason(this.props.title);
    let allProps = {
      shouldFieldDisplay: this.shouldFieldDisplay,
      ...this.props,
    };

    return (
        <Grid container
            direction="column"
            justify="space-between"
            className="card-padding"
            component={Paper}
            elevation={8}>
                <Grid item>
                  <Typography variant="h5" component="h5">PACKAGE DATA</Typography>
                </Grid>
                <Grid item
                  component={ProviderData}
                  onPackageDataChange={this.props.handleChildChange}
                  { ...allProps }
                  />
                {
                  isSeason ?

                    <Grid item component={SeasonPackage}
                      { ...allProps }
                      data={this.state.season}
                      clientSpec={this.props.spec}
                      warning={this.props.titleWarning}
                      onChange={this.props.handleChildChange}
                      languages={config.languages}
                    /> :

                    (
                      <div>
                        <Grid item component={TitleData}
                        { ...allProps }
                        clientSpec={this.props.spec}
                        warning={this.props.titleWarning}
                        hasErrors={this.props.errors.billing}
                        >
                          <BillingData
                            { ...allProps }
                            onPackageDataChange={this.props.handleChildChange}
                            shouldFieldDisplay={this.shouldFieldDisplay}
                            updateErrors={this.props.updateErrors}
                            >
                              <BillingId />
                              <ClosedCaption />
                            </BillingData>
                        </Grid>

                        <Grid item component={RokuLicensingData}
                            { ...allProps }
                            data={this.state.license}
                            onPackageDataChange={this.props.handleChildChange}
                            validationRequired={false}
                            updateErrors={this.props.updateErrors}
                            header={'licensing data'}
                            defaultState={{
                                data: {
                                  startDate: null,
                                  endDate: null,
                                },
                                licensingError: false,
                                estError: false,
                            }}
                        />

                        <Grid item component={DataForm}
                          { ...allProps }
                          header={"feature/episode data"}
                          fieldName="feature"
                          data={this.state.feature}
                          validate={{
                            filename: true,
                            audioType: true,
                            audioLanguage: true,
                          }}
                          onPackageDataChange={this.props.handleChildChange}
                          languages={config.languages}
                          updateErrors={this.props.updateErrors}
                        >
                            <ClosedCaptionData
                              files={this.props.files}
                              { ...allProps }
                              closedCaptionData={this.state.feature ? this.state.feature.closedCaption: {}}
                              languages={config.languages}
                              onChange={this.props.handleChildChange}
                            />

                            <SupplementalAudio
                              files={this.props.files}
                              { ...allProps }
                              supplementalAudio={this.state.feature ? this.state.feature.supplementalAudio: {}}
                              language="Spanish - Mexico"
                              audioTypes={['Dolby 5.1',
                                'Stereo',
                                'Mono'
                              ]}
                              onChange={this.props.handleChildChange}
                            />

                        </Grid>

                        <Grid item component={DataForm}
                          { ...allProps }
                          header={"preview data"}
                          fieldName="preview"
                          data={this.state.preview}
                          validate={{
                            filename: false,
                          }}
                          onPackageDataChange={this.props.handleChildChange}
                          expanded={false}
                          languages={config.languages}
                          updateErrors={this.props.updateErrors}
                          />

                        <Grid item component={ArtworkData}
                          { ...allProps }
                          onPackageDataChange={this.props.handleChildChange}
                          setByProductType={true}
                          disableFields={true}
                          disableAddRowButton={true}
                          featureType={[
                            "Key Art",
                            "Box Cover",
                            "Background Image"
                          ]}
                          tvseriesType={[
                            "Episode Art",
                            "Season Background",
                            "Series Key Art",
                            "Series Box Cover",
                            "Series Background Image"
                          ]}
                          data={this.state.artwork}
                          onPackageDataChange={this.props.handleChildChange}
                          updateErrors={this.props.updateErrors}
                          />

                        <Grid item component={TagsData} { ...allProps } data={this.state.tags} />
                      </div>
                    )
                }
         </Grid>
    );
  }
}

const mapStateToProps = (state) => {
  let { files: filesReducer } = state;
  let newfiles = filesReducer.files.concat([{filename: ''}]);
  return {
    files: newfiles,
  };
};

const storeConnected = connect(mapStateToProps, null)(RokuCablelabs);
const clientConnected = clientShow(storeConnected, 'RokuCablelabs');
export { clientConnected as RokuCablelabs };


