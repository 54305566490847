export const getNormalizedProductType = (productType = '') => {
  switch (productType.toLowerCase()) {
    case 'feature':
    case 'dtv/feature':
    case 'non-episodic alpha':
    case 'm.o.w.':
    case "made-for-digital feature":
    case "short":
    case "special":
    case "mini-series":
      return 'feature';

    case 'tv series':
    case 'episodic alpha':
    case "made-for-digital series":
    case "dtv/series":
    case "pilot":
    case "dtv/non-feature":
    case "3-level series":
    case "2-level series":
    case "serial":
    case "format":
      return 'tv series';
    default:
      return 'feature';
  }
};
