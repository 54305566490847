import React from 'react';
import { Grid, Fab, Button, Typography } from "@material-ui/core";
import { SaveAlt } from "@material-ui/icons";

function getTitleName(data) {
    let seriesName = data.title.seriesName;
    let episodeNumber = data.title.episodeNumber;
    let episodeName = data.title.primaryTitleName;
    return `${seriesName} ${episodeNumber} - ${episodeName}`;
}

function getLabelClass(name, length=20){
    if(!name) {
        return '';
    }

    if(name.length > length) {
        return "label-extended"
    }

    return "label";

}

const SeasonDownload = (props) => {
    return (
        <Grid item>
            <Grid item container style={{
                height: '100%',
            }}>
            <Grid item container justify="center" style={{
                padding: 4,
                marginBottom: 24,
            }}>
                { props.zipfile ?
                    <a href={props.zipfile.location}>
                        <Fab color="primary" variant="extended">
                            Download All XMLs
                        </Fab>
                    </a>
                    :
                    <Fab color="primary" variant="extended" onClick={props.downloadAll}>
                        Download All XMLs
                    </Fab>
                }
            </Grid>

            <Grid item container direction="row" justify="flex-start"
            style={{
                flexGrow: props.downloads.length > 3 ? 0.1 : 0,
                overflowY: "auto",
                height: 320,
                paddingLeft: 12,
            }}
            >
                {props.downloads.map((download, index) => {
                    let titleName = getTitleName(download);
                    let labelClass = getLabelClass(titleName, 45);
                    let alphaName = download.title.alphaName;
                    let alphaClass = props.isAlphaNameLong(alphaName) ? "alpha-extended" : "alpha";
                    return (
                        <Grid container key={index} direction="column" justify="flex-start" style={{
                            margin: '16px 0'
                        }}>
                            <Grid item container>
                                <Button variant="text" disableRipple color="primary"
                                onClick={e => props.downloadFile(download)}
                                style={{
                                    backgroundColor: "white"
                                }}>
                                <SaveAlt
                                    style={{
                                        marginRight: 12
                                    }}
                                />
                                <Typography className={labelClass}>
                                    {titleName}
                                </Typography>
                                </Button>
                            </Grid>
                            <Grid item container direction="row" justify="flex-start">
                                <Grid item
                                style={{
                                    margin: '8px 16px 0 16px'
                                }}>
                                    <Typography>{download.title.alphaName}</Typography>
                                </Grid>
                                <Grid item className={alphaClass}>
                                    <Grid item
                                    style={{
                                        margin: '8px 16px 0 16px'
                                    }}>
                                        <Typography>{download.title.alphaId}</Typography>
                                    </Grid>
                                    <Grid item
                                    style={{
                                        margin: '8px 16px 0 16px'
                                    }}>
                                        <Typography>{download.title.alphaOwner}</Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    );
                })}
            </Grid>
            </Grid>
        </Grid>
    )
}

export { SeasonDownload as default}