import React, { Component } from 'react';
import { Grid, FormControl, InputLabel, TextField, MenuItem, Typography,
    ExpansionPanelSummary, Select } from "@material-ui/core";
import { FileEditableDropdown } from "../FileEditableDropdown/FileEditableDropdown";
import { ExpandableSection } from '../common';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { withStyles } from "@material-ui/styles";
import { FileService } from '../../_services/file.service';
import { clientShow } from '../Clientshow/clientshow';

import _ from 'lodash';

const style = (theme) => ({
    formControl: {
        top: 2,
    },
    label: {
        color: "#333333",
        fontSize: 14,
        fontFamily: ['Lato', 'sans-serif'],
        fontWeight: 700
    },
    input: {
        marginTop: 40,
        width: '12vw',
    },
    focused: {
        fontSize: 14,
        color: "#5e5edb",
        fontFamily: ['Lato', 'sans-serif'],
        fontWeight: 700
    }
});

const defaultState = {
    captionFile: {},
    language: '',
    audioType: '',
    expanded: true,
}
class SupplementalAudio extends Component {
    constructor(props) {
        super(props);
        let { supplementalAudio = {} } = props;
        this.state = Object.assign({}, defaultState, supplementalAudio);
        this.handleFilenameChange = _.debounce(this.handleFilenameChange, 550);
    }

    handleFilenameChange = (label, event) => {
        let value = event.target.value;
        let newState = {};
        newState.language = !_.isEmpty(value) ? 'en' : '';
        let ebucoreData = _.find(this.props.files, { filename: value });
        newState.captionFile = {
            ebucore: ebucoreData,
            filename: value,
        };
        if(ebucoreData) {
            let checksum = FileService.getChecksumForFilename(ebucoreData.filename, this.props.files);
            newState.checksum = checksum || this.state.checksum;
        }

        this.setState(newState, () => {
            this.props.onChange('supplementalAudio', this.state);
        })
    }

    handleExpansionPanel = () => {
        this.setState((prevState) => {
            return {
                expanded: !prevState.expanded
            }
        });
    }

    handleChange = (label) => (event) => {
        let value = event.target.value;
        this.setState({
            [label]: value
        }, () => {
            this.props.onChange('supplementalAudio', this.state);
        });
    }

    handleDropDownChange = (label, event) => {
        let value = event.target.value;
        let newState = Object.assign({}, this.state, {
            [label]: value,
          });   
        this.setState(newState, () => {
            this.props.onChange('supplementalAudio', this.state);
        })        
    }

    getExpansionSummaryComponent = (isExpanded, classes) => {
        return (
            <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}
                className={isExpanded ? classes.focused: classes.label}>
                Supplemental Audio
            </ExpansionPanelSummary>);
    }

    render() {
        let { files, language, audioTypes, classes, disabled } = this.props;
        let isExpanded = this.state.expanded;
        return (
            <Grid item component={ExpandableSection} header="Supplemental Audio"
                defaultExpanded={isExpanded} onChange={this.handleExpansionPanel}
                disabled={disabled} expansionSummary={this.getExpansionSummaryComponent(isExpanded, classes)}>

                    <Grid container direction="row" justify="space-between">
                        <Grid item>
                            <FormControl className={classes.formControl} disabled={this.props.disabled}>
                                <InputLabel shrink={false} className={classes.label}>
                                    Filename
                                </InputLabel>
                                <FileEditableDropdown
                                    id="filename"
                                    onChange={this.handleFilenameChange}
                                    field={'captionFile'}
                                    disabled={this.props.disabled}
                                    value={this.state.captionFile.filename}
                                    files={files}
                                    style={{
                                        marginTop: 40,
                                        width: '20vw',
                                    }}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item>
                            <FormControl className={classes.formControl} disabled={this.props.disabled}>
                                <InputLabel shrink={false} className={classes.label}>
                                    Language
                                </InputLabel>
                                <TextField
                                    type="text"
                                    defaultValue={language}
                                    className={classes.input}
                                    disabled
                                />
                            </FormControl>

                        </Grid>
                        <Grid item>
                            <FormControl className={classes.formControl} disabled={this.props.disabled}>
                                <InputLabel shrink={false} className={classes.label}>
                                    Audio Type
                                </InputLabel>
                                <Select
                                    id="audioType"
                                    value={this.state.audioType}
                                    onChange={e => this.handleDropDownChange('audioType', e)}
                                    MenuProps={{
                                    classes: { paper: classes.dropDownStyle },
                                    getContentAnchorEl: null,
                                    anchorOrigin: {
                                        vertical: "bottom",
                                        horizontal: "left",
                                    }
                                    }}
                                    style={{
                                    marginTop: 40,
                                    width: '12vw',
                                    }}
                                    >{audioTypes.map(audioType => (
                                    <MenuItem key={audioType} value={audioType}>
                                        <Typography noWrap={true}>{audioType}</Typography>
                                    </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        
                    </Grid>

            </Grid>
        )
    }
}

const styled = withStyles(style)(SupplementalAudio);
const clientConnected = clientShow(styled, 'SupplementalAudio')
export { clientConnected as SupplementalAudio };
