import React from 'react';
import { Grid } from '@material-ui/core';
import { TitleField } from '../common';
import { MPAASubstitute } from './MPAASubstitute';



const MovielabsFeatureTitlePane = (props) => {
    let { presentedTitle, displayDate } = props;
    let handleSubMPAA = (e) => {
        props.onSubstituteMPAAClick(e.target.checked);
    }

    return (
        <Grid container>
            <TitleField label="Title" field="name"
                title={presentedTitle}
                warning="No Title found"
            />

            <TitleField label="Runtime" field="runtime"
                title={presentedTitle}
                warning="No Runtime found"
            />

            <TitleField label="Ratings" field="rating"
                title={presentedTitle}
                warning="No Ratings found"
            />

            <MPAASubstitute client={props.client} checked={props.subMPAA} onChange={handleSubMPAA} />

            <TitleField label="Alpha EIDR" field="alphaEidr"
                title={presentedTitle}
                warning="No Alpha EIDR found"
            />

            <TitleField label="Product EIDR" field="productEidr"
                title={presentedTitle}
                warning="No Product EIDR found"
            />

            <TitleField label="Release Date" field="initialReleaseDate"
                title={presentedTitle}
                formatFunc={displayDate}
                warning="No Release Date found for this title"
            />

            <TitleField label="Release Year" field="releaseYear"
                title={presentedTitle}
                warning="Release Year not found"
            />

            <TitleField label="Copyright Line" field="copyrightLine"
                title={presentedTitle}
            />

            <TitleField label="Country of Origin" field="countryOfOrigin"
                title={presentedTitle}
            />

            <TitleField label="Genres" field="genres"
                title={presentedTitle}
                warning="No Genres found for this title"
            />

            <TitleField label="Actors" field="actors"
                subfield="name"
                title={presentedTitle}
                warning="No Actors found for this title"
            />

            <TitleField label="Director" field="directors"
                subfield="name"
                title={presentedTitle}
                warning="No Directors found for this title"
            />

            <TitleField label="Producers" field="producers"
                subfield="name"
                title={presentedTitle}
                warning="No Producers found for this title"
            />

            <TitleField label="Screenplay/Writers" field="screenplay"
                subfield="name"
                title={presentedTitle}
                warning="No Screenplay/Writers found for this title"
            />

            <TitleField label="Composer" field="composers"
                subfield="name"
                title={presentedTitle}
                warning="No Composer found for this title"
            />

            <TitleField label="Original Language" field="originalLanguage"
                title={presentedTitle}
                warning="No Original Language found for this title"
            />

            <TitleField label="Synopsis (190)" field="shortSynopsis"
                subfield="text"
                title={presentedTitle}
                warning="No Synopsis (190) found for this title"
            />

            <TitleField label="Synopsis (400)" field="moderateSynopsis"
                subfield="text"
                title={presentedTitle}
                warning="No Synopsis (400) found for this title"
            />

            <TitleField label="Synopsis (4000)" field="longSynopsis"
                subfield="text"
                title={presentedTitle}
                warning="No Synopsis (4000) found for this title"
            />
        </Grid>
    )
}

export { MovielabsFeatureTitlePane };