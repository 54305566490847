import _ from 'lodash';
import { fileConstants } from '../_constants';
const initialState = {
  files: [],
};

export const files = (state = initialState, action) => {
  switch (action.type) {
    case fileConstants.FILE_REMOVED:
      var newFiles = state.files.filter(item => {
        return item.filename !== action.file.filename;
      });

      return { ...state, files: newFiles };
    case fileConstants.FILES_ADDED:
      let catFiles = state.files.concat(action.files);
      let uniqFiles = _.uniqBy(catFiles, 'filename');
      return { ...state, files: uniqFiles};
    default:
      return state;
  }
};
