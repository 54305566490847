import React from 'react';
import { Grid } from '@material-ui/core';
import { TitleDetails } from '../common';
import { DbbFeatureFields, DbbTvSeriesFields } from './';
import { clientShow } from '../Clientshow/clientshow';
import { getNormalizedProductType } from '../../_helpers/titleHelper';
import moment from 'moment';

function displayDate(dateStr) {
    let momentDateObj = moment(dateStr);
    return momentDateObj.isValid() ? momentDateObj.format("MM/DD/YYYY") : dateStr;
}

const DbbTitlePane = (props) => {
    let { presentedTitle } = props;
    let productType = presentedTitle ? getNormalizedProductType(presentedTitle.productType) : '';
    return (
        <Grid item>
            <Grid container component={TitleDetails}
                direction="column" justify="space-between">
                    {
                        productType.toLowerCase() === 'feature' ? (
                            <DbbFeatureFields
                                presentedTitle={presentedTitle}
                                displayDate={displayDate}
                            />
                        ) : (
                           <DbbTvSeriesFields
                                presentedTitle={presentedTitle}
                                displayDate={displayDate}
                           />
                        )
                    }
            </Grid>
        </Grid>
    )
}

const clientConnected = clientShow(DbbTitlePane, "DbbTitlePane")
export { clientConnected as DbbTitlePane };