import React, { Component } from "react";
import { connect } from "react-redux";
import { Grid, TextField, Button, MenuItem, Typography,
   FormControl, InputLabel, Select
  } from "@material-ui/core";
import { ExpandableSection } from '../common';
import { Add } from "@material-ui/icons";
import { withStyles } from '@material-ui/styles';
import { FileService } from '../../_services/file.service';
import _ from 'lodash';
import { clientShow } from '../Clientshow/clientshow';
import { FileEditableDropdown } from '../FileEditableDropdown/FileEditableDropdown';
import { getNormalizedProductType } from '../../_helpers/titleHelper';

const styles = (theme) => ({
  root: {
    fontSize: 14,
    color: "#5e5edb",
    fontWeight: 700,
  },
  dropDownStyle: {
    maxHeight: 234,
  },
  formControl: {
    position: 'relative',
  },
  label: {
    top: 3,
    fontSize: 14,
    color: '#333333',
  }
})

function defaultRowData(filename, type) {
  return {
    filename,
    type,
    resolutionX: '',
    resolutionY: '',
    checksum: '',
    errors: {
      resolutionXError: false,
      resolutionYError: false,
    }
  };
}

class ArtworkData extends Component {

  constructor(props) {
    super(props);

    let artworkData = props.data || [];
    let artworkForms = artworkData.length ?
      [].concat(artworkData) :
      this.getDefaultRowData();

    this.state = {
      types: this.getTypes(),
      artworkData,
      artworkForms,
    };
  }

  componentDidMount() {
    let hasErrors = this.hasErrors(this.state.artworkForms);
    this.props.updateErrors('artwork', !hasErrors);
    this.props.onPackageDataChange('artwork', _.compact(this.state.artworkData));
  }
  componentDidUpdate(prevProps, prevState) {
    if(prevState.types !== this.state.types) {
      this.setState({
        types: this.getTypes(),
      })
    }
  }

  getTypes = () => {
    if(!this.props.setByProductType) {
      return this.props.defaultTypes;
    }
    let productType =
        getNormalizedProductType(this.props.presentedTitle.productType);

    if(productType) {
      productType = productType.replace(/\s/g, '');
    }

    return productType ?
          this.props[`${productType.toLowerCase()}Type`] :
          null;
  }

  getDefaultRowData = () => {
    if(!this.props.setByProductType) {
      return [ defaultRowData('', this.props.defaultTypes[0])];
    }

    let productType = getNormalizedProductType(this.props.presentedTitle.productType);
    switch(productType.toLowerCase()) {
      case 'feature':
        return this.props.featureType.map((type) => {
          return defaultRowData('', type);
        });
      case 'tv series':
        return this.props.tvseriesType.map((type) => {
          return defaultRowData('', type);
        });
      default :
        return [ defaultRowData('', this.props.defaultTypes[0])];
    };
  }

  enableRequiredFieldValidationFor = (formDataObj) => {
    let errorLabel = '';
    let errorValue = false;
    formDataObj.errors = formDataObj.errors || {};

    _.each(Object.keys(formDataObj), (key) => {
      switch(key) {
        case "resolutionX":
        case "resolutionY":
        case "type":
          errorLabel = `${key}Error`;
          errorValue = formDataObj[key] === '';
          formDataObj.errors[errorLabel] = errorValue;
          break;
      }
    });
  }

  resetToInitial = (formData) => {
    let keys = Object.keys(formData);
    _.each(keys, (key) => {
      if(key === 'errors'){
        formData[key].resolutionXError = false;
        formData[key].resolutionYError = false;
      } else {
        if(key !== 'type') {
          formData[key]  = '';
        }
      }
    });
  }

  hasErrors = (collection) => {
    let errors = _.map(collection, (item) => {
      return item.errors.resolutionXError || item.errors.resolutionYError;
    });

    return !_.every(errors, (bool) => { return bool === false });
  }

  handleChange = (label, event, index) => {
    let formArray = this.state.artworkForms.concat([]);
    let newArray = this.state.artworkData.concat([]);
    let isFilename = label.toLowerCase() === 'filename';
    let formData = formArray[index];
    let value = event.target.value;

    formData[label] = value;
    let obj = Object.assign({}, formData);

    let hasAFileset = (formArray[index] && formArray[index].filename !== '');

    if (isFilename) {
      if (hasAFileset) {
        let ebucoreFileData = _.find(this.props.files, { filename: value});
        obj.ebucore = ebucoreFileData;

        let checksum = FileService.getChecksumForFilename(obj.filename, this.props.files);
        obj.checksum = formData.checksum = checksum ? checksum : '';
        this.enableRequiredFieldValidationFor(formData);
      } else {
        this.resetToInitial(formData);
      }
    } else {
      this.enableRequiredFieldValidationFor(formData);
    }

    let hasErrors = this.hasErrors(formArray);
    newArray[index] = obj;

    let newState = {
      artworkData: newArray,
      artworkForms: formArray,
    }

    this.setState(newState, () => {
        this.props.updateErrors('artwork', !hasErrors);
        this.props.onPackageDataChange('artwork', _.compact(this.state.artworkData));
    });
  }

  disableAddRow = () => {
    return this.props.disableAddRowButton || false;
  }

  handleAddRow = () => {
    if (this.state.artworkForms.length === 2) {
      return;
    }

    let newArray = this.state.artworkForms.concat([
      defaultRowData('', this.state.types[0]),
    ]);

    this.setState({
      artworkForms: newArray
    });
  }

  artworkRow = (artworkItem, index) => {
    let { files, classes } = this.props;
    let notEmptyFile = artworkItem.filename !== '';
    return (
      <Grid item container
            justify="space-between">
              <Grid item>
                <FormControl className={classes.formControl}>
                  <InputLabel shrink={false} className={classes.label}>Filename</InputLabel>
                  <FileEditableDropdown
                    id="filename"
                    index={index}
                    field={'filename'}
                    value={artworkItem.filename}
                    onChange={this.handleChange}
                    files={files}
                    style={{
                      marginTop: 40,
                      width: '20vw'
                    }}
                    />
                </FormControl>
                </Grid>
                <Grid item>
                  <FormControl className={classes.formControl} disabled={this.props.disableFields || false}>
                    <InputLabel shrink={false} className={classes.label}>Type</InputLabel>
                    <Select
                      id="type"
                      value={artworkItem.type}
                      onChange={(e) => (this.handleChange("type", e, index))}
                      MenuProps={{
                        classes: { paper: classes.dropDownStyle },
                        getContentAnchorEl: null,
                        anchorOrigin: {
                          vertical: "bottom",
                          horizontal: "left",
                        }
                      }}
                      style={{
                        marginTop: 38,
                        width: '12vw',
                      }}
                    >{this.state.types.map(type => (
                      <MenuItem key={type} value={type}>
                        <Typography noWrap={true}>{type}</Typography>
                      </MenuItem>
                    ))}</Select>
                  </FormControl>
                </Grid>
                <Grid item>
                  <FormControl required={notEmptyFile} className={classes.formControl}>
                    <InputLabel shrink={false} className={notEmptyFile ? classes.root : classes.label}>Resolution 'X'</InputLabel>
                    <TextField
                    id="resolution-x"
                    type="number"
                    value={artworkItem.resolutionX}
                    error={this.props.errorsEnabled && artworkItem.errors.resolutionXError}
                    onChange={(e) => (this.handleChange("resolutionX", e, index))}
                    style={{
                      marginTop: 40,
                      width: '12vw',
                    }}
                  />
                  </FormControl>
                </Grid>
                <Grid item>
                  <FormControl required={notEmptyFile} className={classes.formControl}>
                    <InputLabel shrink={false} className={notEmptyFile ? classes.root : classes.label}>Resolution 'Y'</InputLabel>
                    <TextField
                      id="resolution-y"
                      value={artworkItem.resolutionY}
                      type="number"
                      onChange={(e) => (this.handleChange("resolutionY", e, index))}
                      error={this.props.errorsEnabled && artworkItem.errors.resolutionYError}
                      style={{
                        marginTop: 40,
                        width: '12vw',
                      }}
                    />
                  </FormControl>
                </Grid>
                <Grid item>
                  <FormControl className={classes.formControl}>
                    <InputLabel shrink={false} className={classes.label}>Checksum</InputLabel>
                    <TextField
                      id="checksum"
                      value={artworkItem.checksum}
                      onChange={(e) => (this.handleChange("checksum", e, index))}
                      style={{
                        marginTop: 38,
                        width: '12vw',
                      }}
                    />
                  </FormControl>
              </Grid>
            </Grid>
    );
  }

  render() {
    let hasErrors = this.hasErrors(this.state.artworkForms);
    let errorsOn = this.props.errorsEnabled;
    let errorsFocusClass = errorsOn && this.props.focusErrorHandler(hasErrors);
    let defaultFocusClass = errorsFocusClass ? errorsFocusClass : 'normal';
    return (
      <Grid container component={ExpandableSection} header="artwork data"
      defaultExpanded={true} focusErrorCssStyle={defaultFocusClass}>
            <Grid container direction="column"
            justify="space-between"
            className="data-pane">
              <Grid container  direction="column"
                justify="space-between">
                  { this.state.artworkForms.map((item, index) => {

                    let ArtworkRow = this.artworkRow(item, index);
                    return (<div key={index}>{ArtworkRow}</div>);
                  })}
                  {
                      !this.disableAddRow() && (<Grid item container
                    justify="space-between">
                      <Grid item>
                        <Button style={{ marginTop: 16 }} color="primary" onClick={this.handleAddRow}>
                            <Add style={{ marginRight: 12 }} />
                            ADD NEW ROW
                        </Button>
                      </Grid>
                    </Grid>)
                  }

              </Grid>
          </Grid>
      </Grid>
    );
  }
}

const mapStateToProps = (state) => {
  let { files: filesReducer, titles } = state;
  let presentedTitle = titles.titles[0];
  let newfiles = filesReducer.files.concat([{filename: ''}]);

  return {
    files: newfiles,
    presentedTitle
  };
}

let clientConnected = clientShow(ArtworkData, 'ArtworkData')
let styled = withStyles(styles)(clientConnected);
let connected = connect(mapStateToProps, null)(styled);
export { connected as ArtworkData };
