import React, { useState } from 'react';
import moment from 'moment';
import { Typography, Button } from '@material-ui/core';
import { withStyles } from '@material-ui/styles'
import Save from '../../assets/icons/Save';
import { clientShow } from '../Clientshow/clientshow';

const styles = (theme) => ({
  root: {
    display: 'inline',
    margin: '0 12px',
  }
});

const getTimestamp = () => {
  return moment().format("MM/DD/YYYY hh:mm:ss a")
}

const SaveProgressButton = (props) => {
  let { classes } = props;
  let [action, setAction] = useState(null);

  let handleClick = () => {
    props.onClick().then(() => {
      setAction({
        timestamp: getTimestamp()
      });
    });
  }

  return (
    <div>
      <Save className={"with-purple-color"} style={{display:'inline', verticalAlign: 'text-top'}} />
      <Button onClick={handleClick}>
        <Typography classes={classes} className="with-purple-color" variant="button">Save Progress</Typography>
      </Button>
      { action ?
        <Typography classes={classes}>Last Modified: {action.timestamp}</Typography>:
        null
      }
    </div>
  )
}


let styled = withStyles(styles)(SaveProgressButton);
let clientConnected = clientShow(styled, 'SaveProgressButton');
export { clientConnected as SaveProgressButton }