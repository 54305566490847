import React from 'react';
import { Grid, InputLabel } from '@material-ui/core';
import { TitleAutoComplete } from '../TitleAutoComplete';
import './TitleSearch.styles.scss';

export const TitleSearch = (props) => {
  return (
    <Grid container
    direction="column"
    justify="space-between"
    component="section">
    <Grid item className="title-label">
      <InputLabel className="client-data-label">Title Lookup:</InputLabel>
    </Grid>
    <Grid item container justify="space-between">

        <TitleAutoComplete
          onSuggestionSelected={props.onTitleSuggestionSelected}
          placeholder={"Search by Title, Alpha ID, Level 2 EIDR ID"}
          fullWidth={props.fullWidth || false}
          title={props.title}
        />

      {
        React.Children.count(props.children) ? (
          <Grid item className="title-details">
            { props.children }
          </Grid>
        ) : null
      }
    </Grid>
  </Grid>
  )
}