import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

let getComponentFromList = (list, componentToCheck) => {
    let str = `^${componentToCheck.toLowerCase()}$`;
    let rgx = new RegExp(str);
    let cmp = _.find(list, (component) => {
        return rgx.test(component.name.toLowerCase());
    });

    return cmp;
}

let getLoadedComponent = (client, component) => {
    let listedComponents = client && client.components;
    return getComponentFromList(listedComponents, component);
}

const clientShow = function (SidecarComponent, sidecarComponentName) {
    return (props) => {
        let { client, spec } = props;

        client = (spec && spec.client) ? spec.client : client;

        let loadedComponent = getLoadedComponent(client, sidecarComponentName);
        let display = !!loadedComponent;
        let newProps = null;

        if (display) {
            newProps = loadedComponent.props || {};
            newProps = Object.assign({}, props, newProps)
        }

        return (
            display ? <SidecarComponent {...newProps} ></SidecarComponent> : null
        );
    }
}

clientShow.propTypes = {
    client: PropTypes.object.isRequired,
    spec: PropTypes.object.isRequired,
}

export { clientShow as clientShow }