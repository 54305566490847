import React from "react";

const SvgAlphas = props => (
  <svg viewBox="0 0 16 16" width="1em" height="1em" {...props}>
    <path
      d="M3.3 11.4c.4.8 1 1.4 1.9 1.6 1.2.3 2.1-.1 2.9-.8 1.3-1.3 1.7-2.9 1.9-4.5 0-.6-.2-1.1-.4-1.7-.3-.9-.8-1.8-1.6-2.4-1.2-1-2.7-1-3.8-.1-.5.4-.8.8-1 1.3-.5 1.1-.7 2.2-.6 3.3 0 1.2.2 2.3.7 3.3zm9.4-9.8c.1-.2.2-.6.5-.6H15c-.3.7-.4.9-.6 1.4-.6 1.6-1.2 3.2-1.7 4.8-.4 1.5-.2 2.9.4 4.4.4 1.1 1.3 1.5 2.5 1.3h.3v1.4c0 .5 0 .5-.5.5-2.3.3-3.6-1-4.2-2.6-.1-.2-.1-.4-.2-.7-.1.1-.1.2-.2.3-.4.7-.9 1.4-1.6 2-1.8 1.5-4.5 1.4-6.3.4-1.4-.8-2.2-2-2.6-3.3C-.2 8.6-.2 6.3 1 4c.8-1.5 2.2-2.6 4.1-2.9 2.2-.4 4 .3 5.3 1.9.3.4.6.9.9 1.4 0 .1.1.2.2.3.4-1.1.8-2.1 1.2-3.1z"
      fillRule="evenodd"
      clipRule="evenodd"
      fill="currentColor"
    />
  </svg>
);

export default SvgAlphas;

