    import React, { Component } from 'react';
import { Grid, FormControl, InputLabel, Select, FormLabel,
    RadioGroup, Radio, FormControlLabel, MenuItem } from '@material-ui/core';
import { ExpandableSection } from '../common';
import { CheckCircle } from '@material-ui/icons';
import { withStyles } from '@material-ui/styles';
import { clientShow } from '../Clientshow/clientshow';
import config from 'config';


const styles = () => ({
    root: {
      fontSize: 14,
      color: '#333333',
      fontWeight: 700
    },
    formControl: {
        marginRight: 16,
    },
    dropDownStyle: {
        maxHeight: 234,
        maxWidth: 272,
    },
});

const types = [ "Audio", "Dubbed", "Subtitled"];
const initialState = {
    type: 'audio',
    subtitlesAvailable: 'yes',
    language: 'en',
}

class LanguageData extends Component {

    constructor(props) {
        super(props);
        let languageData = props.languageData || {};
        this.state = Object.assign({}, initialState, languageData)
    }

    componentDidMount() {
        this.props.handleChildChange('languageData', this.state);
    }

    handleChange = name => event => {
        this.setState({
            [name]: event.target.value,
        }, () => {
            this.props.handleChildChange('languageData', this.state);
        });
    }

    render() {
        let { classes } = this.props;
        return (
            <Grid container component={ExpandableSection} header="language data"
            defaultExpanded={true} focusErrorCssStyle="normal">
                <Grid container direction="column"
                className="data-pane" justify="space-evenly">
                    <Grid item container direction="row">
                        <Grid item>
                            <FormControl className={classes.formControl}>
                                <InputLabel shrink={false} className={classes.root}>Language</InputLabel>
                                <Select
                                value={this.state.language}
                                style={{ marginTop: 40, width: 272 }}
                                onChange={this.handleChange('language')}
                                MenuProps={{
                                    classes: { paper: classes.dropDownStyle },
                                    getContentAnchorEl: null,
                                    anchorOrigin: {
                                    vertical: "bottom",
                                    horizontal: "left",
                                    }
                                }}>
                                {config.languages.map((language, index) => (
                                        <MenuItem key={index} value={language.value}>
                                        {language.name}
                                        </MenuItem>
                                ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item>
                            <FormControl className={classes.formControl}>
                                <InputLabel shrink={false} className={classes.root}>Type</InputLabel>
                                <Select
                                value={this.state.type}
                                style={{ marginTop: 40, width: 124 }}
                                onChange={this.handleChange('type')}
                                MenuProps={{
                                    classes: { paper: classes.dropDownStyle },
                                    getContentAnchorEl: null,
                                    anchorOrigin: {
                                    vertical: "bottom",
                                    horizontal: "left",
                                    }
                                }}>
                                {types.map(type => (
                                    <MenuItem key={type} value={type.toLowerCase()}>
                                        {type}
                                    </MenuItem>
                                ))}
                                </Select>
                            </FormControl>
                        </Grid>
                    </Grid>
                    <Grid item>
                        <FormControl component="fieldset" style={{ marginTop: 22 }}>
                            <FormLabel
                            style={{ fontSize: 14, color: "#333333", fontWeight: 700 }}
                            component="label"
                            >
                            Subtitles Available
                            </FormLabel>
                            <RadioGroup style={{ display: "inline" }}
                            value={this.state.subtitlesAvailable}
                            onChange={this.handleChange('subtitlesAvailable')}
                            >
                            <FormControlLabel
                                value="yes"
                                control={<Radio checkedIcon={<CheckCircle />}/>}
                                label="Yes"
                            />
                            <FormControlLabel
                                value="no"
                                control={<Radio checkedIcon={<CheckCircle />}/>}
                                label="No"
                            />
                            </RadioGroup>
                        </FormControl>
                    </Grid>
                </Grid>
            </Grid>
        );
    }
}

const clientConnected = clientShow(LanguageData, 'LanguageData');
let styledLanguageData = withStyles(styles)(clientConnected);
export { styledLanguageData as LanguageData };