import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import { TitleField } from '../common';

const DbbTvSeriesFields = (props) => {
    let { presentedTitle, displayDate } = props;
    return (
        <Grid container>
            <TitleField label="Episode Name" field="episodeTitle"
                title={presentedTitle}
                warning="No Title found"
            />

            <TitleField label="Episode Number" field="episodeNumber"
                title={presentedTitle}
                warning="Episode number not found"
            />

            <TitleField label="Runtime" field="runtime"
                title={presentedTitle}
                warning="Runtime not found"
            />

            <TitleField label="Rating" field="rating"
                title={presentedTitle}
                warning="TV rating not found"
            />

            <TitleField label="Network" field="networks"
                title={presentedTitle}
                warning="Network not found"
            />

            <TitleField label="ISAN" field="isan"
                title={presentedTitle}
                warning="ISAN not found"
            />

            <TitleField label="Episode MPM" field="mpmWalkerId"
                title={presentedTitle}
                warning="Episode MPM not found"
            />

            <TitleField label="Initial Release Date" field="initialReleaseDate"
                title={presentedTitle}
                warning="Initial Release Date not found"
                formatFunc={displayDate}
            />

            <TitleField label="Release Year" field="releaseYear"
                title={presentedTitle}
                warning="Release Year not found"
            />

            <TitleField label="Color" field="color"
                title={presentedTitle}
            />

            <TitleField label="Copyright Line" field="copyrightLine"
                title={presentedTitle}
            />

            <TitleField label="Original Language" field="language"
                title={presentedTitle}
                warning="No Original Language found for this title"
            />

            <TitleField label="Genres" field="genres"
                title={presentedTitle}
                warning="No Genres found for this title"
            />

            <TitleField label="Actors" field="actors"
                subfield="name"
                title={presentedTitle}
                warning="No Actors found for this title"
            />

            <TitleField label="Director" field="directors"
                subfield="name"
                title={presentedTitle}
                warning="No Directors found for this title"
            />

            <TitleField label="Producers" field="producers"
                subfield="name"
                title={presentedTitle}
                warning="No Producers found for this title"
            />

            <TitleField label="Composer" field="composers"
                subfield="name"
                title={presentedTitle}
                warning="No Composer found for this title"
            />

            <TitleField label="Screenplay" field="screenplay"
                subfield="name"
                title={presentedTitle}
                warning="No Screenplay found for this title"
            />
            
            <TitleField label="Episode Synopsis(short)" field="episodeShortSynopsis"
                subfield="text"
                title={presentedTitle}
                warning="No Synopsis (short) found for this title"
            />

            <TitleField label="Episode Synopsis (long)" field="episodeLongSynopsis"
                subfield="text"
                title={presentedTitle}
                warning="No Synopsis (long) found for this title"
            />
            <Grid item>
                <Typography variant="h5" component="h5"
                className="season-data-header">SERIES/SEASON DATA</Typography>

                <TitleField label="Season Name" field="season"
                    subfield="name"
                    title={presentedTitle}
                    warning="Season Name not found for this title"
                />

                <TitleField label="Season Number" field="season"
                    subfield="seasonNumber"
                    title={presentedTitle}
                    warning="Season number not found for this title"
                />

                <TitleField label="Season MPM" field="season"
                    subfield="mpmWalkerId"
                    title={presentedTitle}
                    warning="Season MPM not found for this title"
                />

                <TitleField label="Season Synopsis" field="season"
                    subfield="synopsis"
                    title={presentedTitle}
                    warning="Season Synopsis not found for this title"
                />

                <TitleField label="Series Name" field="series"
                    subfield="name"
                    title={presentedTitle}
                    warning="Series Name not found for this title"
                />

                <TitleField label="Series MPM" field="series"
                    subfield="mpmWalkerId"
                    title={presentedTitle}
                    warning="Series MPM not found for this title"
                />

                <TitleField label="Series Synopsis" field="series"
                    subfield="synopsis"
                    title={presentedTitle}
                    warning="Series Synopsis not found for this title"
                />
            </Grid>
        </Grid>
    )
}

export { DbbTvSeriesFields };