import React, { Component } from "react";
import { connect } from 'react-redux';
import { Grid } from "@material-ui/core";
import { ExpandableSection } from '../common';
import { clientShow } from '../Clientshow/clientshow';
import { DirectTvTitlePane } from '../DirectTV';
import { DbbTitlePane } from '../Dbb';
import { MovielabsTitlePane } from '../MovielabsManifest';
import { RokuTitlePane } from '../Roku';
import { CablelabsTitlePane } from '../RokuCablelabs';
import { CmdgTitlePane } from '../CmdgInternational';
import { DbbBulkTitlePane } from '../DbbBulkIngest';
import { TitleService, UtilityService } from '../../_services';

class TitleData extends Component {
  state = {
    title: this.props.titles[0],
    language: 'en',
    titleWarning: false
  }

  componentDidMount() {
    this.fetchTitleData();
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.titles[0].externalId !== this.state.title.externalId) {
      this.setState({
        title: this.props.titles[0]
      }, () => {
        this.fetchTitleData();
      });
    }
  }

  fetchTitleData() {
    let title = this.state.title;
    let clientId = this.props.client.id;
    let language = this.props.language;
    let territory = this.props.territory;

    if (!title) {
      console.error('we dont have a title!!!!')
      return;
    }

    TitleService.getTitleForClient(clientId, title.externalId, { language, territory }).then((resp) => {
      this.setState({
        title: Object.assign({}, title, resp.data.presentedTitle, { externalId: title.externalId}),
      });
    });
  }

  checkCurrentClient = () => {
    let { client } = this.props;
    return UtilityService.usesCmdgTitlePane(client);
  }

  handleLanguageChange = (territory = null, language) => {
    let title = this.state.title;
    let clientId = this.props.client.id;

    this.setState({
      language,
    }, () => {
      TitleService.getTitleForClient(clientId, title.externalId, {language, territory }).then((resp) => {
        this.setState({
          title: Object.assign({}, title, resp.data.presentedTitle, { externalId: title.externalId}),
        });
      });
    });
  }

  render() {
    let { title } = this.state;
    let hasErrors = this.props.hasErrors;
    let hasWarnings = this.props.warning;
    let errorsOn = this.props.errorsEnabled;
    let errorsFocusClass = errorsOn && this.props.focusErrorHandler(hasErrors, hasWarnings);
    let defaultFocusClass = errorsFocusClass ? errorsFocusClass : 'normal';
    return (
        <React.Fragment>
          {
            this.checkCurrentClient() ?
            (
              <Grid item component={CmdgTitlePane}
                {...this.props}
                presentedTitle={title}
                titles={this.props.titles}
                client={this.props.client}
                language={this.props.language}
                territory={this.props.territory}
                locales={this.props.locales}
                selectedTerritories={this.props.selectedTerritories}
              >
                {
                  this.props.children
                }
              </Grid>
            )
              :
            (
              <Grid container component={ExpandableSection} header="title data"
              testId="title-data" defaultExpanded={true} focusErrorCssStyle={defaultFocusClass}
              renderButtons={this.props.renderButtons}
              >
                <Grid
                  container
                  direction="column"
                  justify="space-between"
                  className="data-pane">
                  <Grid item component={DirectTvTitlePane}
                    { ...this.props }
                    presentedTitle={title}
                    language={this.props.language}
                    client={this.props.client}
                    warning={this.props.warning}
                    onLanguageChange={this.props.handleLanguageChange}
                    handleTitleWarning={this.props.handleTitleWarning}
                  />
                  <Grid item component={DbbBulkTitlePane}
                    {...this.props}
                    client={this.props.client}
                    presentedTitle={title}
                  />
                  <Grid item component={DbbTitlePane}
                    { ...this.props }
                    presentedTitle={title}
                    client={this.props.client}
                    warning={this.props.warning}
                    handleTitleWarning={this.props.handleTitleWarning}
                  />
                  <Grid item component={MovielabsTitlePane}
                      { ...this.props }
                      presentedTitle={title}
                      client={this.props.client}
                      warning={this.props.warning}
                      handleTitleWarning={this.props.handleTitleWarning}
                  />
                  <Grid item component={RokuTitlePane}
                      { ...this.props }
                      presentedTitle={title}
                      client={this.props.client}
                      warning={this.props.warning}
                      handleTitleWarning={this.props.handleTitleWarning}
                  />
                  <Grid item component={CablelabsTitlePane}
                    { ...this.props }
                    presentedTitle={title}
                    client={this.props.client}
                    warning={this.props.warning}
                    handleTitleWarning={this.props.handleTitleWarning}
                  />

                  {
                    this.props.children
                  }
                </Grid>
            </Grid>)
          }
          </React.Fragment>
    );
  }
}

let mapStateToProps = (state) => {
  let { titles, clients } = state;
  return {
    titles: titles.titles,
    client: clients.client,
  };
}
const connected = connect(mapStateToProps, null)(TitleData);
const clientConnected = clientShow(connected, 'TitleData');
export { clientConnected as TitleData }
