import { titleConstants } from "../_constants";

export const TitleActions = {
  selectTitle: (title, index) => ({
    type: titleConstants.TITLE_SELECTED,
    data: {
      title,
      index,
    },
  }),

  removeTitle: (index) => ({
    type: titleConstants.TITLE_REMOVED,
    data: {
      index,
    },
  }),

  addTitleEntry: () => ({
    type: titleConstants.TITLE_ENTRY_ADDED,
  }),

  addTitles: (titles) => ({
    type: titleConstants.TITLES_SELECTED,
    data: {
      titles,
    },
  }),

  reset: () => ({
    type: titleConstants.RESET,
  }),
};
