import React, { useState } from 'react';
import {
  ButtonBase,
  Fab,
  Grid,
  IconButton,
  Modal,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography
} from '@material-ui/core';
import { withStyles } from "@material-ui/core/styles";
import SvgCloseX from "../../assets/icons/CloseX";

const getModalStyle = () => {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
    alignItems: "stretch",
    borderRadius: 16,
    backgroundColor: '#f5f5f5',
    overflow: 'auto',
  };
}

const styles = theme => ({
  paper: {
    position: "absolute",
    width: 704,
    height: 590,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: '8px 16px 8px 16px',
    outline: "none",
  }
});

const ReleaseDatesModal = (props) => {
  let { classes } = props;
  return (<Modal
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
          open={props.open}
          onClose={props.close}
          className="title-modal"
        >
          <Grid container direction="column" justify="flex-start" wrap="nowrap" style={getModalStyle()} className={classes.paper}>
            <Grid item style={{ alignSelf: 'flex-end' }}>
              <IconButton onClick={props.close}>
                <SvgCloseX style={{ height: 15, width: 16, color: '#333333' }}/>
              </IconButton>
            </Grid>
            <Grid item style={{ height: "fit-content" }}>
              <ReleasingTable {...props} />
            </Grid>
            <Grid item style={{ alignSelf: 'center', marginTop: 40 }}>
              <Fab variant="extended" color="primary" style={{ height: 40, width: 168 }}
              onClick={props.close}>Close</Fab>
            </Grid>
          </Grid>
        </Modal>
  )
}

const StyledReleaseDatesModal = withStyles(styles)(ReleaseDatesModal);

const ReleasingTable = (props) => {
  let columns = [
    {field: 'media', headerName: 'Media', width: 120 },
    {field: 'event', headerName: 'Event', width: 120 },
    {field: 'language', headerName: 'Language', width: 120 },
    {field: 'releaseDate', headerName: 'Release Date', width: 120 },
  ];

  let rows = props.releaseDates.map(release => {
    let { media, event, language, releaseDate, source } = release;
    return {
      media,
      event,
      language,
      releaseDate,
      source,
    };
  });

  return (
    <Table aria-label="simple table">
      <TableHead>
        <TableRow>
          {columns.map((c) => (
            <TableCell align="right" key={c.field}>{c.headerName}</TableCell>
          ))}
        </TableRow>
      </TableHead>
      <TableBody>
        {rows.map((row, index) => (
          <TableRow key={`${row.media}-${row.event}-${index}`}>
            <TableCell component="th" scope="row">
              {row.media}
            </TableCell>
            <TableCell align="right">{row.event}</TableCell>
            <TableCell align="right">{row.language}</TableCell>
            <TableCell align="right">{row.releaseDate}</TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  )
}

export const ReleaseDateField = (props) => {
  let [showModal, setShowModal] = useState(false);

  let ShowReleaseDatesButton = () => (<ButtonBase className='sidecar-link-button' onClick={ () => {setShowModal(true) }}>Click to show release dates.</ButtonBase>);
  let showReleaseDates = (props.releaseDates && props.releaseDates.length);
  let label = 'Release Dates';
  let warning = 'No Release Dates Found.';
  let warningCssClass = showReleaseDates ? '' : 'no-data-found';

  return (
    <React.Fragment>
      <Grid container direction="row" style={{
        margin: '4px auto'
    }}>
        <Grid item style={{
            fontWeight: 700,
            flexBasis: 160,
        }}>
            <Typography style={{ color: "#333333" }}>{label}:</Typography>
        </Grid>
        <Grid item component={Typography} className={warningCssClass} style={{
            maxWidth: '50%'
        }}>{showReleaseDates ? <ShowReleaseDatesButton /> : warning}</Grid>
    </Grid>

      <StyledReleaseDatesModal {...props} open={showModal} close={() => {setShowModal(false)}}/>
    </React.Fragment>
  )
}