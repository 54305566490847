import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Grid, Fab, Typography } from '@material-ui/core';
import { ProfileService } from '../_services';
import { ConfirmationDialog } from '../components/common';
import { TitleProfileModal } from './';
import _ from 'lodash';

const edit = 'EDIT';
const test = 'TEST';
const deleteText = 'DELETE';

const testClient = (title, clientProfile, setTitleProfileModal, setPriorityData) => {
  let titleId = title.externalId || '';
  if(titleId) {
      ProfileService.test({
      titleId,
      prioritySet: clientProfile,
    }).then((resp) => {
      setTitleProfileModal(true);
      setPriorityData(resp.data);
    }).catch(err => {
      console.log(err);
    }) 
  }
};

const sayYes = (clientname, onClientDelete = null, setShowModal) => {
  ProfileService.delete(clientname).then(() => {
    setTimeout(() => { //delete happens but search index may not reflect it yet
      setShowModal(false);
      onClientDelete && onClientDelete(clientname);
    }, 450);
  });
}

const sayNo = (setShowModal) => {
  setShowModal(false);
}

const deleteClient = (setShowModal) => {
  setShowModal(true);
};

const closeTitleProfileModal = (setTitleProfileModal) => {
  setTitleProfileModal(false);
  console.log('closed!!!');
}

export const ClientListItem = ({client, onClientDelete, title}) => {

  let [showModal, setShowModal] = useState(false);
  let [showTitleProfileModal, setTitleProfileModal] = useState(false);
  let [priorityData, setPriorityData] = useState([]);
  let isTitleEmpty = _.isEmpty(title);

  return (
    <Grid container component={'li'} className='client-list-item'
    direction="row" alignItems="center" justify="space-between">
      <Grid item>
        <Typography variant="h6" component="h6" className='client-name'>{client.name}</Typography>
      </Grid>

      <Grid item>
        <Grid container justify="space-evenly">
          <Grid item component={Link} to={{pathname: '/profile-edit', state: { id: client.name }}}>
            <Fab size="small" variant="extended" className="sidecar-button" color="secondary">{edit}</Fab>
          </Grid>
          <Grid item component={Fab} size="small" variant="extended" className="sidecar-button" color="secondary" 
          disabled={isTitleEmpty}
          onClick={e => { testClient(title, client, setTitleProfileModal, setPriorityData) }}>{test}</Grid>
          <Grid item component={Fab} size="small" variant="extended" className="sidecar-button" color="secondary"
            onClick={(e) => deleteClient(setShowModal)}>
            {deleteText}
          </Grid>
        </Grid>
      </Grid>

      <React.Fragment>
          <ConfirmationDialog
            open={showModal}
            onClose={sayNo}
            dialogText="Are you sure you want to delete this profile ?"
            acceptButtonHandler={e => sayYes(client.name, onClientDelete, setShowModal)}
            cancelButtonHandler={e => sayNo(setShowModal)}
          />
      </React.Fragment>

      <React.Fragment>
          {
            !_.isEmpty(priorityData) ? (
              <TitleProfileModal 
                open={showTitleProfileModal}
                priorityData={priorityData}
                close={e => closeTitleProfileModal(setTitleProfileModal)}
              />
            ): null
          }
      </React.Fragment>
    </Grid>
  )
}