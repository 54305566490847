import React from "react";

const SvgCloseX = props => (
  <svg viewBox="0 0 16 16" {...props} width="1em" height="1em">
    <path
      d="M9.8 8l6.1 6.1c.2.2.2.5 0 .7l-1.1 1.1c-.2.2-.5.2-.7 0L8 9.8l-6.1 6.1c-.2.2-.5.2-.7 0L.1 14.8c-.2-.2-.2-.5 0-.7L6.2 8 .1 1.9c-.1-.2-.1-.5 0-.7L1.2.1c.2-.1.5-.1.7 0L8 6.2 14.1.1c.2-.2.5-.2.7 0l1.1 1.1c.2.2.2.5 0 .7L9.8 8z"
      fill="currentColor"
    />
  </svg>
);

export default SvgCloseX;
