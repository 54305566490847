import * as React from "react";

function SvgClapboard(props) {
  return (
    <svg viewBox="0 0 36 41" width="1em" height="1em" {...props}>
      <path
        d="M3 6.3l5.6 7.2L.7 15H35c.3 0 .5.2.5.5v24c0 .3-.2.5-.5.5H1c-.2 0-.4-.2-.5-.4V7.9c-.1-.5.2-1 .7-1.2h.1L3 6.3zm10.1-2.1l5.6 7.2-5 1-5.5-7.3 4.9-.9zM23.7 2l5.6 7.2-5.4 1.1L18.4 3l5.3-1zM34.1.7v.1l1.3 6c.1.5-.2 1-.7 1.2H34.4L29 .8l3.9-.8c.5-.1 1.1.2 1.2.7z"
        fillRule="evenodd"
        clipRule="evenodd"
        fill="currentColor"
      />
    </svg>
  );
}

export default SvgClapboard;

