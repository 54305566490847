import React from 'react';
import _ from 'lodash';
import { Fab, Grid, Typography } from '@material-ui/core';

export class FileAdd extends React.Component {
    constructor(props) {
        super(props);
        this.fileInput = React.createRef();
    }

    handleClick() {
        this.fileInput.current.click();
    }

    render() {
        let fileCount = (this.props.files && this.props.files.length) ? this.props.files.length : 0;

        return (
            <Grid container item className="add-files" alignItems="center" justify="space-between">
                <Grid item>
                  <Typography variant="h4" component="h4">{fileCount} files added.</Typography>
                </Grid>
                <Grid item>
                  <Fab onClick={() => (this.handleClick())} className="add-files-button"
                      variant="extended" color="secondary" >
                    <input className="file-input"
                        onChange={(e) => (this.props.selectFiles(e.target.files))}
                        onClick={(e) => (e.target.value = null)}
                        ref={this.fileInput}
                        type="file"
                        accept=".xml, .md5"
                        multiple={true} />
                    <span>Add Files</span>
                  </Fab>
                </Grid>
            </Grid>
         );
    }
}