export const profiles = [
  {
    encodingProfile: "Archival ProRes 3D Left HD 24p",
    type: "Archival",
    type3d: "Left Eye",
    screenAspectRatio: "16x9",
    frameRate: "23.976",
    videoStandard: "1080_24p",
    horizontalResolution: "1920",
    verticalResolution: "1080",
    scanningFormat: "Progressive",
    chromaSubsampling: "4:2:2",
    colourPrimaries: null,
    videoFormat: "ProRes",
    videoColorSpace: "YUV",
  },
  {
    encodingProfile: "Archival ProRes 3D LR HD 24p",
    type: "Archival",
    type3d: "Left/Right Eye",
    screenAspectRatio: "16x9",
    frameRate: "23.976",
    videoStandard: "1080_24p",
    horizontalResolution: "1920",
    verticalResolution: "1080",
    scanningFormat: "Progressive",
    chromaSubsampling: "4:2:2",
    colourPrimaries: null,
    videoFormat: "ProRes",
    videoColorSpace: "YUV",
  },
  {
    encodingProfile: "Archival ProRes 3D OverUnder HD",
    type: "Archival",
    type3d: "Over Under",
    screenAspectRatio: "16x9",
    frameRate: "23.976",
    videoStandard: "1080_24p",
    horizontalResolution: "1920",
    verticalResolution: "1080",
    scanningFormat: "Progressive",
    chromaSubsampling: "4:2:2",
    colourPrimaries: null,
    videoFormat: "ProRes",
    videoColorSpace: "YUV",
  },
  {
    encodingProfile: "Archival ProRes 3D Right HD 24p",
    type: "Archival",
    type3d: "Right Eye",
    screenAspectRatio: "16x9",
    frameRate: "23.976",
    videoStandard: "1080_24p",
    horizontalResolution: "1920",
    verticalResolution: "1080",
    scanningFormat: "Progressive",
    chromaSubsampling: "4:2:2",
    colourPrimaries: null,
    videoFormat: "ProRes",
    videoColorSpace: "YUV",
  },
  {
    encodingProfile: "Archival ProRes 3D SideBySide HD 24p",
    type: "Archival",
    type3d: "Side By Side",
    screenAspectRatio: "16x9",
    frameRate: "23.976",
    videoStandard: "1080_24p",
    horizontalResolution: "1920",
    verticalResolution: "1080",
    scanningFormat: "Progressive",
    chromaSubsampling: "4:2:2",
    colourPrimaries: null,
    videoFormat: "ProRes",
    videoColorSpace: "YUV",
  },
  {
    encodingProfile: "Archival ProRes HD 24p",
    type: "Archival",
    type3d: "N/A",
    screenAspectRatio: "16x9",
    frameRate: "23.976",
    videoStandard: "1080_24p",
    horizontalResolution: "1920",
    verticalResolution: "1080",
    scanningFormat: "Progressive",
    chromaSubsampling: "4:2:2",
    colourPrimaries: null,
    videoFormat: "ProRes",
    videoColorSpace: "YUV",
  },
  {
    encodingProfile: "DubCard ProRes HD 24p",
    type: "DubCard",
    type3d: "N/A",
    screenAspectRatio: "16x9",
    frameRate: "23.976",
    videoStandard: "1080_24p",
    horizontalResolution: "1920",
    verticalResolution: "1080",
    scanningFormat: "Progressive",
    chromaSubsampling: "4:2:2",
    colourPrimaries: null,
    videoFormat: "ProRes",
    videoColorSpace: "YUV",
  },
  {
    encodingProfile: "J2K 1920 x 1080 23.976p",
    type: "Archival",
    type3d: "N/A",
    screenAspectRatio: "16x9",
    frameRate: "23.976",
    videoStandard: "1080_24p",
    horizontalResolution: "1920",
    verticalResolution: "1080",
    scanningFormat: "Progressive",
    chromaSubsampling: "4:2:2",
    colourPrimaries: null,
    videoFormat: "JPEG 2000",
    videoColorSpace: "YUV",
  },
  {
    encodingProfile: "P2P ProRes 3D Left HD 24p",
    type: "P2P",
    type3d: "Left Eye",
    screenAspectRatio: "16x9",
    frameRate: "23.976",
    videoStandard: "1080_24p",
    horizontalResolution: "1920",
    verticalResolution: "1080",
    scanningFormat: "Progressive",
    chromaSubsampling: "4:2:2",
    colourPrimaries: null,
    videoFormat: "ProRes",
    videoColorSpace: "YUV",
  },
  {
    encodingProfile: "P2P ProRes 3D OverUnder HD",
    type: "P2P",
    type3d: "Over Under",
    screenAspectRatio: "16x9",
    frameRate: "23.976",
    videoStandard: "1080_24p",
    horizontalResolution: "1920",
    verticalResolution: "1080",
    scanningFormat: "Progressive",
    chromaSubsampling: "4:2:2",
    colourPrimaries: null,
    videoFormat: "ProRes",
    videoColorSpace: "YUV",
  },
  {
    encodingProfile: "P2P ProRes 3D Right HD 24p",
    type: "P2P",
    type3d: "Right Eye",
    screenAspectRatio: "16x9",
    frameRate: "23.976",
    videoStandard: "1080_24p",
    horizontalResolution: "1920",
    verticalResolution: "1080",
    scanningFormat: "Progressive",
    chromaSubsampling: "4:2:2",
    colourPrimaries: null,
    videoFormat: "ProRes",
    videoColorSpace: "YUV",
  },
  {
    encodingProfile: "P2P ProRes 3D SideBySide HD 24p",
    type: "P2P",
    type3d: "Side By Side",
    screenAspectRatio: "16x9",
    frameRate: "23.976",
    videoStandard: "1080_24p",
    horizontalResolution: "1920",
    verticalResolution: "1080",
    scanningFormat: "Progressive",
    chromaSubsampling: "4:2:2",
    colourPrimaries: null,
    videoFormat: "ProRes",
    videoColorSpace: "YUV",
  },
  {
    encodingProfile: "ProRes 3D LR HD 24p",
    type: "P2P",
    type3d: "Left/Right Eye",
    screenAspectRatio: "16x9",
    frameRate: "23.976",
    videoStandard: "1080_24p",
    horizontalResolution: "1920",
    verticalResolution: "1080",
    scanningFormat: "Progressive",
    chromaSubsampling: "4:2:2",
    colourPrimaries: null,
    videoFormat: "ProRes",
    videoColorSpace: "YUV",
  },
  {
    encodingProfile: "ProRes HD 24p",
    type: "P2P",
    type3d: "N/A",
    screenAspectRatio: "16x9",
    frameRate: "23.976",
    videoStandard: "1080_24p",
    horizontalResolution: "1920",
    verticalResolution: "1080",
    scanningFormat: "Progressive",
    chromaSubsampling: "4:2:2",
    colourPrimaries: null,
    videoFormat: "ProRes",
    videoColorSpace: "YUV",
  },
  {
    encodingProfile: "Slate ProRes HD 24p",
    type: "Slate",
    type3d: "N/A",
    screenAspectRatio: "16x9",
    frameRate: "23.976",
    videoStandard: "1080_24p",
    horizontalResolution: "1920",
    verticalResolution: "1080",
    scanningFormat: "Progressive",
    chromaSubsampling: "4:2:2",
    colourPrimaries: null,
    videoFormat: "ProRes",
    videoColorSpace: "YUV",
  },
  {
    encodingProfile: "Textless ProRes HD 24p",
    type: "Textless",
    type3d: "N/A",
    screenAspectRatio: "16x9",
    frameRate: "23.976",
    videoStandard: "1080_24p",
    horizontalResolution: "1920",
    verticalResolution: "1080",
    scanningFormat: "Progressive",
    chromaSubsampling: "4:2:2",
    colourPrimaries: null,
    videoFormat: "ProRes",
    videoColorSpace: "YUV",
  },
  {
    encodingProfile: "Archival 4K UHD 23.976p",
    type: "Archival",
    type3d: "N/A",
    screenAspectRatio: "16x9",
    frameRate: "23.976",
    videoStandard: "2160_24p",
    horizontalResolution: "3840",
    verticalResolution: "2160",
    scanningFormat: "Progressive",
    chromaSubsampling: "4:2:2",
    colourPrimaries: "BT.2020",
    videoFormat: "ProRes",
    videoColorSpace: "YUV",
  },
  {
    encodingProfile: "Archival UHD MOV 422 Rec709 23.976p",
    type: "Archival",
    type3d: "N/A",
    screenAspectRatio: "16x9",
    frameRate: "23.976",
    videoStandard: "2160_24p",
    horizontalResolution: "3840",
    verticalResolution: "2160",
    scanningFormat: "Progressive",
    chromaSubsampling: "4:2:2",
    colourPrimaries: null,
    videoFormat: "ProRes",
    videoColorSpace: "YUV",
  },
  {
    encodingProfile: "Archival UHD MOV 444 Rec2020 23.976p",
    type: "Archival",
    type3d: "N/A",
    screenAspectRatio: "16x9",
    frameRate: "23.976",
    videoStandard: "2160_24p",
    horizontalResolution: "3840",
    verticalResolution: "2160",
    scanningFormat: "Progressive",
    chromaSubsampling: "4:4:4",
    colourPrimaries: "BT.2020",
    videoFormat: "ProRes",
    videoColorSpace: "YUV",
  },
  {
    encodingProfile: "Archival UHD MOV 444 Rec709 23.976p",
    type: "Archival",
    type3d: "N/A",
    screenAspectRatio: "16x9",
    frameRate: "23.976",
    videoStandard: "2160_24p",
    horizontalResolution: "3840",
    verticalResolution: "2160",
    scanningFormat: "Progressive",
    chromaSubsampling: "4:4:4",
    colourPrimaries: null,
    videoFormat: "ProRes",
    videoColorSpace: "YUV",
  },
  {
    encodingProfile: "DubCard UHD MOV 422 Rec709 23.976p",
    type: "DubCard",
    type3d: "N/A",
    screenAspectRatio: "16x9",
    frameRate: "23.976",
    videoStandard: "2160_24p",
    horizontalResolution: "3840",
    verticalResolution: "2160",
    scanningFormat: "Progressive",
    chromaSubsampling: "4:2:2",
    colourPrimaries: null,
    videoFormat: "ProRes",
    videoColorSpace: "YUV",
  },
  {
    encodingProfile: "DubCard UHD MOV 444 Rec2020 23.976p",
    type: "DubCard",
    type3d: "N/A",
    screenAspectRatio: "16x9",
    frameRate: "23.976",
    videoStandard: "2160_24p",
    horizontalResolution: "3840",
    verticalResolution: "2160",
    scanningFormat: "Progressive",
    chromaSubsampling: "4:4:4",
    colourPrimaries: null,
    videoFormat: "ProRes",
    videoColorSpace: "YUV",
  },
  {
    encodingProfile: "IMF J2K MXF HDR",
    type: "P2P",
    type3d: "N/A",
    screenAspectRatio: "16x9",
    frameRate: "23.976",
    videoStandard: "2160_24p",
    horizontalResolution: "3840",
    verticalResolution: "2160",
    scanningFormat: "Progressive",
    chromaSubsampling: "4:4:4",
    colourPrimaries: "BT.2020",
    videoFormat: "JPEG 2000",
    videoColorSpace: "RGB",
  },
  {
    encodingProfile: "IMF J2K MXF SDR",
    type: "P2P",
    type3d: "N/A",
    screenAspectRatio: "16x9",
    frameRate: "23.976",
    videoStandard: "2160_24p",
    horizontalResolution: "3840",
    verticalResolution: "2160",
    scanningFormat: "Progressive",
    chromaSubsampling: "4:2:2",
    colourPrimaries: null,
    videoFormat: "JPEG 2000",
    videoColorSpace: "YUV",
  },
  {
    encodingProfile: "P2P UHD MOV 422 Rec709 23.976p",
    type: "P2P",
    type3d: "N/A",
    screenAspectRatio: "16x9",
    frameRate: "23.976",
    videoStandard: "2160_24p",
    horizontalResolution: "3840",
    verticalResolution: "2160",
    scanningFormat: "Progressive",
    chromaSubsampling: "4:2:2",
    colourPrimaries: null,
    videoFormat: "ProRes",
    videoColorSpace: "YUV",
  },
  {
    encodingProfile: "P2P UHD MOV 444 Rec2020 23.976p",
    type: "P2P",
    type3d: "N/A",
    screenAspectRatio: "16x9",
    frameRate: "23.976",
    videoStandard: "2160_24p",
    horizontalResolution: "3840",
    verticalResolution: "2160",
    scanningFormat: "Progressive",
    chromaSubsampling: "4:4:4",
    colourPrimaries: "BT.2020",
    videoFormat: "ProRes",
    videoColorSpace: "YUV",
  },
  {
    encodingProfile: "P2P UHD MOV 444 Rec709 23.976p",
    type: "P2P",
    type3d: "N/A",
    screenAspectRatio: "16x9",
    frameRate: "23.976",
    videoStandard: "2160_24p",
    horizontalResolution: "3840",
    verticalResolution: "2160",
    scanningFormat: "Progressive",
    chromaSubsampling: "4:4:4",
    colourPrimaries: null,
    videoFormat: "ProRes",
    videoColorSpace: "YUV",
  },
  {
    encodingProfile: "Textless UHD MOV 422 Rec709 23.976p",
    type: "Textless",
    type3d: "N/A",
    screenAspectRatio: "16x9",
    frameRate: "23.976",
    videoStandard: "2160_24p",
    horizontalResolution: "3840",
    verticalResolution: "2160",
    scanningFormat: "Progressive",
    chromaSubsampling: "4:2:2",
    colourPrimaries: null,
    videoFormat: "ProRes",
    videoColorSpace: "YUV",
  },
  {
    encodingProfile: "Textless UHD MOV 444 Rec2020 23.976p",
    type: "Textless",
    type3d: "N/A",
    screenAspectRatio: "16x9",
    frameRate: "23.976",
    videoStandard: "2160_24p",
    horizontalResolution: "3840",
    verticalResolution: "2160",
    scanningFormat: "Progressive",
    chromaSubsampling: "4:4:4",
    colourPrimaries: "BT.2020",
    videoFormat: "ProRes",
    videoColorSpace: "YUV",
  },
  {
    encodingProfile: "Archival ProRes PAL 25i 16x9",
    type: "Archival",
    type3d: "N/A",
    screenAspectRatio: "16x9",
    frameRate: "25.00",
    videoStandard: "PAL",
    horizontalResolution: "720",
    verticalResolution: "576",
    scanningFormat: "Interlaced",
    chromaSubsampling: "4:2:2",
    colourPrimaries: null,
    videoFormat: "ProRes",
    videoColorSpace: "YUV",
  },
  {
    encodingProfile: "Archival ProRes PAL 25i 4x3",
    type: "Archival",
    type3d: "N/A",
    screenAspectRatio: "4x3",
    frameRate: "25.00",
    videoStandard: "PAL",
    horizontalResolution: "720",
    verticalResolution: "576",
    scanningFormat: "Interlaced",
    chromaSubsampling: "4:2:2",
    colourPrimaries: null,
    videoFormat: "ProRes",
    videoColorSpace: "YUV",
  },
  {
    encodingProfile: "DubCard ProRes PAL 25i 16x9",
    type: "DubCard",
    type3d: "N/A",
    screenAspectRatio: "16x9",
    frameRate: "25.00",
    videoStandard: "PAL",
    horizontalResolution: "720",
    verticalResolution: "576",
    scanningFormat: "Interlaced",
    chromaSubsampling: "4:2:2",
    colourPrimaries: null,
    videoFormat: "ProRes",
    videoColorSpace: "YUV",
  },
  {
    encodingProfile: "DubCard ProRes PAL 25i 4x3",
    type: "DubCard",
    type3d: "N/A",
    screenAspectRatio: "4x3",
    frameRate: "25.00",
    videoStandard: "PAL",
    horizontalResolution: "720",
    verticalResolution: "576",
    scanningFormat: "Interlaced",
    chromaSubsampling: "4:2:2",
    colourPrimaries: null,
    videoFormat: "ProRes",
    videoColorSpace: "YUV",
  },
  {
    encodingProfile: "J2K PAL 16x9",
    type: "Archival",
    type3d: "N/A",
    screenAspectRatio: "16x9",
    frameRate: "25.00",
    videoStandard: "PAL",
    horizontalResolution: "720",
    verticalResolution: "576",
    scanningFormat: "Interlaced",
    chromaSubsampling: "4:2:2",
    colourPrimaries: null,
    videoFormat: "JPEG 2000",
    videoColorSpace: "YUV",
  },
  {
    encodingProfile: "J2K PAL 4x3",
    type: "Archival",
    type3d: "N/A",
    screenAspectRatio: "4x3",
    frameRate: "25.00",
    videoStandard: "PAL",
    horizontalResolution: "720",
    verticalResolution: "576",
    scanningFormat: "Interlaced",
    chromaSubsampling: "4:2:2",
    colourPrimaries: null,
    videoFormat: "JPEG 2000",
    videoColorSpace: "YUV",
  },
  {
    encodingProfile: "ProRes PAL 25i 16x9",
    type: "P2P",
    type3d: "N/A",
    screenAspectRatio: "16x9",
    frameRate: "25.00",
    videoStandard: "PAL",
    horizontalResolution: "720",
    verticalResolution: "576",
    scanningFormat: "Interlaced",
    chromaSubsampling: "4:2:2",
    colourPrimaries: null,
    videoFormat: "ProRes",
    videoColorSpace: "YUV",
  },
  {
    encodingProfile: "ProRes PAL 25i 4x3",
    type: "P2P",
    type3d: "N/A",
    screenAspectRatio: "4x3",
    frameRate: "25.00",
    videoStandard: "PAL",
    horizontalResolution: "720",
    verticalResolution: "576",
    scanningFormat: "Interlaced",
    chromaSubsampling: "4:2:2",
    colourPrimaries: null,
    videoFormat: "ProRes",
    videoColorSpace: "YUV",
  },
  {
    encodingProfile: "Slate ProRes PAL 25i 16x9",
    type: "Slate",
    type3d: "N/A",
    screenAspectRatio: "16x9",
    frameRate: "25.00",
    videoStandard: "PAL",
    horizontalResolution: "720",
    verticalResolution: "576",
    scanningFormat: "Interlaced",
    chromaSubsampling: "4:2:2",
    colourPrimaries: null,
    videoFormat: "ProRes",
    videoColorSpace: "YUV",
  },
  {
    encodingProfile: "Slate ProRes PAL 25i 4x3",
    type: "Slate",
    type3d: "N/A",
    screenAspectRatio: "4x3",
    frameRate: "25.00",
    videoStandard: "PAL",
    horizontalResolution: "720",
    verticalResolution: "576",
    scanningFormat: "Interlaced",
    chromaSubsampling: "4:2:2",
    colourPrimaries: null,
    videoFormat: "ProRes",
    videoColorSpace: "YUV",
  },
  {
    encodingProfile: "Textless ProRes PAL 25i 16x9",
    type: "Textless",
    type3d: "N/A",
    screenAspectRatio: "16x9",
    frameRate: "25.00",
    videoStandard: "PAL",
    horizontalResolution: "720",
    verticalResolution: "576",
    scanningFormat: "Interlaced",
    chromaSubsampling: "4:2:2",
    colourPrimaries: null,
    videoFormat: "ProRes",
    videoColorSpace: "YUV",
  },
  {
    encodingProfile: "Textless ProRes PAL 25i 4x3",
    type: "Textless",
    type3d: "N/A",
    screenAspectRatio: "4x3",
    frameRate: "25.00",
    videoStandard: "PAL",
    horizontalResolution: "720",
    verticalResolution: "576",
    scanningFormat: "Interlaced",
    chromaSubsampling: "4:2:2",
    colourPrimaries: null,
    videoFormat: "ProRes",
    videoColorSpace: "YUV",
  },
  {
    encodingProfile: "Archival ProRes 3D SideBySide HD 50i",
    type: "Archival",
    type3d: "Side By Side",
    screenAspectRatio: "16x9",
    frameRate: "25.00",
    videoStandard: "1080_50i",
    horizontalResolution: "1920",
    verticalResolution: "1080",
    scanningFormat: "Interlaced",
    chromaSubsampling: "4:2:2",
    colourPrimaries: null,
    videoFormat: "ProRes",
    videoColorSpace: "YUV",
  },
  {
    encodingProfile: "Archival ProRes HD 25i",
    type: "Archival",
    type3d: "N/A",
    screenAspectRatio: "16x9",
    frameRate: "25.00",
    videoStandard: "1080_50i",
    horizontalResolution: "1920",
    verticalResolution: "1080",
    scanningFormat: "Interlaced",
    chromaSubsampling: "4:2:2",
    colourPrimaries: null,
    videoFormat: "ProRes",
    videoColorSpace: "YUV",
  },
  {
    encodingProfile: "DubCard ProRes HD 25i",
    type: "DubCard",
    type3d: "N/A",
    screenAspectRatio: "16x9",
    frameRate: "25.00",
    videoStandard: "1080_50i",
    horizontalResolution: "1920",
    verticalResolution: "1080",
    scanningFormat: "Interlaced",
    chromaSubsampling: "4:2:2",
    colourPrimaries: null,
    videoFormat: "ProRes",
    videoColorSpace: "YUV",
  },
  {
    encodingProfile: "J2K 1920 x 1080 25i",
    type: "Archival",
    type3d: "N/A",
    screenAspectRatio: "16x9",
    frameRate: "25.00",
    videoStandard: "1080_50i",
    horizontalResolution: "1920",
    verticalResolution: "1080",
    scanningFormat: "Interlaced",
    chromaSubsampling: "4:2:2",
    colourPrimaries: null,
    videoFormat: "JPEG 2000",
    videoColorSpace: "YUV",
  },
  {
    encodingProfile: "P2P ProRes 3D SideBySide HD 50i",
    type: "P2P",
    type3d: "Side By Side",
    screenAspectRatio: "16x9",
    frameRate: "25.00",
    videoStandard: "1080_50i",
    horizontalResolution: "1920",
    verticalResolution: "1080",
    scanningFormat: "Interlaced",
    chromaSubsampling: "4:2:2",
    colourPrimaries: null,
    videoFormat: "ProRes",
    videoColorSpace: "YUV",
  },
  {
    encodingProfile: "P2P ProRes HD 25i",
    type: "P2P",
    type3d: "N/A",
    screenAspectRatio: "16x9",
    frameRate: "25.00",
    videoStandard: "1080_50i",
    horizontalResolution: "1920",
    verticalResolution: "1080",
    scanningFormat: "Interlaced",
    chromaSubsampling: "4:2:2",
    colourPrimaries: null,
    videoFormat: "ProRes",
    videoColorSpace: "YUV",
  },
  {
    encodingProfile: "Archival ProRes PAL 25p 16x9",
    type: "Archival",
    type3d: "N/A",
    screenAspectRatio: "16x9",
    frameRate: "25.00",
    videoStandard: "Progressive PAL",
    horizontalResolution: "720",
    verticalResolution: "576",
    scanningFormat: "Progressive",
    chromaSubsampling: "4:2:2",
    colourPrimaries: null,
    videoFormat: "ProRes",
    videoColorSpace: "YUV",
  },
  {
    encodingProfile: "Archival ProRes PAL 25p 4x3",
    type: "Archival",
    type3d: "N/A",
    screenAspectRatio: "4x3",
    frameRate: "25.00",
    videoStandard: "Progressive PAL",
    horizontalResolution: "720",
    verticalResolution: "576",
    scanningFormat: "Progressive",
    chromaSubsampling: "4:2:2",
    colourPrimaries: null,
    videoFormat: "ProRes",
    videoColorSpace: "YUV",
  },
  {
    encodingProfile: "DubCard ProRes PAL 25p 16x9",
    type: "DubCard",
    type3d: "N/A",
    screenAspectRatio: "16x9",
    frameRate: "25.00",
    videoStandard: "Progressive PAL",
    horizontalResolution: "720",
    verticalResolution: "576",
    scanningFormat: "Progressive",
    chromaSubsampling: "4:2:2",
    colourPrimaries: null,
    videoFormat: "ProRes",
    videoColorSpace: "YUV",
  },
  {
    encodingProfile: "DubCard ProRes PAL 25p 4x3",
    type: "DubCard",
    type3d: "N/A",
    screenAspectRatio: "4x3",
    frameRate: "25.00",
    videoStandard: "Progressive PAL",
    horizontalResolution: "720",
    verticalResolution: "576",
    scanningFormat: "Progressive",
    chromaSubsampling: "4:2:2",
    colourPrimaries: null,
    videoFormat: "ProRes",
    videoColorSpace: "YUV",
  },
  {
    encodingProfile: "ProRes PAL 25p 16x9",
    type: "P2P",
    type3d: "N/A",
    screenAspectRatio: "16x9",
    frameRate: "25.00",
    videoStandard: "Progressive PAL",
    horizontalResolution: "720",
    verticalResolution: "576",
    scanningFormat: "Progressive",
    chromaSubsampling: "4:2:2",
    colourPrimaries: null,
    videoFormat: "ProRes",
    videoColorSpace: "YUV",
  },
  {
    encodingProfile: "ProRes PAL 25p 4x3",
    type: "P2P",
    type3d: "N/A",
    screenAspectRatio: "4x3",
    frameRate: "25.00",
    videoStandard: "Progressive PAL",
    horizontalResolution: "720",
    verticalResolution: "576",
    scanningFormat: "Progressive",
    chromaSubsampling: "4:2:2",
    colourPrimaries: null,
    videoFormat: "ProRes",
    videoColorSpace: "YUV",
  },
  {
    encodingProfile: "Slate ProRes PAL 25p 16x9",
    type: "Slate",
    type3d: "N/A",
    screenAspectRatio: "16x9",
    frameRate: "25.00",
    videoStandard: "Progressive PAL",
    horizontalResolution: "720",
    verticalResolution: "576",
    scanningFormat: "Progressive",
    chromaSubsampling: "4:2:2",
    colourPrimaries: null,
    videoFormat: "ProRes",
    videoColorSpace: "YUV",
  },
  {
    encodingProfile: "Slate ProRes PAL 25p 4x3",
    type: "Slate",
    type3d: "N/A",
    screenAspectRatio: "4x3",
    frameRate: "25.00",
    videoStandard: "Progressive PAL",
    horizontalResolution: "720",
    verticalResolution: "576",
    scanningFormat: "Progressive",
    chromaSubsampling: "4:2:2",
    colourPrimaries: null,
    videoFormat: "ProRes",
    videoColorSpace: "YUV",
  },
  {
    encodingProfile: "Textless ProRes PAL 25p 16x9",
    type: "Textless",
    type3d: "N/A",
    screenAspectRatio: "16x9",
    frameRate: "25.00",
    videoStandard: "Progressive PAL",
    horizontalResolution: "720",
    verticalResolution: "576",
    scanningFormat: "Progressive",
    chromaSubsampling: "4:2:2",
    colourPrimaries: null,
    videoFormat: "ProRes",
    videoColorSpace: "YUV",
  },
  {
    encodingProfile: "Textless ProRes PAL 25p 4x3",
    type: "Textless",
    type3d: "N/A",
    screenAspectRatio: "4x3",
    frameRate: "25.00",
    videoStandard: "Progressive PAL",
    horizontalResolution: "720",
    verticalResolution: "576",
    scanningFormat: "Progressive",
    chromaSubsampling: "4:2:2",
    colourPrimaries: null,
    videoFormat: "ProRes",
    videoColorSpace: "YUV",
  },
  {
    encodingProfile: "Archival ProRes HD 25p",
    type: "Archival",
    type3d: "N/A",
    screenAspectRatio: "16x9",
    frameRate: "25.00",
    videoStandard: "1080_25p",
    horizontalResolution: "1920",
    verticalResolution: "1080",
    scanningFormat: "Progressive",
    chromaSubsampling: "4:2:2",
    colourPrimaries: null,
    videoFormat: "ProRes",
    videoColorSpace: "YUV",
  },
  {
    encodingProfile: "DubCard ProRes HD 25p",
    type: "DubCard",
    type3d: "N/A",
    screenAspectRatio: "16x9",
    frameRate: "25.00",
    videoStandard: "1080_25p",
    horizontalResolution: "1920",
    verticalResolution: "1080",
    scanningFormat: "Progressive",
    chromaSubsampling: "4:2:2",
    colourPrimaries: null,
    videoFormat: "ProRes",
    videoColorSpace: "YUV",
  },
  {
    encodingProfile: "Slate ProRes HD 25p",
    type: "Slate",
    type3d: "N/A",
    screenAspectRatio: "16x9",
    frameRate: "25.00",
    videoStandard: "1080_25p",
    horizontalResolution: "1920",
    verticalResolution: "1080",
    scanningFormat: "Progressive",
    chromaSubsampling: "4:2:2",
    colourPrimaries: null,
    videoFormat: "ProRes",
    videoColorSpace: "YUV",
  },
  {
    encodingProfile: "Textless ProRes HD 25p",
    type: "Textless",
    type3d: "N/A",
    screenAspectRatio: "16x9",
    frameRate: "25.00",
    videoStandard: "1080_25p",
    horizontalResolution: "1920",
    verticalResolution: "1080",
    scanningFormat: "Progressive",
    chromaSubsampling: "4:2:2",
    colourPrimaries: null,
    videoFormat: "ProRes",
    videoColorSpace: "YUV",
  },
  {
    encodingProfile: "ProRes HD 25p",
    type: "P2P",
    type3d: "N/A",
    screenAspectRatio: "16x9",
    frameRate: "25.00",
    videoStandard: "1080_25p",
    horizontalResolution: "1920",
    verticalResolution: "1080",
    scanningFormat: "Progressive",
    chromaSubsampling: "4:2:2",
    colourPrimaries: null,
    videoFormat: "ProRes",
    videoColorSpace: "YUV",
  },
  {
    encodingProfile: "Archival ProRes NTSC 29.97i 16x9",
    type: "Archival",
    type3d: "N/A",
    screenAspectRatio: "16x9",
    frameRate: "29.97",
    videoStandard: "NTSC",
    horizontalResolution: "720",
    verticalResolution: "480",
    scanningFormat: "Interlaced",
    chromaSubsampling: "4:2:2",
    colourPrimaries: null,
    videoFormat: "ProRes",
    videoColorSpace: "YUV",
  },
  {
    encodingProfile: "Archival ProRes NTSC 29.97i 4x3",
    type: "Archival",
    type3d: "N/A",
    screenAspectRatio: "4x3",
    frameRate: "29.97",
    videoStandard: "NTSC",
    horizontalResolution: "720",
    verticalResolution: "480",
    scanningFormat: "Interlaced",
    chromaSubsampling: "4:2:2",
    colourPrimaries: null,
    videoFormat: "ProRes",
    videoColorSpace: "YUV",
  },
  {
    encodingProfile: "DubCard ProRes NTSC 29.97i 16x9",
    type: "DubCard",
    type3d: "N/A",
    screenAspectRatio: "16x9",
    frameRate: "29.97",
    videoStandard: "NTSC",
    horizontalResolution: "720",
    verticalResolution: "480",
    scanningFormat: "Interlaced",
    chromaSubsampling: "4:2:2",
    colourPrimaries: null,
    videoFormat: "ProRes",
    videoColorSpace: "YUV",
  },
  {
    encodingProfile: "DubCard ProRes NTSC 29.97i 4x3",
    type: "DubCard",
    type3d: "N/A",
    screenAspectRatio: "4x3",
    frameRate: "29.97",
    videoStandard: "NTSC",
    horizontalResolution: "720",
    verticalResolution: "480",
    scanningFormat: "Interlaced",
    chromaSubsampling: "4:2:2",
    colourPrimaries: null,
    videoFormat: "ProRes",
    videoColorSpace: "YUV",
  },
  {
    encodingProfile: "J2K NTSC 16x9",
    type: "Archival",
    type3d: "N/A",
    screenAspectRatio: "16x9",
    frameRate: "29.97",
    videoStandard: "NTSC",
    horizontalResolution: "720",
    verticalResolution: "480",
    scanningFormat: "Interlaced",
    chromaSubsampling: "4:2:2",
    colourPrimaries: null,
    videoFormat: "JPEG 2000",
    videoColorSpace: "YUV",
  },
  {
    encodingProfile: "J2K NTSC 4x3",
    type: "Archival",
    type3d: "N/A",
    screenAspectRatio: "4x3",
    frameRate: "29.97",
    videoStandard: "NTSC",
    horizontalResolution: "720",
    verticalResolution: "480",
    scanningFormat: "Interlaced",
    chromaSubsampling: "4:2:2",
    colourPrimaries: null,
    videoFormat: "JPEG 2000",
    videoColorSpace: "YUV",
  },
  {
    encodingProfile: "ProRes NTSC 29.97i 16x9",
    type: "P2P",
    type3d: "N/A",
    screenAspectRatio: "16x9",
    frameRate: "29.97",
    videoStandard: "NTSC",
    horizontalResolution: "720",
    verticalResolution: "480",
    scanningFormat: "Interlaced",
    chromaSubsampling: "4:2:2",
    colourPrimaries: null,
    videoFormat: "ProRes",
    videoColorSpace: "YUV",
  },
  {
    encodingProfile: "ProRes NTSC 29.97i 4x3",
    type: "P2P",
    type3d: "N/A",
    screenAspectRatio: "4x3",
    frameRate: "29.97",
    videoStandard: "NTSC",
    horizontalResolution: "720",
    verticalResolution: "480",
    scanningFormat: "Interlaced",
    chromaSubsampling: "4:2:2",
    colourPrimaries: null,
    videoFormat: "ProRes",
    videoColorSpace: "YUV",
  },
  {
    encodingProfile: "Slate ProRes NTSC 29.97i 16x9",
    type: "Slate",
    type3d: "N/A",
    screenAspectRatio: "16x9",
    frameRate: "29.97",
    videoStandard: "NTSC",
    horizontalResolution: "720",
    verticalResolution: "480",
    scanningFormat: "Interlaced",
    chromaSubsampling: "4:2:2",
    colourPrimaries: null,
    videoFormat: "ProRes",
    videoColorSpace: "YUV",
  },
  {
    encodingProfile: "Slate ProRes NTSC 29.97i 4x3",
    type: "Slate",
    type3d: "N/A",
    screenAspectRatio: "4x3",
    frameRate: "29.97",
    videoStandard: "NTSC",
    horizontalResolution: "720",
    verticalResolution: "480",
    scanningFormat: "Interlaced",
    chromaSubsampling: "4:2:2",
    colourPrimaries: null,
    videoFormat: "ProRes",
    videoColorSpace: "YUV",
  },
  {
    encodingProfile: "Textless ProRes NTSC 29.97i 16x9",
    type: "Textless",
    type3d: "N/A",
    screenAspectRatio: "16x9",
    frameRate: "29.97",
    videoStandard: "NTSC",
    horizontalResolution: "720",
    verticalResolution: "480",
    scanningFormat: "Interlaced",
    chromaSubsampling: "4:2:2",
    colourPrimaries: null,
    videoFormat: "ProRes",
    videoColorSpace: "YUV",
  },
  {
    encodingProfile: "Textless ProRes NTSC 29.97i 4x3",
    type: "Textless",
    type3d: "N/A",
    screenAspectRatio: "4x3",
    frameRate: "29.97",
    videoStandard: "NTSC",
    horizontalResolution: "720",
    verticalResolution: "480",
    scanningFormat: "Interlaced",
    chromaSubsampling: "4:2:2",
    colourPrimaries: null,
    videoFormat: "ProRes",
    videoColorSpace: "YUV",
  },
  {
    encodingProfile: "Archival ProRes 3D SideBySide HD 59.94i",
    type: "Archival",
    type3d: "Side By Side",
    screenAspectRatio: "16x9",
    frameRate: "29.97",
    videoStandard: "1080_59.94i",
    horizontalResolution: "1920",
    verticalResolution: "1080",
    scanningFormat: "Interlaced",
    chromaSubsampling: "4:2:2",
    colourPrimaries: null,
    videoFormat: "ProRes",
    videoColorSpace: "YUV",
  },
  {
    encodingProfile: "Archival ProRes HD 29.97i",
    type: "Archival",
    type3d: "N/A",
    screenAspectRatio: "16x9",
    frameRate: "29.97",
    videoStandard: "1080_59.94i",
    horizontalResolution: "1920",
    verticalResolution: "1080",
    scanningFormat: "Interlaced",
    chromaSubsampling: "4:2:2",
    colourPrimaries: null,
    videoFormat: "ProRes",
    videoColorSpace: "YUV",
  },
  {
    encodingProfile: "DubCard ProRes HD 29.97i",
    type: "DubCard",
    type3d: "N/A",
    screenAspectRatio: "16x9",
    frameRate: "29.97",
    videoStandard: "1080_59.94i",
    horizontalResolution: "1920",
    verticalResolution: "1080",
    scanningFormat: "Interlaced",
    chromaSubsampling: "4:2:2",
    colourPrimaries: null,
    videoFormat: "ProRes",
    videoColorSpace: "YUV",
  },
  {
    encodingProfile: "J2K 1920 x 1080 29.97i",
    type: "Archival",
    type3d: "N/A",
    screenAspectRatio: "16x9",
    frameRate: "29.97",
    videoStandard: "1080_59.94i",
    horizontalResolution: "1920",
    verticalResolution: "1080",
    scanningFormat: "Interlaced",
    chromaSubsampling: "4:2:2",
    colourPrimaries: null,
    videoFormat: "JPEG 2000",
    videoColorSpace: "YUV",
  },
  {
    encodingProfile: "P2P ProRes 3D SideBySide HD 59.94i",
    type: "P2P",
    type3d: "Side By Side",
    screenAspectRatio: "16x9",
    frameRate: "29.97",
    videoStandard: "1080_59.94i",
    horizontalResolution: "1920",
    verticalResolution: "1080",
    scanningFormat: "Interlaced",
    chromaSubsampling: "4:2:2",
    colourPrimaries: null,
    videoFormat: "ProRes",
    videoColorSpace: "YUV",
  },
  {
    encodingProfile: "P2P ProRes HD 29.97i",
    type: "P2P",
    type3d: "N/A",
    screenAspectRatio: "16x9",
    frameRate: "29.97",
    videoStandard: "1080_59.94i",
    horizontalResolution: "1920",
    verticalResolution: "1080",
    scanningFormat: "Interlaced",
    chromaSubsampling: "4:2:2",
    colourPrimaries: null,
    videoFormat: "ProRes",
    videoColorSpace: "YUV",
  },
  {
    encodingProfile: "P2P ProRes HD 59.94p",
    type: "P2P",
    type3d: "N/A",
    screenAspectRatio: "16x9",
    frameRate: "59.94",
    videoStandard: "1080_59.94P",
    horizontalResolution: "1920",
    verticalResolution: "1080",
    scanningFormat: "Progressive",
    chromaSubsampling: "4:2:2",
    colourPrimaries: null,
    videoFormat: "ProRes",
    videoColorSpace: "YUV",
  },
  {
    encodingProfile: "Archival ProRes NTSC 29.97p 16x9",
    type: "Archival",
    type3d: "N/A",
    screenAspectRatio: "16x9",
    frameRate: "29.97",
    videoStandard: "NTSC",
    horizontalResolution: "720",
    verticalResolution: "480",
    scanningFormat: "Progressive",
    chromaSubsampling: "4:2:2",
    colourPrimaries: null,
    videoFormat: "ProRes",
    videoColorSpace: "YUV",
  },
  {
    encodingProfile: "Archival ProRes NTSC 29.97p 4x3",
    type: "Archival",
    type3d: "N/A",
    screenAspectRatio: "4x3",
    frameRate: "29.97",
    videoStandard: "NTSC",
    horizontalResolution: "720",
    verticalResolution: "480",
    scanningFormat: "Progressive",
    chromaSubsampling: "4:2:2",
    colourPrimaries: null,
    videoFormat: "ProRes",
    videoColorSpace: "YUV",
  },
  {
    encodingProfile: "DubCard ProRes NTSC 29.97p 16x9",
    type: "DubCard",
    type3d: "N/A",
    screenAspectRatio: "16x9",
    frameRate: "29.97",
    videoStandard: "NTSC",
    horizontalResolution: "720",
    verticalResolution: "480",
    scanningFormat: "Progressive",
    chromaSubsampling: "4:2:2",
    colourPrimaries: null,
    videoFormat: "ProRes",
    videoColorSpace: "YUV",
  },
  {
    encodingProfile: "DubCard ProRes NTSC 29.97p 4x3",
    type: "DubCard",
    type3d: "N/A",
    screenAspectRatio: "4x3",
    frameRate: "29.97",
    videoStandard: "NTSC",
    horizontalResolution: "720",
    verticalResolution: "480",
    scanningFormat: "Progressive",
    chromaSubsampling: "4:2:2",
    colourPrimaries: null,
    videoFormat: "ProRes",
    videoColorSpace: "YUV",
  },
  {
    encodingProfile: "ProRes NTSC 29.97p 16x9",
    type: "P2P",
    type3d: "N/A",
    screenAspectRatio: "16x9",
    frameRate: "29.97",
    videoStandard: "NTSC",
    horizontalResolution: "720",
    verticalResolution: "480",
    scanningFormat: "Progressive",
    chromaSubsampling: "4:2:2",
    colourPrimaries: null,
    videoFormat: "ProRes",
    videoColorSpace: "YUV",
  },
  {
    encodingProfile: "ProRes NTSC 29.97p 4x3",
    type: "P2P",
    type3d: "N/A",
    screenAspectRatio: "4x3",
    frameRate: "29.97",
    videoStandard: "NTSC",
    horizontalResolution: "720",
    verticalResolution: "480",
    scanningFormat: "Progressive",
    chromaSubsampling: "4:2:2",
    colourPrimaries: null,
    videoFormat: "ProRes",
    videoColorSpace: "YUV",
  },
  {
    encodingProfile: "Slate ProRes NTSC 29.97p 16x9",
    type: "Slate",
    type3d: "N/A",
    screenAspectRatio: "16x9",
    frameRate: "29.97",
    videoStandard: "NTSC",
    horizontalResolution: "720",
    verticalResolution: "480",
    scanningFormat: "Progressive",
    chromaSubsampling: "4:2:2",
    colourPrimaries: null,
    videoFormat: "ProRes",
    videoColorSpace: "YUV",
  },
  {
    encodingProfile: "Slate ProRes NTSC 29.97p 4x3",
    type: "Slate",
    type3d: "N/A",
    screenAspectRatio: "4x3",
    frameRate: "29.97",
    videoStandard: "NTSC",
    horizontalResolution: "720",
    verticalResolution: "480",
    scanningFormat: "Progressive",
    chromaSubsampling: "4:2:2",
    colourPrimaries: null,
    videoFormat: "ProRes",
    videoColorSpace: "YUV",
  },
  {
    encodingProfile: "Textless ProRes NTSC 29.97p 16x9",
    type: "Textless",
    type3d: "N/A",
    screenAspectRatio: "16x9",
    frameRate: "29.97",
    videoStandard: "NTSC",
    horizontalResolution: "720",
    verticalResolution: "480",
    scanningFormat: "Progressive",
    chromaSubsampling: "4:2:2",
    colourPrimaries: null,
    videoFormat: "ProRes",
    videoColorSpace: "YUV",
  },
  {
    encodingProfile: "Textless ProRes NTSC 29.97p 4x3",
    type: "Textless",
    type3d: "N/A",
    screenAspectRatio: "4x3",
    frameRate: "29.97",
    videoStandard: "NTSC",
    horizontalResolution: "720",
    verticalResolution: "480",
    scanningFormat: "Progressive",
    chromaSubsampling: "4:2:2",
    colourPrimaries: null,
    videoFormat: "ProRes",
    videoColorSpace: "YUV",
  },
  {
    encodingProfile: "Archival ProRes HD 29.97p",
    type: "Archival",
    type3d: "N/A",
    screenAspectRatio: "16x9",
    frameRate: "29.97",
    videoStandard: "1080_29.97p",
    horizontalResolution: "1920",
    verticalResolution: "1080",
    scanningFormat: "Progressive",
    chromaSubsampling: "4:2:2",
    colourPrimaries: null,
    videoFormat: "ProRes",
    videoColorSpace: "YUV",
  },
  {
    encodingProfile: "Archival ProRes HD 59.94p",
    type: "Archival",
    type3d: "N/A",
    screenAspectRatio: "16x9",
    frameRate: "59.94",
    videoStandard: "1080_59.94p",
    horizontalResolution: "1920",
    verticalResolution: "1080",
    scanningFormat: "Progressive",
    chromaSubsampling: "4:2:2",
    colourPrimaries: null,
    videoFormat: "ProRes",
    videoColorSpace: "YUV",
  },
  {
    encodingProfile: "DubCard ProRes HD 29.97p",
    type: "DubCard",
    type3d: "N/A",
    screenAspectRatio: "16x9",
    frameRate: "29.97",
    videoStandard: "1080_29.97p",
    horizontalResolution: "1920",
    verticalResolution: "1080",
    scanningFormat: "Progressive",
    chromaSubsampling: "4:2:2",
    colourPrimaries: null,
    videoFormat: "ProRes",
    videoColorSpace: "YUV",
  },
  {
    encodingProfile: "Slate ProRes HD 29.97p",
    type: "Slate",
    type3d: "N/A",
    screenAspectRatio: "16x9",
    frameRate: "29.97",
    videoStandard: "1080_29.97p",
    horizontalResolution: "1920",
    verticalResolution: "1080",
    scanningFormat: "Progressive",
    chromaSubsampling: "4:2:2",
    colourPrimaries: null,
    videoFormat: "ProRes",
    videoColorSpace: "YUV",
  },
  {
    encodingProfile: "Textless ProRes HD 29.97p",
    type: "Textless",
    type3d: "N/A",
    screenAspectRatio: "16x9",
    frameRate: "29.97",
    videoStandard: "1080_29.97p",
    horizontalResolution: "1920",
    verticalResolution: "1080",
    scanningFormat: "Progressive",
    chromaSubsampling: "4:2:2",
    colourPrimaries: null,
    videoFormat: "ProRes",
    videoColorSpace: "YUV",
  },
  {
    encodingProfile: "ProRes HD 29.97p",
    type: "P2P",
    type3d: "N/A",
    screenAspectRatio: "16x9",
    frameRate: "29.97",
    videoStandard: "1080_29.97p",
    horizontalResolution: "1920",
    verticalResolution: "1080",
    scanningFormat: "Progressive",
    chromaSubsampling: "4:2:2",
    colourPrimaries: null,
    videoFormat: "ProRes",
    videoColorSpace: "YUV",
  },
  {
    encodingProfile: "Archival ProRes NTSC 29.97i 16x9",
    type: "Archival",
    type3d: "N/A",
    screenAspectRatio: "16x9",
    frameRate: "29.97",
    videoStandard: "NTSC",
    horizontalResolution: "720",
    verticalResolution: "486",
    scanningFormat: "Interlaced",
    chromaSubsampling: "4:2:2",
    colourPrimaries: "BT.601 NTSC",
    videoFormat: "ProRes",
    videoColorSpace: "YUV",
  },
  {
    encodingProfile: "Archival ProRes NTSC 29.97i 4x3",
    type: "Archival",
    type3d: "N/A",
    screenAspectRatio: "4x3",
    frameRate: "29.97",
    videoStandard: "NTSC",
    horizontalResolution: "720",
    verticalResolution: "486",
    scanningFormat: "Interlaced",
    chromaSubsampling: "4:2:2",
    colourPrimaries: "BT.601 NTSC",
    videoFormat: "ProRes",
    videoColorSpace: "YUV",
  },
  {
    encodingProfile: "DubCard ProRes NTSC 29.97i 16x9",
    type: "DubCard",
    type3d: "N/A",
    screenAspectRatio: "16x9",
    frameRate: "29.97",
    videoStandard: "NTSC",
    horizontalResolution: "720",
    verticalResolution: "486",
    scanningFormat: "Interlaced",
    chromaSubsampling: "4:2:2",
    colourPrimaries: "BT.709",
    videoFormat: "ProRes",
    videoColorSpace: "YUV",
  },
  {
    encodingProfile: "DubCard ProRes NTSC 29.97i 4x3",
    type: "DubCard",
    type3d: "N/A",
    screenAspectRatio: "4x3",
    frameRate: "29.97",
    videoStandard: "NTSC",
    horizontalResolution: "720",
    verticalResolution: "486",
    scanningFormat: "Interlaced",
    chromaSubsampling: "4:2:2",
    colourPrimaries: "BT.709",
    videoFormat: "ProRes",
    videoColorSpace: "YUV",
  },
  {
    encodingProfile: "J2K NTSC 16x9",
    type: "Archival",
    type3d: "N/A",
    screenAspectRatio: "16x9",
    frameRate: "29.97",
    videoStandard: "NTSC",
    horizontalResolution: "720",
    verticalResolution: "486",
    scanningFormat: "Interlaced",
    chromaSubsampling: "4:2:2",
    colourPrimaries: "BT.601 NTSC",
    videoFormat: "JPEG 2000",
    videoColorSpace: "YUV",
  },
  {
    encodingProfile: "J2K NTSC 4x3",
    type: "Archival",
    type3d: "N/A",
    screenAspectRatio: "4x3",
    frameRate: "29.97",
    videoStandard: "NTSC",
    horizontalResolution: "720",
    verticalResolution: "486",
    scanningFormat: "Interlaced",
    chromaSubsampling: "4:2:2",
    colourPrimaries: "BT.601 NTSC",
    videoFormat: "JPEG 2000",
    videoColorSpace: "YUV",
  },
  {
    encodingProfile: "ProRes NTSC 29.97i 16x9",
    type: "P2P",
    type3d: "N/A",
    screenAspectRatio: "16x9",
    frameRate: "29.97",
    videoStandard: "NTSC",
    horizontalResolution: "720",
    verticalResolution: "486",
    scanningFormat: "Interlaced",
    chromaSubsampling: "4:2:2",
    colourPrimaries: "BT.709",
    videoFormat: "ProRes",
    videoColorSpace: "YUV",
  },
  {
    encodingProfile: "ProRes NTSC 29.97i 4x3",
    type: "P2P",
    type3d: "N/A",
    screenAspectRatio: "4x3",
    frameRate: "29.97",
    videoStandard: "NTSC",
    horizontalResolution: "720",
    verticalResolution: "486",
    scanningFormat: "Interlaced",
    chromaSubsampling: "4:2:2",
    colourPrimaries: "BT.709",
    videoFormat: "ProRes",
    videoColorSpace: "YUV",
  },
  {
    encodingProfile: "Slate ProRes NTSC 29.97i 16x9",
    type: "Slate",
    type3d: "N/A",
    screenAspectRatio: "16x9",
    frameRate: "29.97",
    videoStandard: "NTSC",
    horizontalResolution: "720",
    verticalResolution: "486",
    scanningFormat: "Interlaced",
    chromaSubsampling: "4:2:2",
    colourPrimaries: "BT.709",
    videoFormat: "ProRes",
    videoColorSpace: "YUV",
  },
  {
    encodingProfile: "Slate ProRes NTSC 29.97i 4x3",
    type: "Slate",
    type3d: "N/A",
    screenAspectRatio: "4x3",
    frameRate: "29.97",
    videoStandard: "NTSC",
    horizontalResolution: "720",
    verticalResolution: "486",
    scanningFormat: "Interlaced",
    chromaSubsampling: "4:2:2",
    colourPrimaries: "BT.709",
    videoFormat: "ProRes",
    videoColorSpace: "YUV",
  },
  {
    encodingProfile: "Textless ProRes NTSC 29.97i 16x9",
    type: "Textless",
    type3d: "N/A",
    screenAspectRatio: "16x9",
    frameRate: "29.97",
    videoStandard: "NTSC",
    horizontalResolution: "720",
    verticalResolution: "486",
    scanningFormat: "Interlaced",
    chromaSubsampling: "4:2:2",
    colourPrimaries: "BT.601 NTSC",
    videoFormat: "ProRes",
    videoColorSpace: "YUV",
  },
  {
    encodingProfile: "Textless ProRes NTSC 29.97i 4x3",
    type: "Textless",
    type3d: "N/A",
    screenAspectRatio: "4x3",
    frameRate: "29.97",
    videoStandard: "NTSC",
    horizontalResolution: "720",
    verticalResolution: "486",
    scanningFormat: "Interlaced",
    chromaSubsampling: "4:2:2",
    colourPrimaries: "BT.601 NTSC",
    videoFormat: "ProRes",
    videoColorSpace: "YUV",
  },
  {
    encodingProfile: "Archival ProRes NTSC 29.97p 16x9",
    type: "Archival",
    type3d: "N/A",
    screenAspectRatio: "16x9",
    frameRate: "29.97",
    videoStandard: "NTSC",
    horizontalResolution: "720",
    verticalResolution: "486",
    scanningFormat: "Progressive",
    chromaSubsampling: "4:2:2",
    colourPrimaries: "BT.601 NTSC",
    videoFormat: "ProRes",
    videoColorSpace: "YUV",
  },
  {
    encodingProfile: "Archival ProRes NTSC 29.97p 4x3",
    type: "Archival",
    type3d: "N/A",
    screenAspectRatio: "4x3",
    frameRate: "29.97",
    videoStandard: "NTSC",
    horizontalResolution: "720",
    verticalResolution: "486",
    scanningFormat: "Progressive",
    chromaSubsampling: "4:2:2",
    colourPrimaries: "BT.601 NTSC",
    videoFormat: "ProRes",
    videoColorSpace: "YUV",
  },
  {
    encodingProfile: "DubCard ProRes NTSC 29.97p 16x9",
    type: "DubCard",
    type3d: "N/A",
    screenAspectRatio: "16x9",
    frameRate: "29.97",
    videoStandard: "NTSC",
    horizontalResolution: "720",
    verticalResolution: "486",
    scanningFormat: "Progressive",
    chromaSubsampling: "4:2:2",
    colourPrimaries: "BT.709",
    videoFormat: "ProRes",
    videoColorSpace: "YUV",
  },
  {
    encodingProfile: "DubCard ProRes NTSC 29.97p 4x3",
    type: "DubCard",
    type3d: "N/A",
    screenAspectRatio: "4x3",
    frameRate: "29.97",
    videoStandard: "NTSC",
    horizontalResolution: "720",
    verticalResolution: "486",
    scanningFormat: "Progressive",
    chromaSubsampling: "4:2:2",
    colourPrimaries: "BT.709",
    videoFormat: "ProRes",
    videoColorSpace: "YUV",
  },
  {
    encodingProfile: "ProRes NTSC 29.97p 16x9",
    type: "P2P",
    type3d: "N/A",
    screenAspectRatio: "16x9",
    frameRate: "29.97",
    videoStandard: "NTSC",
    horizontalResolution: "720",
    verticalResolution: "486",
    scanningFormat: "Progressive",
    chromaSubsampling: "4:2:2",
    colourPrimaries: "BT.709",
    videoFormat: "ProRes",
    videoColorSpace: "YUV",
  },
  {
    encodingProfile: "ProRes NTSC 29.97p 4x3",
    type: "P2P",
    type3d: "N/A",
    screenAspectRatio: "4x3",
    frameRate: "29.97",
    videoStandard: "NTSC",
    horizontalResolution: "720",
    verticalResolution: "486",
    scanningFormat: "Progressive",
    chromaSubsampling: "4:2:2",
    colourPrimaries: "BT.709",
    videoFormat: "ProRes",
    videoColorSpace: "YUV",
  },
  {
    encodingProfile: "Slate ProRes NTSC 29.97p 16x9",
    type: "Slate",
    type3d: "N/A",
    screenAspectRatio: "16x9",
    frameRate: "29.97",
    videoStandard: "NTSC",
    horizontalResolution: "720",
    verticalResolution: "486",
    scanningFormat: "Progressive",
    chromaSubsampling: "4:2:2",
    colourPrimaries: "BT.709",
    videoFormat: "ProRes",
    videoColorSpace: "YUV",
  },
  {
    encodingProfile: "Slate ProRes NTSC 29.97p 4x3",
    type: "Slate",
    type3d: "N/A",
    screenAspectRatio: "4x3",
    frameRate: "29.97",
    videoStandard: "NTSC",
    horizontalResolution: "720",
    verticalResolution: "486",
    scanningFormat: "Progressive",
    chromaSubsampling: "4:2:2",
    colourPrimaries: "BT.709",
    videoFormat: "ProRes",
    videoColorSpace: "YUV",
  },
  {
    encodingProfile: "Textless ProRes NTSC 29.97p 16x9",
    type: "Textless",
    type3d: "N/A",
    screenAspectRatio: "16x9",
    frameRate: "29.97",
    videoStandard: "NTSC",
    horizontalResolution: "720",
    verticalResolution: "486",
    scanningFormat: "Progressive",
    chromaSubsampling: "4:2:2",
    colourPrimaries: "BT.601 NTSC",
    videoFormat: "ProRes",
    videoColorSpace: "YUV",
  },
  {
    encodingProfile: "Textless ProRes NTSC 29.97p 4x3",
    type: "Textless",
    type3d: "N/A",
    screenAspectRatio: "4x3",
    frameRate: "29.97",
    videoStandard: "NTSC",
    horizontalResolution: "720",
    verticalResolution: "486",
    scanningFormat: "Progressive",
    chromaSubsampling: "4:2:2",
    colourPrimaries: "BT.601 NTSC",
    videoFormat: "ProRes",
    videoColorSpace: "YUV",
  },
  {
    encodingProfile: "Archival ProRes CHD 59.94p UPRES",
    type: "Archival_CHD",
    type3d: "N/A",
    screenAspectRatio: "16x9",
    frameRate: "59.94",
    videoStandard: "1080_59.94p",
    horizontalResolution: "1920",
    verticalResolution: "1080",
    scanningFormat: "Progressive",
    chromaSubsampling: "4:2:2",
    colourPrimaries: "BT.601 NTSC",
    videoFormat: "ProRes",
    videoColorSpace: "YUV",
  },
  {
    encodingProfile: "Archival ProRes CHD 23.976p UPRES",
    type: "Archival_CHD",
    type3d: "N/A",
    screenAspectRatio: "16x9",
    frameRate: "23.976",
    videoStandard: "1080_24p",
    horizontalResolution: "1920",
    verticalResolution: "1080",
    scanningFormat: "Progressive",
    chromaSubsampling: "4:2:2",
    colourPrimaries: "BT.601 NTSC",
    videoFormat: "ProRes",
    videoColorSpace: "YUV",
  },
  {
    encodingProfile: "Archival ProRes CHD 25i UPRES",
    type: "Archival_CHD",
    type3d: "N/A",
    screenAspectRatio: "16x9",
    frameRate: "25.00",
    videoStandard: "1080_50i",
    horizontalResolution: "1920",
    verticalResolution: "1080",
    scanningFormat: "Interlaced",
    chromaSubsampling: "4:2:2",
    colourPrimaries: "BT.601 NTSC",
    videoFormat: "ProRes",
    videoColorSpace: "YUV",
  },
  {
    encodingProfile: "Archival ProRes CHD 25p UPRES",
    type: "Archival_CHD",
    type3d: "N/A",
    screenAspectRatio: "16x9",
    frameRate: "25.00",
    videoStandard: "1080_25p",
    horizontalResolution: "1920",
    verticalResolution: "1080",
    scanningFormat: "Progressive",
    chromaSubsampling: "4:2:2",
    colourPrimaries: "BT.601 NTSC",
    videoFormat: "ProRes",
    videoColorSpace: "YUV",
  },
  {
    encodingProfile: "Archival ProRes CHD 29.97i UPRES",
    type: "Archival_CHD",
    type3d: "N/A",
    screenAspectRatio: "16x9",
    frameRate: "29.97",
    videoStandard: "1080_59.94i",
    horizontalResolution: "1920",
    verticalResolution: "1080",
    scanningFormat: "Interlaced",
    chromaSubsampling: "4:2:2",
    colourPrimaries: "BT.601 NTSC",
    videoFormat: "ProRes",
    videoColorSpace: "YUV",
  },
  {
    encodingProfile: "Archival ProRes CHD 29.97p UPRES",
    type: "Archival_CHD",
    type3d: "N/A",
    screenAspectRatio: "16x9",
    frameRate: "29.97",
    videoStandard: "1080_29.97p",
    horizontalResolution: "1920",
    verticalResolution: "1080",
    scanningFormat: "Progressive",
    chromaSubsampling: "4:2:2",
    colourPrimaries: "BT.601 NTSC",
    videoFormat: "ProRes",
    videoColorSpace: "YUV",
  },
  {
    encodingProfile: "P2P ProRes CHD 23.976p UPRES",
    type: "P2P_CHD",
    type3d: "N/A",
    screenAspectRatio: "16x9",
    frameRate: "23.976",
    videoStandard: "1080_24p",
    horizontalResolution: "1920",
    verticalResolution: "1080",
    scanningFormat: "Progressive",
    chromaSubsampling: "4:2:2",
    colourPrimaries: "BT.601 NTSC",
    videoFormat: "ProRes",
    videoColorSpace: "YUV",
  },
  {
    encodingProfile: "P2P ProRes CHD 25i UPRES",
    type: "P2P_CHD",
    type3d: "N/A",
    screenAspectRatio: "16x9",
    frameRate: "25.00",
    videoStandard: "1080_50i",
    horizontalResolution: "1920",
    verticalResolution: "1080",
    scanningFormat: "Interlaced",
    chromaSubsampling: "4:2:2",
    colourPrimaries: "BT.601 NTSC",
    videoFormat: "ProRes",
    videoColorSpace: "YUV",
  },
  {
    encodingProfile: "P2P ProRes CHD 25p UPRES",
    type: "P2P_CHD",
    type3d: "N/A",
    screenAspectRatio: "16x9",
    frameRate: "25.00",
    videoStandard: "1080_25p",
    horizontalResolution: "1920",
    verticalResolution: "1080",
    scanningFormat: "Progressive",
    chromaSubsampling: "4:2:2",
    colourPrimaries: "BT.601 NTSC",
    videoFormat: "ProRes",
    videoColorSpace: "YUV",
  },
  {
    encodingProfile: "P2P ProRes CHD 29.97i UPRES",
    type: "P2P_CHD",
    type3d: "N/A",
    screenAspectRatio: "16x9",
    frameRate: "29.97",
    videoStandard: "1080_59.94i",
    horizontalResolution: "1920",
    verticalResolution: "1080",
    scanningFormat: "Interlaced",
    chromaSubsampling: "4:2:2",
    colourPrimaries: "BT.601 NTSC",
    videoFormat: "ProRes",
    videoColorSpace: "YUV",
  },
  {
    encodingProfile: "P2P ProRes CHD 29.97p UPRES",
    type: "P2P_CHD",
    type3d: "N/A",
    screenAspectRatio: "16x9",
    frameRate: "29.97",
    videoStandard: "1080_29.97p",
    horizontalResolution: "1920",
    verticalResolution: "1080",
    scanningFormat: "Progressive",
    chromaSubsampling: "4:2:2",
    colourPrimaries: "BT.601 NTSC",
    videoFormat: "ProRes",
    videoColorSpace: "YUV",
  },
  {
    encodingProfile: "P2P ProRes CHD 59.94p UPRES",
    type: "P2P_CHD",
    type3d: "N/A",
    screenAspectRatio: "16x9",
    frameRate: "59.94",
    videoStandard: "1080_59.94p",
    horizontalResolution: "1920",
    verticalResolution: "1080",
    scanningFormat: "Progressive",
    chromaSubsampling: "4:2:2",
    colourPrimaries: "BT.601 NTSC",
    videoFormat: "ProRes",
    videoColorSpace: "YUV",
  },
];
