import React, { Component } from 'react';
import { Grid, FormControl, InputLabel } from "@material-ui/core";
import { withStyles } from "@material-ui/styles";
import { ExpandableSection, TextInput } from "../common";
import { FileEditableDropdown } from '../FileEditableDropdown/FileEditableDropdown';
import { AudioProfileInfo } from './';
import audioLanguages from './audioLanguages';
import _ from 'lodash';
import profiles from './audioEncodingProfiles';

const style = (theme) => ({
  formControl: {
    top: 2,
  },
  label: {
    fontSize: 14,
    color: '#333333',
    fontWeight: 700,
  },
  input: {
    marginTop: 40,
    width: '12vw',
  }
});

let initialState = {
  file: null,
  vendorName: "",
  encodingSoftware: "",
  profileData: [],
};

class AudioData extends Component {

  constructor(props) {
    super(props);
    let defaultState = props.audioData || {};
    this.state = Object.assign({}, initialState, defaultState);
    this.handleFilenameChange = _.debounce(this.handleFilenameChange, 550);
    this.handleInputChange = _.debounce(this.handleInputChange, 550);
  }

  componentDidMount() {
    this.postChange();
  }

  hasErrors = () => {
    return this.isFileSet() && !this.state.profileData.length;
  }

  postChange = () => {
    this.props.updateErrors(this.props.updateErrorLabel, !this.hasErrors());
    let isFilenameFilled = this.state.file && !_.isEmpty(this.state.file.filename);
    if (isFilenameFilled) {
      this.props.onChange(this.state);
    }
  }

  handleFilenameChange = (label, event) => {
    let isFilenameEmpty = _.isEmpty(event.target.value);
    let filename = !isFilenameEmpty ? event.target.value : null;
    let ebucore = !isFilenameEmpty ? _.find(this.props.files, { filename }) : null;
    let file = !isFilenameEmpty ? { filename } : null;

    if(ebucore) {
        file = { ebucore, filename }
    }

    this.setState({
        file,
    }, () => {
        this.postChange();
    });
  }

  handleInputChange = (label, value) => {
    this.setState({
        [label]: value,
    }, () => {
        this.postChange();
    });
  }

  handleChange = (label, value) => {
    let newState = Object.assign({}, this.state);
    newState[label] = value;
    this.setState(newState, () => {
      this.postChange();
    });
  }

  isFileSet = () => {
    return (this.state.file && !_.isEmpty(this.state.file.filename));
  }

  render() {
    let { classes, files, errorsEnabled, renderButtons } = this.props;
    let filename = this.state.file ? this.state.file.filename : '';
    let isFilenameFilled = !!filename.length;
    let errorsFocusClass = errorsEnabled && this.props.focusErrorHandler(this.hasErrors());
    let defaultFocusClass = errorsFocusClass ? errorsFocusClass : 'normal';
    return (
      <Grid item component={ExpandableSection} header="Audio Data" defaultExpanded={true}
        focusErrorCssStyle={defaultFocusClass} renderButtons={renderButtons}>
        <Grid container direction="row" justify="space-between">

          <Grid item>
            <FormControl className={classes.formControl}>
              <InputLabel shrink={false} className={classes.label}>Filename</InputLabel>
              <FileEditableDropdown
                id="filename"
                onChange={this.handleFilenameChange}
                field={'file'}
                disabled={this.props.disabled}
                value={filename}
                files={files}
                style={{
                  marginTop: 40,
                  marginRight: 10,
                  width: '32vw',
                }}
              />
            </FormControl>
          </Grid>

          <Grid item component={TextInput}
              label="Vendor Name"
              type="text"
              value={this.state.vendorName}
              field="vendorName"
              onChange={this.handleInputChange}
              error={false}
          />

          <Grid item component={TextInput}
              label="Encoding Software"
              type="text"
              value={this.state.encodingSoftware}
              field="encodingSoftware"
              onChange={this.handleInputChange}
              error={false}
          />

          <Grid item component={ExpandableSection}
          header="Profile Information" defaultExpanded={true}>
            <Grid item component={AudioProfileInfo}
              onChange={this.handleChange}
              languages={audioLanguages}
              isFileSet={this.isFileSet}
              profileData={this.state.profileData}
              isFilenameFilled={isFilenameFilled}
              errorsEnabled={errorsEnabled}
              showNotification={this.props.showNotification}
            />
         </Grid>

        </Grid>
      </Grid>
    );
  }
}

const styled = withStyles(style)(AudioData);
export { styled as AudioData };
