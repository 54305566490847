import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import moment from 'moment';
import {
    Grid, ExpansionPanel, ExpansionPanelSummary,
    ExpansionPanelDetails, FormControl, InputLabel,
    Select, MenuItem, Typography
} from '@material-ui/core';
import { TitleDetails, TitleField, FileForm, DatePicker } from '../common';
import { ClientspecService, TitleService } from '../../_services';
import ArrowDropDown from '@material-ui/icons/ArrowDropDown';
import { ClosedCaptionData } from '../RokuCablelabs';

const displayDate = (dateStr) => {
    let momentDateObj = moment(dateStr);
    return momentDateObj.isValid() ? momentDateObj.format("MM/DD/YYYY") : dateStr;
}

const initialState = {
    alphas: [""],
    alpha: null,
    title: null,
    episodeArt: {},
    episodeFile: {},
    licensingStartDate: null,
    licensingEndDate: null,
    loadingSpinner: true,
    alphaNotFound: false,
    closedCaption: null,
    errors: {}
}
export class Episode extends React.Component {
    constructor(props) {
        super(props);

        let { prevData } = props;
        this.state = Object.assign({}, initialState, prevData);
    }

    shouldComponentUpdate = (nextProps, nextState) => {
        return !((nextState.filename !== this.state.filename) ||
            (nextState.keyart !== this.state.keyart) ||
            (nextState.closedCaption !== this.state.closedCaption)) ||
            (nextState.errors !== this.state.errors);
    }

    static getDerivedStateFromProps(props, state) {
        let seasonSettings = props.seasonSettings;
        let { useEpisodeLevel } = props;
        let newState = {};

        if (useEpisodeLevel) {
            newState.alpha = null;
        }

        if (seasonSettings) {
            if (seasonSettings.alphaName && !useEpisodeLevel) {
                let alphaName = seasonSettings.alphaName;
                let alpha = _.find(state.alphas, { alphaName });
                if (alpha) {
                    newState.alpha = alpha;
                    newState.alphaNotFound = false;
                } else {
                    newState.alpha = null;
                    newState.alphaNotFound = true;
                    return newState;
                }
            } else {
                if (state.alphaNotFound) {
                    newState.alphaNotFound = false;
                }
            }

            if (seasonSettings.licensing) {
                if (!state.userEntered) {
                    let { startDate, endDate } = seasonSettings.licensing;

                    if ((startDate !== state.licensingStartDate) ||
                        (endDate !== state.licensingEndDate)) {
                        newState = Object.assign({}, newState, {
                            licensingStartDate: startDate,
                            licensingEndDate: endDate,
                        });
                    }
                }
            }
        }

        return _.isEmpty(newState) ? null : newState;
    }

    fetchClientSpecForTitle = (titleId, clientId, label) => {
        ClientspecService.getSpec(titleId, clientId).then((resp) => {
            let spec = resp.data;
            let { presentedTitle, rawTitle } = spec;
            let title = Object.assign({}, rawTitle, presentedTitle);
            this.setState({
                [label]: title,
                loadingSpinner: false
            }, () => {
                this.postChange();
            });
        });

    }

    postChange = () => {
        let { licensingStartDate, licensingEndDate,
            alpha, episodeFile, episodeArt, errors,
            alphaNotFound, closedCaption } = this.state;
        let title = this.getTitle();
        let titleId = title ? title.externalId : null;
        let alphaId = (alpha && !this.props.useEpisodeLevel) ? alpha.externalId : null;
        let episodeData = {
            licensingStartDate,
            licensingEndDate,
            titleId,
            alphaId,
            episodeFile,
            episodeArt,
            errors,
            alphaNotFound,
            closedCaption
        }
        this.props.onChange(episodeData);
    }

    componentDidMount() {
        let clientId = this.props.client ? this.props.client.id : '';

        this.fetchClientSpecForTitle(this.props.episode.externalId, clientId, 'title');
        TitleService.getTitlesByParent(this.props.episode.externalId).then((resp) => {
            let alphas = resp.data;

            if (alphas.length) {
                let alpha = { ...alphas[0] };

                this.props.addToAlphas(alphas.map(item => item.alphaName));
                alphas = [].concat(alphas);
                this.setState({
                    alphas,
                    alpha,
                    loadingSpinner: false,
                }, () => {
                    this.postChange();
                });

                this.fetchClientSpecForTitle(alpha.externalId, clientId, 'alphaTitle');
            }
        });
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.alphaNotFound !== this.state.alphaNotFound) {
            this.postChange();
        }
    }

    handleSelection = (e) => {
        let alphaId = e.target.value;
        let alpha = _.find(this.state.alphas, { externalId: alphaId });
        this.setState({
            alpha
        }, () => {
            this.postChange();
        });
    }

    hasSeasonAlphaSettingBeenApplied = () => {
        return !!(this.props.seasonSettings &&
            this.props.seasonSettings.alphaName);
    }

    handleDataFormChange = (data) => {
        // We are getting files list from props;
        this.setState(data, () => {
            this.postChange();
        });
    }

    handleClosedCaption = (label, data) => {
        this.setState({
            [label]: data
        }, () => {
            this.postChange();
        });
    }

    handleDatePickerChange = (data) => {
        this.setState({
            licensingStartDate: data.startDate,
            licensingEndDate: data.endDate,
            userEntered: true,
        }, () => {
            this.postChange();
        });
    }

    updateEpisodeErrors = (errors) => {
        this.setState({
            errors,
        });
    }

    renderAlphaSelect(alphas) {
        let disabled = this.hasSeasonAlphaSettingBeenApplied() || this.props.useEpisodeLevel;
        let disabledClass = disabled ? 'disabled-item' : '';
        return (
            <FormControl>
                <InputLabel shrink={false}><Typography>Alphas</Typography></InputLabel>
                <Select
                    id="alpha-type"
                    className={disabledClass}
                    value={this.state.alpha ? this.state.alpha.externalId : 0}
                    MenuProps={{
                        getContentAnchorEl: null,
                        anchorOrigin: {
                            vertical: "bottom",
                            horizontal: "left",
                        }
                    }}
                    style={{
                        width: 150,
                        marginTop: 40,
                        marginRight: 10,
                        marginBottom: 15
                    }}
                    onClick={e => e.stopPropagation()}
                    onChange={e => this.handleSelection(e)}
                    disabled={disabled}
                >
                    {alphas.map(alpha => (
                        <MenuItem key={'alphaId' + alpha.externalId} value={alpha.externalId}>
                            <Typography>{alpha.alphaName}</Typography>
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        )
    }

    getTitle = () => {
        let { title: episodeTitle, alphaTitle } = this.state;
        return this.props.useEpisodeLevel ? episodeTitle : (alphaTitle || episodeTitle);
    }

    render() {
        let { alphas, alphaNotFound } = this.state;
        let disabled = (!this.props.useEpisodeLevel && alphaNotFound);
        let props = this.props;
        let title = this.getTitle();
        let errorsOn = !disabled ? this.props.errorsEnabled && !_.isEmpty(this.state.errors) : false;
        let defaultClassName = errorsOn ? this.props.focusErrorHandler(errorsOn) : 'normal';
        return this.state.loadingSpinner ?
            this.props.renderLoader() :
            (
                <ExpansionPanel defaultExpanded={true} className={`panel ${defaultClassName}`} disabled={disabled}>
                    <ExpansionPanelSummary expandIcon={<ArrowDropDown />}>
                        <Grid container direction="column">
                            <Grid item>
                                {title ? <Typography component="h5" variant="h5">{title.name}</Typography> : null}
                            </Grid>
                            <Grid item>
                                {title ? this.renderAlphaSelect(alphas) : null}
                            </Grid>
                        </Grid>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails>
                        <Grid container direction="column" justify="space-between">
                            <Grid item component={TitleDetails}>
                                <TitleField label="Episode Number" field="episodeNumber" title={title}
                                    warning="No Episode Number found for this episode" />
                                <TitleField label="Runtime" field="runtime" title={title} warning="No Runtime found for this episode" />
                                <TitleField label="TV Rating" field="rating" title={title} warning="No Rating found for this episode" />
                                <TitleField label="Release Date" field="initialReleaseDate" title={title}
                                    formatFunc={displayDate} warning="No Release Date found for this episode" />
                                <TitleField label="Actors" field="actors" subfield="name"
                                    title={title} warning="No Actors found for this episode" />
                                <TitleField label="Directors" field="directors" subfield="name"
                                    title={title} warning="No Directors found for this episode" />
                                <TitleField label="Genres" field="genres" title={title} warning="No Genres found for this episode" />
                                <TitleField label="Episode MPM" field="mpmWalkerId" title={title}
                                    warning="No Episode MPM found for this episode" />
                                <TitleField label="Short Synopsis" field="shortSynopsis" subfield="text"
                                    title={title} warning="No Short Synopsis found for this episode" />
                                <TitleField label="Long Synopsis" field="longSynopsis" subfield="text"
                                    title={title} warning="No Long Synopsis found for this episode" />
                            </Grid>
                            <Grid item component={FileForm}
                                episodeFile={this.state.episodeFile}
                                episodeArt={this.state.episodeArt}
                                errorsEnabled={props.errorsEnabled}
                                updateErrors={this.updateEpisodeErrors}
                                onChange={this.handleDataFormChange}
                                files={props.files}
                                disabled={disabled}
                            >
                                <DatePicker
                                    label="Licensing Window"
                                    onChange={this.handleDatePickerChange}
                                    startDate={this.state.licensingStartDate}
                                    endDate={this.state.licensingEndDate}
                                    showNotification={props.showNotification}
                                    disabled={disabled}
                                />
                            </Grid>
                            <Grid item component={ClosedCaptionData}
                                files={this.props.files}
                                closedCaptionData={this.state.closedCaption}
                                client={this.props.client}
                                languages={this.props.languages}
                                onChange={this.handleClosedCaption}
                                disabled={disabled}
                            />
                        </Grid>
                    </ExpansionPanelDetails>
                </ExpansionPanel>
            )
    }
}

Episode.propTypes = {
    // title: PropTypes.object.isRequired,
    // client: PropTypes.object.isRequired,
    episode: PropTypes.object.isRequired,
    files: PropTypes.array.isRequired,
    seasonSettings: PropTypes.object,
    onChange: PropTypes.func,
}