import React from "react";

const SvgSidecarLogoPurple = props => (
  <svg
    id="SidecarLogo_Purple_svg__Layer_1"
    x={0}
    y={0}
    viewBox="0 0 176 40"
    xmlSpace="preserve"
    {...props}
  >
    <style>{`.SidecarLogo_Purple_svg__st0{fill:${props.color}`}</style>
    <g id="SidecarLogo_Purple_svg__Components-_x2F_-Logo-_x2F_-Purple---1">
      <g id="SidecarLogo_Purple_svg__Logo">
        <path
          id="SidecarLogo_Purple_svg__SIDECAR"
          className="SidecarLogo_Purple_svg__st0"
          d="M80.2 23.6c0 1.7-.6 3-1.8 3.9-1.2 1-2.9 1.4-5.1 1.4-2 0-3.8-.4-5.3-1.1v-3.6c1.3.6 2.3.9 3.2 1.2.9.2 1.7.3 2.4.3.9 0 1.5-.2 2-.5s.7-.8.7-1.5c0-.4-.1-.7-.3-1s-.5-.6-.9-.8c-.4-.3-1.2-.7-2.4-1.3-1.1-.5-2-1-2.6-1.5-.6-.5-1-1.1-1.4-1.7s-.5-1.4-.5-2.3c0-1.6.6-2.9 1.7-3.9 1.1-.9 2.7-1.4 4.6-1.4 1 0 1.9.1 2.8.3.9.2 1.8.5 2.8 1l-1.3 3c-1-.4-1.8-.7-2.5-.8s-1.3-.2-1.9-.2c-.8 0-1.3.2-1.7.5-.4.3-.6.8-.6 1.4 0 .3.1.6.2.9.2.3.4.5.8.7s1.2.7 2.5 1.3c1.7.8 2.9 1.7 3.6 2.5.7 1 1 2 1 3.2zm3.4 5.1V10.3h4v18.5h-4zm24.2-9.4c0 3-.9 5.4-2.6 7-1.8 1.6-4.3 2.4-7.6 2.4h-5.3V10.3h5.9c3.1 0 5.4.8 7.1 2.4 1.6 1.5 2.5 3.8 2.5 6.6zm-4.2.1c0-4-1.8-6-5.3-6h-2.1v12h1.7c3.8.1 5.7-1.9 5.7-6zm18.8 9.3h-10.8V10.3h10.8v3.2h-6.8v4.1h6.3v3.2h-6.3v4.8h6.8v3.1zm11.8-15.4c-1.5 0-2.6.6-3.5 1.7-.8 1.1-1.2 2.7-1.2 4.6 0 4.1 1.6 6.2 4.7 6.2 1.3 0 2.9-.3 4.8-1v3.3c-1.5.6-3.2.9-5.1.9-2.7 0-4.8-.8-6.2-2.4s-2.2-4-2.2-7c0-1.9.4-3.6 1.1-5.1s1.7-2.6 3.1-3.3c1.3-.8 2.9-1.2 4.7-1.2 1.8 0 3.6.4 5.5 1.3l-1.3 3.2-2.1-.9c-.9-.2-1.6-.3-2.3-.3zm20.2 15.4l-1.4-4.4h-6.8l-1.4 4.4h-4.3l6.6-18.6h4.8l6.6 18.6h-4.1zm-2.3-7.6c-1.3-4-2-6.2-2.1-6.8-.2-.5-.3-.9-.3-1.2-.3 1.1-1.1 3.7-2.4 8h4.8zm12.9-2.6h1.3c1.3 0 2.2-.2 2.8-.6.6-.4.9-1.1.9-1.9 0-.9-.3-1.5-.9-1.9-.6-.4-1.6-.6-2.8-.6H165v5zm0 3.2v7.1h-4V10.3h5.4c2.5 0 4.4.5 5.6 1.4s1.8 2.3 1.8 4.2c0 1.1-.3 2.1-.9 2.9-.6.8-1.5 1.5-2.6 2 2.8 4.2 4.6 6.8 5.5 8.1h-4.4l-4.5-7.1H165z"
        />
        <path
          id="SidecarLogo_Purple_svg__Fill-10"
          className="SidecarLogo_Purple_svg__st0"
          d="M13.4 20.2l22.2-.2-5.2 6.7-17.1.1 6.7 6.7h5L19.6 40h8.7L60 0H0l6.7 6.7h39.4l-5.3 6.7H6.6"
        />
      </g>
    </g>
  </svg>
);

export default SvgSidecarLogoPurple;

