import React, { Component } from "react";
import { Grid, FormControl, InputLabel, MenuItem, Typography } from "@material-ui/core";
import { withStyles } from "@material-ui/styles";
import { ExpandableSection, Dropdown, TextInput, TimecodeInput, InputEditableSelect, SimpleRadioList } from "../common";
import { FileEditableDropdown } from '../FileEditableDropdown/FileEditableDropdown';
import { ProfileInfo, DynamicRange } from './';
import _ from 'lodash';
import activeFormats from './activeFormats';
import { textedLanguages, burnInSubtitleLangugages } from './videoLanguages';
import dubcardLanguages from './dubcardLanguages';

const activeTopLeftX = ['0', '64', '123', '127', '220', '136', '240', '246', '262', '440', '480', '484', '516'];
const activeTopLeftY = ["0", "1", "9", "10", "11", "12", "21", "22", "27", "32", "34", "35", "40",
    "41", "42", "45", "46", "47", "48", "50", "55", "57", "58", "59", "60",
    "62", "63", "67", "68", "70", "71", "72", "73", "74", "75",
    "80", "81", "84", "85", "87", "91", "93", "95", "96", "102", "104",
    "106", "107", "109", "113", "115", "120", "124", "125", "127", "128",
    "131", "132", "135", "137", "138", "140", "149", "158", "164",
    "187", "192", "199", "207", "221", "262", "263", "277", "280",
    "281", "299", "315", "327", "336", "349", "384"];

const activeBottomRightX = ["720", "1680", "1700", "1797", "1856", "1919", "1920", "3356", "3360", "3400", "3580", "3594", "3713", "3834", "3840", "3960", "4096"];
const activeBottomRightY = ["356", "365", "367", "373", "374", "376", "385", "389",
    "395", "400", "407", "410", "412", "413", "417", "418", "420", "421", "427",
    "432", "433", "434", "438", "439", "441", "445", "446", "448", "449", "451",
    "453", "463", "467", "470", "471", "474", "480", "489", "492", "495", "496",
    "501", "502", "504", "505", "506", "518", "519", "521", "526", "535", "536",
    "544", "564", "565", "576", "888", "916", "922", "940", "942", "948", "949", "976",
    "987", "1020", "1058", "1059", "1079", "1080", "1776", "1833", "1845", "1880", "1883",
    "1897", "1898", "1953", "1955", "1968", "1973", "2005", "2023", "2024", "2040", "2083",
    "2105", "2118", "2160", "2176", "2259", "2304"]

const activePictureAspectRatios = ["1.33", "1.37", "1.40", "1.55", "1.66", "1.77", "1.78", "1.85", "2.00", "2.15", "2.20", "2.35", "2.39", "2.40", "2.51", "2.55", "2.76"];
const activePictureFormats = ['Full Frame', 'Letterboxed', 'Pillarboxed'];
const forcedSubtitle = ['None', 'Present', 'Removed'];

const style = (theme) => ({
    formControl: {
        top: 2,
    },
    radioForm: {
        top: 24,
    },
    label: {
        fontSize: 14,
        color: '#333333',
        fontWeight: 700,
    },
    input: {
        marginTop: 40,
        width: '12vw',
    },
    labelRequired: {
        fontSize: 14,
        color: "#5e5edb",
        fontWeight: 700,
    },
    formLabel: {
        fontSize: 14,
        color: "#333333",
        fontWeight: 700
    },
    radioGroup: {
        display: "inline"
    }
});

const initialState = {
    file: null,
    sourceTapeBarcode: "",
    profile: {
        type: "",
        type3d: "N/A",
        scanningFormat: "",
        chromaSubsampling: "",
        colorPrimaries: "",
        videoColorSpace: "",
        videoFormat: "",
        encodingProfile: "",
        videoStandard: "",
        screenAspectRatio: "",
        horizontalResolution: "",
        verticalResolution: "",
        frameRate: "",
    },
    textedLanguage: "",
    burnedInSubtitle: "",
    activePictureAspectRatio: "",
    activeTopLeftX: "",
    activeTopLeftY: "",
    activeBottomRightX: "",
    activeBottomRightY: "",
    activePictureFormat: "",
    forcedSubtitle: "",
    isMlfCore: "",
    runtime: "",
    dynamicRangeValues: {
        type: "SDR",
        maxCll: "",
        maxFall: "",
        bx: "",
        by: "",
        gx: "",
        gy: "",
        rx: "",
        ry: "",
        lMax: "",
        lMin: "",
        transferFunction: "",
    }
};
const debounceTime = 550;

const setProfileFromEbucoreData = (ebucore, profile) => {
    let {
        width: horizontalResolution,
        height: verticalResolution,
        aspectRatio,
        videoColorSpace,
        colourPrimaries: colorPrimaries,
    } = ebucore;

    let screenAspectRatio = aspectRatio ? aspectRatio.replace(':', 'x') : null;
    return Object.assign(
        {},
        profile,
        ebucore,
        {
            horizontalResolution,
            verticalResolution,
            screenAspectRatio,
            videoColorSpace,
            colorPrimaries,
        });
}

class VideoData extends Component {
    constructor(props) {
        super(props);
        let defaultState = props.videoData || {};
        this.state = Object.assign({}, initialState, defaultState);
        this.handleFilenameChange = _.debounce(this.handleFilenameChange, debounceTime);
        this.handleInputChange = _.debounce(this.handleInputChange, debounceTime);
    }

    componentDidMount() {
        this.postChange();
    }

    postChange = () => {
        this.props.updateErrors(this.props.updateErrorLabel, !this.hasErrors());
        let isFilenameFilled = this.state.file && !_.isEmpty(this.state.file.filename);
        if (isFilenameFilled) {
            this.props.onChange(this.state);
        }
    }

    handleFilenameChange = (label, event) => {
        let isFilenameEmpty = _.isEmpty(event.target.value);
        let filename = !isFilenameEmpty ? event.target.value : null;
        let ebucore = !isFilenameEmpty ? _.find(this.props.files, { filename }) : null;
        let file = !isFilenameEmpty ? { filename } : null;
        let profile = { ...this.state.profile };

        if (ebucore) {
            file = { ebucore, filename }
            profile = setProfileFromEbucoreData(ebucore, this.state.profile);

        }

        this.setState({
            file,
            profile
        }, () => {
            this.postChange();
        });
    }

    handleInputChange = (label, value) => {
        this.setState({
            [label]: value,
        }, () => {
            this.postChange();
        });
    }

    handleInputSelect = (label) => (value) => {
        this.handleInputChange(label, value);
    }

    handleProfileChange = (profile) => {
        this.setState({
            profile,
        }, () => {
            this.postChange();
        })
    }

    getActiveData = (state) => {
        let { activePictureAspectRatio } = state;
        let { screenAspectRatio, horizontalResolution, verticalResolution } = state.profile;
        if (screenAspectRatio && horizontalResolution &&
            verticalResolution && activePictureAspectRatio) {
            let activeData = _.find(activeFormats, {
                screenAspectRatio,
                horizontalResolution,
                verticalResolution,
                activePictureAspectRatio
            });

            if (activeData) {
                let { activePictureFormat, activeTopLeftX, activeTopLeftY,
                    activeBottomRightX, activeBottomRightY } = activeData;
                return Object.assign({}, state, {
                    activePictureFormat,
                    activeTopLeftX,
                    activeTopLeftY,
                    activeBottomRightX,
                    activeBottomRightY,
                });
            }
            return state;
        }
        return state;
    }

    handleDynamicRangeChange = (dynamicRangeValues) => {
        this.setState({
            dynamicRangeValues,
        }, () => {
            this.postChange();
        })
    }

    handleChange = (label, value) => {
        let newState = Object.assign({}, this.state);
        newState[label] = value;
        newState = this.getActiveData(newState);
        this.setState(newState, () => {
            this.postChange();
        });
    }

    hasErrors = () => {
        if (this.state.file && !_.isEmpty(this.state.file.filename)) {
            // check for errors
            let requiredFields = Object.keys(this.props.validate);
            let hasError = false;
            _.forEach(requiredFields, (field) => {

                switch (field) {

                    case "profile":
                        let hasEncodingProfile = this.state.profile.hasOwnProperty('encodingProfile');
                        if (hasEncodingProfile) {
                            hasError = _.isEmpty(this.state.profile.encodingProfile);
                        }
                        break;

                    case "dynamicRange":
                        let isHdr = this.state.dynamicRangeValues.type.toLowerCase() === 'hdr';
                        if (isHdr) {
                            let dynamicRangeValues = this.state.dynamicRangeValues;
                            _.forEach(dynamicRangeValues, (value, key) => {
                                if (_.isEmpty(value)) {
                                    hasError = true;
                                }
                            })
                        }
                        break;

                    default:
                        if (_.isEmpty(this.state[field])) {
                            hasError = true;
                        }
                }
            })
            return hasError;
        } else {
            return false;
        }
    }

    checkIfComponentHasError = (name) => {
        if (this.state.file && !_.isEmpty(this.state.file.filename)) {
            return _.isEmpty(this.state[name]);
        }
        return false;
    }

    handleSimpleRadioList = (label, value) => {
        this.setState({
            [label]: value
        });
    }

    render() {
        let { classes, files, errorsEnabled, renderButtons } = this.props;
        let filename = this.state.file ? this.state.file.filename : '';
        let isFilenameFilled = !!filename.length;
        let hasDubcard = this.state.dubcardPresent === 'Yes';

        errorsEnabled = errorsEnabled && isFilenameFilled;
        let hasErrors = errorsEnabled && this.hasErrors();

        let errorsFocusClass = errorsEnabled && this.props.focusErrorHandler(hasErrors);
        let defaultFocusClass = errorsFocusClass ? errorsFocusClass : 'normal';
        return (
            <Grid item component={ExpandableSection} header="Video Data" defaultExpanded={true}
                focusErrorCssStyle={defaultFocusClass} renderButtons={renderButtons}>
                <Grid container direction="row" justify="space-between">

                    <Grid item>
                        <FormControl className={classes.formControl}>
                            <InputLabel shrink={false} className={classes.label}>Filename</InputLabel>
                            <FileEditableDropdown
                                id="filename"
                                onChange={this.handleFilenameChange}
                                field={'file'}
                                disabled={this.props.disabled}
                                value={filename}
                                files={files}
                                style={{
                                    marginTop: 40,
                                    width: '56vw',
                                }}
                            />
                        </FormControl>
                    </Grid>

                    <Grid item component={TextInput}
                        label="Source Tape Barcode # (if available)"
                        type="text"
                        value={this.state.sourceTapeBarcode}
                        field="sourceTapeBarcode"
                        onChange={this.handleInputChange}
                        error={false}
                        style={{
                            width: '24vw'
                        }}
                    />

                    <Grid item component={ExpandableSection}
                        header="Profile Information" defaultExpanded={true}>
                        <Grid item component={ProfileInfo}
                            profile={this.state.profile}
                            errorsEnabled={errorsEnabled}
                            isFilenameFilled={isFilenameFilled}
                            handleProfileChange={this.handleProfileChange}
                        />
                    </Grid>

                    <Grid container justify="space-between">
                        <Grid item>
                            <Grid container justify="space-between">
                                <Grid item component={Dropdown}
                                    label="Active Picture Aspect Ratio"
                                    required={isFilenameFilled}
                                    field={'activePictureAspectRatio'}
                                    onChange={this.handleChange}
                                    value={this.state.activePictureAspectRatio}
                                    error={errorsEnabled && this.checkIfComponentHasError('activePictureAspectRatio')}
                                >
                                    {
                                        _.map(activePictureAspectRatios, (ratio, index) => {
                                            return (
                                                <MenuItem key={index} value={ratio}>
                                                    <Typography noWrap={true}>{ratio}</Typography>
                                                </MenuItem>
                                            )
                                        })
                                    }
                                </Grid>

                                <Grid item component={Dropdown}
                                    label="Active Top Left 'X'"
                                    required={isFilenameFilled}
                                    field={'activeTopLeftX'}
                                    onChange={this.handleChange}
                                    value={this.state.activeTopLeftX}
                                    error={errorsEnabled && this.checkIfComponentHasError('activeTopLeftX')}
                                >
                                    {
                                        _.map(activeTopLeftX, (value, index) => {
                                            return (
                                                <MenuItem key={index} value={value}>
                                                    <Typography noWrap={true}>{value}</Typography>
                                                </MenuItem>
                                            )
                                        })
                                    }
                                </Grid>

                                <Grid item component={Dropdown}
                                    label="Active Top Left 'Y'"
                                    required={isFilenameFilled}
                                    field={'activeTopLeftY'}
                                    onChange={this.handleChange}
                                    value={this.state.activeTopLeftY}
                                    error={errorsEnabled && this.checkIfComponentHasError('activeTopLeftY')}
                                >
                                    {
                                        _.map(activeTopLeftY, (value, index) => {
                                            return (
                                                <MenuItem key={index} value={value}>
                                                    <Typography noWrap={true}>{value}</Typography>
                                                </MenuItem>
                                            )
                                        })
                                    }
                                </Grid>

                                <Grid item component={Dropdown}
                                    label="Active Bottom Right 'X'"
                                    required={isFilenameFilled}
                                    field={'activeBottomRightX'}
                                    onChange={this.handleChange}
                                    value={this.state.activeBottomRightX}
                                    error={errorsEnabled && this.checkIfComponentHasError('activeBottomRightX')}
                                >
                                    {
                                        _.map(activeBottomRightX, (value, index) => {
                                            return (
                                                <MenuItem key={index} value={value}>
                                                    <Typography noWrap={true}>{value}</Typography>
                                                </MenuItem>
                                            )
                                        })
                                    }
                                </Grid>

                                <Grid item component={Dropdown}
                                    label="Active Bottom Right 'Y'"
                                    required={isFilenameFilled}
                                    field={'activeBottomRightY'}
                                    onChange={this.handleChange}
                                    value={this.state.activeBottomRightY}
                                    error={errorsEnabled && this.checkIfComponentHasError('activeBottomRightY')}
                                >
                                    {
                                        _.map(activeBottomRightY, (value, index) => {
                                            return (
                                                <MenuItem key={index} value={value}>
                                                    <Typography noWrap={true}>{value}</Typography>
                                                </MenuItem>
                                            )
                                        })
                                    }
                                </Grid>

                                <Grid item component={Dropdown}
                                    label="Active Picture Format"
                                    required={isFilenameFilled}
                                    field={'activePictureFormat'}
                                    onChange={this.handleChange}
                                    value={this.state.activePictureFormat}
                                    error={errorsEnabled && this.checkIfComponentHasError('activePictureFormat')}
                                >
                                    {
                                        _.map(activePictureFormats, (value, index) => {
                                            return (
                                                <MenuItem key={index} value={value}>
                                                    <Typography noWrap={true}>{value}</Typography>
                                                </MenuItem>
                                            )
                                        })
                                    }
                                </Grid>

                                <Grid item>
                                    <FormControl className={classes.formControl} required={isFilenameFilled}>
                                        <InputLabel shrink={false}
                                            className={isFilenameFilled ? classes.labelRequired : classes.label}>
                                            Texted Language
                            </InputLabel>
                                        <InputEditableSelect
                                            onChange={this.handleInputSelect('textedLanguage')}
                                            value={this.state.textedLanguage}
                                            error={errorsEnabled && this.checkIfComponentHasError('textedLanguage')}
                                            collection={textedLanguages}
                                            style={{
                                                marginTop: 40,
                                                width: '12vw'
                                            }}
                                        />
                                    </FormControl>
                                </Grid>

                                <Grid item>
                                    <FormControl className={classes.formControl} required={isFilenameFilled}>
                                        <InputLabel shrink={false}
                                            className={isFilenameFilled ? classes.labelRequired : classes.label}>
                                            Burned in Subtitle Language
                            </InputLabel>
                                        <InputEditableSelect
                                            onChange={this.handleInputSelect('burnedInSubtitle')}
                                            value={this.state.burnedInSubtitle}
                                            error={errorsEnabled && this.checkIfComponentHasError('burnedInSubtitle')}
                                            collection={burnInSubtitleLangugages}
                                            style={{
                                                marginTop: 40,
                                                width: '12vw'
                                            }}
                                        />
                                    </FormControl>
                                </Grid>

                                <Grid item component={Dropdown}
                                    label="Forced Subtitle"
                                    required={isFilenameFilled}
                                    field={'forcedSubtitle'}
                                    onChange={this.handleChange}
                                    value={this.state.forcedSubtitle}
                                    error={errorsEnabled && this.checkIfComponentHasError('forcedSubtitle')}
                                >
                                    {
                                        _.map(forcedSubtitle, (value, index) => {
                                            return (
                                                <MenuItem key={index} value={value}>
                                                    <Typography noWrap={true}>{value}</Typography>
                                                </MenuItem>
                                            )
                                        })
                                    }
                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid item>
                            <Grid container justify="space-between">
                                <Grid item>
                                    <SimpleRadioList heading="Is MLF Core?"
                                        field="isMlfCore"
                                        value={this.state.isMlfCore}
                                        items={['Yes', 'No']}
                                        onChange={this.handleChange} />
                                </Grid>

                                <Grid item>
                                    <SimpleRadioList heading="Dub Card Present?"
                                        field="dubcardPresent"
                                        value={this.state.dubcardPresent}
                                        items={['Yes', 'No']}
                                        onChange={this.handleChange} />
                                </Grid>
                                {hasDubcard ?
                                    <Grid item>
                                        <FormControl className={classes.formControl} required={isFilenameFilled}>
                                            <InputLabel shrink={false}
                                                className={isFilenameFilled ? classes.labelRequired : classes.label}>
                                                Dub Card Language
                                    </InputLabel>
                                            <InputEditableSelect
                                                onChange={this.handleInputSelect('dubcardLanguage')}
                                                value={this.state.dubcardLanguage}
                                                error={errorsEnabled && hasDubcard && this.checkIfComponentHasError('dubcardLanguage')}
                                                collection={dubcardLanguages}
                                                style={{
                                                    marginTop: 40,
                                                    width: '12vw'
                                                }}
                                            />
                                        </FormControl>
                                    </Grid> : null}
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid item component={TimecodeInput}
                        label="Runtime"
                        onChange={this.handleInputChange}
                        value={this.state.runtime}
                        required={isFilenameFilled}
                        field="runtime"
                        error={errorsEnabled && this.checkIfComponentHasError('runtime')}
                        type="number"
                    />

                    <Grid item component={DynamicRange}
                        isFilenameFilled={isFilenameFilled}
                        handleDynamicRangeChange={this.handleDynamicRangeChange}
                        dynamicRangeValues={this.state.dynamicRangeValues}
                        errorsEnabled={errorsEnabled}
                    />

                </Grid>
            </Grid>
        );
    }
}

const styled = withStyles(style)(VideoData);
export { styled as VideoData };