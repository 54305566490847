import React from "react";

const SvgUpload = props => (
  <svg viewBox="0 0 16 16" {...props} width="1em" height="1em">
    <path
      d="M4.8 15H1.6S0 15.2 0 13.1v-2.8.2c0-.3 0-.5 1-.5s1 .2 1 .4v-.2V13h12v-2.6c0-.2.4-.4 1-.4s1 .2 1 .4v-.1 2.8s0 1.7-1.9 1.9H4.8zM8.3 1c.1 0 .1 0 0 0 .1 0 .1.1.1.1s1.2 1.4 3.5 4.2c.1.1.1.2 0 .3l-1 .7c-.1.1-.2.1-.3 0L9 4.3v7.5c0 .1-.1.2-.2.2H7.2c-.1 0-.2-.1-.2-.2V4.3c-.5.5-.9.9-1.1 1.2-.2.3-.4.6-.6.8-.1.1-.2.1-.3 0l-.9-.7C4 5.5 4 5.4 4 5.3l3.7-4.2h.1c.1-.1.2-.1.5-.1z"
      fill="currentColor"
    />
  </svg>
);

export default SvgUpload;
