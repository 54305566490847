import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import _ from 'lodash';
import moment from "moment";
import './Clientform.styles.scss';

// components
import { Grid, Paper } from '@material-ui/core';
import { ClientSelect } from './ClientSelect';
import { TitleSearchList } from '../TitleSearchList/TitleSearchList';
import { TaskIdInput } from '../TaskIdInput/TaskIdInput';
import { ErrorSnackBar } from '../SnackBar/ErrorSnackBar';
import { TitleSearchMultiInput } from '../TitleSearchMultiInput/TitleSearchMultiInput';

//actions
import NotifierAction from '../../_actions/notifier.actions';
import { ResetStoreActions } from '../../_actions/resetstore.actions';
import { TitleActions } from '../../_actions/title.actions';
import { ClientActions } from '../../_actions/client.actions';
import { TaskIdActions } from '../../_actions/taskId.actions';

//services
import { ClientspecService } from '../../_services/clientspec.service';

class ClientForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      language: 'en',
      showTitleModal: false,
      loadingSpinner: false,
      defaultTerritory: {
        name: 'U.S.A. (English)',
        sourceTerritory: 'U.S./English',
        locale: 'en-US',
        alphaRating: 'U.S.A. or U.S.A[excl t\'s & p\'s]'
      },
    };

    this.deboucedTaskIdSelection = _.debounce((e) => {
      this.props.setTaskIds(e.target.value);
    }, 550);
  }

  handleClientSelection = (event) => {
    let client = _.find(this.props.clients, { id: event.target.value} );
    if (!client)
      return;

    this.props.changeClient(client);
    if (this.props.title) {
      this.showWarningIfExistingDelivery(client, this.props.title);
      this.props.onClientChange(client, this.props.title);
    }
  }

  showWarningIfExistingDelivery = (client, title) => {
    let titleId = title.alphaId || title.gpmsId;
    ClientspecService.getDeliveries(client.id, titleId).then((resp)=> {
      let existingDelivery = _.head(resp.data.deliveries);
      if (existingDelivery) {
        let deliveryDate = moment(existingDelivery.manifestCreationDate);
        let msg = `Warning: This title was previously delivered to this client on ${deliveryDate.format('MM-DD-YYYY')}`;
        this.props.showNotification(true, msg);
      }
    }, (ex) => {
      console.error(`Failed to retrieve previous delivery-${ex.message}`);
    });
  }

  handleTaskIdSelection = (e) => {
    e.persist();
    this.deboucedTaskIdSelection(e);
  }

  showTitleSearch = () => {
    let { client } = this.props;

    if (!client) {
      return false;
    }

    return client.deliveryModel !== "distributionMetadata" && client.name !== "Exhibition Metadata Multi";
  }

  render() {
    const clients = this.props.clients;
    const showSearch = this.showTitleSearch();
    return(
      <Paper className="client-data card-padding" elevation={8} style={{
        marginBottom: 40,
      }}>
        <ErrorSnackBar />
        <Grid container
           direction="row"
           justify="space-between"
           component="section"
           className="client-data-wrapper">

          <Grid item component={ClientSelect}
            clients={clients}
            handleClientChange={this.handleClientSelection}
            selectedClient={this.props.client}
            />

          <Grid item className="title-search">
            { showSearch ?
            <TitleSearchList
              client={this.props.client}
              titles={this.props.titles}
              onTitleSelected={this.props.selectTitle}
              onTitleRemoved={this.props.removeTitle}
              onAddTitleEntry={this.props.addTitleEntry}
            />
            : null
            }

            <TaskIdInput
              client={this.props.client}
              taskIds={this.props.taskIds}
              handleChange={this.handleTaskIdSelection}
              showNotification={this.props.showNotification}
            ></TaskIdInput>

            <TitleSearchMultiInput
              client={this.props.client}
              showNotification={this.props.showNotification}
            ></TitleSearchMultiInput>

          </Grid>
        </Grid>
      </Paper>
    );
  }
}

ClientForm.propTypes = {
  titles: PropTypes.array.isRequired,
  clients: PropTypes.array.isRequired,
  client: PropTypes.object
};

let mapStateToProps = (state) => {
  let { titles } = state.titles;
  let { taskIds } = state.taskIds;

  return {
    titles,
    taskIds,
  }
}

let mapDispatchToProps = (dispatch) => {
  let showNotification = NotifierAction.showNotification;
  let { selectTitle, removeTitle, addTitleEntry } = TitleActions;
  let { changeClient } = ClientActions;
  let { resetStore } = ResetStoreActions;
  let { setTaskIds } = TaskIdActions;

  return {
    addTitleEntry: () => {
      dispatch(addTitleEntry());
    },
    selectTitle: (title, index) => {
      dispatch(selectTitle(title, index));
    },
    removeTitle: (title, index) => {
      dispatch(removeTitle(title, index));
    },
    setTaskIds: (taskIds) => {
      dispatch(setTaskIds(taskIds));
    },
    changeClient: (client) => {
      dispatch(resetStore());
      dispatch(changeClient(client));
    },
    showNotification: (errorStatus, msg) => {
      dispatch(showNotification(errorStatus, msg, 'warning'));
    },
    resetStore: () => {
      dispatch(resetStore());
    },
  }
}

let connected = connect(mapStateToProps, mapDispatchToProps)(ClientForm);
export { connected as ClientForm };
