import React from "react";

const SvgAlertEnclosedExclamation = props => (
  <svg viewBox="0 0 16 16" {...props} width="1em" height="1em">
    <path
      d="M8 14c-.6 0-1-.4-1-1s.4-1 1-1 1 .4 1 1-.4 1-1 1zM7 4.4c0-.2.2-.4.5-.4h1c.3 0 .5.2.5.4v5.2c0 .2-.2.4-.5.4h-1c-.3 0-.5-.2-.5-.4V4.4zm1.6-3C8.5 1.1 8.3 1 8 1s-.5.1-.6.4L.1 13.9c-.1.2-.1.5 0 .7.1.3.4.4.6.4h14.6c.4 0 .7-.3.7-.7 0-.2-.1-.3-.1-.4L8.6 1.4z"
      fill="currentColor"
    />
  </svg>
);

export default SvgAlertEnclosedExclamation;
