import * as React from "react";
import SvgIcon from '@material-ui/core/SvgIcon';

function Drag(props) {

  return (
    <SvgIcon {...props}>
      <path
        d="M12 11l-4 5-4-5h8zm0-2.5v1H4v-1h8zm0-2v1H4v-1h8zM8 0l4 5H4l4-5z"
      />
    </SvgIcon>
  );

  //viewBox="0 0 16 16" width="1em" height="1em"
}

export default Drag;

