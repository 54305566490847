import React, { Component} from 'react';
import { Grid, FormControl, TextField } from '@material-ui/core';
import { ExpandableSection } from '../common';

export class TagsData extends Component {

    constructor(props) {
        super(props);
        let { data=[] } = props;
        this.state = {
            tags: data.join(', ')
        };
    }

    handleChange = (event) => {
        let value = event.target.value;
        this.setState({
            tags: value
        }, () => {
            let listWithoutEmptyValues = this.state.tags.split(',').filter(
                (value) => value.trim() !== ''
            );
            let tagsList = listWithoutEmptyValues.map((tag) => tag.trim());
            this.props.handleChildChange('tags', tagsList);
        });
    }

    render() {
        return (
            <Grid container component={ExpandableSection} header="tags" 
                defaultExpanded={true} focusErrorCssStyle="normal">
                <Grid item container direction="column"
                    justify="space-between"
                    className="data-pane"
                >
                    <Grid item container>
                        <FormControl>
                            <TextField placeholder="Enter tags here seperated by comma"
                                value={this.state.tags}
                                onChange={(e) => this.handleChange(e)}
                                style={{
                                    marginTop: 20,
                                    width: '36vw'
                                }}
                            />
                        </FormControl>
                    </Grid>
                </Grid>
            </Grid>
        )
    }
}