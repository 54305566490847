import React, { Component } from 'react';
import { Grid, Button, Radio, FormControl, FormLabel, RadioGroup, FormControlLabel } from '@material-ui/core';
import { ExpandableSection } from '../common';
import { Add, CheckCircle } from "@material-ui/icons";
import { TimecodeDetails, DraggableTimecodeProgramField,
 DraggableTimecodeField } from './';
import _ from 'lodash';
import update from 'immutability-helper';
import Backend from 'react-dnd-html5-backend';
import { DndProvider} from 'react-dnd';
import { withStyles } from '@material-ui/styles';


const style = (theme) => ({
    formControl: {
        marginVertical: 15
    },
    radioGroup: {
        display: "inline"
    }
});

let id = 0;
const CHRONOLOGICAL_ERROR = 'Please make sure the timecode data is in chronological order';
const generateTimecodeField = (type, inValue='', outValue='') => {
    id++;
    return {
        id,
        [type]: {
            in: inValue,
            out: outValue,
        }
    }
}

const initialState = [
    generateTimecodeField('black', '', ''),
    generateTimecodeField('barsAndTones', '', ''),
    generateTimecodeField('programSlate', '', ''),
    generateTimecodeField('black', '', ''),
    generateTimecodeField('programStart', '', ''),
    generateTimecodeField('programEnd', '', ''),
    generateTimecodeField('black', '', ''),
    generateTimecodeField('additionalElements', '', ''),
    generateTimecodeField('black', '', ''),
];

class TimecodeData extends Component {

    constructor(props) {
        super(props);
        this.state = {
            timecodeData: props.timecodeData || [].concat(initialState),
            newLabel: '',
            frameRateLimit: parseFloat(props.frameRateLimit),
            isNonDropFrame: 'yes'
        };
    }

    componentDidUpdate(prevProps) {
        if(prevProps.frameRateLimit !== this.props.frameRateLimit) {
            let newFrameRateLimit = this.props.frameRateLimit;
            this.setState({
                frameRateLimit: parseFloat(newFrameRateLimit),
            });
        }
    }

    hasErrors = () => {
        // use some or every lodash function to see if every error is falsy
        let errors = this.state.timecodeData.map((data, index) => {
            let error = false;

            for(let value in data) {
                error = data[value].error || false;
            }

            return error;
        });

        return _.every(errors, (error) => error === false);
    }

    postChange = () => {
        let isEveryErrorFalse = this.hasErrors();
        this.props.updateErrors('timecode', isEveryErrorFalse);
        this.props.onChange('timecode', this.state.timecodeData);
    }

    checkIftheDataIsInOrder = (timecodeData) => {
        // check if the every OUT value is less than upcoming IN value
        let data = [].concat(timecodeData);
        let outValue = [];
        let inValue = [];
        for(var i = 0; i < timecodeData.length; i++) {
            outValue = this.getSplitArray(timecodeData[i], 'out');
            for(var j = i+1; j < timecodeData.length; j++) {
                inValue = this.getSplitArray(timecodeData[j], 'in');
                if(!_.isEmpty(outValue) && !_.isEmpty(inValue)) {
                    let isValid = this.isOutTimeLessThanInTime(outValue, inValue);
                    if(!isValid) {
                        let newValue = this.getValueOfProp(timecodeData[j], !isValid);
                        this.props.showNotification(!isValid,CHRONOLOGICAL_ERROR);
                        data[j] = { ...data[j], ...newValue };
                    }
                }
            }
        }
        return data;
    }

    getValueOfProp = (obj, error = false) => {
        /*
            TO access properties from array of object like of
            "black", "barsAndTones" etc
        */
        let result = '';
        for(var value in obj) {
            result = {
                [value]: Object.assign({}, obj[value], { error }),
            }
        }
        return result;
    }

    getSplitArray = (obj, propLabel) => {
        /*
            This is because the structure is
            var arr = [ { "black": { in: "02:02:02:02", out: "03:03:03:03", error: false } }]

            The input of this function is: { "black": { "in": ...............}}
        */
        let result = "";
        for(var value in obj) {
            result = obj[value][propLabel];
        }
        return result ? result.replace(/;/,":").split(":") : ""
    }

    isOutTimeLessThanInTime = (currentOut, nextIn) => {
        let inValueSeconds = this.getSeconds(nextIn);
        let outValueSeconds = this.getSeconds(currentOut);
        let inValueFrames = +nextIn[3];
        let outValueFrames = +currentOut[3];
        let frameRateError = false;
        if(inValueSeconds === outValueSeconds) {
            frameRateError = outValueFrames < inValueFrames;
            return frameRateError;
        }
        return outValueSeconds <= inValueSeconds;
    }

    getSeconds = (arr) => {
        return (+arr[0]) * 60 * 60 + (+arr[1]) * 60 + (+arr[2]);
    }

    handleAddRow = () => {
        let newState = Object.assign({}, this.state);
        newState.timecodeData = newState.timecodeData.concat(
            [generateTimecodeField('', '', '')]);
        this.setState({
            timecodeData: newState.timecodeData,
        })
    }

    handleDeleteRow = (deletedIndex) => {
        let timecodeData = this.state.timecodeData.filter((data, index) => {
            return index !== deletedIndex;
        });
        this.setState({
            timecodeData,
        }, () => {
            this.postChange();
        })
    }

    onNewTagSelection = (e, index) => {
        let value = e.target.value;
        if(_.isEmpty(value)) {
            return;
        }
        let newState = Object.assign({}, this.state);
        if(index) {
            newState.timecodeData[index] = generateTimecodeField(_.camelCase(value), '', '');
        }
        this.setState(newState, () => {
            this.postChange();
        });
    }

    handleChange = (label, value, index = null) => {
        let newState = Object.assign({}, this.state);
        let oldValue = newState.timecodeData[index];
        newState.timecodeData[index] = { ...oldValue, [label]: value };

        newState.timecodeData = this.checkIftheDataIsInOrder(newState.timecodeData);

        this.setState(() => {
            return newState;
        }, () => {
            this.postChange();
        })
    }

    getDatakeyForTimecode = (timecodeItem) => {
        let keys = Object.keys(timecodeItem);
        let dataKey = _.reject(keys, (key) => {
            return ["id", "error"].includes(key);
        })[0];
        return dataKey;
    }

    moveTimeCode = (dragIndex, hoverIndex) => {
        let draggedItem = this.state.timecodeData[dragIndex];
        let hoveredItem = this.state.timecodeData[hoverIndex];

        let datakeyForDrag = this.getDatakeyForTimecode(draggedItem);
        let datakeyForHover = this.getDatakeyForTimecode(hoveredItem);

        // let draggedData = draggedItem[datakeyForDrag];
        // let hoveredData = hoveredItem[datakeyForHover];
        // console.log(`dragged ${JSON.stringify(draggedData)}`);
        // console.log(`hovered ${JSON.stringify(hoveredData)}`);

        let newTimecodeData = update(this.state.timecodeData, {
            $splice: [
            [dragIndex, 1],
            [hoverIndex, 0, draggedItem]
            ]
        });

        this.setState({timecodeData: newTimecodeData }, () => {
            this.checkIftheDataIsInOrder(newTimecodeData);
        });
    }

    handleRadioSelection = (label) => (e) => {
        let value = e.target.value;
        this.setState({
            [label]: value,
        }, () => {
            this.postChange();
        })
    }

    render() {
        let { timecodeData, displayCloseIcon, isNonDropFrame } = this.state;
        let focusErrorClass = this.props.focusErrorHandler(!this.hasErrors());
        let defaultFocusClass = focusErrorClass ? focusErrorClass : 'normal';
        let { classes} = this.props;

        return (
            <Grid item component={ExpandableSection} header="Timecode Data" defaultExpanded={true} focusErrorCssStyle={defaultFocusClass}>
                <Grid container direction="column">
                    <Grid item>
                        <FormControl component="fieldset" className={classes.formControl}>
                        <FormLabel component="label"></FormLabel>
                            <RadioGroup className={classes.radioGroup}
                                    value={isNonDropFrame}
                                    onChange={this.handleRadioSelection('isNonDropFrame')}>
                                <FormControlLabel
                                    value="yes"
                                    control={<Radio checkedIcon={<CheckCircle />}/>}
                                    label="Non-Drop frame"
                                />
                                <FormControlLabel
                                    value="no"
                                    control={<Radio checkedIcon={<CheckCircle />}/>}
                                    label="Drop frame"
                                />
                            </RadioGroup>
                        </FormControl>
                    </Grid>
                <Grid container direction="column" justify="space-between">
                    <Grid container component={TimecodeDetails} direction="column" justify="space-between">
                        <DndProvider backend={Backend}>
                        {
                            timecodeData.map((data, index) => {
                                let fields = Object.getOwnPropertyNames(data);
                                let field = _.find(fields, (field) => {
                                    return field !== 'id';
                                });

                                if(data.hasOwnProperty('programStart') || data.hasOwnProperty('programEnd')) {
                                    return (
                                        <Grid item component={DraggableTimecodeProgramField}
                                            key={data.id}
                                            field={field}
                                            index={index}
                                            onChange={this.handleChange}
                                            value={data}
                                            frameRateLimit={this.state.frameRateLimit}
                                            showNotification={this.props.showNotification}
                                            moveItem={this.moveTimeCode}
                                            type='timecodefield'
                                            dragItem={{id: data.id}}
                                            isNonDropFrame={isNonDropFrame}
                                        />
                                    )
                                } else {
                                    return (
                                        <Grid item component={DraggableTimecodeField}
                                            key={data.id}
                                            field={field || ''}
                                            fieldIndex={index}
                                            value={data}
                                            frameRateLimit={this.state.frameRateLimit}
                                            handleDeleteRow={this.handleDeleteRow}
                                            onNewTagSelection={this.onNewTagSelection}
                                            displayCloseIcon={displayCloseIcon}
                                            onChange={this.handleChange}
                                            showNotification={this.props.showNotification}
                                            moveItem={this.moveTimeCode}
                                            index={index}
                                            type='timecodefield'
                                            dragItem={{id: data.id}}
                                            isNonDropFrame={isNonDropFrame}
                                        />
                                    )
                                }
                            })
                        }
                        </DndProvider>
                    </Grid>

                    <Grid container justify="space-between">
                        <Grid item>
                            <Button color="primary" onClick={this.handleAddRow}>
                                <Add style={{ marginRight: 12 }} />
                                ADD NEW ROW
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
                </Grid>
            </Grid>
        )
    }
}

const styled = withStyles(style)(TimecodeData);
export { styled as TimecodeData };