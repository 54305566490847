import React from 'react';
import { Grid } from '@material-ui/core';
import { TitleField } from '../common';

const RokuFeatureTitlePane = (props) => {
    let { presentedTitle, displayDate } = props;
    return (
        <Grid container>
            <TitleField label="Title" field="name"
                title={presentedTitle}
                warning="No Title found"
            />

            <TitleField label="EIDR" field="eidr"
                title={presentedTitle}
                warning="EIDR not found"
            />

            <TitleField label="Runtime" field="runtime"
                title={presentedTitle}
                warning="No Runtime found"
            />

            <TitleField label="Rating" field="rating"
                title={presentedTitle}
                warning="No Rating found"
            />

            <TitleField label="Theatrical Release Date" field="initialReleaseDate"
                title={presentedTitle}
                formatFunc={displayDate}
                warning="Theatrical Release Date not found for this title"
            />

            <TitleField label="Genres" field="genres"
                title={presentedTitle}
                warning="No Genres found for this title"
            />

            <TitleField label="Actors" field="actors"
                subfield="name"
                title={presentedTitle}
                warning="No Actors found for this title"
            />

            <TitleField label="Director" field="directors"
                subfield="name"
                title={presentedTitle}
                warning="No Directors found for this title"
            />

            <TitleField label="Producers" field="producers"
                subfield="name"
                title={presentedTitle}
                warning="No Producers found for this title"
            />

            <TitleField label="Composer" field="composers"
                subfield="name"
                title={presentedTitle}
                warning="No Composer found for this title"
            />

            <TitleField label="Screenplay" field="screenplay"
                subfield="name"
                title={presentedTitle}
                warning="No Screenplay found for this title"
            />

            <TitleField label="Synopsis Short" field="shortSynopsis"
                subfield="text"
                title={presentedTitle}
                warning="No synopsis found for this title"
            />

            <TitleField label="Synopsis Long" field="longSynopsis"
                subfield="text"
                title={presentedTitle}
                warning="No synopsis found for this title"
            />
        </Grid>
    )
}

export { RokuFeatureTitlePane };