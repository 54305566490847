import React, { Component } from 'react';
import _ from 'lodash';

function makeErrorObj(defaultState, value = false) {
    let fieldNames = Object.keys(defaultState);
    let errorObj = {}
    _.forEach(fieldNames, (field) => {
        errorObj[`${field}Error`] = value;
    });
    return errorObj;
}

export const withHandleMethods = (ComponentNeedingMethods) => {
    return class methodsHOC extends Component {
        constructor(props) {
            super(props);
            let errors = this.isFeatureData() ? makeErrorObj(props.defaultState.data, true) : {};
            let assignedObj = Object.assign({}, props.defaultState, { errors });
            let dfState = _.cloneDeep(assignedObj);

            this.state = {
                formData: [],
                defaultState: dfState,
                dataFormRow: [
                    _.cloneDeep(assignedObj)
                ],
                fieldError: true,
            }
        }

        componentDidMount() {
            if(this.props.componentName.includes('feature')) {
                let fieldError = this.setFieldError(this.state);
                this.props.updateErrors('feature', !fieldError);
            } else {
                this.setState({
                    fieldError: false
                });
            }
        }

        handleChange = (label, event, index) => {
          let formArray = this.state.dataFormRow.concat([]);
          let newArray = this.state.formData.concat([]);
          let formData = formArray[index];
          let value = event.target.value;

          formData.data[label] = value;
          let obj = Object.assign({}, formData);

          newArray[index] = obj;

          let newState = {
              dataFormRow: formArray,
              formData: newArray,
          }

          let componentName = this.props.componentName;
          let isFeatureOrTrailer = /^(feature|trailer)$/.test(componentName);

          if(newState.formData[index].data['dynamicRange'] === 'SDR') {
            newState.dataFormRow[index].errors = _.omit(
                makeErrorObj(this.props.defaultState.data, false),
                [
                    'maxCllError',
                    'maxFallError',
                    'displayPresetError'
                ]
            );
            formData.data = _.omit(
                newState.formData[index].data,
                [
                    'maxCll',
                    'maxFall',
                    'displayPreset',
                ]
            )
            newState.formData[index].disableFields = true;
            newState.dataFormRow[index].disableFields = true;
          } else {
            if(isFeatureOrTrailer) {
                newState.formData[index].disableFields = false;
                newState.dataFormRow[index].disableFields = false;
                formData.data = _.defaults(newState.formData[index].data, {
                    maxCll: '',
                    maxFall: '',
                    displayPreset: null,
                });
                newState.formData[index].data = formData.data;
            }
          }

          newState.fieldError = false;

          if(newState.formData[index].data['filename'] !== '') {
            let { errorObj, hasErrors  } = this.props.validateForm(formData.data, newState.dataFormRow[index].errors);
            if(hasErrors) {
                newState.dataFormRow[index].errors = Object.assign({}, makeErrorObj(this.props.defaultState.data, false) , errorObj);
                newState.fieldError = this.setFieldError(newState);
            }
          } else {
            newState.formData[index] = this.assignNewState();
            newState.dataFormRow[index] = this.assignNewState();
            newState.fieldError = this.isFeatureData();
          }

          if(this.props.componentName.includes('dub')) {
            this.props.isFieldError(newState.fieldError)
          }

          newState.formData[index].data['ebucore'] = _.find(
              this.props.files, (file) => {
                return newState.formData[index].data.filename === file.filename;
          });

          this.setState(newState, () => {
              let data = newState.formData.map((value) => value.data);
              this.props.updateErrors && this.props.updateErrors(componentName, !this.state.fieldError);
              this.props.handleChildChange(componentName, data);
          });
        }

        assignNewState = () => {
            return _.cloneDeep(this.state.defaultState);
        }

        setFieldError = (newState) => {
            let result = false;
            newState.dataFormRow.forEach((row) => {
                for(let val in row.errors) {
                    if(row.errors[val] === true) {
                        result = true;
                        break;
                    }
                }
            });
            return result;
        }

        isFeatureData = () => {
            return this.props.componentName.includes('feature');
        }


        handleAddRow = () => {
          // Here take a prop for defining the limit the user can add rows and replace it with 2
          let maxRowLimit = this.props.rowLimit || 2;
          if(this.state.dataFormRow.length === maxRowLimit) {
              return;
          }

          let newArray = this.state.dataFormRow.concat([
            _.cloneDeep(this.state.defaultState),
          ]);

          let newState = {
            dataFormRow: newArray,
          }

          newState.fieldError = this.setFieldError(newState);
          this.props.updateErrors(this.props.componentName, !newState.fieldError);

          this.setState(newState)
        }

        handleDeleteRow = (deletedRowIndex) => {
            if(this.state.dataFormRow.length === 1) {
                return;
            }

            let newDataFormRow = this.state.dataFormRow.filter((row, index) => {
                return index !== deletedRowIndex;
            });

            let newFormData = this.state.formData.filter((row, index) => {
                return index !== deletedRowIndex;
            });

            let newState = {
                dataFormRow: newDataFormRow,
                formData: newFormData
            }

            if(newState.formData.length) {
                newState.fieldError = this.setFieldError(newState);
                this.props.updateErrors(this.props.componentName, !newState.fieldError);
            }

            this.setState(newState)
        }

        render() {
            return <ComponentNeedingMethods {...this.props}
                fieldError={this.state.fieldError}
                formData={this.state.formData}
                dataFormRow={this.state.dataFormRow}
                handleChange={this.handleChange}
                handleAddRow={this.handleAddRow}
                handleDeleteRow={this.handleDeleteRow}
            />
        }
    }
}

