import React, { useState } from 'react';
import { clientShow } from '../Clientshow/clientshow';
import SvgSearch from "../../assets/icons/Search";
import { TextField, InputAdornment, InputLabel, Fab, Grid, Modal} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { TaskDataModal } from './TaskDataModal';

const placeholderText = 'Search by Task ID';
const checkTaskIdButtonText = 'Check Task Id';
const styles = theme => ({
  input: {
    fontSize: 14,
    height: 42,
    [theme.breakpoints.down('sm')]: {
      width: "calc(100vw - 304px)",
    },
    [theme.breakpoints.up('md')]: {
      width: "calc(100vw - 304px)",
    },
    [theme.breakpoints.up('lg')]: {
      width: "calc(100vw - 304px)",
    },
  }
})

const TaskIdInput = (props) => {
  let { placeholder = placeholderText, classes, handleChange, taskIds} = props;
  return (
    <Grid container direction="row" justify="space-between">
      <Grid item component={TextField}
        placeholder={placeholder}
        InputProps={{
          startAdornment: <InputAdornment position="start"><SvgSearch></SvgSearch></InputAdornment>,
          className: classes.input,
        }}
        onChange={handleChange}
      />
      <Grid item className="title-details">
        <CheckTaskIdButton {...props} disabled={!taskIds} />
      </Grid>
      <Grid item style={{marginTop: 16}}>
      </Grid>
    </Grid>
  );
}

const TaskIdContainer = (props) => {
  return (
    <Grid container direction="column" justify="space-between" component="section">
      <Grid item className="title-label">
        <InputLabel className="client-data-label">Task Lookup:</InputLabel>
      </Grid>
      <Grid item container justify="space-between">
        <TaskIdInput {...props} />
      </Grid>
    </Grid>
  )
}

const CheckTaskIdButton = (props) => {
  let { loadingSpinner, disabled, showNotification } = props;
  let [showModal, setShowModal] = useState(false);

  return (
    <div>
      <Fab variant="extended" color="secondary"
      className={loadingSpinner ? 'title-details-button loading' : 'title-details-button'} disabled={disabled}
      onClick={(e) => { setShowModal(true) }}
    >
        {loadingSpinner ? <Loader type="line-spin-fade-loader" active={true} /> : checkTaskIdButtonText}
      </Fab>
      <TaskDataModal
        client={props.client}
        open={showModal}
        showNotification={showNotification}
        close={() => { setShowModal(false)}}
      />
    </div>
  )
}

let clientConnected = clientShow(TaskIdContainer, 'TaskIdInput');
let styled = withStyles(styles)(clientConnected);
export { styled as TaskIdInput };