import React, { Component } from "react";
import { Grid, FormControl, InputLabel, MenuItem, Typography } from "@material-ui/core";
import { withStyles } from "@material-ui/styles";
import { ExpandableSection, Dropdown, InputEditableSelect } from "../common";
import { FileEditableDropdown } from '../FileEditableDropdown/FileEditableDropdown';
import { SubtitleInfo } from './';
import subtitleLanguages from './subtitleLanguages';
import profiles from './subtitleEncodingProfiles';
import _ from 'lodash';

const imageFormat = ['N/A', '.PNG', '.BMP', '.TFF'];
const timingFormat = ["N/A", ".XML", ".AVS", ".TXT", ".SCC", ".STL", ".CAP", ".ITT", ".DFXP", ".SRT", ".TTML", ".VTT", ".SSA"];
const encodingTypes = ["ANSI", "EBU", "UTF-8", "UTF-16"];
const horizontalResolution = ["3840", "1920", "720"];
const verticalResolution = ["2160", "1080", "728", "576", "480"];
const activePictureFormats = ['Full Frame', 'Letterboxed', 'Pillarboxed'];
const ccStandards = ['EIA-608', 'EIA-708', 'N/A'];
const frameRates = ["23.976", "24.00", "25.00", "29.97", "30.00", "47.952",
"48.00", "50.00", "59.94", "60.00", "95.90", "96.00", "100.00", "119.88", "120.00"];
const activePictureAspectRatios = ['1.33', '1.37', '1.37', '1.40', '1.55', '1.66', '1.77', '1.78', '1.85', '2.35', '2.39', '2.39', '2.40', '2.55', '2.76'];
const screenAspectRatios = ['16x9', '4x3', '3x2', '5x4'];

const style = (theme) => ({
    formControl: {
        top: 2,
    },
    label: {
        fontSize: 14,
        color: '#333333',
        fontWeight: 700,
    }
});

const initialState = {
    file: null,
    encodingProfile: "",
    format: "",
    contentType: "",
    fileLayout: "",
    subtitleLanguage: "",
    imageFormat: "",
    timingFormat: "",
    textEncodingType: "",
    horizontalResolution: "",
    verticalResolution: "",
    activePictureFormat: "",
    ccStandard: "",
    frameRate: "",
    activePictureFormat: "",
    activePictureAspectRatio: "",
    screenAspectRatio: "",
}

class SubtitleData extends Component {
    constructor(props) {
        super(props);
        let defaultState = props.subtitleData || {};
        this.state = Object.assign({}, initialState, defaultState);
        this.handleFilenameChange = _.debounce(this.handleFilenameChange, 550);
    }

    componentDidMount() {
        this.postChange();
    }

    postChange = () => {
        this.props.updateErrors(this.props.updateErrorLabel, !this.hasErrors());
        let isFilenameFilled = this.state.file && !_.isEmpty(this.state.file.filename);
        if (isFilenameFilled) {
            this.props.onChange(this.state);
        }
    }

    hasErrors = () => {
        if(this.state.file && !_.isEmpty(this.state.file.filename)) {
            let requiredFields = Object.keys(this.props.validate);
            let hasError = false;
            _.forEach(requiredFields, (field) => {
                if(_.isEmpty(this.state[field])) {
                    hasError = true;
                }
            })
            return hasError;
        } else {
            return false;
        }
    }

    handleFilenameChange = (label, event) => {
        let value = event.target.value;
        let ebucore = _.find(this.props.files, { filename: value })
        let file = null;

        if(ebucore) {
            file = {
                ebucore,
                filename: value
            }
        } else {
            file = {
                filename: value
            }
        }

        this.setState({
            file,
            ccStandard: 'EIA-608',
            textEncodingType: 'ANSI',
        }, () => {
            this.postChange();
        })
    }

    handleInputChange = (label) => (value) => {
        this.setState({
            [label]: value,
        }, () => {
            this.postChange();
        });
    }

    checkIfComponentHasError = (name) => {
        if(this.state.file && !_.isEmpty(this.state.file.filename)) {
            return _.isEmpty(this.state[name]);
        }
        return false;
    }

    handleChange = (label, value) => {
        let newState = Object.assign({}, this.state);
        if(label === 'encodingProfile') {
            let profile = _.find(profiles, { encodingProfile: value });
            if(profile) {
                newState = Object.assign({}, newState, profile);
            }
        } else {
            newState[label] = value;
        }

        this.setState(newState, () => {
            this.postChange();
        });
    }

    render() {
        let { classes, files, errorsEnabled, renderButtons } = this.props;
        let filename = this.state.file ? this.state.file.filename : '';
        let isFilenameEmpty = !!filename.length;
        let errorsFocusClass = errorsEnabled && this.props.focusErrorHandler(this.hasErrors());
        let defaultFocusClass = errorsFocusClass ? errorsFocusClass : 'normal';
        let encodingProfileList = Object.keys(profiles);
        return (
            <Grid item component={ExpandableSection} header="Subtitle Data" defaultExpanded={true}
                focusErrorCssStyle={defaultFocusClass} renderButtons={renderButtons}>
                <Grid container direction="row" justify="space-between">

                    <Grid item>
                        <FormControl className={classes.formControl}>
                            <InputLabel shrink={false} className={classes.label}>Filename</InputLabel>
                            <FileEditableDropdown
                                id="filename"
                                onChange={this.handleFilenameChange}
                                field={'file'}
                                disabled={this.props.disabled}
                                value={filename}
                                files={files}
                                style={{
                                    marginTop: 40,
                                    width: '64vw',
                                }}
                            />
                        </FormControl>
                    </Grid>

                    <Grid item>
                        <FormControl className={classes.formControl} required={isFilenameEmpty}>
                            <InputLabel shrink={false}
                                className={isFilenameEmpty ? classes.labelRequired : classes.label}
                            >
                                Encoding Profile
                            </InputLabel>
                            <InputEditableSelect
                                onChange={this.handleInputChange('encodingProfile')}
                                value={this.state.encodingProfile}
                                error={errorsEnabled && this.checkIfComponentHasError('encodingProfile')}
                                collection={encodingProfileList}
                                style={{
                                    marginTop: 40,
                                    width: '16vw',
                                }}
                            />
                        </FormControl>
                    </Grid>

                    <Grid item component={ExpandableSection}
                        header="Subtitle Information" defaultExpanded={true}>
                        <Grid item component={SubtitleInfo}
                            format={this.state.format}
                            contentType={this.state.contentType}
                            fileLayout={this.state.fileLayout}
                            onChange={this.handleChange}
                            checkIfComponentHasError={this.checkIfComponentHasError}
                            isFilenameEmpty={isFilenameEmpty}
                            errorsEnabled={errorsEnabled}
                        />
                    </Grid>

                    <Grid item>
                        <FormControl className={classes.formControl} required={isFilenameEmpty}>
                            <InputLabel shrink={false}
                                className={isFilenameEmpty ? classes.labelRequired : classes.label}>
                                Subtitle Language
                            </InputLabel>
                            <InputEditableSelect
                                onChange={this.handleInputChange('subtitleLanguage')}
                                value={this.state.subtitleLanguage}
                                error={errorsEnabled && this.checkIfComponentHasError('subtitleLanguage')}
                                collection={subtitleLanguages}
                                style={{
                                    marginTop: 40,
                                    width: '16vw',
                                }}
                            />
                        </FormControl>
                    </Grid>

                    <Grid item component={Dropdown}
                        label="Subtitle Image Format"
                        required={isFilenameEmpty}
                        field={'imageFormat'}
                        onChange={this.handleChange}
                        value={this.state.imageFormat}
                        error={errorsEnabled && this.checkIfComponentHasError('imageFormat')}
                    >
                        {
                            _.map(imageFormat, (format, index) => {
                                return (
                                    <MenuItem key={index} value={format}>
                                        <Typography noWrap={true}>{format}</Typography>
                                    </MenuItem>
                                )
                            })
                        }
                    </Grid>

                    <Grid item component={Dropdown}
                        label="Subtitle Timing Format"
                        required={isFilenameEmpty}
                        field={'timingFormat'}
                        onChange={this.handleChange}
                        value={this.state.timingFormat}
                        error={errorsEnabled && this.checkIfComponentHasError('timingFormat')}
                    >
                        {
                            _.map(timingFormat, (format, index) => {
                                return (
                                    <MenuItem key={index} value={format}>
                                        <Typography noWrap={true}>{format}</Typography>
                                    </MenuItem>
                                )
                            })
                        }
                    </Grid>

                    <Grid item component={Dropdown}
                        label="Text Encoding Type"
                        required={isFilenameEmpty}
                        field={'textEncodingType'}
                        onChange={this.handleChange}
                        value={this.state.textEncodingType}
                        error={errorsEnabled && this.checkIfComponentHasError('textEncodingType')}
                    >
                        {
                            _.map(encodingTypes, (type, index) => {
                                return (
                                    <MenuItem key={index} value={type}>
                                        <Typography noWrap={true}>{type}</Typography>
                                    </MenuItem>
                                )
                            })
                        }
                    </Grid>

                    <Grid item component={Dropdown}
                        label="Horizontal Resolution"
                        required={isFilenameEmpty}
                        field={'horizontalResolution'}
                        onChange={this.handleChange}
                        value={this.state.horizontalResolution}
                        error={errorsEnabled && this.checkIfComponentHasError('horizontalResolution')}
                    >
                        {
                            _.map(horizontalResolution, (resolution, index) => {
                                return (
                                    <MenuItem key={index} value={resolution}>
                                        <Typography noWrap={true}>{resolution}</Typography>
                                    </MenuItem>
                                )
                            })
                        }
                    </Grid>

                    <Grid item component={Dropdown}
                        label="Vertical Resolution"
                        required={isFilenameEmpty}
                        field={'verticalResolution'}
                        onChange={this.handleChange}
                        value={this.state.verticalResolution}
                        error={errorsEnabled && this.checkIfComponentHasError('verticalResolution')}
                    >
                        {
                            _.map(verticalResolution, (resolution, index) => {
                                return (
                                    <MenuItem key={index} value={resolution}>
                                        <Typography noWrap={true}>{resolution}</Typography>
                                    </MenuItem>
                                )
                            })
                        }
                    </Grid>

                    <Grid item component={Dropdown}
                        label="Active Picture Format"
                        required={isFilenameEmpty}
                        field={'activePictureFormat'}
                        onChange={this.handleChange}
                        value={this.state.activePictureFormat}
                        error={errorsEnabled && this.checkIfComponentHasError('activePictureFormat')}
                    >
                        {
                            _.map(activePictureFormats, (value, index) => {
                                return (
                                    <MenuItem key={index} value={value}>
                                        <Typography noWrap={true}>{value}</Typography>
                                    </MenuItem>
                                )
                            })
                        }
                    </Grid>

                    <Grid item component={Dropdown}
                        label="CC Standard"
                        required={isFilenameEmpty}
                        field={'ccStandard'}
                        onChange={this.handleChange}
                        value={this.state.ccStandard}
                        error={errorsEnabled && this.checkIfComponentHasError('ccStandard')}
                    >
                        {
                            _.map(ccStandards, (value, index) => {
                                return (
                                    <MenuItem key={index} value={value}>
                                        <Typography noWrap={true}>{value}</Typography>
                                    </MenuItem>
                                )
                            })
                        }
                    </Grid>

                    <Grid item component={Dropdown}
                        label="Frame Rate"
                        required={isFilenameEmpty}
                        field={'frameRate'}
                        onChange={this.handleChange}
                        value={this.state.frameRate}
                        error={errorsEnabled && this.checkIfComponentHasError('frameRate')}
                    >
                        {
                            _.map(frameRates, (value, index) => {
                                return (
                                    <MenuItem key={index} value={value}>
                                        <Typography noWrap={true}>{value}</Typography>
                                    </MenuItem>
                                )
                            })
                        }
                    </Grid>

                    <Grid item component={Dropdown}
                        label="Active Picture Aspect Ratio"
                        required={isFilenameEmpty}
                        field={'activePictureAspectRatio'}
                        onChange={this.handleChange}
                        value={this.state.activePictureAspectRatio}
                        error={errorsEnabled && this.checkIfComponentHasError('activePictureAspectRatio')}
                    >
                        {
                            _.map(activePictureAspectRatios, (ratio, index) => {
                                return (
                                    <MenuItem key={index} value={ratio}>
                                        <Typography noWrap={true}>{ratio}</Typography>
                                    </MenuItem>
                                )
                            })
                        }
                    </Grid>

                    <Grid item component={Dropdown}
                        label="Screen Aspect Ratio"
                        required={isFilenameEmpty}
                        field={'screenAspectRatio'}
                        onChange={this.handleChange}
                        value={this.state.screenAspectRatio}
                        error={errorsEnabled && this.checkIfComponentHasError('screenAspectRatio')}
                    >
                        {
                            _.map(screenAspectRatios, (ratio, index) => {
                                return (
                                    <MenuItem key={index} value={ratio}>
                                        <Typography noWrap={true}>{ratio}</Typography>
                                    </MenuItem>
                                )
                            })
                        }
                    </Grid>

                </Grid>
            </Grid>
         );
    }
}

const styled = withStyles(style)(SubtitleData);
export { styled as SubtitleData };