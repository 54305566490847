import React, { Component } from "react";
import PropTypes from 'prop-types';
import { withStyles } from "@material-ui/core/styles";
import { Modal, Grid, Fab, IconButton } from "@material-ui/core";
import SvgCloseX from "../../assets/icons/CloseX";
import { TitleHeader } from './TitleHeader';
import { ModalTerritorySelect } from './ModalTerritorySelect';
import { DirectTvTitlePane } from '../DirectTV';
import { DbbTitlePane } from '../Dbb';
import { DbbBulkTitlePane } from '../DbbBulkIngest';
import { MovielabsTitlePane } from '../MovielabsManifest';
import { RokuTitlePane } from '../Roku';
import { CablelabsTitlePane } from '../RokuCablelabs';
import { CmdgTitlePane } from '../CmdgInternational';

const getModalStyle = () => {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
    alignItems: "stretch",
    borderRadius: 16,
    backgroundColor: '#f5f5f5',
  };
}

const styles = theme => ({
  paper: {
    position: "absolute",
    width: 704,
    height: 590,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: '8px 16px 8px 16px',
    outline: "none",
  }
});

class TitleModal extends Component {
  render() {
    const { title } = this.props;
    const { classes, language, territory } = this.props;
    return (
      <div>
        <Modal
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
          open={this.props.open}
          onClose={this.props.close}
          className="title-modal"
        >
          <Grid container direction="column" justify="flex-start" wrap="nowrap" style={getModalStyle()} className={classes.paper}>
            <Grid item style={{ alignSelf: 'flex-end' }}>
              <IconButton onClick={this.props.close}>
                <SvgCloseX style={{ height: 15, width: 16, color: '#333333' }}/>
              </IconButton>
            </Grid>
            <Grid item style={{ height: "fit-content" }}>
            <TitleHeader />
            <ModalTerritorySelect
              client={this.props.client}
              chipValue={this.props.chipValue}
              selectedTerritories={this.props.selectedTerritories}
              handleLanguageChange={this.props.handleLanguageChange}
            />
            {
              title && title.gpmsId &&
              (
                <Grid item>
                  <DirectTvTitlePane
                    presentedTitle={title}
                    language={language}
                    client={this.props.client}
                    onLanguageChange={this.props.handleLanguageChange}
                  />
                  <DbbTitlePane
                    presentedTitle={title}
                    language={language}
                    client={this.props.client}
                  />
                  <DbbBulkTitlePane
                    presentedTitle={title}
                    language={language}
                    client={this.props.client}
                  />
                  <MovielabsTitlePane
                    presentedTitle={title}
                    language={language}
                    client={this.props.client}
                  />
                  <RokuTitlePane
                    presentedTitle={title}
                    language={language}
                    client={this.props.client}
                  />
                  <CablelabsTitlePane
                    presentedTitle={title}
                    language={language}
                    client={this.props.client}
                  />
                  <CmdgTitlePane
                    presentedTitle={title}
                    language={language}
                    territory={territory}
                    singleTitle={true}
                    client={this.props.client}
                    handleSetClick={this.props.handleSetClick}
                  />
                </Grid>
              )
            }
            </Grid>
            <Grid item style={{ alignSelf: 'center', marginTop: 40 }}>
              <Fab variant="extended" color="primary" style={{ height: 40, width: 168 }}
              onClick={this.props.close}>Close</Fab>
            </Grid>
          </Grid>
        </Modal>
      </div>
    );
  }
}

TitleModal.propTypes = {
  open: PropTypes.bool,
  close: PropTypes.func,
  handleLanguageChange: PropTypes.func,
  clientSpec: PropTypes.object,
}

const modalWithStyles = withStyles(styles)(TitleModal);
export { modalWithStyles as TitleModal};
