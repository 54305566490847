import React, { Component } from "react";
import { Grid, FormControl, InputLabel, TextField } from "@material-ui/core";
import { withStyles } from "@material-ui/styles";
import MaskedInput from 'react-text-mask';
import { checkHours } from '../../_helpers/timecodeInputHelper';

const style = (theme) => ({
    formControl: {
        top: 12,
        position: 'relative',
    },
    labelRequired: {
        fontSize: 14,
        color: "#5e5edb",
        fontWeight: 700,
        marginTop: -16,
    },
    label: {
        fontSize: 14,
        color: "#333333",
        fontWeight: 700,
        marginTop: -16,
    },
    textfield: {
        marginTop: 32,
        marginRight: 10,
        marginBottom: 15,
        width: '16vw',
    },
    input: {
        position: 'relative',
        height: '28px !important',
    }
});

const MaskInput = (props) => {
    const { inputRef, ...other } = props;
    return (
        <MaskedInput
        {...other}
        ref={ref => {
          inputRef(ref ? ref.inputElement : null);
        }}
        mask={[/[0-2]/, checkHours(other.value), ":", /[0-5]/, /\d/, ":", /[0-5]/, /\d/, ":", /[0-5]/, /\d/]}
        placeholder="HH:MM:SS:FRAMES"
      />
    )
}

class TimecodeInput extends Component {
    constructor(props) {
        super(props);
        this.state = {};
        this.state.value = props.value || '';
    }

    handleChange = (e) => {
        let value = e.target.value;
        let field = this.props.field
        this.setState({
            value,
        }, () => {
            let regex = new RegExp(/\d\d:\d\d:\d\d:\d\d/g);
            value = regex.test(value) ? value : '';
            this.props.onChange(field, value);
        });
    }

    render() {
        let { classes, label, required = false,
            error } = this.props;
        let labelClassName = required ? classes.labelRequired : classes.label;
        return (
            <Grid item component={FormControl} className={classes.formControl}
                required={required}>
                <InputLabel shrink={false} className={labelClassName}>
                    {label}
                </InputLabel>
                <TextField
                    className={classes.textfield}
                    value={this.state.value}
                    error={error}
                    onChange={e => this.handleChange(e)}
                    variant="outlined"
                    InputProps={{
                        inputComponent: MaskInput,
                        classes : { root: classes.input }
                    }}
                />
            </Grid>
        )
    }
}

const styled = withStyles(style)(TimecodeInput);
export { styled as TimecodeInput }