import React, { Component } from 'react';
import { Grid, ExpansionPanel, ExpansionPanelSummary, 
    ExpansionPanelDetails, Table, TableBody, TableHead, 
    TableRow, TableCell, Typography } from '@material-ui/core';
import { TableSelect } from '../common';
import { withStyles } from '@material-ui/styles';

const channelsList = [ "Left", "Right", "Front Center", "LFE", "Rear Left", "Rear Right", "Side Left",
"Side Right", "Left Total", "Right Total", "Surround", "Rear Center", "Dolby E 1", "Dolby E 2"];

const styles = (theme) => ({
    table: {
        border: "2px solid rgba(117,117,117, 0.5)",
        marginBottom: 16,
    },
    tableHead: {
        borderRight: "2px solid rgba(117,117,117, 0.5)",
        borderBottom: "2px solid rgba(117,117,117, 0.5)",
        fontSize: 14,
    },
    tableRow: {
        borderTop: "2px solid rgba(117,117,117, 0.5)",
        borderBottom: "2px solid rgba(117,117,117, 0.5)",
    },
    cell: {
        borderRight: "2px solid rgba(117,117,117, 0.5)",
        borderBottom: "2px solid rgba(117,117,117, 0.5)",
    },
    wrapper: {
        overflowY: "auto",
        marginTop: 32,
    }
});

class AudioChannelList extends Component {

    constructor(props) {
        super(props);
        this.state = {
            channels: props.channels,
        }
    }

    handleChange = (label, value) => {
        let channelObjIndex = this.props.index;
        let newChannels = Object.assign({}, this.state.channels);
        newChannels[label] = value;
        this.setState({
            channels: newChannels,
        }, () => {
            this.props.onChange(newChannels, channelObjIndex);
        })
    }

    render() {
        let { profileName, channels, classes } = this.props;
        let numberOfChannelsInProfile = Object.keys(channels);
        return (
            <Grid container style={{
                margin: "16px 0"
            }}>

                <ExpansionPanel expanded={true} style={{
                    width: "100%"
                }}>
                    <ExpansionPanelSummary style={{
                        borderBottom: "2px solid rgba(117,117,117, 0.5)",
                    }}>
                        <Typography variant="h5" component="h5">{profileName}</Typography>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails>
                        { /* list of channels */ }
                        <Grid container className={classes.wrapper}>
                            <Table className={classes.table}>
                                <TableBody>
                                    <TableRow className={classes.tableRow}>
                                        <TableCell className={classes.tableHead} variant="head">Channels</TableCell>
                                        {
                                            numberOfChannelsInProfile.map((channelNumber, index) => (
                                                <TableCell className={classes.cell} key={index}>{channelNumber}</TableCell>
                                            ))
                                        }
                                    </TableRow>
                                    <TableRow className={classes.tableRow}>
                                        <TableCell className={classes.tableHead} variant="head">
                                            Audio Assignment
                                        </TableCell>
                                    {
                                            numberOfChannelsInProfile.map((channelNumber, index) => (
                                                <TableCell key={index} className={classes.cell}>
                                                    <TableSelect 
                                                        collection={channelsList}
                                                        label={channelNumber}
                                                        value={channels[channelNumber]}
                                                        onChange={this.handleChange}
                                                    />
                                                </TableCell>
                                            ))    
                                    }
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </Grid>
                    </ExpansionPanelDetails>
                </ExpansionPanel>
            </Grid>
        )
    }
}

let styleConnected = withStyles(styles)(AudioChannelList);
export { styleConnected as AudioChannelList }