import React from 'react';
import { Link } from 'react-router-dom';
import { ClientListItem } from './ClientListItem';
import { Grid, Fab, Typography, Divider } from '@material-ui/core';

const create = 'CREATE';

const renderClients = ({clients=[], onClientDelete, title}) => {
  return clients.map((client, index) => (
    <React.Fragment key={index}>
      <ClientListItem client={client} onClientDelete={onClientDelete} title={title}
        className="client-list-item" />
      <Divider className="divider"/>
    </React.Fragment>
  ));
}

export const ClientList = (props) => {
  return (
    <Grid container direction="column">
      <Grid container key="header-row" className='header-item'
        direction="row" justify="space-between">
        <Grid item>
          <Typography variant="h5" component="h5" className='client-name'>Client Name:</Typography>
        </Grid>

        <Grid item component={Link} to={{pathname: "/profile-edit" }}>
            <Fab size="small" variant="extended" className="sidecar-button"
            color="secondary">
              {create}
            </Fab>
        </Grid>
      </Grid>
      <Divider className="divider"/>
      {renderClients(props)}
    </Grid>
  )
}
