import React from 'react';
import { connect } from 'react-redux';
import { withStyles } from "@material-ui/core/styles";
import { Modal, Grid, Fab, IconButton } from "@material-ui/core";
import SvgCloseX from "../../assets/icons/CloseX";
import { clientShow } from "../Clientshow/clientshow";
import { DistributionMetadata } from "../DistributionMetadata";

const getModalStyle = () => {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
    alignItems: "stretch",
    borderRadius: 16,
    backgroundColor: '#f5f5f5',
    overflow: 'auto',
  };
}

const styles = theme => ({
  paper: {
    position: "absolute",
    width: 704,
    height: 590,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: '8px 16px 8px 16px',
    outline: "none",
  }
});

const TaskDataModal = (props) => {
  const { classes } = props;
    return (
      <div>
        <Modal
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
          open={props.open}
          onClose={props.close}
          className="title-modal"
        >
          <Grid container direction="column" justify="flex-start" wrap="nowrap" style={getModalStyle()} className={classes.paper}>
            <Grid item style={{ alignSelf: 'flex-end' }}>
              <IconButton onClick={props.close}>
                <SvgCloseX style={{ height: 15, width: 16, color: '#333333' }}/>
              </IconButton>
            </Grid>
            <Grid item style={{ height: "fit-content" }}>
              <DistributionMetadata {...props} />
            </Grid>
            <Grid item style={{ alignSelf: 'center', marginTop: 40 }}>
              <Fab variant="extended" color="primary" style={{ height: 40, width: 168 }}
              onClick={props.close}>Close</Fab>
            </Grid>
          </Grid>
        </Modal>
      </div>
    );
}

const mapStateToProps = (state) => {
  let { taskIds: taskIdsReducer } = state;

  return {
    taskIds: taskIdsReducer.taskIds,
  }
}

let clientConnected = clientShow(TaskDataModal, 'TaskDataModal');
let styled = withStyles(styles)(clientConnected);
let connected = connect(mapStateToProps)(styled);

export { connected as TaskDataModal }