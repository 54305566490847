import React from 'react';
import { Grid, FormControl, FormLabel, FormControlLabel, RadioGroup, Radio } from '@material-ui/core';
import { CheckCircle } from '@material-ui/icons';

export const SimpleRadioList = ({ heading, field, value, items, onChange}) => {
  return (<Grid container>
    <FormControl component="fieldset" style={{ marginTop: 22 }}>
        <FormLabel style={{ fontSize: 14, color: "#333333" }} component="label">
            {heading}
        </FormLabel>
        <RadioGroup style={{ display: "inline" }}
            value={value}
            onChange={e => onChange(field, e.target.value)}
        >
          { items.map((item, index) => (
            <FormControlLabel
              key={`${item.value || item}-${index}`}
              value={item.value || item.label || item }
              control={<Radio checkedIcon={<CheckCircle />}/>}
              label={item.label || item}
            />
          ))}
        </RadioGroup>
    </FormControl>
</Grid>)
}