import React, { Component } from 'react';
import { Grid, MenuItem, Typography, Button } from '@material-ui/core';
import { ExpandableSection, Dropdown } from '../components/common';
import _ from 'lodash';
import { Add } from "@material-ui/icons";

function getDefaultSynopsesData() {
    return {
        source: '',
        type: '',
        language: '',
    }
}

const synopsisSources = ['PRISM', 'GPMS', 'SPT', 'SPTB2B'];
const synopsisTypes = ['Logline', 'Long', 'Broadcast', 'DLD-43', 'DLD-100', 'DLD-250',
    'DLD-1000', 'DLD-4000'];

class SynopsesData extends Component {

    constructor(props) {
        super(props);
        let field = props.field;
        let synopses = props.synopses && props.synopses[field]
        this.state = {
            [field]: synopses || [getDefaultSynopsesData()],
            synopsesLanguages: this.getSynopsesLanguages(props.languages),
        }
    }

    componentDidUpdate(prevProps, prevState) {
        let { field, synopses } = this.props;
        if (!_.isEqual(prevProps.synopses[field], synopses[field])) {
            this.setState({
                [field]: synopses[field]
            });
        }
    }

    getSynopsesLanguages = (languages) => {
        let synopsesLanguages = [].concat(languages);
        synopsesLanguages.unshift('Localized', 'Language (default)');
        return synopsesLanguages;
    }

    handleChange = (label, value, index) => {
        let field = this.props.field;
        let newState = [].concat(this.state[field]);
        newState[index][label] = value;
        this.setState({
            [field]: newState,
        }, () => {
            this.props.onChange(field, this.state[field]);
        })
    }

    handleAddRow = () => {
        let field = this.props.field;
        if(this.state[field].length === 10) {
            return;
        }
        let fieldData = [].concat(this.state[field]);
        let newArray = fieldData.concat([getDefaultSynopsesData()]);

        this.setState({
            [field]: newArray,
        }, () => {
            this.props.onChange(field, this.state[field]);
        })
    }

    render() {
        let { field, getPriorityNum } = this.props;
        let { synopsesLanguages } = this.state;
        let synopsesData = this.state[field];
        let expandableSectionHeader = `Synopsis ${field}`;
        return (
            <Grid item component={ExpandableSection} header={expandableSectionHeader} 
            defaultExpanded={true} focusErrorCssStyle="normal">
                <Grid container direction="column" justify="space-between">
                {
                    synopsesData.map((synopsis, index) => (
                        <Grid container direction="column" justify="space-between"
                            key={index} 
                        >
                            <Typography variant="h5" component="h5" 
                            style={{
                                color: "#5e5edb"
                            }}>{`${getPriorityNum(index)} PRIORITY`}</Typography>
                            <Grid container direction="row" justify="space-between">
                                <Grid item component={Dropdown}
                                    label="Source"
                                    value={synopsis.source}
                                    onChange={this.handleChange}
                                    field={'source'}
                                    index={index}
                                >
                                    {
                                        synopsisSources.map((source, index) => (
                                            <MenuItem value={source} key={index}>
                                                <Typography>{source}</Typography>
                                            </MenuItem>
                                        ))
                                    }
                                </Grid>
                                <Grid item component={Dropdown}
                                    label="Type"
                                    value={synopsis.type}
                                    onChange={this.handleChange}
                                    field={'type'}
                                    index={index}
                                >
                                    {
                                        synopsisTypes.map((type, index) => (
                                            <MenuItem value={type} key={index}>
                                                <Typography>{type}</Typography>
                                            </MenuItem>
                                        ))
                                    }
                                </Grid>
                                <Grid item component={Dropdown}
                                    label="Language"
                                    value={synopsis.language}
                                    onChange={this.handleChange}
                                    field={'language'}
                                    index={index}
                                >
                                    {
                                        synopsesLanguages.map((language, index) => (
                                            <MenuItem value={language} key={index}>
                                                <Typography>{language}</Typography>
                                            </MenuItem>
                                        ))
                                    }
                                </Grid>
                            </Grid>
                        </Grid>
                    ))
                }
                <Grid item container justify="space-between">
                    <Grid item>
                        <Button style={{ marginTop: 16 }} color="primary" onClick={this.handleAddRow}>
                            <Add style={{ marginRight: 12 }} />
                            ADD NEW ROW
                        </Button>
                    </Grid>
                </Grid>
                </Grid>
            </Grid>
        )
    }
}

export { SynopsesData };