import { SidecarService } from './sidecar.service';

export const ClientspecService = {
  getSpec: getSpec,
  submitSpec: submitSpec,
  getDeliveries: getDeliveries,
  getClients: getClients,
  getLoginId,
};

// titleId = GPMS alphaId || GPMS ID
function getDeliveries(clientId, titleId) {
  return SidecarService.get(`/deliveries?clientId=${clientId}&titleId=${titleId}`);
}

function getSpec(titleId, clientId, dataFilter) {
  let body = {
    gpmsId: titleId,
    clientId,
    dataFilter,
  };

  return SidecarService.post('/clientspec', body);
}

function getLoginId() {
  let user = localStorage.getItem('user');
  return user && JSON.parse(user).loginId;
};

function submitSpec(packageData, client, titleIds) {
  let user = getLoginId();
  let body = {
    clientId: client.id,
    titleIds,
    packageData,
    user,
  };

  return SidecarService.post('/create_manifest', body);
}

function getClients() {
  return SidecarService.get('/clients');
}
