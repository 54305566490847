import React, { Component } from 'react';
import { Grid, InputLabel, FormControl, Typography } from '@material-ui/core';
import { FileEditableDropdown } from '../FileEditableDropdown/FileEditableDropdown';
import _ from 'lodash';

const initialState = {
    episodeFile: {},
    episodeArt: {},
    errors: {
        filename: true,
    },
}
class FileForm extends Component {

    constructor(props) {
        super(props);
        let { episodeFile={}, episodeArt={} } = this.props;
        this.state = Object.assign({}, initialState, {episodeFile, episodeArt});

        this.handleChange = _.debounce(this.handleChange, 550);
    }

    componentDidMount() {
        this.postChange();
    }

    static getDerivedStateFromProps(props, state) {
        if(props.disabled) {
            return {
                errors: {}
            }
        }
        return null;
    }

    doErrorsExist = () => {
        return !_.isEmpty(this.state.errors);
    }

    postChange = () => {
        this.props.updateErrors(this.state.errors);
        this.props.onChange(this.state);
    }

    emptyField = (filename) => {
        let val = filename;
        return this.props.disabled ? false : !val;
    }

    getEbucoreData = (filename) => {
        if(!filename) {
            return null;
        }
        let ebucore =  _.find(this.props.files, { filename });
        if(!ebucore) {
            return {
                filename: filename || ''
            }
        }
        return {
            ebucore,
            filename
        };
    }

    handleChange = (label, e) => {
        let filename = e.target.value;
        let error = false;
        let val = this.getEbucoreData(filename) || {};

        if (label === 'episodeFile') {
            error = this.emptyField(filename);
        }

        let newState = {
            [label]: val,
        };

        newState.errors = error ? { filename: true } : {};

        this.setState(newState, () => {
            this.postChange();
        });
    }

    render() {
        let errorsEnabled = this.props.errorsEnabled
        return (
            <Grid container direction="row" justify="flex-start" wrap="nowrap">
                <Grid item>
                    <FormControl required={true && !this.props.disabled}
                    disabled={this.props.disabled}
                    style={{
                        marginRight: 16,
                    }}>
                        <InputLabel style={{
                            display: 'inline-flex',
                            fontSize: 14,
                            color: !this.props.disabled ? "#5e5edb" : "#333333",
                            fontWeight: 700,
                        }}>
                            Episode Filename
                        </InputLabel>
                        <FileEditableDropdown
                        onChange={this.handleChange}
                        field={'episodeFile'}
                        error={this.state.errors.filename && errorsEnabled}
                        value={this.state.episodeFile.filename}
                        files={this.props.files}
                        disabled={this.props.disabled || false}
                        style={{
                            marginTop: 40,
                            width: '20vw',
                        }}
                    />
                    </FormControl>
                </Grid>
                <Grid item>
                    <FormControl disabled={this.props.disabled}
                    style={{
                        marginRight: 16
                    }}>
                        <InputLabel><Typography>Episode Key Art</Typography></InputLabel>
                        <FileEditableDropdown
                            onChange={this.handleChange}
                            field={'episodeArt'}
                            files={this.props.files}
                            value={this.state.episodeArt.filename}
                            disabled={this.props.disabled || false}
                            style={{
                                marginTop: 40,
                                width: '20vw',
                            }}
                        />
                    </FormControl>
                </Grid>
                <Grid item>
                    <Grid item container justify="flex-start" wrap="nowrap">
                        {
                            this.props.children
                        }
                    </Grid>
                </Grid>
            </Grid>
        )
    }
}


export { FileForm }
