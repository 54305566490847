import React from 'react';
import { Grid, Checkbox, FormControl, FormLabel, FormControlLabel, Typography } from '@material-ui/core';
import { CheckCircle, RadioButtonUncheckedRounded } from '@material-ui/icons';


export class RadioButton extends React.Component {
    constructor(props){
        super(props);
        let checked = false;
        this.state = {
            checked
        }
    }

    static getDerivedStateFromProps(props, state) {
        if(props.value !== state.value) {
            return {
                checked: props.value,
            }
        }
        return null;
    }

    handleChange = (e, checked) => {
        this.setState({
            checked
        }, () => {
            this.props.handleChange(checked);
        });
    }

    render() {
        let { label, disabled } = this.props;
        let { checked } = this.state;
        return (
            <Grid item style={{
                alignSelf: 'center',
                paddingTop: 32,
                marginLeft: 16,
            }}>
                
                <FormControlLabel 
                    control={
                        <Checkbox
                            checked={checked}
                            icon={<RadioButtonUncheckedRounded />}
                            checkedIcon={<CheckCircle />}
                            onChange={this.handleChange}
                            disabled={disabled}
                            value="applyAll"
                            color="primary"
                            data-testid="checkbox"
                        />
                    }
                    label={label}
                />
               
            </Grid>
        )
    }
}
