/*eslint security/detect-object-injection:0 */

import _ from "lodash";
import { titleConstants } from "../_constants";

const emptyTitle = {
  alphaName: "",
  titleFullName: "",
  primaryTitleName: "",
  name: "",
};

const initialState = {
  titles: [{ ...emptyTitle }],
};

export const titles = (state = initialState, action) => {
  let newTitles = [];
  let index = -1;

  switch (action.type) {
    case titleConstants.TITLE_ENTRY_ADDED:
      newTitles = [].concat(state.titles);
      newTitles.push({ ...emptyTitle });
      return { ...state, titles: newTitles };

    case titleConstants.TITLE_SELECTED:
      index = action.data.index;
      newTitles = [].concat(state.titles);
      newTitles[index] = action.data.title;
      return { ...state, titles: newTitles };

    case titleConstants.TITLE_REMOVED:
      index = action.data.index;
      newTitles = _.filter(state.titles, (title, titleIndex) => {
        return index !== titleIndex;
      });
      return { ...state, titles: newTitles };

    case titleConstants.TITLES_SELECTED:
      newTitles = [].concat(action.data.titles);
      return { ...state, titles: newTitles };

    case titleConstants.RESET:
      return { ...state, titles: [{}] };

    default:
      return state;
  }
};
