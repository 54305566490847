import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Grid, Fab } from '@material-ui/core';
import { history } from '../_helpers/history';
import Loader from 'react-loaders';
import { SaveProgressButton } from '../components/DbbBulkIngest/SaveProgressButton';
import NotifierAction from '../_actions/notifier.actions';

class SubmitData extends Component {

    handlePrevious = () => {
        let location = {
          pathname: "/"
        }

        history.push(location);
    }

    setClassName = () => {
      let classname = "submit-button";
      classname = this.props.loadingSpinner ? classname.concat(" loading") : classname;
      classname = this.props.disableButton ? classname.concat(" disabled") : classname;
      return classname;
    }

    saveProgressButtonClick = () => {
      let promise = this.props.persistForm();
      promise.then((jobId) => {
        let msg = this.props.successMessage(jobId);
        this.props.showNotification(msg);
      });
      return promise;
    }

    render() {
        return (
            <Grid container
            justify="space-between"
            component="footer" className="footer">
              <Grid item>
                <SaveProgressButton client={this.props.client} onClick={this.saveProgressButtonClick} />
              </Grid>
              <Grid item>
                <Fab variant="extended" style={{ marginRight: 10 }}  className="previous-button" onClick={this.handlePrevious} color="secondary">Previous</Fab>
                <Fab variant="extended"  className={this.setClassName()} onClick={this.props.onSubmit}
                color="primary">{this.props.loadingSpinner ? <Loader type="line-spin-fade-loader"
                active={true}/> : "Submit"}</Fab>
              </Grid>
            </Grid>
        )
    };
};

const mapDispatchToProps = (dispatch) => {
  let showNotification = NotifierAction.showNotification;
  return {
    showNotification: (msg) => {
      dispatch(showNotification(true, msg, 'success'));
    }
  }

};

let connected = connect(null, mapDispatchToProps)(SubmitData);
export { connected as default }