import React, { Component, PureComponent } from 'react';
import { FormControl, InputLabel, Select, Chip, MenuItem, 
    Typography, Checkbox, ListItemText } from '@material-ui/core';
import SvgCircleX from '../../assets/icons/CircleX';
import { withStyles } from '@material-ui/styles';
import PropTypes from 'prop-types'; 
import _ from 'lodash';

const style = (theme) => ({
    labelRequired: {
      fontSize: 14,
      color: "#5e5edb",
      fontWeight: 700,
    },
    dropDownStyle: {
      maxHeight: 234,
      marginTop: 8,
      width: '40vw',
    },
    formControl: {
      position: 'relative',
      width: '40vw',
      top: -4,
    },
    label: {
      fontSize: 14,
      color: "#333333",
      fontWeight: 700,
    },
    chips: {
      display: "flex",
      flexWrap: "wrap"
    },
    chip: {
        backgroundColor: "#ffffff",
        color: "#5e5edb",
        border: "1px solid #5e5edb",
        marginBottom: 8,
        marginRight: 8,
        fontSize: 14,
        height: 24,
    },
    deleteIcon: {
        color: "#5e5edb",
        borderRadius: "50%",
        width: 16,
    }
  });

class MultiSelect extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            value: props.value || [],
        }
    }

    componentDidUpdate(prevProps) {
        if(!_.isEqual(prevProps.value, this.props.value)) {
            let value = this.props.value;
            this.setState({
                value
            });
        }
    }

    handleChange = (e) => {
        let value = e.target.value;
        let field = this.props.field;
        this.setState({
            value,
        }, () => {
            this.props.onChange(field, value);
        })
    }

    handleDelete = (e, deletedIndex) => {
        let newList = this.state.value.filter((value, index) => {
            return index !== deletedIndex;
        });
        let field = this.props.field;
        this.setState({
            value: newList,
        }, () => {
            this.props.onChange(field, newList);
        });
    }

    render() {
        let { value } = this.state;
        let { classes, menuItems=[], required, label } = this.props;
        return (
            <FormControl className={classes.formControl} required={required}>
                <InputLabel shrink={false} className={required ? classes.labelRequired : classes.label}>
                    {label}
                </InputLabel>
                <Select
                    multiple
                    autoWidth={true}
                    value={value}
                    onChange={this.handleChange}
                    style={{ marginTop: 40 }}
                    renderValue={selected => (
                        <div className={classes.chips}>
                          {selected.map((value, index) => (
                            <Chip
                              key={index}
                              clickable={false}
                              label={value}
                              className={classes.chip}
                              deleteIcon={<SvgCircleX className={classes.deleteIcon} />}
                              onDelete={e => this.handleDelete(e, index)}
                            />
                          ))}
                        </div>
                    )}
                    MenuProps={{
                        classes: { paper: classes.dropDownStyle },
                        getContentAnchorEl: null,
                        anchorOrigin: {
                            vertical: "bottom",
                            horizontal: "left",
                        }
                    }}
                >
                {
                    menuItems.map((value, index) => {
                        let checked = this.state.value.indexOf(value) > -1;
                        return (
                            <MenuItem key={index} value={value}>
                                <Checkbox checked={checked}/>
                                <Typography>{value}</Typography>
                            </MenuItem>
                        )
                    })
                }
                </Select>
            </FormControl>
        )
    }
}

MultiSelect.propTypes = {
    menuItems: PropTypes.array.isRequired,
    onChange: PropTypes.func.isRequired,
}

const styledComponent = withStyles(style)(MultiSelect);
export { styledComponent as MultiSelect };