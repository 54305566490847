import React from 'react';
import { Grid } from '@material-ui/core';

export const TimecodeDetails = (props) => {
    return (
      <Grid container direction="column" justify="space-between">
        {
            React.Children.map(props.children, (child) => {
                return React.cloneElement(child, {
                    ...props
                })
            })
        }
      </Grid>
    );
}

