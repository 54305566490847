import React, { Component } from "react";
import { TextField, Grid, Paper, InputAdornment, LinearProgress, Typography,
  Table, TableRow, TableCell, TableBody } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import Downshift from 'downshift';
import SvgSearch from "../../assets/icons/Search";
import SvgAlphas from '../../assets/icons/alpha';
import _ from "lodash";
import '../ClientForm/Clientform.styles.scss';
import { getNormalizedProductType } from '../../_helpers/titleHelper';
import { TitleService } from '../../_services/title.service';

const SUGGESTION_START_LENGTH = 3;
const styles = theme => ({
  root: {
    height: 48,
    flexGrow: 1
  },
  container: {
    position: "relative"
  },
  suggestionsContainerOpen: {
    position: "absolute",
    zIndex: 1,
    marginTop: theme.spacing.unit,
    left: 0,
    right: 0,
    maxHeight: 234,
    overflowY: 'overlay',
  },
  suggestion: {
    display: "block",
  },
  suggestionsList: {
    margin: 0,
    padding: 0,
    listStyleType: "none"
  },
  input: {
    fontSize: 14,
    height: 42,
    [theme.breakpoints.down('sm')]: {
      width: "calc(100vw - 304px)",
    },
    [theme.breakpoints.up('md')]: {
      width: "calc(100vw - 304px)",
    },
    [theme.breakpoints.up('lg')]: {
      width: "calc(100vw - 304px)",
    },
  },
  deletableInput: {
    fontSize: 14,
    height: 42,
    [theme.breakpoints.down('sm')]: {
      width: "calc(100vw - 328px)",
    },
    [theme.breakpoints.up('md')]: {
      width: "calc(100vw - 328px)",
    },
    [theme.breakpoints.up('lg')]: {
      width: "calc(100vw - 328px)",
    },
  }
});

const buildMenu = (
  { getMenuProps, getItemProps, highlightedIndex, selectedItem },
  data
) => {
  let newIndex = 0;

  let incrementNewIndex = () => {
    return newIndex++;
  };

  let levels = 0;
  let submenus = buildSubmenu(data, incrementNewIndex, levels, {
    getItemProps,
    highlightedIndex,
    selectedItem
  });

  return <Paper className="title-suggestion-menu" {...getMenuProps()} elevation={8}>
    {submenus}
  </Paper>
}

const buildSubmenu = (
  data,
  incrementNewIndex,
  levels,
  { getItemProps, highlightedIndex, selectedItem }
) => {
  levels++;
  return data.map((element, index) => {
    let hasItems = element.children && element.children.length;
    let isATitle = !!element.externalId; //feature / episode sections get into this code
    let itemKey = element.name + index;
    return (
      <Grid
        key={itemKey}
        className="section-container">
          { isATitle ?
            (<Table>
              {
                buildItem(element, incrementNewIndex, {
                  getItemProps,
                  highlightedIndex,
                  selectedItem,
                })
              }
            </Table>) :
            (hasItems ? <Typography style={{
              marginLeft: 16,
              marginTop: 8,
              textTransform: "uppercase"
            }} variant="h5" component="h5">{element.name}</Typography> : null)
          }

          {
            hasItems ?
            buildSubmenu(element.children, incrementNewIndex, levels, {
                getItemProps,
                highlightedIndex,
                selectedItem
              }) :
            null
          }
      </Grid>)
  });
}

const buildItem = (
  item,
  incrementNewIndex,
  { getItemProps, highlightedIndex, selectedItem }
) => {
  let newIndex = incrementNewIndex();
  let compositeKey = (item.alphaId || item.gpmsId);
  return (
    <TableBody
      highlighted={(highlightedIndex === newIndex).toString()}
      selected={selectedItem === item}
      {...getItemProps({
        key: compositeKey,
        index: newIndex,
        item
      })}
      >
           <TableRow className="title-menu-item">
            { !item.alphaId ?
                (<TableCell  className="title-cell">
                  <Typography>{`${item.titleFullName || item.primaryTitleName || item.name}:`}</Typography>
                </TableCell>)
              :
              null
            }
            { item.alphaId ?
              (
                  <React.Fragment>
                  <TableCell align="left" className="title-cell alphaIcon">
                    <SvgAlphas />
                  </TableCell>
                  <TableCell align="left" className="title-cell alphaName">
                    {item.alphaName}
                  </TableCell>
                  <TableCell align="left" className="title-cell alphaId">
                    {item.alphaId}
                  </TableCell>
                  <TableCell align="right" className="title-cell alphaOwner">
                    {item.alphaOwner}
                  </TableCell>
                  </React.Fragment>
                ) :
              null
          }
        </TableRow>
    </TableBody>
  );
}

const renderInputComponent = (inputProps) => {
  const { classes, loadingProgress,
          getInputProps, fullWidth = false,
          titleIndex, placeholder } = inputProps;

  return (
    <div>
      <TextField
        fullWidth={fullWidth}
        placeholder={placeholder}
        inputProps={{
          ['data-testid']: "autocomplete-input",
        }}
        InputProps={{
          startAdornment: <InputAdornment position="start"><SvgSearch></SvgSearch></InputAdornment>,
          className: titleIndex > 0 ? classes.deletableInput : classes.input,
          disableUnderline: loadingProgress,
        }}
        {...getInputProps()}
      />
    </div>
  );
}

const getSuggestions = (value) => {
  if (!value || !value.length) {
    return [];
  }

  const suggestions = [
    {
      name: 'Features/Volumes',
      children: [],
    },
    {
      name: 'Episodes',
      children: [],
    }
  ];

  value.forEach((data) => {
    data.children = data.children || [];

    if (data.categoryLongName) {
      let productType = data.categoryLongName || data.productType;
      let categoryLongName = getNormalizedProductType(productType);
      switch(categoryLongName) {
        case 'feature':
          suggestions[0].children.push(data);
          break;
        case 'tv series':
          suggestions[1].children.push(data);
          break;
      }
    }
  });

  return suggestions;
}

const fetchSuggestions = (searchQuery) => {
  return TitleService.search(searchQuery);
}

class AutoComplete extends Component {
  constructor(props) {
    super(props);
    this.state = {
      suggestions: [],
      loadingProgress: false,
    };

    this.debouncedLoadSuggestions = _.debounce(this.loadSuggestions, 500);
  }

  componentDidMount() {
    this.unmounted = false;
  }

  handleSuggestionsInputChange = (changes, downshift) => {
    if (this.unmounted) {
      return;
    }

    if (changes.hasOwnProperty('inputValue')) {
      let { inputValue } = changes;
      if (inputValue.length > SUGGESTION_START_LENGTH) {
        this.debouncedLoadSuggestions(inputValue);
      } else {
        downshift.clearItems();
        downshift.closeMenu();
        this.setState({
          suggestions: []
        })
      }
    }
  };

  componentWillUnmount() {
    this.unmounted = true;
  }

  render() {
    const { classes, fullWidth } = this.props;

    let inputProps = {
      classes,
      placeholder: this.props.placeholder,
      loadingProgress: this.state.loadingProgress,
      titleIndex: this.props.titleIndex,
      fullWidth,
    }
    return (
      <Downshift
        onStateChange={this.handleSuggestionsInputChange}
        itemToString={TitleService.displayTitle}
        onChange={this.props.onSuggestionSelected}
        selectedItem={this.props.title}
      >
        {(downshiftOptions) => {
          let { isOpen, getInputProps } = downshiftOptions;
          inputProps = Object.assign({}, inputProps, { getInputProps });

          return (
            <div className={classes.root}>
              { renderInputComponent(inputProps) }
              {
                isOpen && this.state.suggestions.length ?
                buildMenu(downshiftOptions, this.state.suggestions) :
                null
              }
              { this.state.loadingProgress && <LinearProgress className="linear-progress"/> }
            </div>
          );
        }}
      </Downshift>
    );
  }

  loadSuggestions = (value) => {
    if (this.unmounted) {
      return;
    }

    this.setState({
      loadingProgress: true,
    });
    fetchSuggestions(value).then((res) => {
        this.setState({
          loadingProgress: false,
          suggestions: getSuggestions(res.data)
        })
    }, (err) => {
    });
  }
}

let styledAutoComplete = withStyles(styles)(AutoComplete);
export { styledAutoComplete as TitleAutoComplete };
