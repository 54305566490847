import React, { Component } from 'react';
import { TwoSelectDataForm } from './TwoSelectDataForm';
import { ExpandableSection } from '../common';
import { Grid, Typography, ExpansionPanel, ExpansionPanelSummary,
    ExpansionPanelDetails, Divider } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
class DubCard extends Component {

    constructor(props) {
        super(props);
        this.state = {
            fieldError: false,
        }
    }

    isFieldError = (fieldError) => {
        this.setState({
            fieldError
        })
    }

    render() {
        const applyCardFocusClass = this.props.errorsEnabled &&
                                this.props.focusErrorHandler(this.state.fieldError, null);
        const defaultFocusClass = applyCardFocusClass ? applyCardFocusClass : 'normal';
        return (
            <Grid container component={ExpandableSection} header="dub card" 
            defaultExpanded={true} focusErrorCssStyle={defaultFocusClass}>
                    <Grid container className="data-pane">
                        <TwoSelectDataForm defaultState={this.props.defaultState}
                            componentName="dubcard"
                            files={this.props.files}
                            isFieldError={this.isFieldError}
                            validateForm={this.props.validateForm}
                            focusErrorHandler={this.props.focusErrorHandler}
                            updateErrors={this.props.updateErrors}
                            errorsEnabled={this.props.errorsEnabled}
                            rowLimit={this.props.rowLimit}
                            handleChildChange={this.props.handleChildChange}
                            languages={this.props.languages}
                        />
                    </Grid>
            </Grid>
        );
    }
};

export { DubCard };