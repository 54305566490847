import React, { Component } from 'react';
import { Grid, Typography, MenuItem, Button } from '@material-ui/core';
import { Dropdown, ExpandableSection, RadioButtonGroup, MultiSelect } from '../components/common';
import { SynopsesData } from './';
import territoryList from './territories';
import languages from './languages';
import prismTitleTypes from './prismTitleTypes';
import { Add } from "@material-ui/icons";
import _ from 'lodash';

const titleSources = ['GPMS', 'PRISM'];
const gpmsTitleTypes = ['Display Title', 'Primary'];

function getPriorityNum(index) {
    // Because index starts from 0, add 1
    let num = index + 1;
    switch(num) {
        case 1:
            return `${num}ST`;
        case 2:
            return `${num}ND`;
        case 3:
            return `${num}RD`;
        default:
            return `${num}TH`;
    }
}

function getDefaultTitleData() {
    return {
        source: "",
        type: "",
    }
}

class TerritoryDetails extends Component {
    constructor(props) {
        super(props);
        this.state = props.language;
    }

    componentDidUpdate(prevProps) {
        if(!_.isEqual(prevProps.language.territories, this.props.language.territories)) {
            this.setState(this.props.language);
        }
    }


    handleChange = (label, value) => {
        this.setState({
            [label]: value,
        }, () => {
            this.props.onLanguageChange(this.state, this.props.index);
        })
    }

    handleTitleChange = (label, value, index) => {
        let newState = Object.assign({}, this.state);

        let aryTitle = newState.title.concat([]);
        aryTitle[index][label] = value;
        newState.title = aryTitle;
        this.setState(newState, () => {
            this.props.onLanguageChange(this.state, this.props.index);
        })
    }

    handleSynopsisChange = (label, value) => {
        let newState = Object.assign({}, this.state);
        newState['synopses'][label] = value;
        this.setState(newState, () => {
            this.props.onLanguageChange(this.state, this.props.index);
        })
    }

    handleAddRow = () => {
        if(this.state.title.length === 4) {
            return;
        }

        let newArray = this.state.title.concat([getDefaultTitleData()]);

        this.setState({
            title: newArray,
        }, () => {
            this.props.onLanguageChange(this.state, this.props.index);
        })
    }

    isTitleSourceEmpty = (index) => {
        let source = this.state.title[index].source;
        return _.isEmpty(source);
    }

    getTitleSource = (index) => {
        let source = this.state.title[index].source;
        if(source) {
            return source.toLowerCase();
        }
    }

    disableRepeatedLanguage = (territory) => {
        let selectedTerritories = _.map(this.props.territories, (territory) => {
            return territory.languageName;
        });
        return selectedTerritories.includes(territory);
    }

    render() {
        let { language, territories, title, eidr, alphaId,
            mpmWalkerId, isan, copyrightLine, mpaaRatingReason, synopses } = this.state;
        return (
            <Grid container className="card-padding" style={{
                backgroundColor: '#ffffff',
                marginBottom: 0,
            }}>
                <Grid item component={Dropdown}
                    label="Language"
                    required={true}
                    field={'language'}
                    value={language}
                    onChange={this.handleChange}
                >
                    {
                        languages.map((language, index) => {
                            let disableRepeatedLanguage = this.disableRepeatedLanguage(language);
                            return (
                                <MenuItem key={index} value={language} disabled={disableRepeatedLanguage}>
                                    <Typography>{language}</Typography>
                                </MenuItem>
                            )
                        })
                    }
                </Grid>

                <Grid container>
                    <MultiSelect
                    label="Territories"
                    menuItems={territoryList}
                    field="territories"
                    value={territories}
                    onChange={this.handleChange}
                    required={true}/>
                </Grid>

                <Grid item component={ExpandableSection} header="Title" defaultExpanded={true}
                    focusErrorCssStyle="normal">
                    <Grid container direction="column" justify="space-between">
                    {
                        title.map((titleData, index) => {
                            return (
                                <Grid container direction="column" justify="space-between" key={index} style={{
                                    marginTop: 16,
                                }}>
                                    <Typography variant="h5" component="h5" 
                                    style={{
                                        color: "#5e5edb"
                                    }}>{`${getPriorityNum(index)} PRIORITY`}</Typography>
                                    <Grid container direction="row" justify="space-between">
                                        <Grid item component={Dropdown}
                                            label="Title Source"
                                            value={titleData.source}
                                            onChange={this.handleTitleChange}
                                            field={'source'}
                                            index={index}
                                            style={{
                                                width: '32vw',
                                                marginTop: 40,
                                                marginRight: 10,
                                                marginBottom: 15,
                                            }}
                                        >
                                            {
                                                titleSources.map((source, index) => {
                                                    return (
                                                        <MenuItem key={index} value={source}>
                                                            <Typography>{source}</Typography>
                                                        </MenuItem>
                                                    )
                                                })
                                            }
                                        </Grid>
                                        <Grid item component={Dropdown}
                                            label="Title Type"
                                            value={titleData.type}
                                            onChange={this.handleTitleChange}
                                            field={'type'}
                                            disabled={this.isTitleSourceEmpty(index)}
                                            index={index}
                                            style={{
                                                width: '32vw',
                                                marginTop: 40,
                                                marginRight: 10,
                                                marginBottom: 15,
                                            }}
                                        >
                                            {
                                                this.getTitleSource(index) === 'gpms' ? (
                                                    gpmsTitleTypes.map((type, index) => {
                                                        return (
                                                            <MenuItem key={index} value={type}>
                                                                <Typography noWrap={true}>{type}</Typography>
                                                            </MenuItem>
                                                        )
                                                    })
                                                ) : (
                                                    prismTitleTypes.map((type, index) => {
                                                        return (
                                                            <MenuItem key={index} value={type}>
                                                                <Typography noWrap={true}>{type}</Typography>
                                                            </MenuItem>
                                                        )
                                                    })
                                                )
                                            }
                                        </Grid>
                                    </Grid>
                                </Grid>
                            )
                        })
                    }
                    <Grid item container justify="space-between">
                        <Grid item>
                            <Button style={{ marginTop: 16 }} color="primary" onClick={this.handleAddRow}>
                                <Add style={{ marginRight: 12 }} />
                                ADD NEW ROW
                            </Button>
                        </Grid>
                    </Grid>
                    </Grid>
                </Grid>

                <Grid container direction="row" justify="space-between">
                    <Grid item component={RadioButtonGroup}
                        label="EIDR"
                        field="eidr"
                        onChange={this.handleChange}
                        value={eidr}
                    />
                    <Grid item component={RadioButtonGroup}
                        label="Alpha ID"
                        field="alphaId"
                        onChange={this.handleChange}
                        value={alphaId}
                    />
                    <Grid item component={RadioButtonGroup}
                        label="MPM"
                        field="mpmWalkerId"
                        onChange={this.handleChange}
                        value={mpmWalkerId}
                    />
                    <Grid item component={RadioButtonGroup}
                        label="ISAN"
                        field="isan"
                        onChange={this.handleChange}
                        value={isan}
                    />
                    <Grid item component={RadioButtonGroup}
                        label="Copyright Line"
                        field="copyrightLine"
                        onChange={this.handleChange}
                        value={copyrightLine}
                    />
                    <Grid item component={RadioButtonGroup}
                        label="Ratings Reason"
                        field="mpaaRatingReason"
                        onChange={this.handleChange}
                        value={mpaaRatingReason}
                    />
                </Grid>

                <SynopsesData
                    key="logline"
                    onChange={this.handleSynopsisChange}
                    field="logline"
                    synopses={synopses}
                    languages={languages}
                    getPriorityNum={getPriorityNum}
                />

                <SynopsesData 
                    key="medium"
                    onChange={this.handleSynopsisChange}
                    field="medium"
                    synopses={synopses}
                    languages={languages}
                    getPriorityNum={getPriorityNum}
                />

                <SynopsesData
                    key="long"
                    onChange={this.handleSynopsisChange}
                    field="long"
                    synopses={synopses}
                    languages={languages}
                    getPriorityNum={getPriorityNum}
                />

            </Grid>
        )
    }
}

export { TerritoryDetails };