import React from 'react';
import CloseX from '../../assets/icons/CloseX';
import { Button, Grid, Typography } from '@material-ui/core';

export class FileList extends React.Component {

  render() {
    let { files } = this.props;
    return (
      <Grid container item className="file-list">
        {
          files.map((file,index) => {
            return (
              <Grid
                container item
                alignItems="center"
                justify="space-between"
                key={`file-item-${index}`}
                className="file-item">
              <Grid item component="div">
                <Typography style={{ color: '#333333' }}>{file.filename}</Typography>
              </Grid>
              <Grid item>
                <Button data-testid={file.filename} className="close-icon" onClick={ (e) => {
                  e.preventDefault();
                  this.props.removeFile(file);
                }}>
                  <CloseX className="btn-x-remove" />
                </Button>
              </Grid>
              </Grid>
            )
          })}
      </Grid>
    );
  }
}
