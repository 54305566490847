import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { clientShow } from '../Clientshow/clientshow';
import { TaskIdService } from '../../_services';

const DistributionMetadata = (props) => {
  let { taskIds } = props;
  let [ prismData, setPrismData ] = useState(null);
  let [ errors, setErrors] = useState({});

  let showDeliveryTaskDueDateErrors = (errors) => {
    let { deliveryTaskDueDate } = errors;

    if (!deliveryTaskDueDate) {
      return;
    }

    let dates = deliveryTaskDueDate.map((d) => {
      let date = new Date(d);
      let formatted = new Intl.DateTimeFormat(
        'en-US',{ month: 'long', day: 'numeric'})
        .format(date);

      return formatted;
    })

    let msg = `Warning: Your metadata access date is ` +
      dates.join(',') +
     `. Please pull again on this date or contact your CMDG representative.`;

     props.showNotification && props.showNotification(true, msg);
  }

  let showMissingTaskIdsError = (errors) => {
    let { missingTaskIds } = errors;
    if (!missingTaskIds) {
      return;
    }

    let msg = `These Task ID(s): ${missingTaskIds.join(', ')} were not found.`;
     props.showNotification && props.showNotification(true, msg);
  }

  let handleErrors = (errors) => {
    let { missingTaskIds, deliveryTaskDueDate } = errors;

    if (missingTaskIds) {
      setTimeout(() => {
        showMissingTaskIdsError(errors);
      }, 0);
    }

    if (deliveryTaskDueDate) {
      setTimeout(() => {
        showDeliveryTaskDueDateErrors(errors);
      }, 0);
    }
  }

  useEffect(() => {
    let fetchPrismData = async () => {
      let resp = await TaskIdService.retrievePrismTaskData(taskIds).catch(ex => {
        console.error('Error occurred while attempt to retrieve task data');
      });

      if (!resp) {
        return;
      }

      let {errors, ...rest} = resp.data;
      setPrismData(rest);
      setErrors(errors);
      handleErrors(errors);
    }

    if (taskIds) {
      fetchPrismData();
    }

  }, [taskIds]);

  return(<pre style={{overflow:'auto'}}><code>{JSON.stringify(prismData, undefined, 4)}</code></pre>);
}

const mapStateToProps = state => {
  let { taskIds: taskIdsReducer } = state;
  return {
    taskIds: taskIdsReducer.taskIds,
  }
}

let clientConnected = clientShow(DistributionMetadata, 'DistributionMetadata');
let connected = connect(mapStateToProps)(clientConnected);
export { connected as DistributionMetadata }
