import { notifierConstants } from '../_constants/notifier.constants';

const NotifierAction = {
  showNotification: (show, msg, notifierType) => {
    return {
      type: notifierConstants.showNotification,
      show,
      msg,
      notifierType,
    };
  },
  hideNotification: (show) => {
    return {
      type: notifierConstants.hideNotification,
      show,
    };
  },
};

export default NotifierAction;
