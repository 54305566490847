import React, { Component } from 'react';
import { Grid, Paper, Typography } from '@material-ui/core';
import { ManifestDataForm } from './ManifestDataForm';
import { DubCard } from './DubCard';
import { SubtitleData } from './SubtitleData';
import { ManifestArtworkData } from './ManifestArtworkData';
import { clientShow } from '../Clientshow/clientshow';
import { connect } from "react-redux";
import { languages } from "./manifestLanguages";
import { TitleData } from "../TitleDisplay/TitleData";
import { TerritorySelect } from "../Dbb/TerritorySelect";

class MovielabsManifest extends Component {
    constructor(props) {
        super(props);
        this.state = {
            errors: null,
            ...props,
            subMPAA: true,
        }
    }

    setSubMPAA = (subMPAA) => {
        this.setState({
            subMPAA
        }, () => {
            this.props.handleChildChange('substituteMPAA',  { subMPAA });
        });
    }

    validateForm = (data, errorObj) => {
        if(!data) {
            return null;
        }

        let hasErrors = false;

        _.forEach(data, (value, key) => {
            if(key !== 'ebucore') {
                if(_.isEmpty(value)) {
                    errorObj[`${key}Error`] = true;
                    hasErrors = true;
                } else {
                    errorObj[`${key}Error`] = false;
                }
            }
        });

        return {
            errorObj,
            hasErrors,
        };
    }

    render() {
        return (
            <Grid container direction="column"
            justify="space-between"
            className="card-padding"
            component={Paper}
            elevation={8}>
                <Grid item>
                  <Typography variant="h5" component="h5">PACKAGE DATA</Typography>
                </Grid>
                <Grid item component={TitleData}
                    {...this.props}
                    warning={this.props.titleWarning}
                    onSubstituteMPAAClick={this.setSubMPAA}
                    subMPAA={this.state.subMPAA}
                >
                    <TerritorySelect {...this.props} />
                </Grid>

                <Grid item component={ManifestDataForm}
                    header={'Feature/Episode Data'}
                    componentName="feature"
                    handleChildChange={this.props.handleChildChange}
                    validateForm={this.validateForm}
                    focusErrorHandler={this.props.focusErrorHandler}
                    updateErrors={this.props.updateErrors}
                    languages={languages}
                    errorsEnabled={this.props.errorsEnabled}
                    files={this.props.files}
                    rowLimit={2}
                    defaultState={{
                        data: {
                            filename: '',
                            language: '',
                            dynamicRange: '',
                            maxCll: '',
                            maxFall: '',
                            displayPreset: '',
                        },
                        disableFields: false,
                    }}
                />
                <Grid item component={ManifestDataForm}
                    header={'Trailer Data'}
                    componentName="trailer"
                    validateForm={this.validateForm}
                    focusErrorHandler={this.props.focusErrorHandler}
                    updateErrors={this.props.updateErrors}
                    errorsEnabled={this.props.errorsEnabled}
                    handleChildChange={this.props.handleChildChange}
                    languages={languages}
                    files={this.props.files}
                    rowLimit={2}
                    defaultState={{
                        data: {
                            filename: '',
                            language: '',
                            dynamicRange: '',
                            maxCll: '',
                            maxFall: '',
                            displayPreset: '',
                        },
                        disableFields: false,
                    }}
                />
                <Grid item component={DubCard}
                    files={this.props.files}
                    validateForm={this.validateForm}
                    focusErrorHandler={this.props.focusErrorHandler}
                    updateErrors={this.props.updateErrors}
                    errorsEnabled={this.props.errorsEnabled}
                    handleChildChange={this.props.handleChildChange}
                    languages={languages}
                    rowLimit={10}
                    defaultState={{
                        data: {
                            filename: '',
                            language: '',
                        }
                    }}/>
                <Grid item component={SubtitleData}
                    componentName="subtitle"
                    validateForm={this.validateForm}
                    focusErrorHandler={this.props.focusErrorHandler}
                    updateErrors={this.props.updateErrors}
                    errorsEnabled={this.props.errorsEnabled}
                    handleChildChange={this.props.handleChildChange}
                    languages={languages}
                    rowLimit={10}
                    files={this.props.files}
                    defaultState={{
                        data: {
                            filename: '',
                            language: '',
                            type: '',
                        }
                    }}
                />
                <Grid item component={ManifestArtworkData}
                    componentName="artwork"
                    validateForm={this.validateForm}
                    focusErrorHandler={this.props.focusErrorHandler}
                    updateErrors={this.props.updateErrors}
                    errorsEnabled={this.props.errorsEnabled}
                    handleChildChange={this.props.handleChildChange}
                    languages={languages}
                    rowLimit={10}
                    files={this.props.files}
                    defaultState={{
                        data: {
                            filename: '',
                            language: '',
                            resolutionX: '',
                            resolutionY: '',
                        }
                    }}
                />
            </Grid>
        );
    }
}

const mapStateToProps = (state) => {
    let { files: filesReducer, titles } = state;
    let newfiles = filesReducer.files.concat([{filename: ''}]);
    return {
      files: newfiles,
    };
}

const storeConnected = connect(mapStateToProps, null)(MovielabsManifest);
const clientConnected = clientShow(storeConnected, 'MovielabsManifest');
export { clientConnected as MovielabsManifest };
