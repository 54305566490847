import { SidecarService } from './sidecar.service';

const getAll = () => {
  return SidecarService.get('/profiles');
};

const get = (profileName) => {
  return SidecarService.get(`/profiles/${profileName}`);
};

const save = (profile) => {
  return SidecarService.post('/profiles', profile);
};

const deleteProfile = (profileName) => {
  return SidecarService.delete(`/profiles/${profileName}`);
};

const verify = (profile) => {
  return SidecarService.post(`/profiles/verify`, profile);
};

const test = (profile) => {
  return SidecarService.post(`/profiles/test`, profile);
};

export const ProfileService = {
  save,
  get,
  getAll,
  delete: deleteProfile,
  verify,
  test,
};

