import { notifierConstants } from '../_constants/notifier.constants';

const initState = {
  show: false,
  msg: '',
  notifierType: 'warning', // success, warning, error
};

export const notifier = (state = initState, action) => {
  switch (action.type) {
    case notifierConstants.showNotification:
      return Object.assign({}, state, {
        show: action.show,
        msg: action.msg,
        notifierType: action.notifierType,
      });
    case notifierConstants.hideNotification:
      return Object.assign({}, state, { show: action.show });
    default:
      return state;
  };
};
