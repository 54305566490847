import React, { useState } from 'react';
import { DragDrop } from '../common/';
import { TimecodeField, TimecodeProgramField} from '.';
import Drag from '../../assets/icons/Drag';
import { Grid } from '@material-ui/core';
import './DraggableTimecodeField.styles.scss';

 const DraggableTimecode = (props) => {
  let [isHovering, setIsHovering] = useState(false);
  let itemClass = isHovering ? 'item-hover' : 'item-normal';

  let handleMouseOver = (e) => {
    setIsHovering(!isHovering);
  }

  return (
    <DragDrop {...props}>
      <Grid container direction="row" wrap="nowrap" className={itemClass} onMouseEnter={handleMouseOver} onMouseLeave={handleMouseOver}>
        { isHovering ?
          <Grid item component={Drag} className="drag-handle" /> :
          null
        }
        { props.children }
      </Grid>
    </DragDrop>
  );
}

export const DraggableTimecodeField = (props) => {
  return (<DraggableTimecode {...props}>
    <Grid item component={TimecodeField} {...props}  />
  </DraggableTimecode>);
}

export const DraggableTimecodeProgramField = (props) => {
  return (<DraggableTimecode {...props}>
    <Grid item component={TimecodeProgramField} {...props} />
  </DraggableTimecode>);
}