import _ from 'lodash';
import { SidecarService } from './sidecar.service';

function transformToBase64(file) {
  return new Promise((resolve, reject) => {
    let reader = new FileReader();

    reader.onloadend = (event) => {
      let data = event.currentTarget.result.split(',')[1];
      resolve({
        name: file.name,
        fileData: data,
      });
    };

    reader.onerror = (event) => {
      reader.abort();
      reject(new DOMException("Problem parsing file"));
    };

    reader.readAsDataURL(file.slice(0));
  });
}

function findFileFor(filename, files) {
  return _.find(files, { filename: filename});
}

function getChecksumForFilename(filename, files) {
  let file = this.findFileFor(filename, files);
  if (file) {
    return file.md5;
  }
}

function processFiles(files) {
  return SidecarService.post('/files', { files });
}

export const FileService = {
  transformToBase64,
  findFileFor,
  getChecksumForFilename,
  processFiles,
};
