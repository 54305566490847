import moment from "moment";

export class UtilityService {
  static isSeason = (title) => {
    return (title &&
      title.productLevel &&
      title.productLevel.toLowerCase() === 'season');
  };

  static isAlphaTitle = (title) => {
    return !!title.alphaId;
  };

  static convertDateField = (dateStr) => {
    if (!dateStr) {
      return null;
    }
    let momentDateObj = moment.utc(dateStr);
    return momentDateObj.isValid() ? momentDateObj.format("MM/DD/YYYY") : dateStr;
  };

  static usesCmdgTitlePane = (client) => {
    let clientList = [
      'cmdgInt',
      'cmdgTv',
      'movielabsTitleMetadata',
      'PXL',
      'PXLBoxy',
      'PXL_TV',
      'exhibitionMetadata',
    ];
    return (client && clientList.includes(client.deliveryModel));
  };

  static isDbbBulkClient = (client) => {
    return (client && client.deliveryModel === 'dbbBulkIngest');
  };

  static performActionForDbbNonAlphaSelected = (client, title, callback) => {
    let dbbNonAlphaSelected = (this.isDbbBulkClient(client) &&
      title && title.externalId && !this.isAlphaTitle(title));

    this.performActionForCondition(dbbNonAlphaSelected, callback);
  };

  static performActionForCondition = (condition, callback) => {
    if (condition) {
      callback && callback();
    }
  };
};
