import React, { Component } from 'react';
import _ from 'lodash';
import { Grid, MenuItem, Paper, Typography } from '@material-ui/core';
import { SeriesArt, TitleInfo, SeasonDatePicker, Episode } from './';
import { BillingData, BillingId, ClosedCaption } from '../DirectTV';
import { ExpandableSection, Dropdown, RadioButton, TitleDetails, TitleField } from '../common';
import { TitleService } from '../../_services';
import Loader from 'react-loaders';
import moment from 'moment';

let initialState = {
    applyAll: false,
    useEpisodeLevel: false,
    licensingStartDate: null,
    licensingEndDate: null,
    episodes: [],
    alphas: [""],
    alphaName: '',
    artwork: null,
    loadingSpinner: true,
    billing: null,
};
class SeasonPackage extends Component {
    constructor(props) {
        super(props);
        let { season={} } = props;
        let { episodeData = [], ...seasonData } = season;

        this.state = {
            ...initialState,
            episodeData,
            ...seasonData,
            title: {}
        };
    }

    shouldComponentUpdate(nextProps, nextState) {
        return ((nextState.alphas !== this.state.alphas) ||
                (nextState.alphaName !== this.state.alphaName) ||
                (nextState.episodes.length !== this.state.episodes.length) ||
                (nextState.applyAll !== this.state.applyAll) ||
                (nextState.useEpisodeLevel !== this.state.useEpisodeLevel) ||
                (nextState.loadingSpinner !== this.state.loadingSpinner) ||
                (nextProps.errorsEnabled !== this.props.errorsEnabled))
    }

    postChange = () => {
        let { episodes} = this.state;

        let episodeData = _.map(episodes, 'data');
        episodeData = _.compact(episodeData);

        let eligibleEpisodes = _.filter(episodeData, (obj) => {
            return !obj.alphaNotFound;
        });

        let hasErrors = _.some(eligibleEpisodes, (obj) => {
            return !_.isEmpty(obj.errors);
        });

        let data = {
            ...this.state,
            episodeData: eligibleEpisodes,
        };

        this.props.updateErrors('season', !hasErrors);
        this.props.onChange('season', data);
    }

    componentDidMount() {
        let { client, title } = this.props;
        let titlePromise = TitleService.getTitleForClient(client.id, title.externalId);
        let episodesPromise = TitleService.getTitlesByParent(title.externalId);

        titlePromise.then((resp) => {
            this.setState({title: resp.data.presentedTitle});
        });
        episodesPromise.then((resp) => {

            this.setState({
                episodes: resp.data,
                loadingSpinner: false,
            }, () => {
                this.postChange();
            });

        }).catch(ex => {
            console.log(ex);
        });
    }

    handlesArtworkDataChange = (data) => {
        this.setState(() => {
            return {
                artwork: data
            }
        }, () => {
            this.postChange();
        });
    }

    handleSeasonDatePickerChange = (data) => {
        let { applyAll, startDate, endDate } = data;
        this.setState({
            applyAll,
            licensingStartDate: startDate,
            licensingEndDate: endDate,
        }, () => {
            this.postChange();
        });
    }

    handleEpisodeChange = (data) => {
        let episodes = [].concat(this.state.episodes);
        if (data.titleId) {
            let episode = _.find(episodes, { externalId: data.titleId });
            if (episode) {
                episode.data = data;

                this.setState({
                    episodes
                }, () => {
                    this.postChange();
                });
            }
        }
    }

    addToAlphas = (alphaAry) => {
        if (_.difference(alphaAry, this.state.alphas).length) {
            let collection = this.state.alphas.concat(alphaAry);
            collection = _.sortBy(collection);
            let alphas = _.uniq(collection);
            this.setState({
                alphas
            });
        }
    }

    handleAlphaDropdown = (label, value) => {
        this.setState({
                [label]: value,
            }, () => {
            this.postChange();
        });
    }

    renderLoader = () => {
        return (
            <Grid item container>
                <Loader type="line-spin-fade-loader" active={true} color='#5e5edb' style={{
                    position: 'relative',
                    transform: 'scale(0.5)',
                }}/>
            </Grid>
        );
    }

    handleBillingData = (pane, data) => {
        this.setState({
            billing: data,
        })
    }

    handleEpisodeLevelToggle = (useEpisodeLevel) => {
        this.setState({
            useEpisodeLevel,
            alphaName: ''
        });
    }

    displayDate = (dateStr) => {
        let momentDateObj = moment(dateStr);
        return momentDateObj.isValid() ? momentDateObj.format("MM/DD/YYYY") : dateStr;
    }

    render() {
        let {
            licensingStartDate: startDate,
            licensingEndDate: endDate,
            applyAll,
            alphaName,
            useEpisodeLevel,
        } = this.state;

        let { title } = this.state;
        let seasonPackageSettings = {};
        if (alphaName) {
            seasonPackageSettings.alphaName = alphaName;
        }

        if (applyAll) {
            seasonPackageSettings.licensing = {
                startDate,
                endDate
            }
        }

        return (
            <Grid container
            direction="column">
                <Grid item component={TitleInfo}
                    {...this.props}
                    spec={this.props.clientSpec}
                    header={'Season/Series Data'}
                >
                    <Grid item container component={TitleDetails} direction="column" justify="space-between">
                        <TitleField label="Series Name" field="name"
                            title={title.series}  warning="No Series Name found"/>
                        <TitleField label="Season Number" field="seasonNumber"
                            title={title} warning="No Season Number found"/>
                        <TitleField label="Series MPM" field="mpmWalkerId"
                            title={title.series} warning="No Series MPM found" />
                        <TitleField label="Season MPM" field="mpmWalkerId"
                            title={title} warning="No Season MPM found" />
                        <TitleField label="Series Premiere Date" field="initialReleaseDate"
                            formatFunc={this.displayDate} title={title.series}
                            warning="No Series Premiere Date MPM found"
                        />
                    </Grid>
                    <Grid container direction="row" justify="flex-start">
                        <Grid item component={BillingData}
                            { ...this.props}
                            billing={this.state.billing}
                            onPackageDataChange={this.handleBillingData}>
                            <BillingId />
                            <ClosedCaption />
                        </Grid>

                        <Grid item component={Dropdown}
                            onChange={this.handleAlphaDropdown}
                            label={'Alphas'}
                            field={'alphaName'}
                            value={this.state.alphaName}
                            disabled={useEpisodeLevel}
                        >
                        {
                            this.state.alphas.map((item, index) => {
                                return (
                                    <MenuItem key={index} value={item}>
                                        <Typography noWrap={true}>{item}</Typography>
                                    </MenuItem>
                                )
                            })
                        }
                        </Grid>
                        <Grid item component={RadioButton}
                            label="Use Episode Level Metadata"
                            handleChange={this.handleEpisodeLevelToggle}
                            value={this.state.useEpisodeLevel}
                        />
                    </Grid>
                </Grid>

                <Grid component={ExpandableSection} item defaultExpanded={true}
                    focusErrorCssStyle="normal" header='Season Licensing Data'>
                    <SeasonDatePicker
                        onChange={this.handleSeasonDatePickerChange}
                        showNotification={this.props.showNotification}
                        startDate={this.state.licensingStartDate}
                        endDate={this.state.licensingEndDate}
                        applyAll={this.state.applyAll}
                    />
                </Grid>

                <Grid item component={SeriesArt}
                    { ...this.props }
                    value={this.state.artwork}
                    onChange={this.handlesArtworkDataChange}
                />

                <Grid item component={Paper}
                    className="card-padding"
                >
                        <Grid item style={{
                            marginBottom: 20
                        }}>
                            <Typography variant="h5" component="h5">EPISODE DATA</Typography>
                        </Grid>
                        { this.state.loadingSpinner ?

                            this.renderLoader() :

                            this.state.episodes.map((episode, index) => {
                            let prevData = _.find(this.state.episodeData, { titleId: episode.externalId});
                            return (
                                <Grid key={'episode' + index} item component={Episode}
                                    {...this.props}
                                    episode={episode}
                                    prevData={prevData}
                                    seasonSettings={seasonPackageSettings}
                                    useEpisodeLevel={useEpisodeLevel}
                                    validationRequired={false}
                                    addToAlphas={this.addToAlphas}
                                    onChange={this.handleEpisodeChange}
                                    renderLoader={this.renderLoader}
                                />
                            )
                        })}
                </Grid>
            </Grid>
        )
    }
}

export default SeasonPackage;