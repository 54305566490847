import React, { Component, useState } from 'react';
import { connect } from 'react-redux';
import {  TextField, MenuItem, Select, InputLabel, FormControl, Grid, Typography } from '@material-ui/core';
import NotifierAction from '../../_actions/notifier.actions';
import { withStyles } from '@material-ui/styles';
import { clientShow } from '../Clientshow/clientshow';


const captions = ["Y", "N"];

const styles = (theme) => ({
  root: {
    fontSize: 14,
    color: "#5e5edb",
    fontWeight: 700,
  },
  formControl: {
    top: 16,
    position: 'relative',
  }
})

const BillingId = (props) => (
  <Grid item>
    <FormControl required className={props.classes.formControl}>
      <InputLabel className={props.classes.root} style={{ top: 3 }}>Billing ID</InputLabel>
      <TextField
        type="number"
        value={props.billingId}
        error={props.errorsEnabled && props.billingIdError}
        style={{
          position: 'relative',
          marginTop: 44,
          marginRight: 10,
          marginBottom: 15,
          width: 90
        }}
        onChange={props.handleChange("billingId")}
      />
    </FormControl>
  </Grid>
);

const ClosedCaption = (props) => {
  return (
    <Grid item>
      <FormControl required className={props.classes.formControl}>
        <InputLabel shrink={false} className={props.classes.root} style={{ top: 3 }}>Closed Caption</InputLabel>
        <Select
          id="closed-caption"
          value={props.closedCaption}
          onChange={props.handleChange("closedCaption")}
          MenuProps={{
            getContentAnchorEl: null,
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "left",
            }
          }}
          style={{
            width: 150,
            marginTop: 40,
            marginRight: 10,
            marginBottom: 15
          }}
        >
          {captions.map(caption => (
            <MenuItem key={caption} value={caption}>
              <Typography>{caption}</Typography>
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Grid>
    );
}

const ServiceCategory = (props) => (
  <Grid item>
    <FormControl required className={props.classes.formControl}>
      <InputLabel className={props.classes.root} style={{ top: 3 }}>Service Category</InputLabel>
      <TextField
        value={props.serviceCatalog}
        error={props.errorsEnabled && props.serviceCatalogError}
        style={{ marginTop: 42, marginRight: 10, marginBottom: 15 }}
        onChange={props.handleChange("serviceCatalog")}
      />
    </FormControl>
  </Grid>
);

const initialState = {
  billingId: 0,
  serviceCatalog: "",
  closedCaption: "Y",
  billingIdError: false,
  serviceCatalogError: false,
}

class BillingData extends Component {

    constructor(props) {
        super(props);
        let { billing={} } = props;

        let billingState = Object.assign({}, initialState, billing);

        if (props.client.name === "Roku CableLab 1.1 (LAS)"){
          billingState.closedCaption = "N"
        }

        this.state = Object.assign({}, billingState, { 
          serviceCatalogError: props.serviceCategoryError || false
        })
    }

    componentDidMount() {
      let hasErrors =  this.hasErrors();
      if(hasErrors) {
        this.props.updateErrors('billing', !hasErrors);
      }
      this.props.onPackageDataChange('billing', this.state);
    }

    hasErrors = () => {
      return this.state.billingIdError || this.state.serviceCatalogError;
    }

    handleChange = name => event => {
      let newState = {
        [name]: event.target.value,
      };

      if (name === 'billingId' || name === 'serviceCatalog') {
        let errorName = `${name}Error`;
        newState[errorName] = !event.target.value;
      }

      this.setState(newState, () => {
        this.props.updateErrors('billing', !this.hasErrors());
        this.props.onPackageDataChange('billing', this.state);
      });
    }

    render() {
        // const { classes } = this.props;
        // let errorsOn = this.props.errorsEnabled;
        // const fields = this.props.fields;

        return (
            <Grid container direction="row" style={{ marginTop: '-16px', width: 'auto' }}>
            {
              React.Children.map(this.props.children, (childElement) => {
                let data = {
                  ...this.state
                }
                return React.cloneElement(childElement, {
                  handleChange: this.handleChange,
                  ...data,
                  ...this.props
                });
              })
            }
            </Grid>
        )
    }
}

const mapStateToProps = (state) => {
  return {
  };
}

const mapDispatchToProps = (dispatch) => {
  let showNotification = NotifierAction.showNotification;
  return {
    showNotification: (errorStatus, msg) => {
      dispatch(showNotification(errorStatus, msg))
    }
  }
}

let clientConnected = clientShow(BillingData, 'BillingData');
let styled = withStyles(styles)(clientConnected);
let connected = connect(mapStateToProps, mapDispatchToProps)(styled);
export { connected as BillingData,
     BillingId,
    ClosedCaption,
    ServiceCategory };
