import React, { Component } from 'react';
import { Snackbar, Button, Typography } from '@material-ui/core';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/styles'
import NotifierAction from '../../_actions/notifier.actions';
import AlertIcon from '../../assets/icons/Alert';
import { CheckCircle } from '@material-ui/icons';
import styles from './snackbar.styles.scss';

const style = theme => ({
    error: {
        backgroundColor: '#c8003c',
        color: 'white',
        maxWidth: 'none'
    },
    warning: {
        backgroundColor: '#f39c0f',
        color: '#333333',
        maxWidth: 'none'
    },
    success: {
        backgroundColor: '#378400',
        color: 'white',
        maxWidth: 'none'
    },
    message: {
        width: 'auto',
        color: 'white',
    }
});

class ErrorSnackBar extends Component {
    handleClose = () => {
        this.props.hideNotification(false);
    }

    getNotificationClass = (notifierType, classes) => {
        let notificationColor = classes.error;
        switch (notifierType) {
            case 'warning':
                notificationColor = classes.warning;
                break;
            case 'success':
                notificationColor = classes.success;
                break;
        }

        return notificationColor;
    }

    isSuccess = () => {
        return (this.props.type === 'success');
    }

    render() {
        let duration = this.props.type === 'warning' ? null : 8000;
        const { classes } = this.props;
        let notificationColor = this.getNotificationClass(this.props.type, classes);
        let Icon = this.isSuccess() ? CheckCircle : AlertIcon;

        return (
            <div>
                <Snackbar
                    anchorOrigin={{
                        vertical: "top",
                        horizontal: "center"
                    }}
                    style={{ marginTop: 50 }}
                    open={this.props.open}
                    autoHideDuration={null}
                    onClose={this.handleClose}
                    ContentProps={{
                        "aria-describedby": "message-id",
                        classes: {
                            root: notificationColor,
                            message: classes.message,
                        }
                    }}
                    message={
                        <div>
                            <Icon className="icon" style={{ fontSize: '24px', verticalAlign: 'middle', marginTop: '8px' }} />
                            <Typography component="span" style={{
                                color: 'white',
                                display: 'inline',
                                verticalAlign: 'bottom',
                                paddingLeft: '8px',
                            }}>
                                {this.props.msg}
                            </Typography>
                            <Button key="Close" color="primary" size="small" onClick={this.handleClose}
                                style={{ float: 'right', marginTop: '8px' }}
                                classes={{
                                    root: notificationColor
                                }}><Typography style={{ color: 'white' }}>Close</Typography>
                            </Button>
                        </div>
                    }
                />
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    let { show, msg, notifierType } = state.notifier;

    return {
        open: show,
        msg,
        type: notifierType
    }
}

const mapDipatchToProps = (dispatch) => {
    let hideNotification = NotifierAction.hideNotification
    return {
        hideNotification: (show) => {
            return dispatch(hideNotification(show))
        }
    }
}

let snackBarWithStyles = withStyles(style)(ErrorSnackBar)
let connected = connect(mapStateToProps, mapDipatchToProps)(snackBarWithStyles);
export { connected as ErrorSnackBar };