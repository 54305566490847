import React from 'react';
import PropTypes from 'prop-types';
import {
    Grid, FormControl, InputLabel } from '@material-ui/core';
import { InlineDatePicker } from "material-ui-pickers";
import MomentUtils from "@date-io/moment";
import { MuiPickersUtilsProvider } from "material-ui-pickers";
import SvgCalendar from "../../assets/icons/Calendar";
import { withStyles } from '@material-ui/styles';
import moment from "moment";

const errorMsg = 'End date must be after Start date';

const styles = (theme) => ({
    root: {
      fontSize: 14,
      color: "#5e5edb",
      fontWeight: 700,
    },
    normal: {
      fontSize: 14,
      color: "#333333",
      fontWeight: 700,
    },
    formControl: {
        position: 'relative',
        marginRight: 36,
    }
  })

class DatePicker extends React.Component {
    constructor(props) {
        super(props);
        let validationRequired = false || props.validationRequired;
        let startDate = props.startDate || null;
        let endDate = props.endDate || null;
        this.state = {
            validationRequired,
            startDate,
            endDate,
        }
    }

    static getDerivedStateFromProps(props, state) {
        if(state.userEntered) {
            return null;
        }
        if ((props.startDate !== state.startDate) ||
            (props.endDate !== state.endDate)) {
            if (props.startDate || props.endDate) {
                return {
                    startDate: props.startDate,
                    endDate: props.endDate,
                }
            }
        }

        return null;
    }

    isDateValid = () => {
        let { startDate, endDate } = this.state;
        if(startDate && endDate) {
            return this.validDateRange(startDate, endDate);
        }
        return false;
    }

    isDatesEmpty = () => {
        if(this.state.validationRequired) {
            return !this.state.startDate || !this.state.endDate;
        }
        return false
    };

    onDateChange = (label) => (date) => {
        let value = date ? new Date(date) : '';
        this.setState({
            [label]: value,
            userEntered: true
        }, () => {
            let {startDate, endDate } = this.state;
            this.props.showNotification && this.props.showNotification(this.isDateValid(), errorMsg);
            this.props.onChange({
                startDate,
                endDate
            });
        });
    }

    validDateRange = (startDate, endDate) => {
        let startUTCDate = moment.utc(startDate);
        let endUTCDate = moment.utc(endDate);
        return moment(endUTCDate).isSameOrBefore(startUTCDate);
    }

    render() {
        let validationRequired = this.state.validationRequired;
        let { label, classes, disablePast = true } = this.props;

        return (
            <MuiPickersUtilsProvider utils={MomentUtils}>
                <Grid item>
                <FormControl required={validationRequired} disabled={this.props.disabled}
                className={classes.formControl}>
                <InputLabel className={validationRequired ? classes.root : classes.normal}>
                    {`${label} Start`}
                </InputLabel>
                <InlineDatePicker
                    keyboard
                    onlyCalendar
                    clearable
                    data-testid="datepicker-start"
                    style={{ marginTop: 40 }}
                    margin="normal"
                    value={this.state.startDate}
                    disabled={this.props.disabled}
                    className="date-picker"
                    placeholder="MM/DD/YYYY"
                    disablePast={disablePast}
                    error={this.props.errorsEnabled && this.isDatesEmpty()}
                    onChange={this.onDateChange('startDate')}
                    mask={value =>
                    value ? [/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/] : []
                    }
                    keyboardIcon={<SvgCalendar/>}
                    format="MM/DD/YYYY"
                />
                </FormControl>
                </Grid>
                <Grid item>
                <FormControl required={validationRequired} disabled={this.props.disabled}
                className={classes.formControl}>
                    <InputLabel className={validationRequired ? classes.root : classes.normal}>
                        {`${label} End`}
                    </InputLabel>
                    <InlineDatePicker
                    keyboard
                    onlyCalendar
                    clearable
                    data-testid="datepicker-end"
                    style={{ marginTop: 40 }}
                    margin="normal"
                    value={this.state.endDate}
                    className="date-picker"
                    disablePast={true}
                    placeholder="MM/DD/YYYY"
                    disabled={this.props.disabled}
                    error={this.props.errorsEnabled && this.isDatesEmpty()}
                    onChange={this.onDateChange('endDate')}
                    mask={value =>
                    value ? [/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/] : []
                    }
                    keyboardIcon={<SvgCalendar/>}
                    format="MM/DD/YYYY"
                />
                </FormControl>
                </Grid>
            </MuiPickersUtilsProvider>
        )
    }
}

DatePicker.propTypes = {
    label: PropTypes.string.isRequired,
}

const styled = withStyles(styles)(DatePicker);
export { styled as DatePicker };