import React from 'react';
import { FileDragDrop } from './FileDragDrop';
import { FileAdd } from './FileAdd';
import { FileService } from '../../_services/file.service';
import { clientShow } from '../Clientshow/clientshow';
import './FileUpload.styles.scss';

import { Paper, Grid } from '@material-ui/core';

class FileUpload extends React.Component {

  constructor(props) {
    super(props);
  }

  // updateFiles = (files) => {
  //   this.props.onChange(files);
  // }

  convertFiles = (newFiles) => {
    let selectedFiles = Array.from(newFiles);

    let promises = _.map(selectedFiles, (file) => {
        return FileService.transformToBase64(file);
    });

    Promise.all(promises).then((transformedFiles) => {
      this.props.addFiles(transformedFiles);
    });
  }

  // selectFiles = (files) => {
  //   let selectedFiles = Array.from(files);

  //   let promises = _.map(selectedFiles, (file) => {
  //       return FileService.transformToBase64(file);
  //   });

  //   Promise.all(promises).then((transformedFiles) => {
  //     this.addFiles(transformedFiles);
  //   });
  // }

  // removeFile = (file) => {
  //   var newFiles = this.props.files.filter(item => {
  //     return item.name !== file.name;
  //   });
  //   this.updateFiles(newFiles);
  // }

  render() {
    return (
      <Grid container component={Paper} alignContent="flex-start" className="file-upload" elevation={8}>
        <Grid container item component={FileAdd} selectFiles={this.convertFiles} files={this.props.files} />
        <Grid container item component={FileDragDrop} selectFiles={this.convertFiles} files={this.props.files} removeFile={this.props.removeFile} />
      </Grid>
    );
  }
}

let clientConnected = clientShow(FileUpload, 'FileUpload');
export { clientConnected as FileUpload }