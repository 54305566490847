import React, { Component } from 'react';
import { Grid, FormControl, FormLabel, RadioGroup, 
    FormControlLabel, Radio } from '@material-ui/core';
import _ from 'lodash';
import { CheckCircle, RadioButtonUncheckedRounded } from '@material-ui/icons';

class RadioButtonGroup extends Component {

    constructor(props) {
        super(props);
        this.state = {
            value: props.value || "yes"
        }
    }

    componentDidUpdate(prevProps) {
        if(!_.isEqual(prevProps.value, this.props.value)) {
            let value = this.props.value
            this.setState({
                value,
            }, () => {
                this.props.onChange(this.props.field, value);
            });
        }
    }

    handleChange = (label) => (event) => {
        let value = event.target.value;
        this.setState({
            [label]: value,
        }, () => {
            this.props.onChange(this.props.field, value);
        })
    }

    render() {
        let{ label, firstOption = "yes", secondOption = "no" } = this.props;
        return (
            <Grid item>
                <FormControl component="fieldset" style={{ marginTop: 22 }}>
                    <FormLabel component="label"
                        style={{ fontSize: 14, marginLeft: 10, color: "#333333" }}
                    >
                        {label}
                    </FormLabel>
                    <RadioGroup style={{ display: "inline", marginLeft: 10 }}
                    value={this.state.value}
                    onChange={this.handleChange("value")}
                    >
                    <FormControlLabel
                        value={firstOption}
                        control={<Radio 
                            icon={<RadioButtonUncheckedRounded />}
                            checkedIcon={<CheckCircle />}    
                        />}
                        label={_.startCase(firstOption)}
                    />
                    <FormControlLabel
                        value={secondOption}
                        control={<Radio 
                            icon={<RadioButtonUncheckedRounded />}
                            checkedIcon={<CheckCircle />}    
                        />}
                        label={_.startCase(secondOption)}
                    />
                    </RadioGroup>
                </FormControl>
            </Grid>
        )
    }
}

export { RadioButtonGroup };