import React, { Component } from 'react';
import { Grid, Typography, FormControl, RadioGroup,
    FormControlLabel, Radio } from '@material-ui/core';
import { CheckCircle } from '@material-ui/icons';
import { clientShow } from '../Clientshow/clientshow';

class TitleLanguage extends Component {
    render() {
        return (
        <Grid item className="title-pane-language">
            <Typography className="title-pane-subheading">Title and Synopsis Language</Typography>
            <FormControl component="fieldset" fullWidth={false}>
              <RadioGroup row={true}
                value={this.props.language}
              >
                <FormControlLabel
                  value="en"
                  control={<Radio onClick={(e) => (this.props.onLanguageChange(null, 'en'))} data-testid="en"  checkedIcon={<CheckCircle />}/>}
                  label="English"
                />
                <FormControlLabel
                  value="es"
                  control={<Radio onClick={(e) => (this.props.onLanguageChange(null, 'es'))} data-testid="es" checkedIcon={<CheckCircle />} />}
                  label="Spanish"
                />
              </RadioGroup>
            </FormControl>
        </Grid>
        );
    }
}

const clientConnected = clientShow(TitleLanguage, 'TitleLanguage');
export { clientConnected as TitleLanguage }
