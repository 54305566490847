import React, { Component } from "react";
import {
  Grid, FormControl, InputLabel
} from "@material-ui/core";
import { InlineDatePicker } from "material-ui-pickers";
import MomentUtils from "@date-io/moment";
import { ExpandableSection } from '../common';
import { MuiPickersUtilsProvider } from "material-ui-pickers";
import { withStyles } from '@material-ui/styles';
import SvgCalendar from "../../assets/icons/Calendar";
import { withDateValidation } from "./withDateValidation";
// MomentUtils.prototype.startOfMonth = MomentUtils.prototype.getStartOfMonth;


const styles = (theme) => ({
  root: {
    fontSize: 14,
    color: "#5e5edb",
    fontWeight: 700,
  }
})

class LicensingData extends Component {

  render() {
    const { classes } = this.props;
    let hasErrors = this.props.hasErrors();
    let errorsOn = this.props.errorsEnabled;
    let errorsFocusClass = errorsOn && this.props.focusErrorHandler(hasErrors);
    let defaultFocusClass = errorsFocusClass ? errorsFocusClass : 'normal';

    return (
      <Grid container component={ExpandableSection} header="licensing data" 
      defaultExpanded={true} focusErrorCssStyle={defaultFocusClass}>
            <Grid container direction="column"
            justify="space-between"
            className="data-pane">
                <Grid container  direction="column"
                justify="space-between">
                  <Grid item container
                  justify="space-between">
                    <MuiPickersUtilsProvider utils={MomentUtils}>
                      <Grid item>
                      <FormControl required>
                        <InputLabel className={classes.root}>Licensing Window Start</InputLabel>
                        <InlineDatePicker
                          keyboard
                          onlyCalendar
                          clearable
                          data-testid="licensing-start"
                          style={{ marginTop: 40 }}
                          margin="normal"
                          value={this.props.licensingStartDate}
                          className="date-picker"
                          placeholder="MM/DD/YYYY"
                          disablePast={true}
                          error={errorsOn && this.props.licensingError}
                          onChange={this.props.handleLicenseDateChange("licensingStartDate")}
                          mask={value =>
                            value ? [/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/] : []
                          }
                          keyboardIcon={<SvgCalendar/>}
                          format="MM/DD/YYYY"
                        />
                      </FormControl>
                      </Grid>
                      <Grid item>
                        <FormControl required>
                          <InputLabel className={classes.root}>Licensing Window End</InputLabel>
                          <InlineDatePicker
                          keyboard
                          onlyCalendar
                          clearable
                          style={{ marginTop: 40 }}
                          margin="normal"
                          value={this.props.licensingEndDate}
                          className="date-picker"
                          disablePast={true}
                          placeholder="MM/DD/YYYY"
                          error={errorsOn && this.props.licensingError}
                          onChange={this.props.handleLicenseDateChange("licensingEndDate")}
                          mask={value =>
                            value ? [/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/] : []
                          }
                          keyboardIcon={<SvgCalendar/>}
                          format="MM/DD/YYYY"
                        />
                        </FormControl>
                      </Grid>
                      <Grid item>
                        <FormControl required>
                          <InputLabel className={classes.root}>Est Window Start</InputLabel>
                          <InlineDatePicker
                          keyboard
                          onlyCalendar
                          clearable
                          style={{ marginTop: 40 }}
                          margin="normal"
                          value={this.props.estStartDate}
                          placeholder="MM/DD/YYYY"
                          disablePast={true}
                          className="date-picker"
                          error={errorsOn && this.props.estError}
                          format="MM/DD/YYYY"
                          onChange={this.props.handleLicenseDateChange("estStartDate")}
                          mask={value =>
                            value ? [/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/] : []
                          }
                          keyboardIcon={<SvgCalendar/>}
                        />
                        </FormControl>
                      </Grid>
                      <Grid item>
                        <FormControl required>
                          <InputLabel className={classes.root}>Est Window End</InputLabel>
                          <InlineDatePicker
                          keyboard
                          onlyCalendar
                          clearable
                          data-testid="est-end"
                          style={{ marginTop: 40 }}
                          margin="normal"
                          value={this.props.estEndDate}
                          className="date-picker"
                          placeholder="MM/DD/YYYY"
                          disablePast={true}
                          error={errorsOn && this.props.estError}
                          format="MM/DD/YYYY"
                          onChange={this.props.handleLicenseDateChange("estEndDate")}
                          mask={value =>
                            value ? [/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/] : []
                          }
                          keyboardIcon={<SvgCalendar/>}
                        />
                        </FormControl>
                      </Grid>
                    </MuiPickersUtilsProvider>
                  </Grid>
                </Grid>
          </Grid>
      </Grid>
    );
  }
}

let styledComponent = withStyles(styles)(LicensingData);
let validated = withDateValidation(styledComponent);
export { validated as LicensingData };
