export default [
  {
    screenAspectRatio: "16x9",
    horizontalResolution: "720",
    verticalResolution: "576",
    activePictureAspectRatio: "2.76",
    activePictureFormat: "Letterboxed",
    activeTopLeftX: "0",
    activeTopLeftY: "102",
    activeBottomRightX: "720",
    activeBottomRightY: "474",
  },
  {
    screenAspectRatio: "16x9",
    horizontalResolution: "720",
    verticalResolution: "576",
    activePictureAspectRatio: "2.55",
    activePictureFormat: "Letterboxed",
    activeTopLeftX: "0",
    activeTopLeftY: "87",
    activeBottomRightX: "720",
    activeBottomRightY: "489",
  },
  {
    screenAspectRatio: "16x9",
    horizontalResolution: "720",
    verticalResolution: "576",
    activePictureAspectRatio: "2.51",
    activePictureFormat: "Letterboxed",
    activeTopLeftX: "0",
    activeTopLeftY: "84",
    activeBottomRightX: "720",
    activeBottomRightY: "492",
  },
  {
    screenAspectRatio: "16x9",
    horizontalResolution: "720",
    verticalResolution: "576",
    activePictureAspectRatio: "2.40",
    activePictureFormat: "Letterboxed",
    activeTopLeftX: "0",
    activeTopLeftY: "75",
    activeBottomRightX: "720",
    activeBottomRightY: "501",
  },
  {
    screenAspectRatio: "16x9",
    horizontalResolution: "720",
    verticalResolution: "576",
    activePictureAspectRatio: "2.39",
    activePictureFormat: "Letterboxed",
    activeTopLeftX: "0",
    activeTopLeftY: "74",
    activeBottomRightX: "720",
    activeBottomRightY: "502",
  },
  {
    screenAspectRatio: "16x9",
    horizontalResolution: "720",
    verticalResolution: "576",
    activePictureAspectRatio: "2.35",
    activePictureFormat: "Letterboxed",
    activeTopLeftX: "0",
    activeTopLeftY: "70",
    activeBottomRightX: "720",
    activeBottomRightY: "506",
  },
  {
    screenAspectRatio: "16x9",
    horizontalResolution: "720",
    verticalResolution: "576",
    activePictureAspectRatio: "2.20",
    activePictureFormat: "Letterboxed",
    activeTopLeftX: "0",
    activeTopLeftY: "55",
    activeBottomRightX: "720",
    activeBottomRightY: "521",
  },
  {
    screenAspectRatio: "16x9",
    horizontalResolution: "720",
    verticalResolution: "576",
    activePictureAspectRatio: "2.15",
    activePictureFormat: "Letterboxed",
    activeTopLeftX: "0",
    activeTopLeftY: "50",
    activeBottomRightX: "720",
    activeBottomRightY: "526",
  },
  {
    screenAspectRatio: "16x9",
    horizontalResolution: "720",
    verticalResolution: "576",
    activePictureAspectRatio: "2.00",
    activePictureFormat: "Letterboxed",
    activeTopLeftX: "0",
    activeTopLeftY: "32",
    activeBottomRightX: "720",
    activeBottomRightY: "544",
  },
  {
    screenAspectRatio: "16x9",
    horizontalResolution: "720",
    verticalResolution: "576",
    activePictureAspectRatio: "1.85",
    activePictureFormat: "Letterboxed",
    activeTopLeftX: "0",
    activeTopLeftY: "11",
    activeBottomRightX: "720",
    activeBottomRightY: "565",
  },
  {
    screenAspectRatio: "16x9",
    horizontalResolution: "720",
    verticalResolution: "576",
    activePictureAspectRatio: "1.78",
    activePictureFormat: "Full Frame",
    activeTopLeftX: "0",
    activeTopLeftY: "0",
    activeBottomRightX: "720",
    activeBottomRightY: "576",
  },
  {
    screenAspectRatio: "4x3",
    horizontalResolution: "720",
    verticalResolution: "576",
    activePictureAspectRatio: "2.76",
    activePictureFormat: "Letterboxed",
    activeTopLeftX: "0",
    activeTopLeftY: "149",
    activeBottomRightX: "720",
    activeBottomRightY: "427",
  },
  {
    screenAspectRatio: "4x3",
    horizontalResolution: "720",
    verticalResolution: "576",
    activePictureAspectRatio: "2.55",
    activePictureFormat: "Letterboxed",
    activeTopLeftX: "0",
    activeTopLeftY: "137",
    activeBottomRightX: "720",
    activeBottomRightY: "439",
  },
  {
    screenAspectRatio: "4x3",
    horizontalResolution: "720",
    verticalResolution: "576",
    activePictureAspectRatio: "2.51",
    activePictureFormat: "Letterboxed",
    activeTopLeftX: "0",
    activeTopLeftY: "135",
    activeBottomRightX: "720",
    activeBottomRightY: "441",
  },
  {
    screenAspectRatio: "4x3",
    horizontalResolution: "720",
    verticalResolution: "576",
    activePictureAspectRatio: "2.40",
    activePictureFormat: "Letterboxed",
    activeTopLeftX: "0",
    activeTopLeftY: "128",
    activeBottomRightX: "720",
    activeBottomRightY: "448",
  },
  {
    screenAspectRatio: "4x3",
    horizontalResolution: "720",
    verticalResolution: "576",
    activePictureAspectRatio: "2.39",
    activePictureFormat: "Letterboxed",
    activeTopLeftX: "0",
    activeTopLeftY: "127",
    activeBottomRightX: "720",
    activeBottomRightY: "449",
  },
  {
    screenAspectRatio: "4x3",
    horizontalResolution: "720",
    verticalResolution: "576",
    activePictureAspectRatio: "2.35",
    activePictureFormat: "Letterboxed",
    activeTopLeftX: "0",
    activeTopLeftY: "125",
    activeBottomRightX: "720",
    activeBottomRightY: "451",
  },
  {
    screenAspectRatio: "4x3",
    horizontalResolution: "720",
    verticalResolution: "576",
    activePictureAspectRatio: "2.20",
    activePictureFormat: "Letterboxed",
    activeTopLeftX: "0",
    activeTopLeftY: "113",
    activeBottomRightX: "720",
    activeBottomRightY: "463",
  },
  {
    screenAspectRatio: "4x3",
    horizontalResolution: "720",
    verticalResolution: "576",
    activePictureAspectRatio: "2.15",
    activePictureFormat: "Letterboxed",
    activeTopLeftX: "0",
    activeTopLeftY: "109",
    activeBottomRightX: "720",
    activeBottomRightY: "467",
  },
  {
    screenAspectRatio: "4x3",
    horizontalResolution: "720",
    verticalResolution: "576",
    activePictureAspectRatio: "2.00",
    activePictureFormat: "Letterboxed",
    activeTopLeftX: "0",
    activeTopLeftY: "96",
    activeBottomRightX: "720",
    activeBottomRightY: "480",
  },
  {
    screenAspectRatio: "4x3",
    horizontalResolution: "720",
    verticalResolution: "576",
    activePictureAspectRatio: "1.85",
    activePictureFormat: "Letterboxed",
    activeTopLeftX: "0",
    activeTopLeftY: "80",
    activeBottomRightX: "720",
    activeBottomRightY: "496",
  },
  {
    screenAspectRatio: "4x3",
    horizontalResolution: "720",
    verticalResolution: "576",
    activePictureAspectRatio: "1.78",
    activePictureFormat: "Letterboxed",
    activeTopLeftX: "0",
    activeTopLeftY: "72",
    activeBottomRightX: "720",
    activeBottomRightY: "504",
  },
  {
    screenAspectRatio: "4x3",
    horizontalResolution: "720",
    verticalResolution: "576",
    activePictureAspectRatio: "1.66",
    activePictureFormat: "Letterboxed",
    activeTopLeftX: "0",
    activeTopLeftY: "57",
    activeBottomRightX: "720",
    activeBottomRightY: "519",
  },
  {
    screenAspectRatio: "4x3",
    horizontalResolution: "720",
    verticalResolution: "576",
    activePictureAspectRatio: "1.55",
    activePictureFormat: "Letterboxed",
    activeTopLeftX: "0",
    activeTopLeftY: "40",
    activeBottomRightX: "720",
    activeBottomRightY: "536",
  },
  {
    screenAspectRatio: "4x3",
    horizontalResolution: "720",
    verticalResolution: "576",
    activePictureAspectRatio: "1.33",
    activePictureFormat: "Full Frame",
    activeTopLeftX: "0",
    activeTopLeftY: "0",
    activeBottomRightX: "720",
    activeBottomRightY: "576",
  },
  {
    screenAspectRatio: "16x9",
    horizontalResolution: "720",
    verticalResolution: "486",
    activePictureAspectRatio: "2.76",
    activePictureFormat: "Letterboxed",
    activeTopLeftX: "0",
    activeTopLeftY: "85",
    activeBottomRightX: "720",
    activeBottomRightY: "395",
  },
  {
    screenAspectRatio: "16x9",
    horizontalResolution: "720",
    verticalResolution: "486",
    activePictureAspectRatio: "2.55",
    activePictureFormat: "Letterboxed",
    activeTopLeftX: "0",
    activeTopLeftY: "73",
    activeBottomRightX: "720",
    activeBottomRightY: "407",
  },
  {
    screenAspectRatio: "16x9",
    horizontalResolution: "720",
    verticalResolution: "486",
    activePictureAspectRatio: "2.51",
    activePictureFormat: "Letterboxed",
    activeTopLeftX: "0",
    activeTopLeftY: "70",
    activeBottomRightX: "720",
    activeBottomRightY: "410",
  },
  {
    screenAspectRatio: "16x9",
    horizontalResolution: "720",
    verticalResolution: "486",
    activePictureAspectRatio: "2.40",
    activePictureFormat: "Letterboxed",
    activeTopLeftX: "0",
    activeTopLeftY: "62",
    activeBottomRightX: "720",
    activeBottomRightY: "418",
  },
  {
    screenAspectRatio: "16x9",
    horizontalResolution: "720",
    verticalResolution: "486",
    activePictureAspectRatio: "2.39",
    activePictureFormat: "Letterboxed",
    activeTopLeftX: "0",
    activeTopLeftY: "62",
    activeBottomRightX: "720",
    activeBottomRightY: "418",
  },
  {
    screenAspectRatio: "16x9",
    horizontalResolution: "720",
    verticalResolution: "486",
    activePictureAspectRatio: "2.35",
    activePictureFormat: "Letterboxed",
    activeTopLeftX: "0",
    activeTopLeftY: "59",
    activeBottomRightX: "720",
    activeBottomRightY: "421",
  },
  {
    screenAspectRatio: "16x9",
    horizontalResolution: "720",
    verticalResolution: "486",
    activePictureAspectRatio: "2.20",
    activePictureFormat: "Letterboxed",
    activeTopLeftX: "0",
    activeTopLeftY: "46",
    activeBottomRightX: "720",
    activeBottomRightY: "434",
  },
  {
    screenAspectRatio: "16x9",
    horizontalResolution: "720",
    verticalResolution: "486",
    activePictureAspectRatio: "2.15",
    activePictureFormat: "Letterboxed",
    activeTopLeftX: "0",
    activeTopLeftY: "42",
    activeBottomRightX: "720",
    activeBottomRightY: "438",
  },
  {
    screenAspectRatio: "16x9",
    horizontalResolution: "720",
    verticalResolution: "486",
    activePictureAspectRatio: "2.00",
    activePictureFormat: "Letterboxed",
    activeTopLeftX: "0",
    activeTopLeftY: "27",
    activeBottomRightX: "720",
    activeBottomRightY: "453",
  },
  {
    screenAspectRatio: "16x9",
    horizontalResolution: "720",
    verticalResolution: "486",
    activePictureAspectRatio: "1.85",
    activePictureFormat: "Letterboxed",
    activeTopLeftX: "0",
    activeTopLeftY: "9",
    activeBottomRightX: "720",
    activeBottomRightY: "471",
  },
  {
    screenAspectRatio: "16x9",
    horizontalResolution: "720",
    verticalResolution: "486",
    activePictureAspectRatio: "1.78",
    activePictureFormat: "Full Frame",
    activeTopLeftX: "0",
    activeTopLeftY: "0",
    activeBottomRightX: "720",
    activeBottomRightY: "480",
  },
  {
    screenAspectRatio: "16x9",
    horizontalResolution: "720",
    verticalResolution: "480",
    activePictureAspectRatio: "2.76",
    activePictureFormat: "Letterboxed",
    activeTopLeftX: "0",
    activeTopLeftY: "85",
    activeBottomRightX: "720",
    activeBottomRightY: "395",
  },
  {
    screenAspectRatio: "16x9",
    horizontalResolution: "720",
    verticalResolution: "480",
    activePictureAspectRatio: "2.55",
    activePictureFormat: "Letterboxed",
    activeTopLeftX: "0",
    activeTopLeftY: "73",
    activeBottomRightX: "720",
    activeBottomRightY: "407",
  },
  {
    screenAspectRatio: "16x9",
    horizontalResolution: "720",
    verticalResolution: "480",
    activePictureAspectRatio: "2.51",
    activePictureFormat: "Letterboxed",
    activeTopLeftX: "0",
    activeTopLeftY: "70",
    activeBottomRightX: "720",
    activeBottomRightY: "410",
  },
  {
    screenAspectRatio: "16x9",
    horizontalResolution: "720",
    verticalResolution: "480",
    activePictureAspectRatio: "2.40",
    activePictureFormat: "Letterboxed",
    activeTopLeftX: "0",
    activeTopLeftY: "62",
    activeBottomRightX: "720",
    activeBottomRightY: "418",
  },
  {
    screenAspectRatio: "16x9",
    horizontalResolution: "720",
    verticalResolution: "480",
    activePictureAspectRatio: "2.39",
    activePictureFormat: "Letterboxed",
    activeTopLeftX: "0",
    activeTopLeftY: "62",
    activeBottomRightX: "720",
    activeBottomRightY: "418",
  },
  {
    screenAspectRatio: "16x9",
    horizontalResolution: "720",
    verticalResolution: "480",
    activePictureAspectRatio: "2.35",
    activePictureFormat: "Letterboxed",
    activeTopLeftX: "0",
    activeTopLeftY: "59",
    activeBottomRightX: "720",
    activeBottomRightY: "421",
  },
  {
    screenAspectRatio: "16x9",
    horizontalResolution: "720",
    verticalResolution: "480",
    activePictureAspectRatio: "2.20",
    activePictureFormat: "Letterboxed",
    activeTopLeftX: "0",
    activeTopLeftY: "46",
    activeBottomRightX: "720",
    activeBottomRightY: "434",
  },
  {
    screenAspectRatio: "16x9",
    horizontalResolution: "720",
    verticalResolution: "480",
    activePictureAspectRatio: "2.15",
    activePictureFormat: "Letterboxed",
    activeTopLeftX: "0",
    activeTopLeftY: "42",
    activeBottomRightX: "720",
    activeBottomRightY: "438",
  },
  {
    screenAspectRatio: "16x9",
    horizontalResolution: "720",
    verticalResolution: "480",
    activePictureAspectRatio: "2.00",
    activePictureFormat: "Letterboxed",
    activeTopLeftX: "0",
    activeTopLeftY: "27",
    activeBottomRightX: "720",
    activeBottomRightY: "453",
  },
  {
    screenAspectRatio: "16x9",
    horizontalResolution: "720",
    verticalResolution: "480",
    activePictureAspectRatio: "1.85",
    activePictureFormat: "Letterboxed",
    activeTopLeftX: "0",
    activeTopLeftY: "9",
    activeBottomRightX: "720",
    activeBottomRightY: "471",
  },
  {
    screenAspectRatio: "16x9",
    horizontalResolution: "720",
    verticalResolution: "480",
    activePictureAspectRatio: "1.78",
    activePictureFormat: "Full Frame",
    activeTopLeftX: "0",
    activeTopLeftY: "0",
    activeBottomRightX: "720",
    activeBottomRightY: "480",
  },
  {
    screenAspectRatio: "4x3",
    horizontalResolution: "720",
    verticalResolution: "486",
    activePictureAspectRatio: "2.76",
    activePictureFormat: "Letterboxed",
    activeTopLeftX: "0",
    activeTopLeftY: "124",
    activeBottomRightX: "720",
    activeBottomRightY: "356",
  },
  {
    screenAspectRatio: "4x3",
    horizontalResolution: "720",
    verticalResolution: "486",
    activePictureAspectRatio: "2.55",
    activePictureFormat: "Letterboxed",
    activeTopLeftX: "0",
    activeTopLeftY: "115",
    activeBottomRightX: "720",
    activeBottomRightY: "365",
  },
  {
    screenAspectRatio: "4x3",
    horizontalResolution: "720",
    verticalResolution: "486",
    activePictureAspectRatio: "2.51",
    activePictureFormat: "Letterboxed",
    activeTopLeftX: "0",
    activeTopLeftY: "113",
    activeBottomRightX: "720",
    activeBottomRightY: "367",
  },
  {
    screenAspectRatio: "4x3",
    horizontalResolution: "720",
    verticalResolution: "486",
    activePictureAspectRatio: "2.40",
    activePictureFormat: "Letterboxed",
    activeTopLeftX: "0",
    activeTopLeftY: "107",
    activeBottomRightX: "720",
    activeBottomRightY: "373",
  },
  {
    screenAspectRatio: "4x3",
    horizontalResolution: "720",
    verticalResolution: "486",
    activePictureAspectRatio: "2.39",
    activePictureFormat: "Letterboxed",
    activeTopLeftX: "0",
    activeTopLeftY: "106",
    activeBottomRightX: "720",
    activeBottomRightY: "374",
  },
  {
    screenAspectRatio: "4x3",
    horizontalResolution: "720",
    verticalResolution: "486",
    activePictureAspectRatio: "2.35",
    activePictureFormat: "Letterboxed",
    activeTopLeftX: "0",
    activeTopLeftY: "104",
    activeBottomRightX: "720",
    activeBottomRightY: "376",
  },
  {
    screenAspectRatio: "4x3",
    horizontalResolution: "720",
    verticalResolution: "486",
    activePictureAspectRatio: "2.20",
    activePictureFormat: "Letterboxed",
    activeTopLeftX: "0",
    activeTopLeftY: "95",
    activeBottomRightX: "720",
    activeBottomRightY: "385",
  },
  {
    screenAspectRatio: "4x3",
    horizontalResolution: "720",
    verticalResolution: "486",
    activePictureAspectRatio: "2.15",
    activePictureFormat: "Letterboxed",
    activeTopLeftX: "0",
    activeTopLeftY: "91",
    activeBottomRightX: "720",
    activeBottomRightY: "389",
  },
  {
    screenAspectRatio: "4x3",
    horizontalResolution: "720",
    verticalResolution: "486",
    activePictureAspectRatio: "2.00",
    activePictureFormat: "Letterboxed",
    activeTopLeftX: "0",
    activeTopLeftY: "80",
    activeBottomRightX: "720",
    activeBottomRightY: "400",
  },
  {
    screenAspectRatio: "4x3",
    horizontalResolution: "720",
    verticalResolution: "486",
    activePictureAspectRatio: "1.85",
    activePictureFormat: "Letterboxed",
    activeTopLeftX: "0",
    activeTopLeftY: "67",
    activeBottomRightX: "720",
    activeBottomRightY: "413",
  },
  {
    screenAspectRatio: "4x3",
    horizontalResolution: "720",
    verticalResolution: "486",
    activePictureAspectRatio: "1.78",
    activePictureFormat: "Letterboxed",
    activeTopLeftX: "0",
    activeTopLeftY: "60",
    activeBottomRightX: "720",
    activeBottomRightY: "420",
  },
  {
    screenAspectRatio: "4x3",
    horizontalResolution: "720",
    verticalResolution: "486",
    activePictureAspectRatio: "1.66",
    activePictureFormat: "Letterboxed",
    activeTopLeftX: "0",
    activeTopLeftY: "47",
    activeBottomRightX: "720",
    activeBottomRightY: "433",
  },
  {
    screenAspectRatio: "4x3",
    horizontalResolution: "720",
    verticalResolution: "486",
    activePictureAspectRatio: "1.55",
    activePictureFormat: "Letterboxed",
    activeTopLeftX: "0",
    activeTopLeftY: "34",
    activeBottomRightX: "720",
    activeBottomRightY: "446",
  },
  {
    screenAspectRatio: "4x3",
    horizontalResolution: "720",
    verticalResolution: "486",
    activePictureAspectRatio: "1.33",
    activePictureFormat: "Full Frame",
    activeTopLeftX: "0",
    activeTopLeftY: "0",
    activeBottomRightX: "720",
    activeBottomRightY: "480",
  },
  {
    screenAspectRatio: "4x3",
    horizontalResolution: "720",
    verticalResolution: "480",
    activePictureAspectRatio: "2.76",
    activePictureFormat: "Letterboxed",
    activeTopLeftX: "0",
    activeTopLeftY: "124",
    activeBottomRightX: "720",
    activeBottomRightY: "356",
  },
  {
    screenAspectRatio: "4x3",
    horizontalResolution: "720",
    verticalResolution: "480",
    activePictureAspectRatio: "2.55",
    activePictureFormat: "Letterboxed",
    activeTopLeftX: "0",
    activeTopLeftY: "115",
    activeBottomRightX: "720",
    activeBottomRightY: "365",
  },
  {
    screenAspectRatio: "4x3",
    horizontalResolution: "720",
    verticalResolution: "480",
    activePictureAspectRatio: "2.51",
    activePictureFormat: "Letterboxed",
    activeTopLeftX: "0",
    activeTopLeftY: "113",
    activeBottomRightX: "720",
    activeBottomRightY: "367",
  },
  {
    screenAspectRatio: "4x3",
    horizontalResolution: "720",
    verticalResolution: "480",
    activePictureAspectRatio: "2.40",
    activePictureFormat: "Letterboxed",
    activeTopLeftX: "0",
    activeTopLeftY: "107",
    activeBottomRightX: "720",
    activeBottomRightY: "373",
  },
  {
    screenAspectRatio: "4x3",
    horizontalResolution: "720",
    verticalResolution: "480",
    activePictureAspectRatio: "2.39",
    activePictureFormat: "Letterboxed",
    activeTopLeftX: "0",
    activeTopLeftY: "106",
    activeBottomRightX: "720",
    activeBottomRightY: "374",
  },
  {
    screenAspectRatio: "4x3",
    horizontalResolution: "720",
    verticalResolution: "480",
    activePictureAspectRatio: "2.35",
    activePictureFormat: "Letterboxed",
    activeTopLeftX: "0",
    activeTopLeftY: "104",
    activeBottomRightX: "720",
    activeBottomRightY: "376",
  },
  {
    screenAspectRatio: "4x3",
    horizontalResolution: "720",
    verticalResolution: "480",
    activePictureAspectRatio: "2.20",
    activePictureFormat: "Letterboxed",
    activeTopLeftX: "0",
    activeTopLeftY: "95",
    activeBottomRightX: "720",
    activeBottomRightY: "385",
  },
  {
    screenAspectRatio: "4x3",
    horizontalResolution: "720",
    verticalResolution: "480",
    activePictureAspectRatio: "2.15",
    activePictureFormat: "Letterboxed",
    activeTopLeftX: "0",
    activeTopLeftY: "91",
    activeBottomRightX: "720",
    activeBottomRightY: "389",
  },
  {
    screenAspectRatio: "4x3",
    horizontalResolution: "720",
    verticalResolution: "480",
    activePictureAspectRatio: "2.00",
    activePictureFormat: "Letterboxed",
    activeTopLeftX: "0",
    activeTopLeftY: "80",
    activeBottomRightX: "720",
    activeBottomRightY: "400",
  },
  {
    screenAspectRatio: "4x3",
    horizontalResolution: "720",
    verticalResolution: "480",
    activePictureAspectRatio: "1.85",
    activePictureFormat: "Letterboxed",
    activeTopLeftX: "0",
    activeTopLeftY: "67",
    activeBottomRightX: "720",
    activeBottomRightY: "413",
  },
  {
    screenAspectRatio: "4x3",
    horizontalResolution: "720",
    verticalResolution: "480",
    activePictureAspectRatio: "1.78",
    activePictureFormat: "Letterboxed",
    activeTopLeftX: "0",
    activeTopLeftY: "60",
    activeBottomRightX: "720",
    activeBottomRightY: "420",
  },
  {
    screenAspectRatio: "4x3",
    horizontalResolution: "720",
    verticalResolution: "480",
    activePictureAspectRatio: "1.66",
    activePictureFormat: "Letterboxed",
    activeTopLeftX: "0",
    activeTopLeftY: "47",
    activeBottomRightX: "720",
    activeBottomRightY: "433",
  },
  {
    screenAspectRatio: "4x3",
    horizontalResolution: "720",
    verticalResolution: "480",
    activePictureAspectRatio: "1.55",
    activePictureFormat: "Letterboxed",
    activeTopLeftX: "0",
    activeTopLeftY: "34",
    activeBottomRightX: "720",
    activeBottomRightY: "446",
  },
  {
    screenAspectRatio: "4x3",
    horizontalResolution: "720",
    verticalResolution: "480",
    activePictureAspectRatio: "1.33",
    activePictureFormat: "Full Frame",
    activeTopLeftX: "0",
    activeTopLeftY: "0",
    activeBottomRightX: "720",
    activeBottomRightY: "480",
  },
  {
    screenAspectRatio: "16x9",
    horizontalResolution: "1920",
    verticalResolution: "1080",
    activePictureAspectRatio: "2.76",
    activePictureFormat: "Letterboxed",
    activeTopLeftX: "0",
    activeTopLeftY: "192",
    activeBottomRightX: "1920",
    activeBottomRightY: "888",
  },
  {
    screenAspectRatio: "16x9",
    horizontalResolution: "1920",
    verticalResolution: "1080",
    activePictureAspectRatio: "2.55",
    activePictureFormat: "Letterboxed",
    activeTopLeftX: "0",
    activeTopLeftY: "164",
    activeBottomRightX: "1920",
    activeBottomRightY: "916",
  },
  {
    screenAspectRatio: "16x9",
    horizontalResolution: "1920",
    verticalResolution: "1080",
    activePictureAspectRatio: "2.51",
    activePictureFormat: "Letterboxed",
    activeTopLeftX: "0",
    activeTopLeftY: "158",
    activeBottomRightX: "1920",
    activeBottomRightY: "922",
  },
  {
    screenAspectRatio: "16x9",
    horizontalResolution: "1920",
    verticalResolution: "1080",
    activePictureAspectRatio: "2.40",
    activePictureFormat: "Letterboxed",
    activeTopLeftX: "0",
    activeTopLeftY: "140",
    activeBottomRightX: "1920",
    activeBottomRightY: "940",
  },
  {
    screenAspectRatio: "16x9",
    horizontalResolution: "1920",
    verticalResolution: "1080",
    activePictureAspectRatio: "2.39",
    activePictureFormat: "Letterboxed",
    activeTopLeftX: "0",
    activeTopLeftY: "138",
    activeBottomRightX: "1920",
    activeBottomRightY: "942",
  },
  {
    screenAspectRatio: "16x9",
    horizontalResolution: "1920",
    verticalResolution: "1080",
    activePictureAspectRatio: "2.35",
    activePictureFormat: "Letterboxed",
    activeTopLeftX: "0",
    activeTopLeftY: "131",
    activeBottomRightX: "1920",
    activeBottomRightY: "949",
  },
  {
    screenAspectRatio: "16x9",
    horizontalResolution: "1920",
    verticalResolution: "1080",
    activePictureAspectRatio: "2.20",
    activePictureFormat: "Letterboxed",
    activeTopLeftX: "0",
    activeTopLeftY: "104",
    activeBottomRightX: "1920",
    activeBottomRightY: "976",
  },
  {
    screenAspectRatio: "16x9",
    horizontalResolution: "1920",
    verticalResolution: "1080",
    activePictureAspectRatio: "2.15",
    activePictureFormat: "Letterboxed",
    activeTopLeftX: "0",
    activeTopLeftY: "93",
    activeBottomRightX: "1920",
    activeBottomRightY: "987",
  },
  {
    screenAspectRatio: "16x9",
    horizontalResolution: "1920",
    verticalResolution: "1080",
    activePictureAspectRatio: "2.00",
    activePictureFormat: "Letterboxed",
    activeTopLeftX: "0",
    activeTopLeftY: "60",
    activeBottomRightX: "1920",
    activeBottomRightY: "1020",
  },
  {
    screenAspectRatio: "16x9",
    horizontalResolution: "1920",
    verticalResolution: "1080",
    activePictureAspectRatio: "1.85",
    activePictureFormat: "Letterboxed",
    activeTopLeftX: "0",
    activeTopLeftY: "21",
    activeBottomRightX: "1920",
    activeBottomRightY: "1059",
  },
  {
    screenAspectRatio: "16x9",
    horizontalResolution: "1920",
    verticalResolution: "1080",
    activePictureAspectRatio: "1.78",
    activePictureFormat: "Full Frame",
    activeTopLeftX: "0",
    activeTopLeftY: "0",
    activeBottomRightX: "1920",
    activeBottomRightY: "1080",
  },
  {
    screenAspectRatio: "16x9",
    horizontalResolution: "1920",
    verticalResolution: "1080",
    activePictureAspectRatio: "1.66",
    activePictureFormat: "Pillarboxed",
    activeTopLeftX: "64",
    activeTopLeftY: "0",
    activeBottomRightX: "1856",
    activeBottomRightY: "1080",
  },
  {
    screenAspectRatio: "16x9",
    horizontalResolution: "1920",
    verticalResolution: "1080",
    activePictureAspectRatio: "1.55",
    activePictureFormat: "Pillarboxed",
    activeTopLeftX: "123",
    activeTopLeftY: "0",
    activeBottomRightX: "1797",
    activeBottomRightY: "1080",
  },
  {
    screenAspectRatio: "16x9",
    horizontalResolution: "1920",
    verticalResolution: "1080",
    activePictureAspectRatio: "1.37",
    activePictureFormat: "Pillarboxed",
    activeTopLeftX: "220",
    activeTopLeftY: "1",
    activeBottomRightX: "1700",
    activeBottomRightY: "1080",
  },
  {
    screenAspectRatio: "16x9",
    horizontalResolution: "1920",
    verticalResolution: "1080",
    activePictureAspectRatio: "1.33",
    activePictureFormat: "Pillarboxed",
    activeTopLeftX: "240",
    activeTopLeftY: "0",
    activeBottomRightX: "1680",
    activeBottomRightY: "1080",
  },
  {
    screenAspectRatio: "16x9",
    horizontalResolution: "3840",
    verticalResolution: "2160",
    activePictureAspectRatio: "2.76",
    activePictureFormat: "Letterboxed",
    activeTopLeftX: "0",
    activeTopLeftY: "384",
    activeBottomRightX: "3840",
    activeBottomRightY: "1776",
  },
  {
    screenAspectRatio: "16x9",
    horizontalResolution: "3840",
    verticalResolution: "2160",
    activePictureAspectRatio: "2.55",
    activePictureFormat: "Letterboxed",
    activeTopLeftX: "0",
    activeTopLeftY: "327",
    activeBottomRightX: "3840",
    activeBottomRightY: "1833",
  },
  {
    screenAspectRatio: "16x9",
    horizontalResolution: "3840",
    verticalResolution: "2160",
    activePictureAspectRatio: "2.51",
    activePictureFormat: "Letterboxed",
    activeTopLeftX: "0",
    activeTopLeftY: "315",
    activeBottomRightX: "3840",
    activeBottomRightY: "1845",
  },
  {
    screenAspectRatio: "16x9",
    horizontalResolution: "3840",
    verticalResolution: "2160",
    activePictureAspectRatio: "2.40",
    activePictureFormat: "Letterboxed",
    activeTopLeftX: "0",
    activeTopLeftY: "280",
    activeBottomRightX: "3840",
    activeBottomRightY: "1880",
  },
  {
    screenAspectRatio: "16x9",
    horizontalResolution: "3840",
    verticalResolution: "2160",
    activePictureAspectRatio: "2.39",
    activePictureFormat: "Letterboxed",
    activeTopLeftX: "0",
    activeTopLeftY: "277",
    activeBottomRightX: "3840",
    activeBottomRightY: "1883",
  },
  {
    screenAspectRatio: "16x9",
    horizontalResolution: "3840",
    verticalResolution: "2160",
    activePictureAspectRatio: "2.35",
    activePictureFormat: "Letterboxed",
    activeTopLeftX: "0",
    activeTopLeftY: "263",
    activeBottomRightX: "3840",
    activeBottomRightY: "1897",
  },
  {
    screenAspectRatio: "16x9",
    horizontalResolution: "3840",
    verticalResolution: "2160",
    activePictureAspectRatio: "2.20",
    activePictureFormat: "Letterboxed",
    activeTopLeftX: "0",
    activeTopLeftY: "207",
    activeBottomRightX: "3840",
    activeBottomRightY: "1953",
  },
  {
    screenAspectRatio: "16x9",
    horizontalResolution: "3840",
    verticalResolution: "2160",
    activePictureAspectRatio: "2.15",
    activePictureFormat: "Letterboxed",
    activeTopLeftX: "0",
    activeTopLeftY: "187",
    activeBottomRightX: "3840",
    activeBottomRightY: "1973",
  },
  {
    screenAspectRatio: "16x9",
    horizontalResolution: "3840",
    verticalResolution: "2160",
    activePictureAspectRatio: "2.00",
    activePictureFormat: "Letterboxed",
    activeTopLeftX: "0",
    activeTopLeftY: "120",
    activeBottomRightX: "3840",
    activeBottomRightY: "2040",
  },
  {
    screenAspectRatio: "16x9",
    horizontalResolution: "3840",
    verticalResolution: "2160",
    activePictureAspectRatio: "1.85",
    activePictureFormat: "Letterboxed",
    activeTopLeftX: "0",
    activeTopLeftY: "42",
    activeBottomRightX: "3840",
    activeBottomRightY: "2118",
  },
  {
    screenAspectRatio: "16x9",
    horizontalResolution: "3840",
    verticalResolution: "2160",
    activePictureAspectRatio: "1.78",
    activePictureFormat: "Full Frame",
    activeTopLeftX: "0",
    activeTopLeftY: "0",
    activeBottomRightX: "3840",
    activeBottomRightY: "2160",
  },
  {
    screenAspectRatio: "16x9",
    horizontalResolution: "3840",
    verticalResolution: "2160",
    activePictureAspectRatio: "1.66",
    activePictureFormat: "Pillarboxed",
    activeTopLeftX: "127",
    activeTopLeftY: "0",
    activeBottomRightX: "3713",
    activeBottomRightY: "2160",
  },
  {
    screenAspectRatio: "16x9",
    horizontalResolution: "3840",
    verticalResolution: "2160",
    activePictureAspectRatio: "1.55",
    activePictureFormat: "Pillarboxed",
    activeTopLeftX: "246",
    activeTopLeftY: "0",
    activeBottomRightX: "3594",
    activeBottomRightY: "2160",
  },
  {
    screenAspectRatio: "16x9",
    horizontalResolution: "3840",
    verticalResolution: "2160",
    activePictureAspectRatio: "1.37",
    activePictureFormat: "Pillarboxed",
    activeTopLeftX: "440",
    activeTopLeftY: "1",
    activeBottomRightX: "3400",
    activeBottomRightY: "2160",
  },
  {
    screenAspectRatio: "16x9",
    horizontalResolution: "3840",
    verticalResolution: "2160",
    activePictureAspectRatio: "1.33",
    activePictureFormat: "Pillarboxed",
    activeTopLeftX: "480",
    activeTopLeftY: "0",
    activeBottomRightX: "3360",
    activeBottomRightY: "2160",
  },
  {
    screenAspectRatio: "16x9",
    horizontalResolution: "4096",
    verticalResolution: "2304",
    activePictureAspectRatio: "2.55",
    activePictureFormat: "Letterboxed",
    activeTopLeftX: "0",
    activeTopLeftY: "349",
    activeBottomRightX: "4096",
    activeBottomRightY: "1955",
  },
  {
    screenAspectRatio: "16x9",
    horizontalResolution: "4096",
    verticalResolution: "2304",
    activePictureAspectRatio: "2.51",
    activePictureFormat: "Letterboxed",
    activeTopLeftX: "0",
    activeTopLeftY: "336",
    activeBottomRightX: "4096",
    activeBottomRightY: "1968",
  },
  {
    screenAspectRatio: "16x9",
    horizontalResolution: "4096",
    verticalResolution: "2304",
    activePictureAspectRatio: "2.40",
    activePictureFormat: "Letterboxed",
    activeTopLeftX: "0",
    activeTopLeftY: "299",
    activeBottomRightX: "4096",
    activeBottomRightY: "2005",
  },
  {
    screenAspectRatio: "16x9",
    horizontalResolution: "4096",
    verticalResolution: "2304",
    activePictureAspectRatio: "2.35",
    activePictureFormat: "Letterboxed",
    activeTopLeftX: "0",
    activeTopLeftY: "281",
    activeBottomRightX: "4096",
    activeBottomRightY: "2023",
  },
  {
    screenAspectRatio: "16x9",
    horizontalResolution: "4096",
    verticalResolution: "2304",
    activePictureAspectRatio: "2.20",
    activePictureFormat: "Letterboxed",
    activeTopLeftX: "0",
    activeTopLeftY: "221",
    activeBottomRightX: "4096",
    activeBottomRightY: "2083",
  },
  {
    screenAspectRatio: "16x9",
    horizontalResolution: "4096",
    verticalResolution: "2304",
    activePictureAspectRatio: "2.15",
    activePictureFormat: "Letterboxed",
    activeTopLeftX: "0",
    activeTopLeftY: "199",
    activeBottomRightX: "4096",
    activeBottomRightY: "2105",
  },
  {
    screenAspectRatio: "16x9",
    horizontalResolution: "4096",
    verticalResolution: "2304",
    activePictureAspectRatio: "2.00",
    activePictureFormat: "Letterboxed",
    activeTopLeftX: "0",
    activeTopLeftY: "128",
    activeBottomRightX: "4096",
    activeBottomRightY: "2176",
  },
  {
    screenAspectRatio: "16x9",
    horizontalResolution: "4096",
    verticalResolution: "2304",
    activePictureAspectRatio: "1.85",
    activePictureFormat: "Letterboxed",
    activeTopLeftX: "0",
    activeTopLeftY: "45",
    activeBottomRightX: "4096",
    activeBottomRightY: "2259",
  },
  {
    screenAspectRatio: "16x9",
    horizontalResolution: "4096",
    verticalResolution: "2304",
    activePictureAspectRatio: "1.78",
    activePictureFormat: "Full Frame",
    activeTopLeftX: "0",
    activeTopLeftY: "0",
    activeBottomRightX: "4096",
    activeBottomRightY: "2304",
  },
  {
    screenAspectRatio: "16x9",
    horizontalResolution: "4096",
    verticalResolution: "2304",
    activePictureAspectRatio: "1.66",
    activePictureFormat: "Pillarboxed",
    activeTopLeftX: "136",
    activeTopLeftY: "0",
    activeBottomRightX: "3960",
    activeBottomRightY: "2304",
  },
  {
    screenAspectRatio: "16x9",
    horizontalResolution: "4096",
    verticalResolution: "2304",
    activePictureAspectRatio: "1.55",
    activePictureFormat: "Pillarboxed",
    activeTopLeftX: "262",
    activeTopLeftY: "0",
    activeBottomRightX: "3834",
    activeBottomRightY: "2304",
  },
  {
    screenAspectRatio: "16x9",
    horizontalResolution: "4096",
    verticalResolution: "2304",
    activePictureAspectRatio: "1.33",
    activePictureFormat: "Pillarboxed",
    activeTopLeftX: "516",
    activeTopLeftY: "0",
    activeBottomRightX: "3580",
    activeBottomRightY: "2304",
  },
];
