import React, { Component } from 'react';
import Downshift from 'downshift';
import _ from 'lodash';
import { withStyles } from "@material-ui/core/styles";
import { TextField, MenuItem, Paper, Typography } from "@material-ui/core";
import ArrowDropDown from "@material-ui/icons/ArrowDropDown";

const styles = theme => ({
    root: {
      flexGrow: 1,
      height: 240
    },
    container: {
      flexGrow: 1,
      position: "relative"
    },
    paper: {
      position: "absolute",
      zIndex: 1,
      marginTop: theme.spacing.unit,
      left: 0,
      right: 0,
      height: 240,
      width: 'min-content',
      overflowY: 'auto',
    },
    inputRoot: {
      flexWrap: "wrap"
    },
    inputInput: {
      width: "auto",
      flexGrow: 1
    }
  });

function renderInput(inputProps) {
    const { InputProps, classes, openMenu, closeMenu, error, style, isOpen, ref, ...other } = inputProps;
    return (
      <TextField
        onFocus={openMenu}
        onKeyDown={closeMenu}
        error={error}
        InputProps={{
          inputRef: ref,
          classes: {
            root: classes.inputRoot,
            input: classes.inputInput
          },
          ...InputProps
        }}
        style={style}
        {...other}
      />
    );
}

function renderSuggestion({
    suggestion,
    index,
    itemProps,
    highlightedIndex,
    selectedItem
  }) {
    const isHighlighted = highlightedIndex === index;
    const isSelected = (selectedItem || "").indexOf(suggestion.label) > -1;

    return (
      <MenuItem
        {...itemProps}
        key={suggestion.filename}
        value={suggestion.filename}
        selected={isHighlighted}
        component="div"
        style={{
          fontWeight: isSelected ? 500 : 400,
        }}
      >
        <Typography noWrap={true}>{suggestion.filename}</Typography>
      </MenuItem>
    );
}

class FileEditableDropdown extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isOpen: false,
            value: props.value ? props.value : "",
        }
    }

    getEventObject = (value) => {
        let event = {
            target: {
                value
            }
        }
        return event;
    }

    postChange = (fieldname, val) => {
      this.props.onChange(
        fieldname,
        this.getEventObject(val),
        this.props.index
      );
    }

    handleFilenameChange = (changes) => {
        let fieldName = this.props.field;

        let value = null;

        if(changes.hasOwnProperty('selectedItem')) {
          value = changes.selectedItem;

        } else if (changes.hasOwnProperty('inputValue')) {
          value = changes.inputValue;
        }

        if (!(changes.hasOwnProperty('inputValue') || changes.hasOwnProperty('selectedItem'))) {
          return;
        }

        this.setState({
          value,
        }, () => {
          this.postChange(fieldName, value);
        });
    }

    render() {
        const { classes } = this.props;
        const { value } = this.state;
        return(
            <Downshift id="downshift-simple"
                onStateChange={this.handleFilenameChange}
                selectedItem={value}
            >
            {({
              getInputProps,
              getItemProps,
              getMenuProps,
              highlightedIndex,
              isOpen,
              selectedItem,
              openMenu,
              closeMenu,
            }) => (
              <div className={classes.container}>
                {renderInput({
                  fullWidth: false,
                  classes,
                  isOpen,
                  openMenu,
                  closeMenu,
                  disabled: this.props.disabled || false,
                  error: this.props.error,
                  style: this.props.style,
                  InputProps: getInputProps({
                    endAdornment: <ArrowDropDown style={{
                      transform: (this.props.files.length > 1 && isOpen) ?
                                'rotate(180deg)' : undefined,
                    }}/>,
                    spellCheck: "false",
                  })
                })}
                <div {...getMenuProps()}>
                  {(isOpen && this.props.files.length > 0) ? (
                    <Paper className={classes.paper} square>
                      {this.props.files.map((suggestion, index) =>
                        renderSuggestion({
                          suggestion,
                          index,
                          itemProps: getItemProps({ item: suggestion.filename }),
                          highlightedIndex,
                          selectedItem
                        })
                      )}
                    </Paper>
                  ) : null}
                </div>
              </div>
            )}
          </Downshift>
        );
    }
}

const styledComponent = withStyles(styles)(FileEditableDropdown);
export { styledComponent as FileEditableDropdown }