import React, { Component } from 'react';
import _ from 'lodash';
import { Grid, Card, Typography, Fab } from '@material-ui/core';
import { connect } from 'react-redux';
import { history } from '../_helpers/history';
import fileDownload from 'js-file-download';
import axios from 'axios';
import { ResetStoreActions } from '../_actions/resetstore.actions';
import './download.styles.scss';
import Loader from 'react-loaders';
import { DefaultDownload } from './DefaultDownload';
import { RokuCablelabsDownload } from '../components/RokuCablelabs';

const mimeTypeFor = (filename) => {
  if ((/.zip$/).test(filename)) {
    return 'application/zip';
  }

  if ((/.xlsx$/).test(filename)) {
    return 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
  }

  return null;
}
class DownloadPage extends Component {
  constructor(props) {
    super(props);
    this.state = {};

    let files = props.manifest.fileDownloads || [];
    if (files.length && files[0].downloadName === 'delivery.zip') {
      this.state.zipfile = files.shift();
    }

    let fileSpinner = _.reduce(files, (accumulator, file) => {
      accumulator[file.downloadName] = { loadingSpinner: false };
      return accumulator;
    }, {});

    this.state = Object.assign({}, this.state, { files, ...fileSpinner });
  }

  handlePrevious = () => {
    let location = {
      pathname: "/package-data"
    }
    history.push(location);
  }

  toggleSpinner = (key, loadingSpinner = false) => {
    this.setState({
      [key]: {
        loadingSpinner
      }
    });
  }

  downloadAll = () => {
    let ary = [].concat(this.state.files);
    this.downloadSync(ary);
  }

  downloadSync = (files) => {
    if (_.isEmpty(files)) {
      return;
    }

    let file = files.shift();
    this.downloadFile(file).then(() => {
      return this.downloadSync(files);
    });
  }

  downloadFile = (file) => {
    this.toggleSpinner(file.downloadName, true);
    return axios({
      method: 'get',
      url: file.location,
      responseType: 'arraybuffer'
    }).then((resp) => {
      if (resp.data) {
        let mimeType = mimeTypeFor(file.downloadName);
        fileDownload(resp.data, file.downloadName, mimeType);
        this.toggleSpinner(file.downloadName, false);
      }
    }, (ex) => {
      throw new Error(`Download failed: ${ex.message}`);
    });
  }

  handleDone = () => {
    let location = {
      pathname: "/"
    }
    history.push(location);
    this.props.resetStore();
  }

  checkIfTitleIsSeason = (title) => {
    if (title.productLevel) {
      return title.productLevel.toLowerCase() === 'season';
    }
  }

  isAlphaNameLong = (name) => {
    if (!name) {
      return false;
    }
    return name.length > 20;
  }

  renderMultiTitle() {
    return this.props.titles.map((title, index) => {
      return (
        <React.Fragment key={`title-fragment-${index}`}>
          { this.renderTitle(title)}
        </React.Fragment>
      );
    });
  }

  getAlphaDataFor(title) {
    let {alphaId, alphaOwner, alphaName } = title;

    if (title.alpha) {
      alphaId = title.alpha && title.alpha.alphaId;
      alphaOwner = title.alpha && title.alpha.alphaOwner;
      alphaName = title.alpha && title.alpha.alphaName;
    }
    let alphaClass = this.isAlphaNameLong(alphaName) ? "alpha-extended" : "alpha";
    return { alphaId, alphaOwner, alphaClass, alphaName };
  }

  renderTitle(title) {
    let { alphaId, alphaOwner, alphaClass, alphaName } = this.getAlphaDataFor(title);

    return (
      <>
        { title ? (
          <React.Fragment>     
            <Grid item>
              <Typography variant="h6" component="h6">{title.titleFullName || title.primaryTitleName}</Typography>
            </Grid>
            <Grid item container direction="row" justify="flex-start">
              <Grid item style={{
                marginRight: 16,
              }}>
                <Typography>{alphaName}</Typography>
              </Grid>
              <Grid item className={alphaClass}>
                <Grid item style={{
                  marginRight: 16,
                }}>
                  <Typography>{alphaId}</Typography>
                </Grid>
                <Grid item>
                  <Typography>{alphaOwner}</Typography>
                </Grid>
              </Grid>
            </Grid>
          </React.Fragment>
        ) : (
          <div></div>
        )}  
      </>
    );
  }

  renderTitleSection(title) {
    let downloads = this.state.files;

    let singleTitle = (this.props.titles.length === 1);

    return (
      <Grid item className="download-content">
        { singleTitle ? this.renderTitle(title) : null }
        { !singleTitle ? this.renderMultiTitle() : null }

        {
          _.map(downloads, (download, index) => {
            let loadingSpinner = this.state[download.downloadName].loadingSpinner;
            let marginTop = index > 0 ? 20 : 40;

            return (
              <Grid key={'download-' + index} item style={{ marginTop }}>
                <Fab onClick={() => { this.downloadFile(download) }} variant="extended" color="primary"
                  className={loadingSpinner ? 'download-manifest-button loading' : 'download-manifest-button'}>
                  {loadingSpinner ? <Loader type="line-spin-fade-loader" /> : download.downloadLabel}
                </Fab>
              </Grid>
            )
          })
        }
      </Grid>
    )
  }

  render() {
    let title = this.props.title || {};
    let isSeason = this.checkIfTitleIsSeason(title);

    return (
      <React.Fragment>
        <Grid item container
          justify="center" alignItems="center" className="content download">
          <Grid item component={Card} className="download-pane">
            <Grid item container style={{
              height: isSeason ? 480 : 'none',
              flexDirection: 'column',
              justifyContent: 'flex-start',
            }}>
              <Grid item container justify={isSeason ? "center" : "flex-start"}>
                <Grid item>
                  <Typography variant="h3" component="h3" className="download-header">
                    FILES READY TO DOWNLOAD!
                  </Typography>
                </Grid>
              </Grid>

              <RokuCablelabsDownload
                isSeason={isSeason}
                client={this.props.client}
                downloads={this.state.files}
                zipfile={this.state.zipfile}
                downloadFile={this.downloadFile}
                downloadAll={this.downloadAll}
                getLabelText={this.getLabelText}
                isAlphaNameLong={this.isAlphaNameLong}>
                  { this.renderTitleSection(title) }
              </RokuCablelabsDownload>

              <DefaultDownload client={this.props.client}>
                { this.renderTitleSection(title) }
              </DefaultDownload>
            </Grid>
          </Grid>
          <Grid item>
            <div className="download-image"></div>
          </Grid>
        </Grid>
        <Grid item container
          justify="flex-end"
          component="footer">
          <Grid item>
            <Fab variant="extended" className="previous-button" onClick={this.handlePrevious} color="secondary">Previous</Fab>
          </Grid>
          <Grid item>
            <Fab variant="extended" className="done-button" onClick={this.handleDone} color="primary">Done</Fab>
          </Grid>
        </Grid>
      </React.Fragment>
    )
  }
}

const mapStateToProps = (state) => {
  let { manifest, clients, titles } = state;
  let title = titles.titles[0];

  return {
    titles: titles.titles,
    title,
    manifest,
    client: clients.client,
  };
}

const mapDispatchToProps = (dispatch) => {
  let resetStore = ResetStoreActions.resetStore;
  return {
    resetStore: () => {
      return dispatch(resetStore());
    }
  }
};

let connected = connect(mapStateToProps, mapDispatchToProps)(DownloadPage)
export { connected as DownloadPage };
