import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
    FormControlLabel, Grid, TextField,
    RadioGroup, Radio, FormControl, FormLabel, InputLabel
  } from "@material-ui/core";
import { withStyles } from '@material-ui/styles';
import NotifierAction from '../../_actions/notifier.actions';
import { clientShow } from '../Clientshow/clientshow';
import _ from 'lodash';

const errorMessage = "Please make sure all the highlighted fields have been filled.";

const styles = (theme) => ({
  root: {
    fontSize: 14,
    color: '#333333',
  }
})

function makeFieldErrorObj(boolValue) {
  return {
    providerError: boolValue,
    providerIdError: boolValue,
    versionMajorError: boolValue,
    versionMinorError: boolValue,
  }
}

const initialState = {
  provider: 'Sony',
  providerId: 'sony.com',
  versionMajor: 1,
  versionMinor: 0,
  packageType: 'mod',
  ...makeFieldErrorObj(false),
}

class ProviderData extends Component {
  constructor(props) {
    super(props);
    let dbProviderData = props.provider || {};
    this.state = Object.assign({...initialState}, dbProviderData);
  }

  componentDidMount() {
      this.props.onPackageDataChange('provider', this.state);
  }

  errorFieldForName = (fieldName) => {
    return `${fieldName}Error`;
  }

  handleChange = name => event => {
      let fieldError = (event.target.value === '');
      let erroredField = this.errorFieldForName(name);

      this.setState({
        [name]: event.target.value,
        [erroredField]: fieldError,
      }, () => {
        this.props.showNotification(fieldError, errorMessage);
        this.props.onPackageDataChange('provider', this.state);
      });
  };

  render() {
    const { classes } = this.props;
    const fields = this.props.fields;
    return (
      <Grid container
      justify="space-between">
        <Grid item>
          <FormControl>
            <InputLabel className={classes.root}>Provider</InputLabel>
            <TextField
            style={{ marginTop: 40 }}
            error={this.state.providerError}
            value={this.state.provider}
            onChange={this.handleChange("provider")}
          />
        </FormControl>
      </Grid>

      <Grid item>
        <FormControl>
          <InputLabel className={classes.root}>Provider ID</InputLabel>
          <TextField
          style={{ marginTop: 40, marginBottom: 20 }}
          error={this.state.providerIdError}
          value={this.state.providerId}
          onChange={this.handleChange("providerId")}
        />
        </FormControl>
      </Grid>
      {
          this.props.shouldFieldDisplay('packageType', fields) && (<Grid item>
            <FormControl component="fieldset" style={{ marginTop: 22 }}>
              <FormLabel
                style={{ fontSize: 14, marginLeft: 10, color: "#333333" }}
                component="label"
              >
                Package Type
              </FormLabel>
              <RadioGroup style={{ display: "inline", marginLeft: 10 }}
                value={this.state.packageType}
                onChange={this.handleChange("packageType")}
              >
                <FormControlLabel
                  value="mod"
                  control={<Radio />}
                  label="MOD"
                />
                <FormControlLabel
                  value="svod"
                  control={<Radio />}
                  label="SVOD"
                />
              </RadioGroup>
            </FormControl>
          </Grid>)

      }
      <Grid item>
        <FormControl>
          <InputLabel className={classes.root}>Version Major</InputLabel>
          <TextField
          value={this.state.versionMajor}
          error={this.state.versionMajorError}
          type="number"
          style={{ marginTop: 40 }}
          onChange={this.handleChange("versionMajor")}
        />
        </FormControl>
      </Grid>

      <Grid item>
        <FormControl>
          <InputLabel className={classes.root}>Version Minor</InputLabel>
          <TextField
          type="number"
          error={this.state.versionMinorError}
          style={{ marginTop: 40 }}
          value={this.state.versionMinor}
          onChange={this.handleChange("versionMinor")}
        />
        </FormControl>
      </Grid>
    </Grid>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    spec: {}
  }
}

const mapDispatchToProps = (dispatch) => {
  let showNotification = NotifierAction.showNotification;
  return {
    showNotification: (errorStatus, msg) => {
      dispatch(showNotification(errorStatus, msg))
    }
  }
}

let clientConnected = clientShow(ProviderData, 'ProviderData');
let styled = withStyles(styles)(clientConnected)
let connected = connect(mapStateToProps, mapDispatchToProps)(styled);
export {connected as ProviderData};