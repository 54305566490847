import React, { Component } from "react";
import {
  Grid } from "@material-ui/core";
import { ExpandableSection } from '../common';
import { withStyles } from '@material-ui/styles';
import { DatePicker } from '../common';
// MomentUtils.prototype.startOfMonth = MomentUtils.prototype.getStartOfMonth;

const styles = (theme) => ({
  root: {
    fontSize: 14,
    color: "#5e5edb",
    fontWeight: 700,
  },
  normal: {
    fontSize: 14,
    color: "#333333",
    fontWeight: 700,
  },
  formControl: {
      position: 'relative',
      marginRight: 36,
  }
})

const initialState = {
  startDate: null,
  endDate: null,
};
class RokuLicensingData extends Component {

    constructor(props) {
      super(props);
      let data = props.data || {};
      this.state = Object.assign({}, initialState, data)
    }

    componentDidMount() {
      this.props.updateErrors('license', !this.hasErrors());
    }

    hasErrors = () => {
      return this.props.validationRequired && (!this.state.startDate || !this.state.endDate);
    }

    handleDateChange = (data) => {
      let {startDate , endDate } = data;
      let payload = { startDate, endDate };
      this.setState(() => {
          return payload;
      }, () => {
        this.props.updateErrors('license', !this.hasErrors());
        this.props.onPackageDataChange('license', payload);
      });
    }

    render() {
        let header = this.props.header ?
                    this.props.header.toUpperCase() : 'LICENSING DATA';
        let hasErrors = this.hasErrors();
        let errorsOn = this.props.errorsEnabled && this.props.validationRequired;
        let errorsFocusClass = errorsOn && this.props.focusErrorHandler(hasErrors);
        let defaultFocusClass = errorsFocusClass ? errorsFocusClass : 'normal';
        let validationRequired = this.props.validationRequired === undefined ?  true : this.props.validationRequired;
        return (
          <Grid container component={ExpandableSection} header={header}
            defaultExpanded={true} focusErrorCssStyle={defaultFocusClass}>
              <Grid container direction="row"
              justify="flex-start"
              className="data-pane">
                    <Grid item>
                      <Grid container justify="flex-start">
                        <DatePicker
                          label="Licensing Window"
                          errorsEnabled={errorsOn}
                          validationRequired={validationRequired}
                          onChange={this.handleDateChange}
                          showNotification={this.props.showNotification}
                          startDate={this.state.startDate}
                          endDate={this.state.endDate}
                        />
                      </Grid>
                    </Grid>
                    {
                      this.props.children
                    }
              </Grid>
          </Grid>
        );
    }
}


let styled = withStyles(styles)(RokuLicensingData);
export { styled as RokuLicensingData }
