import { clientspecConstants } from '../_constants/clientspec.constants';

let initialState = {
  client: {},
  spec: {
    files: [],
  },
  loadingSpinner: false,
};

export const clientspec = (state = initialState, action) => {
  switch (action.type) {
    case clientspecConstants.SPEC_FETCHED:
      let hasFiles = action.spec.files && action.spec.files.length;
      let clientdata = action.spec.client;

      if (hasFiles) {
        return Object.assign({}, state,
          { client: clientdata, spec: action.spec, loadingSpinner: false });
      } else {
        let { files } = state.spec;
        let spec = Object.assign({}, action.spec, { files });

        return Object.assign({}, state, {
          client: clientdata,
          spec,
          loadingSpinner: false,
        });
      }
    case clientspecConstants.FETCHING_SPEC:
      let {title, client, files} = action.payload;
      let newFiles = files.length > 0 ? files : state.files;

      return Object.assign({}, state, {
        loadingSpinner: true,
        title,
        client,
        files: newFiles,
      });
    case clientspecConstants.SUBMITTING_PACKAGE:
      let packageData = action.specPackage;
      return Object.assign({}, state, { loadingSpinner: true, packageData });
    case clientspecConstants.PACKAGE_SUBMITTED:
      return Object.assign({}, state, { loadingSpinner: false });
    default:
      return state;
  }
};
