import React, { Component } from 'react';
import Downshift from 'downshift';
import { TextField, MenuItem, Paper, Typography } from '@material-ui/core';
import ArrowDropDown from "@material-ui/icons/ArrowDropDown";
import { withStyles } from '@material-ui/styles';
import PropTypes from 'prop-types';

const styles = () => ({
  root: {
    flexGrow: 1,
    height: 240
  },
  container: {
    flexGrow: 1,
    position: "relative"
  },
  paper: {
    position: "absolute",
    zIndex: 1,
    marginTop: 1,
    left: 0,
    right: 0,
    height: 240,
    width: '16vw',
    overflowY: 'auto',
  },
  inputRoot: {
    flexWrap: "nowrap"
  },
  inputInput: {
    width: "auto",
    flexGrow: 1
  }
});

const renderInput = (inputProps) => {
  const { InputProps, classes, openMenu, closeMenu, error, style, isOpen, ref, ...other } = inputProps;
  return (
    <TextField
      onFocus={openMenu}
      onKeyDown={closeMenu}
      error={error}
      InputProps={{
        inputRef: ref,
        classes: {
          root: classes.inputRoot,
          input: classes.inputInput
        },
        ...InputProps
      }}
      style={style}
      {...other}
    />
  );
}

const renderSuggestion = ({
  suggestion,
  index,
  itemProps,
  highlightedIndex,
  selectedItem
}) => {
  const isHighlighted = highlightedIndex === index;
  const isSelected = (selectedItem || "").indexOf(suggestion) > -1;

  return (
    <MenuItem
      {...itemProps}
      key={suggestion}
      value={suggestion}
      selected={isHighlighted}
      component="div"
      style={{
        fontWeight: isSelected ? 500 : 400,
      }}
    >
      <Typography noWrap={true}>{suggestion}</Typography>
    </MenuItem>
  );
}

const getFilteredCollection = (collection, inputValue) => {
  if(inputValue === '') {
      return collection;
  }

  return collection.filter((item) =>
    item.toLowerCase().includes(inputValue.toLowerCase()));
}

const InputEditableSelect = (props) => {
  let { value, onChange, collection, disabled = false, error, style, classes } = props;

  return (
    <Downshift onChange={onChange} selectedItem={value}
    >
      {({
        getInputProps,
        getItemProps,
        getMenuProps,
        isOpen,
        selectedItem,
        inputValue,
        highlightedIndex,
        openMenu,
        closeMenu,
      }) => (
          <div classes={classes.container}>
            {renderInput({
              fullWidth: false,
              classes,
              isOpen,
              openMenu,
              closeMenu,
              disabled: disabled || false,
              error: error,
              style: style,
              InputProps: getInputProps({
                endAdornment: <ArrowDropDown style={{
                  transform: (collection.length > 1 && isOpen) ?
                    'rotate(180deg)' : undefined,
                }} />,
                spellCheck: "false",
              })
            })}
            <div {...getMenuProps()}>
              {(isOpen && collection.length > 0) ? (
                <Paper className={classes.paper} square>
                  {getFilteredCollection(collection, inputValue).map((suggestion, index) =>
                    renderSuggestion({
                      suggestion,
                      index,
                      itemProps: getItemProps({ item: suggestion }),
                      highlightedIndex,
                      selectedItem
                    })
                  )}
                </Paper>
              ) : null}
            </div>
          </div>
        )}
    </Downshift>
  )
}

InputEditableSelect.propTypes = {
  collection: PropTypes.array.isRequired,
  error: PropTypes.bool.isRequired,
}

let styleConnected = withStyles(styles)(InputEditableSelect);
export { styleConnected as InputEditableSelect };