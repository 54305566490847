import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import Loader from 'react-loaders';
import { ClientForm, FileUpload } from '../components';
import styles from './home.styles.scss';
import { Grid, Fab, Button } from '@material-ui/core';
import { ClientActions } from '../_actions/client.actions';
import { FileActions } from '../_actions/file.actions';
import { ClientSpecActions } from '../_actions/clientspec.actions';
import NotifierAction from '../_actions/notifier.actions';
import { UtilityService } from '../_services/utility.service';
import { global } from '../_constants/global.constants';
import { history } from '../_helpers/history';

class HomePage extends React.Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    this.props.fetchClients();
  }

  disableNext = () => {
    let { taskIds, client, title } = this.props;

    if (!client) {
      return true;
    }

    switch(client.deliveryModel) {
      case "distributionMetadata":
        return !taskIds;
      case "dbbBulkIngest":
        return !UtilityService.isAlphaTitle(title);
      default:
        return !title.externalId;
    }
  }

  displayWarningForDbbIfNonAlphaSelected = (client, title) => {
    UtilityService.performActionForDbbNonAlphaSelected(client, title, () => {
      this.props.showNotification(true, global.NON_ALPHA_SELECTED);
    });
  }

  handleClick = () => {
    history.push({pathname: '/package-data'});
  }

  render() {
    return (
        <React.Fragment>
          <Grid container
          direction="column"
          justify="flex-start"
          className="content">
            <Grid item component={ClientForm}
              clients={this.props.clients}
              client={this.props.client}
              title={this.props.title}
              onClientChange={this.displayWarningForDbbIfNonAlphaSelected}
            />
            <Grid item component={FileUpload}
              files={this.props.files}
              client={this.props.client}
              removeFile={this.props.removeFile}
              addFiles={this.props.addFiles}
              />
          </Grid>
          <Grid container>
            <Grid container
            justify="flex-end"
            component="footer">
              <Grid item>
                  <Fab
                    variant="extended"
                    color="primary"
                    className={this.props.loadingSpinner ? "next-button loading" : "next-button" }
                    disabled={this.disableNext()}
                    onClick={this.handleClick}
                    >
                      {this.props.loadingSpinner ? <Loader type="line-spin-fade-loader" active={true}/> : "Next"}
                  </Fab>
              </Grid>
            </Grid>
          </Grid>
        </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  let {
    clients: clientsReducer,
    titles: titlesReducer,
    files: filesReducer,
    taskIds: taskIdsReducer,
  } = state;
  let { titles } = titlesReducer;
  let { client, clients } = clientsReducer;
  let { files } = filesReducer;
  let { taskIds } = taskIdsReducer;
  let title = titles[0];

  return {
    title,
    titles,
    client,
    clients,
    files,
    taskIds,
  }
}

function mapDispatchToProps(dispatch) {
  let { removeFile, addFiles } = FileActions;
  let { fetchSpec } = ClientSpecActions;
  let { fetchClients } = ClientActions;
  let { showNotification } = NotifierAction;

  return {
    removeFile: (file) => {
      dispatch(removeFile(file));
    },
    addFiles: (files) => {
      dispatch(addFiles(files));
    },
    fetchClients: () => {
      dispatch(fetchClients());
    },
    getClientSpec: (title, client, language, files) => {
      dispatch(fetchSpec(title, client, language, files, true));
    },
    showNotification: (errorStatus, msg) => {
      dispatch(showNotification(errorStatus, msg, 'warning'));
    },
  }
}

const connectedHomePage = connect(mapStateToProps, mapDispatchToProps)(HomePage);
export { connectedHomePage as HomePage };
