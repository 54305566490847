import React from 'react';
import { Grid } from '@material-ui/core';
import { TitleField } from '../common';

const CablelabsFeatureTitlePane = (props) => {
    let { presentedTitle, displayDate } = props;
    let lasClient = props.client.name === "Roku CableLab 1.1 (LAS)" 

    return (
        <Grid container>

            {lasClient ? <>
                <TitleField label="Title (English)" field="name"
                title={presentedTitle}
                warning="No Title found"
            />
                <TitleField label="Title (Spanish)" field="titleNameSpanish"
                    title={presentedTitle}
                    warning="No Title found"
                />
            </> : <>
                <TitleField label="Title" field="name"
                    title={presentedTitle}
                    warning="No Title found"
                />
            </>}
            
            <TitleField label="EIDR" field="eidr"
                title={presentedTitle}
                warning="EIDR not found"
            />

            <TitleField label="Runtime" field="runtime"
                title={presentedTitle}
                warning="No Runtime found"
            />

            <TitleField label="MPAA Rating" field="rating"
                title={presentedTitle}
                warning="No Rating found"
            />

            <TitleField label="Release Date" field="initialReleaseDate"
                title={presentedTitle}
                formatFunc={displayDate}
                warning="Release Date not found for this title"
            />

            <TitleField label="Genres" field="genres"
                title={presentedTitle}
                warning="No Genres found for this title"
            />

            <TitleField label="Actors" field="actors"
                subfield="name"
                title={presentedTitle}
                warning="No Actors found for this title"
            />

            <TitleField label="Director" field="directors"
                subfield="name"
                title={presentedTitle}
                warning="No Directors found for this title"
            />

            { lasClient ? 
            <>
                <TitleField label="Synopsis Short (English)" field="shortSynopsis"
                    subfield="text"
                    title={presentedTitle}
                    warning="No synopsis found for this title"
                />

                <TitleField label="Synopsis Long (English)" field="longSynopsis"
                    subfield="text"
                    title={presentedTitle}
                    warning="No synopsis found for this title"
                />

                <TitleField label="Synopsis Short (Spanish)" field="shortSynopsisSpanish"
                    subfield="text"
                    title={presentedTitle}
                    warning="No synopsis found for this title"
                />

                <TitleField label="Synopsis Long (Spanish)" field="longSynopsisSpanish"
                    subfield="text"
                    title={presentedTitle}
                    warning="No synopsis found for this title"
                />
            </> : 
            <>
                <TitleField label="Synopsis Short" field="shortSynopsis"
                    subfield="text"
                    title={presentedTitle}
                    warning="No synopsis found for this title"
                />

                <TitleField label="Synopsis Long" field="longSynopsis"
                    subfield="text"
                    title={presentedTitle}
                    warning="No synopsis found for this title"
                />
            </>}       
        </Grid>
    )
}

export { CablelabsFeatureTitlePane };