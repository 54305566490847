import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import { TitleField } from '../common';
import { clientShow } from '../Clientshow/clientshow';
import { UtilityService } from '../../_services';
import _ from 'lodash';

const validateEpisodeNum = (episodeNum) => {
  let leadingZeroRegEx = new RegExp(/^0+(.+)/);
  let result = episodeNum.match(leadingZeroRegEx);
  return result ? result[1] : episodeNum;
}

const getVendorId = (title) => {
  let vendorId = { id: '' };

  if (title.episodeNumber) {
    let episodeNum = validateEpisodeNum(title.episodeNumber);

    vendorId = {
      id: `SONY_${title.series.name}_${episodeNum}`,
    }
  }

  return vendorId;
}

const EpisodeFields = (props) => {
  let { presentedTitle, client, index, tvSynopsisLengths } = props;
  let { shortSynopsisLength, longSynopsisLength } = tvSynopsisLengths;
  let vendorId = getVendorId(presentedTitle);
  return (
    <React.Fragment>
      <TitleField label="Title" field="episodeTitle"
        title={presentedTitle}
        warning="No Title found"
      />

      <TitleField label="Episode Number" field="episodeNumber"
        title={presentedTitle}
        warning="Episode number not found"
      />

      <TitleField label="Runtime" field="runtime"
        title={presentedTitle}
        warning="Runtime not found"
      />

      <TitleField label="Vendor ID" field="id"
        title={vendorId}
      />

      <TitleField label="Network" field="networks"
        title={presentedTitle}
        warning="Network not found"
      />

      <TitleField label="Rating" field="rating"
        title={presentedTitle}
        warning="TV rating not found"
      />

      <TitleField label="ISAN" field="isan"
        title={presentedTitle}
        warning="ISAN not found"
      />

      <TitleField label="Episode MPM" field="mpmWalkerId"
        title={presentedTitle}
        warning="Episode MPM not found"
      />

      <TitleField label="Alpha ID" field="alphaId"
        title={presentedTitle}
        warning="Alpha ID not found"
      />

      <TitleField label="Initial Release Date" field="initialReleaseDate"
        title={presentedTitle}
        formatFunc={UtilityService.convertDateField}
        warning="Initial Release Date not found"
      />

      <TitleField label="Release Year" field="releaseYear"
        title={presentedTitle}
        warning="Release Year not found"
      />

      <TitleField label="Color" field="color"
        title={presentedTitle}
      />

      <TitleField label="Copyright Line" field="copyrightLine"
        title={presentedTitle}
      />

      <TitleField label="Original Language" field="language"
        title={presentedTitle}
        warning="No Original Language found for this title"
      />

      <TitleField label="Genres" field="genres"
        title={presentedTitle}
        warning="No Genres found for this title"
      />

      <TitleField label="Actors" field="actors"
        subfield="name"
        title={presentedTitle}
        warning="No Actors found for this title"
      />

      <TitleField label="Voice" field="voice"
        subfield="name"
        title={presentedTitle}
        warning="No Voices found for this title"
      />

      <TitleField label="Director" field="directors"
        subfield="name"
        title={presentedTitle}
        warning="No Directors found for this title"
      />

      <TitleField label="Producers" field="producers"
        subfield="name"
        title={presentedTitle}
        warning="No Producers found for this title"
      />

      <TitleField label="Composer" field="composers"
        subfield="name"
        title={presentedTitle}
        warning="No Composer found for this title"
      />

      <TitleField label="Teleplay" field="teleplay"
        subfield="name"
        title={presentedTitle}
        warning="No Teleplay found for this title"
      />

      <TitleField label="Writer" field="writer"
        subfield="name"
        title={presentedTitle}
        warning="No Writers found for this title"
      />

      <TitleField label="Screenplay" field="screenplay"
        subfield="name"
        title={presentedTitle}
        warning="No Screenplay found for this title"
      />

      <TitleField label="Episode Synopsis(short)" field="episodeShortSynopsis"
        subfield="text"
        title={presentedTitle}
        warning="No Synopsis (short) found for this title"
        handleSetClick={(charLength) => props.handleSetClick('shortSynopsisLength', charLength)}
        index={index}
        client={client}
        maxSynopsisLength={shortSynopsisLength}
      />

      <TitleField label="Episode Synopsis (long)" field="episodeLongSynopsis"
        subfield="text"
        title={presentedTitle}
        warning="No Synopsis (long) found for this title"
        handleSetClick={(charLength) => props.handleSetClick('longSynopsisLength', charLength)}
        index={index}
        client={client}
        maxSynopsisLength={longSynopsisLength}
      />
    </React.Fragment>
  )
}

const SeasonFields = (props) => {
  let { presentedTitle, client, index, tvSynopsisLengths } = props;
  let { seasonSynopsisLength } = tvSynopsisLengths;
  let isSeason = !presentedTitle.season;

  return (
    <React.Fragment>
      { isSeason ?
        (
          <React.Fragment>
            <TitleField label="Season Name"
              field="seasonName"
              title={presentedTitle}
              warning="Season Name not found for this title"
            />

            <TitleField label="Season Number"
              field="seasonNumber"
              title={presentedTitle}
              warning="Season number not found for this title"
            />

            <TitleField label="Season MPM"
              field="mpmWalkerId"
              title={presentedTitle}
              warning="Season MPM not found for this title"
            />

            <TitleField label="Season Synopsis"
              field="synopsis"
              subfield="text"
              title={presentedTitle}
              warning="Season Synopsis not found for this title"
              handleSetClick={(charLength) => props.handleSetClick('seasonSynopsisLength', charLength)}
              index={index}
              client={client}
              maxSynopsisLength={seasonSynopsisLength}
            />
          </React.Fragment>
        )
        :
        (
          <React.Fragment>
            <TitleField label="Season Name" field="season"
              subfield="name"
              title={presentedTitle}
              warning="Season Name not found for this title"
            />

            <TitleField label="Season Number" field="season"
              subfield="seasonNumber"
              title={presentedTitle}
              warning="Season number not found for this title"
            />

            <TitleField label="Season MPM" field="season"
              subfield="mpmWalkerId"
              title={presentedTitle}
              warning="Season MPM not found for this title"
            />

            <TitleField label="Season Synopsis" field="season"
              subfield="synopsis"
              title={presentedTitle}
              warning="Season Synopsis not found for this title"
              handleSetClick={(charLength) => props.handleSetClick('seasonSynopsisLength', charLength)}
              index={index}
              client={client}
              maxSynopsisLength={seasonSynopsisLength}
            />
          </React.Fragment>
        )
      }
    </React.Fragment>
  )
}

const CmdgTvFields = (props) => {
  let { presentedTitle, client, index, tvSynopsisLengths } = props;
  let { seasonSynopsisLength, seriesSynopsisLength } = tvSynopsisLengths;
  let isSeason = UtilityService.isSeason(presentedTitle);

  return (
    <Grid container>
      {!isSeason ? <EpisodeFields {...props} /> : null}
      <Grid item>
        <Typography variant="h5" component="h5"
          className="season-data-header">SERIES/SEASON DATA</Typography>

        { <SeasonFields {...props} /> }

        <TitleField label="Series Name" field="series"
          subfield="name"
          title={presentedTitle}
          warning="Series Name not found for this title"
        />

        <TitleField label="Series MPM" field="series"
          subfield="mpmWalkerId"
          title={presentedTitle}
          warning="Series MPM not found for this title"
        />

        <TitleField label="Series Synopsis" field="series"
          subfield="synopsis"
          title={presentedTitle}
          warning="Series Synopsis not found for this title"
          handleSetClick={(charLength) => props.handleSetClick('seriesSynopsisLength', charLength)}
          index={index}
          client={client}
          maxSynopsisLength={seriesSynopsisLength}
        />
      </Grid>
    </Grid>
  )
}

let clientConnected = clientShow(CmdgTvFields, 'CmdgTvFields');
export { clientConnected as CmdgTvFields };