import React from 'react';

export const TabPanel = ({ children, component:Component, currentIndex, index, ...rest }) => {
    if(currentIndex === index) {
        return Component ? (
            <Component {...rest}>
                {children}
            </Component>
        ) : (
            <React.Fragment>
                {children}
            </React.Fragment>
        );
    }
    return null;
}