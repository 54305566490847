import { clientspecConstants } from '../_constants/clientspec.constants';
let { PACKAGE_SUBMITTED } = clientspecConstants;

export const manifest = (state = {}, action) => {
  switch (action.type) {
    case PACKAGE_SUBMITTED:
      return Object.assign({}, state, action.payload);
    default:
      return state;
  }
};
