import React from "react";

const SvgCalendar = props => (
  <svg viewBox="0 0 16 16" {...props} width="1em" height="1em">
    <path
      d="M14 9.6c0 .2-.2.4-.5.4h-1c-.3 0-.5-.2-.5-.4V8.4c0-.2.2-.4.5-.4h1c.3 0 .5.2.5.4v1.2zm0 4c0 .2-.3.4-.6.4h-1c-.3 0-.5-.2-.5-.4v-1.2c0-.2.2-.5.5-.5h1c.3 0 .5.3.5.5v1.2zm-5-4c0 .2-.2.4-.5.4h-1c-.3 0-.5-.2-.5-.4V8.4c0-.2.2-.4.5-.4h1c.3 0 .5.2.5.4v1.2zm0 4c0 .2-.2.4-.5.4h-1c-.3 0-.5-.2-.5-.4v-1.2c0-.2.2-.4.5-.4h1c.3 0 .5.2.5.4v1.2zm-5-4c0 .2-.2.4-.5.4h-1c-.3 0-.5-.2-.5-.4V8.4c0-.2.2-.4.5-.4h1c.3 0 .5.2.5.4v1.2zm0 3.9c0 .3-.2.5-.5.5H2.4c-.1 0-.2-.1-.3-.1-.1-.1-.1-.2-.1-.4v-1.2c0-.1.2-.3.5-.3h1c.3 0 .5.2.5.4v1.1zM0 4h16v2H0V4zm14-2V.5c0-.3-.2-.5-.4-.5h-1.1c-.2 0-.5.2-.5.5V2H4V.5c0-.3-.2-.5-.4-.5H2.4c-.2 0-.4.2-.4.5V2H.4c-.2 0-.4.2-.4.5v13.1c0 .2.2.4.4.4h15.2c.2 0 .4-.2.4-.5V2.3s-.2-.3-.4-.3H14z"
      fill="currentColor"
    />
  </svg>
);

export default SvgCalendar;