import React, { Component } from 'react';
import { clientShow } from '../Clientshow/clientshow';
import { TitleData } from '../TitleDisplay';
import { TerritorySelect } from '../Dbb/TerritorySelect';
import { ExhibitionTerritorySelect } from '../ExhibitionMetadata/TerritorySelect';
import '../Dbb/TitlePackage.styles.scss';
import { Grid, Paper, Typography } from '@material-ui/core';

class CmdgInternational extends Component {
    constructor(props) {
        super(props);
        this.state =  {
            selectedTerritories: [props.territory],
        }
    }

    handleTerritoryChange = (field, data) => {
        this.setState({[field]: data}, () => {
            this.props.handleChildChange(field, data);
        });
    }

    render() {
        let { selectedTerritories } = this.state;
        let { titleWarning, ...rest } = this.props;
        return (
            <Grid container
            direction="column"
            justify="space-between"
            className="card-padding"
            component={Paper}
            elevation={8}>
                <Grid item>
                  <Typography variant="h5" component="h5">PACKAGE DATA</Typography>
                </Grid>
                <TerritorySelect
                    {...this.props}
                    clientSpec={this.props.spec}
                    tabsClickable={false}
                    handleChildChange={this.handleTerritoryChange}
                    />
                <ExhibitionTerritorySelect
                    {...this.props}
                    clientSpec={this.props.spec}
                    tabsClickable={false}
                    handleChildChange={this.handleTerritoryChange}
                    />
                <Grid item component={TitleData}
                    {...this.props}
                    warning={this.props.titleWarning}
                    errorsEnabled={true}
                    selectedTerritories={selectedTerritories}
                />
            </Grid>
        );
    }
}

let clientConnected = clientShow(CmdgInternational, 'CmdgInternational');
export { clientConnected as CmdgInternational };