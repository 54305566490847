import React, { Component } from 'react';
import { Grid, FormControl, InputLabel, TextField, MenuItem, Typography,
    ExpansionPanelSummary } from "@material-ui/core";
import { FileEditableDropdown } from "../FileEditableDropdown/FileEditableDropdown";
import { Dropdown } from "../common";
import { ExpandableSection } from '../common';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { withStyles } from "@material-ui/styles";
import { FileService } from '../../_services/file.service';
import { clientShow } from '../Clientshow/clientshow';

import _ from 'lodash';

const style = (theme) => ({
    formControl: {
        top: 2,
    },
    label: {
        color: "#333333",
        fontSize: 14,
        fontFamily: ['Lato', 'sans-serif'],
        fontWeight: 700
    },
    input: {
        marginTop: 40,
        width: '12vw',
    },
    focused: {
        fontSize: 14,
        color: "#5e5edb",
        fontFamily: ['Lato', 'sans-serif'],
        fontWeight: 700
    }
});

const defaultState = {
    captionFile: {},
    language: '',
    fileSize: '',
    checksum: '',
    expanded: true,
}
class ClosedCaptionData extends Component {
    constructor(props) {
        super(props);
        let { closedCaptionData = {} } = props;
        this.state = Object.assign({}, defaultState, closedCaptionData);
        this.handleFilenameChange = _.debounce(this.handleFilenameChange, 550);
    }

    handleFilenameChange = (label, event) => {
        let value = event.target.value;
        let newState = {};
        newState.language = !_.isEmpty(value) ? 'en' : '';
        let ebucoreData = _.find(this.props.files, { filename: value });
        newState.captionFile = {
            ebucore: ebucoreData,
            filename: value,
        };
        if(ebucoreData) {
            let checksum = FileService.getChecksumForFilename(ebucoreData.filename, this.props.files);
            newState.checksum = checksum || this.state.checksum;
        }

        this.setState(newState, () => {
            this.props.onChange('closedCaption', this.state);
        })
    }

    handleExpansionPanel = () => {
        this.setState((prevState) => {
            return {
                expanded: !prevState.expanded
            }
        });
    }

    handleChange = (label) => (event) => {
        let value = event.target.value;
        this.setState({
            [label]: value
        }, () => {
            this.props.onChange('closedCaption', this.state);
        });
    }

    handleDropDownChange = (label, value) => {
        let newState = Object.assign({}, this.state);
        newState[label] = value;
        this.setState(newState, () => {
            this.props.onChange('closedCaption', this.state);
        })
    }

    getExpansionSummaryComponent = (isExpanded, classes) => {
        return (
            <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}
                className={isExpanded ? classes.focused: classes.label}>
                Closed Caption Data
            </ExpansionPanelSummary>);
    }

    render() {
        let { files, languages, classes, disabled } = this.props;
        let isExpanded = this.state.expanded;
        return (
            <Grid item component={ExpandableSection} header="Closed Caption Data"
                defaultExpanded={isExpanded} onChange={this.handleExpansionPanel}
                disabled={disabled} expansionSummary={this.getExpansionSummaryComponent(isExpanded, classes)}>

                    <Grid container direction="row" justify="space-between">
                        <Grid item>
                            <FormControl className={classes.formControl} disabled={this.props.disabled}>
                                <InputLabel shrink={false} className={classes.label}>
                                    Filename
                                </InputLabel>
                                <FileEditableDropdown
                                    id="filename"
                                    onChange={this.handleFilenameChange}
                                    field={'captionFile'}
                                    disabled={this.props.disabled}
                                    value={this.state.captionFile.filename}
                                    files={files}
                                    style={{
                                        marginTop: 40,
                                        width: '20vw',
                                    }}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item>
                            <Dropdown
                                value={this.state.language}
                                field={'language'}
                                onChange={this.handleDropDownChange}
                                label="Language"
                                disabled={this.props.disabled}
                            >
                            {
                                languages.map((language, index) => (
                                    <MenuItem key={index} value={language.value}>
                                    <Typography noWrap={true}>{language.name}</Typography>
                                    </MenuItem>
                                ))
                            }
                            </Dropdown>
                        </Grid>
                        <Grid item>
                            <FormControl className={classes.formControl} disabled={this.props.disabled}>
                                <InputLabel shrink={false} className={classes.label}>
                                    File Size
                                </InputLabel>
                                <TextField
                                    type="number"
                                    value={this.state.fileSize}
                                    onChange={this.handleChange('fileSize')}
                                    className={classes.input}
                                    disabled={this.props.disabled}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item>
                            <FormControl className={classes.formControl} disabled={this.props.disabled}>
                                <InputLabel shrink={false} className={classes.label}>
                                    Checksum
                                </InputLabel>
                                <TextField
                                    type="text"
                                    value={this.state.checksum}
                                    onChange={this.handleChange('checksum')}
                                    className={classes.input}
                                    disabled={this.props.disabled}
                                />
                            </FormControl>
                        </Grid>
                    </Grid>

            </Grid>
        )
    }
}

const styled = withStyles(style)(ClosedCaptionData);
const clientConnected = clientShow(styled, 'ClosedCaptionData');
export { clientConnected as ClosedCaptionData };
