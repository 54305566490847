import React, { Component } from 'react';
import { Grid, FormControl, InputLabel, TextField } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import PropTypes from 'prop-types';
import _ from 'lodash';

const style = (theme) => ({
    formControl: {
        top: 2,
        position: 'relative',
    },
    labelRequired: {
        fontSize: 14,
        color: "#5e5edb",
        fontWeight: 700,
    },
    label: {
        fontSize: 14,
        color: "#333333",
        fontWeight: 700,
    },
    textfield: {
        marginTop: 40,
        marginRight: 10,
        marginBottom: 15,
        width: '16vw',
    }
})


class TextInput extends Component {
    constructor(props) {
        super(props);
        this.state = {};
        this.state.value = props.value || '';
    }

    onChange = (e) => {
        let value = e.target.value;
        let field = this.props.field;
        this.setState({
            value,
        }, () => {
            this.props.onChange(field, value);
        })
    }

    render() {
        let { classes, label, type, required = false,
             error, style = null } = this.props;
        let labelClassName = required ? classes.labelRequired : classes.label;
        return (
            <Grid item component={FormControl} className={classes.formControl}
                required={required}>
                <InputLabel shrink={false} className={labelClassName}>
                    {label}
                </InputLabel>
                <TextField
                    className={classes.textfield}
                    style={style}
                    type={type}
                    value={this.state.value}
                    error={error}
                    onChange={this.onChange}
                />
            </Grid>
        )
    }
}

TextInput.propTypes = {
    label: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    type: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired
}

const styled = withStyles(style)(TextInput);
export { styled as TextInput };