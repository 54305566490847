import { createMuiTheme } from '@material-ui/core/styles';

export const sidecarTheme = createMuiTheme({
  palette: {
    primary: { main: "#5E5EDB", dark: "#4F2BF9" },
    secondary: { main: "#4F2BF9"},
  },
  typography: {
    useNextVariants: "true",
    fontFamily: [
      'Lato',
      'sans-serif',
    ].join(','),
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 700,
    button: {
      fontSize: "14px",
      fontWeight: "400px",
      textTransform: 'uppercase',
      color: "primary",
    },
    h1: {
      fontSize: "2rem",
      lineHeight: "2.5rem",
    },
    h2: {
      fontSize: "1.75rem",
      lineHeight: "2.25rem",
    },
    h3: {
      fontSize: "1.5rem",
      lineHeight: "2rem",
    },
    h4: {
      fontSize: "1.25rem",
      lineHeight: "1.75rem",
      letterSpacing: ".15px",
    },
    h5: {
      fontSize: "1rem",
      lineHeight: "1.5rem",
      letterSpacing: "0.5px",
    },
    h6: {
      fontSize: "0.875rem",
      lineHeight: "1.25rem",
      letterSpacing: ".25px",
    },
    subtitle1: {
      fontSize: "0.875rem",
      lineHeight: "1.25rem",
      letterSpacing: ".25px",
    },
  },
});
