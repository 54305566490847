import React, { Component } from 'react';
import { withStyles } from '@material-ui/styles';
import { FormControl, InputLabel, Select, MenuItem } from '@material-ui/core';
import PropTypes from 'prop-types';

const style = (theme) => ({
  formControl: {
    top: 2,
  },
  label: {
    fontSize: 14,
    color: '#333333',
    fontWeight: 700,
  },
  labelRequired: {
    fontSize: 14,
    color: "#5e5edb",
    fontWeight: 700,
  },
  dropDownStyle: {
    maxHeight: 234,
  },
});

const defaultSelectStyle = {
  width: '16vw',
  marginTop: 40,
  marginRight: 10,
  marginBottom: 15,
}

class Dropdown extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: props.value || ""
    }
  }

  handleChange = (e) => {
    let value = e.target.value;
    let field = this.props.field;
    let index = this.props.index;
    this.setState({
      value,
    }, () => {
      this.props.onChange(field, value, index);
    })
  }

  static getDerivedStateFromProps(props, state) {
    if(props.value !== state.value) {
      return {
        value: props.value,
      }
    }
    return null;
  } 

  render() {
    let { label, classes, disabled = false, required, error, style = null } = this.props;
    let disabledClass = disabled ? 'disabled-item' : '';
    return (
      <FormControl className={classes.formControl} disabled={disabled} required={required}>
        <InputLabel shrink={false} className={required ? classes.labelRequired : classes.label}>
          {label}
        </InputLabel>
        <Select className={disabledClass} onChange={this.handleChange} value={this.state.value}
          error={error}
          MenuProps={{
            classes: { paper: classes.dropDownStyle },
            getContentAnchorEl: null,
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "left",
            },
          }}
          inputProps={{
            "data-testid": "dropdown"
          }}
          style={ style || defaultSelectStyle}>
          <MenuItem value=""></MenuItem>
          {
            this.props.children
          }
        </Select>
      </FormControl>
    );
  }
};

Dropdown.propTypes = {
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.any.isRequired,
};

const styled = withStyles(style)(Dropdown);
export { styled as Dropdown };
