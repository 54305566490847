import React, { Component } from 'react';
import { Grid, InputLabel, MenuItem, Typography, FormControl, Select,
    Chip, Checkbox, ListItemText, Fab, Divider, TextField, InputAdornment } from "@material-ui/core";
import { withStyles } from '@material-ui/styles';
import { clientShow } from '../Clientshow/clientshow';
import SvgCircleX from "../../assets/icons/CircleX";
import SvgSearch from "../../assets/icons/Search";
import { TitleModal } from '../TitleDisplay/TitleModal';
import { ClientspecService } from '../../_services/clientspec.service';
import config from 'config';
import _ from 'lodash';

const styles = (theme) => ({
    root: {
      fontSize: 14,
      color: "#5e5edb",
      fontWeight: 700,
    },
    dropDownStyle: {
      maxHeight: 234,
      width: '40vw',
    },
    formControl: {
      position: 'relative',
      width: '40vw'
    },
    label: {
      top: 3,
      fontSize: 14,
      color: '#333333',
      fontWeight: 700,
    },
    chips: {
      display: "flex",
      flexWrap: "wrap",
      maxHeight: '256px',
      overflowY: 'scroll'
    },
    clearAll: {
        width: 72,
        height: 24,
        display: 'inline',
        fontSize: 14,
        borderRadius: 20,
        textTransform: 'capitalize',
        marginLeft: 16,
        marginTop: 16,
        marginBottom: 12,
        color: 'white',
    },
  });

  const initialState = {
    selectedTerritories: [
        {   name: 'U.S.A. (English)',
            sourceTerritory: 'U.S./English',
            locale: 'en-US',
            alphaRating: "U.S.A. or U.S.A[excl t's & p's]"
        }
    ],
    territories: config.territories,
    showTitleModal: false,
    title: null,
    chipValue: {
        name: 'U.S.A. (English)',
        sourceTerritory: 'U.S./English',
        locale: 'en-US',
        alphaRating: "U.S.A. or U.S.A[excl t's & p's]"
    },
  }
class TerritorySelect extends Component {
    constructor(props) {
        super(props);

        let locales = props.locales || ['en-US'];
        let selectedTerritories = locales.map((item) => {
            return _.find(config.territories, { locale: item});
        });

        this.state = Object.assign({}, initialState, {selectedTerritories});
    }

    componentDidMount() {
        this.propagateToDataEntry();
    }

    propagateToDataEntry = () => {
        this.props.handleChildChange('locales', this.getLocales());
        this.props.handleChildChange('selectedTerritories', this.state.selectedTerritories);
    }

    getLocales = () => {
        return _.map(this.state.selectedTerritories, 'locale');
    }

    handleChange = (event) => {
        switch(event.currentTarget.id) {
            case 'clearAll':
                this.clearAll();
                event.preventDefault();
                break;
            case 'selectAll':
                this.selectAll();
                event.preventDefault();
                break;
            default:
                if(!_.includes(event.target.value, undefined)) {
                    this.setState({
                        selectedTerritories: event.target.value,
                    }, this.propagateToDataEntry);
                }
        }
    }

    handleDelete = (event, index) => {
        let newArray = this.state.selectedTerritories.filter(val => {
            return val !== this.state.selectedTerritories[index];
        });
        this.setState({
            selectedTerritories: newArray
        }, this.propagateToDataEntry);
    }

    handleClick = (event, index) => {
        event.stopPropagation();
        let tabsClickable = this.props.tabsClickable === undefined ?
                            true: this.props.tabsClickable;
        if(tabsClickable) {
            let territoryData = JSON.parse(event.currentTarget.dataset.key);
            this.fetchClientSpec(territoryData);
            this.setState({
                chipValue: territoryData,
            });
        }
    }

    isChecked = (territoryName) => {
        let result = _.find(this.state.selectedTerritories, (territory) => {
            if(territoryName === territory.name) {
                return territory;
            }
        });
        return result ? true : false
    }

    fetchClientSpec = (territoryData) => {
        let { client, title }  = this.props;
        return ClientspecService.getSpec(title.externalId, client.id, { territory: territoryData })
        .then((resp)=> {
            this.setState({
              showTitleModal: true,
              title: resp.data.presentedTitle,
            });
        })
        .catch(ex => {
          // TODO: no client spec returned;
        });
      }

    inputChange = (event) => {
        this.setState({
            territories: config.territories.filter((territory) => {
                return territory.name.toLowerCase().includes(event.target.value.toLowerCase());
            }),
        });
    }

    onFocusOut = (event) => {
        this.setState({
            territories: config.territories,
        })
    }

    clearAll = () => {
        this.setState({
            selectedTerritories: [{ name: 'U.S.A. (English)',
                                    sourceTerritory: 'U.S./English',
                                    locale: 'en-US',
                                    alphaRating: "U.S.A. or U.S.A[excl t's & p's]"
                                }],
        }, this.propagateToDataEntry);
    }

    selectAll = () => {
        let selectedTerritories = [].concat(this.state.territories);
        this.setState({selectedTerritories}, this.propagateToDataEntry);
    }

    closeTitleModal = () => {
        this.setState({
          showTitleModal: false
        });
    }

    render() {
        let { classes, tabsClickable = true } = this.props;
        return (
            <Grid container>
                <Grid item component={FormControl} className={classes.formControl}>
                    <InputLabel shrink={false} className={classes.label}>Territory/Region:</InputLabel>
                    <Select
                        multiple
                        autoWidth={true}
                        className="territory"
                        value={this.state.selectedTerritories}
                        onChange={this.handleChange}
                        onBlur={this.onFocusOut}
                        renderValue={selected => (
                            <div className={classes.chips}>
                              {selected.map((value, index) => (
                                <Chip
                                  key={value.sourceTerritory}
                                  clickable={tabsClickable}
                                  data-key={JSON.stringify(value)}
                                  label={value.name}
                                  className="select-chip"
                                  deleteIcon={<SvgCircleX className="clear-sign" />}
                                  onDelete={e => this.handleDelete(e, index)}
                                  onClick={e => this.handleClick(e, index)}
                                />
                              ))}
                            </div>
                          )}
                        MenuProps={{
                        classes: { paper: classes.dropDownStyle },
                        getContentAnchorEl: null,
                        anchorOrigin: {
                            vertical: "bottom",
                            horizontal: "left",
                        }
                        }}
                        style={{ marginTop: 50, backgroundColor: 'white'}}>
                        <section>
                        <Fab id="clearAll" className={classes.clearAll} extended="contained"
                            onClick={this.clearAll} color="primary" size="small">Clear All</Fab>
                        <Fab id="selectAll" className={classes.clearAll} extended="contained"
                        onClick={this.selectAll} color="primary" size="small">Select All</Fab>
                        </section>

                        <TextField placeholder="Search" id="input" onChange={this.inputChange}
                            style={{ marginBottom: 16, marginRight: 16, marginLeft: 16, width: 516 }}
                            InputProps={{
                                startAdornment: <InputAdornment position="start"><SvgSearch></SvgSearch></InputAdornment>,
                                autoComplete: "off"
                            }}
                        />
                        <Divider style={{ color: '#cccccc'}}/>
                        {this.state.territories.map(territory => (
                        <MenuItem key={territory.sourceTerritory} value={territory}>
                            <Checkbox checked={territory && this.isChecked(territory.name)} />
                            <ListItemText className="list"><Typography className="territory-list" noWrap={true}>{territory.name}</Typography></ListItemText>
                        </MenuItem>
                        ))}
                    </Select>
                    {/* Title Modal */}
                    <TitleModal
                        client={this.props.client}
                        title={this.state.title}
                        handleLanguageChange={this.fetchClientSpec}
                        open={this.state.showTitleModal}
                        close={this.closeTitleModal}
                        selectedTerritories={this.state.selectedTerritories}
                        chipValue={this.state.chipValue}
                    />
                </Grid>
            </Grid>
        );
    }
}

let clientConnected = clientShow(TerritorySelect, 'TerritorySelect');
let styled = withStyles(styles)(clientConnected);
export { styled as TerritorySelect}