import React from "react";

const SvgCircleX = props => (
  <svg viewBox="0 0 16 16" {...props} width="1em" height="1em">
    <path
      d="M8 6.6L6.3 4.9c-.2-.2-.5-.2-.7 0l-.7.7c-.2.2-.2.5 0 .7L6.6 8 4.9 9.8c-.2.2-.2.5 0 .7l.7.7c.2.2.5.2.7 0L8 9.4l1.8 1.8c.2.2.5.2.7 0l.7-.7c.2-.2.2-.5 0-.7L9.4 8l1.8-1.8c.2-.2.2-.5 0-.7l-.7-.7c-.2-.2-.5-.2-.7 0L8 6.6zM8 0c4.4 0 8 3.6 8 8s-3.6 8-8 8-8-3.6-8-8 3.6-8 8-8z"
      fillRule="evenodd"
      clipRule="evenodd"
      fill="currentColor"
    />
  </svg>
);

export default SvgCircleX;

