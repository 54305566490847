import { combineReducers } from "redux";
import { resetStoreConstants } from "../_constants/resetstore.constants";
import { authentication } from "./authentication.reducer";
import { alert } from "./alert.reducer";
import { clientspec } from "./clientspec.reducer";
import { clients } from "./clients.reducer";
import { files } from "./files.reducer";
import { notifier } from "./notifier.reducer";
import { manifest } from "./manifest.reducer";
import { titles } from "./title.reducer";
import { taskIds } from "./taskId.reducer";

const appReducer = combineReducers({
  authentication,
  alert,
  clients,
  clientspec,
  files,
  manifest,
  notifier,
  taskIds,
  titles,
});

const rootReducer = (state, action) => {
  if (action.type === resetStoreConstants.ResetStore) {
    let { clients } = state;
    state = { clients };
  }
  return appReducer(state, action);
};

export default rootReducer;
