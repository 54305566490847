import React, { Component } from 'react';
import { Grid, Tabs, Tab, Fab } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import { Add } from "@material-ui/icons";
import { TabPanel } from '../components/common';
import { TerritoryDetails } from './';
import _ from 'lodash';

const style = () => ({
    scrollButtons: {
        width: 32,
        '&[type=button]': {
            width: 32,
            backgroundColor: '#5e5edb'
        },
        '& > svg': {
            color: '#ffffff'
        }
    },
    fab: {
        position: "absolute",
        right: 112,
        marginTop: 6,
        width: 36,
        height: 24,
    },
    icon: {
        width: 16,
        height: 16,
    }
})

class DataEntryPanel extends Component {
    constructor(props) {
        super(props);
        this.state = {
            tabIndex: 0,
            languages: props.languageData,
        }
    }

    componentDidUpdate(prevProps) {
        if(!_.isEqual(prevProps.languageData, this.props.languageData)) {
            let languages = this.props.languageData;
            this.setState({
                languages,
            })
        }
    }

    handleTabChange = (e, tabIndex) => {
        this.setState({
            tabIndex: tabIndex,
        }, () => {
            console.log(this.state);
        })
    }

   

    createNewTab = () => {
        // Creating new tab logic
        let languages = [].concat(this.state.languages);
        let newLanguages = languages.concat([this.props.initState]);
        let tabIndex = newLanguages.length - 1;
        this.setState({
            languages: newLanguages,
            tabIndex: tabIndex,
        }, () => {
            this.props.onChange(newLanguages);
        })
    }

    duplicateTab = () => {
        // Duplicate tab logic
    }

    render() {
        let { classes } = this.props;
        let { tabIndex, languages } = this.state;
        let scrollButtonsVariant = languages.length > 5 ? 'auto' : 'off';
        return (
            <Grid container style={{
                backgroundColor: '#f5f5f5',
                borderTop: "2px solid rgba(117,117,117, 0.5)"
            }}>
                <Tabs
                value={tabIndex}
                onChange={this.handleTabChange}
                indicatorColor="primary"
                variant="scrollable"
                scrollButtons={scrollButtonsVariant}
                textColor="primary"
                classes={{
                    scrollButtons: classes.scrollButtons
                }}>
                    {
                        languages.map((data, index) => {
                            return (
                                <Tab label={data.language || 'New Language'} key={index}/>
                            )
                        })
                    }
                </Tabs>
                {
                    languages.map((language, index) => {
                        return (
                            <TabPanel currentIndex={tabIndex} index={index} key={index}>
                                <TerritoryDetails
                                    language={language}
                                    languages={languages}
                                    onLanguageChange={this.props.onChange}
                                    index={index}
                                />
                            </TabPanel>
                        )
                    })
                }
                <Fab color="primary" size="small" className={classes.fab} 
                    onClick={this.createNewTab}>
                    <Add className={classes.icon}/>
                </Fab>
            </Grid>
        )
    }
}

let styledComponent = withStyles(style)(DataEntryPanel);
export { styledComponent as DataEntryPanel };