import React from "react";
import { Grid, Select, FormControl, InputLabel, Card,
  MenuItem, TextField, Button, Typography, ExpansionPanelSummary,
  IconButton } from "@material-ui/core";
import { withStyles } from "@material-ui/styles";
import { ExpandableSection } from '../common';
import { TwoSelectDataForm } from './TwoSelectDataForm';
import { Add, Close } from "@material-ui/icons";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { withHandleMethods } from "./withHandleMethods";
import { FileEditableDropdown } from '../FileEditableDropdown/FileEditableDropdown';

const styles = theme => ({
  root: {
    fontSize: 14,
    color: "#5e5edb",
    fontWeight: 700
  },
  dropDownStyle: {
    maxHeight: 234
  },
  formControl: {
    position: "relative"
  },
  label: {
    top: 3,
    fontSize: 14,
    fontFamily: ['Lato', 'sans-serif'],
    color: "#333333",
  },
  focused: {
    fontSize: 14,
    color: "#5e5edb",
    fontFamily: ['Lato', 'sans-serif'],
    fontWeight: 700
  }
});

const displayPresetValues = ['Sony x300 BT709', 'Sony x300 BT2020', 'Sony x300 P3D65', 'Dolby Pulsar P3D65'];

class ManifestDataForm extends React.Component {

  state = {
      expanded: true,
  }

  isFeatureData = () => {
      return this.props.header.includes('Feature');
  }

  isFileNameNotEmpty = (form) => {
    return form && form.data.filename !== '';
  }

  formRow = (form, index) => {
      const classes = this.props.classes;
      const isFeature = this.isFeatureData();
      const errorsEnabled = this.props.errorsEnabled;
      const isDisable = form ? form.disableFields : false;
      const isFileNameNotEmpty = this.isFileNameNotEmpty(form);
      return (
            <Grid item container justify="space-between">
                <Grid item>
                    <FormControl required={isFeature} className={classes.formControl}>
                    <InputLabel shrink={false} className={isFeature ? classes.root : classes.label}>
                        Filename
                    </InputLabel>
                    <FileEditableDropdown
                        index={index}
                        error={isFeature && errorsEnabled && form.errors.filenameError}
                        onChange={this.props.handleChange}
                        files={this.props.files}
                        field={'filename'}
                        style={{
                            marginTop: 44,
                            marginBottom: 12,
                            width: "16vw"
                        }}
                    />
                    </FormControl>
                </Grid>
                <Grid item>
                    <FormControl required={isFeature || isFileNameNotEmpty} className={classes.formControl}>
                    <InputLabel shrink={false} className={(isFeature || isFileNameNotEmpty) ? classes.root : classes.label}>
                        Audio Language
                    </InputLabel>
                    <Select
                        value={form ? form.data.language : ''}
                        error={(isFeature || isFileNameNotEmpty) && errorsEnabled && form.errors.languageError}
                        onChange={(e) => this.props.handleChange('language', e, index)}
                        MenuProps={{
                        classes: { paper: classes.dropDownStyle },
                        getContentAnchorEl: null,
                        anchorOrigin: {
                            vertical: "bottom",
                            horizontal: "left"
                        }
                        }}
                        style={{
                        marginTop: 44,
                        width: "12vw"
                        }}
                    > {
                        this.props.languages.map((language, index) => (
                           <MenuItem key={`${language.iso} - ${index}`} value={language.iso}>
                               <Typography noWrap={true}>{language.name}</Typography>
                           </MenuItem>
                        ))
                    }
                    </Select>
                    </FormControl>
                </Grid>
                <Grid item>
                    <FormControl required={isFeature || isFileNameNotEmpty} className={classes.formControl}>
                    <InputLabel shrink={false} className={(isFeature || isFileNameNotEmpty) ? classes.root : classes.label}>
                        Dynamic Range
                    </InputLabel>
                    <Select
                        value={form ? form.data.dynamicRange : ''}
                        error={errorsEnabled && (isFeature || isFileNameNotEmpty) &&  form.errors.dynamicRangeError}
                        onChange={(e) => this.props.handleChange('dynamicRange', e, index)}
                        MenuProps={{
                        classes: { paper: classes.dropDownStyle },
                        getContentAnchorEl: null,
                        anchorOrigin: {
                            vertical: "bottom",
                            horizontal: "left"
                        }
                        }}
                        style={{
                        marginTop: 44,
                        width: "12vw"
                        }}
                    >
                        <MenuItem value="HDR">
                            <Typography>HDR</Typography>
                        </MenuItem>
                        <MenuItem value="SDR">
                            <Typography>SDR</Typography>
                        </MenuItem>
                    </Select>
                    </FormControl>
                </Grid>
                <Grid item>
                    <FormControl required={(isFeature || isFileNameNotEmpty) && !isDisable} disabled={isDisable} className={classes.formControl}>
                    <InputLabel shrink={false} className={(isFeature || isFileNameNotEmpty) ? classes.root : classes.label}>
                        Max CLL
                    </InputLabel>
                    <TextField
                        value={form && !isDisable ? form.data.maxCll : ''}
                        disabled={isDisable}
                        error={errorsEnabled && (isFeature || isFileNameNotEmpty) &&  form.errors.maxCllError}
                        type="number"
                        onChange={(e) => this.props.handleChange('maxCll', e, index)}
                        style={{
                        marginTop: 48,
                        width: "8vw"
                        }}
                    />
                    </FormControl>
                </Grid>
                <Grid item>
                    <FormControl required={(isFeature || isFileNameNotEmpty)  && !isDisable} disabled={isDisable}  className={classes.formControl}>
                    <InputLabel shrink={false} className={(isFeature || isFileNameNotEmpty) ? classes.root : classes.label}>
                        Max FALL
                    </InputLabel>
                    <TextField
                        value={form && !isDisable ? form.data.maxFall : ''}
                        disabled={isDisable}
                        error={errorsEnabled && (isFeature || isFileNameNotEmpty) && form.errors.maxFallError}
                        type="number"
                        onChange={(e) => this.props.handleChange('maxFall', e, index)}
                        style={{
                        marginTop: 48,
                        width: "8vw"
                        }}
                    />
                    </FormControl>
                </Grid>
                <Grid item>
                    <FormControl required={(isFeature || isFileNameNotEmpty)  && !isDisable} disabled={isDisable} className={classes.formControl}>
                    <InputLabel shrink={false} className={(isFeature || isFileNameNotEmpty) ? classes.root : classes.label}>
                        Mastering Display Preset
                    </InputLabel>
                    <Select
                        value={form ? form.data.displayPreset : ''}
                        error={errorsEnabled && (isFeature || isFileNameNotEmpty) && form.errors.displayPresetError}
                        onChange={(e) => this.props.handleChange('displayPreset', e, index)}
                        MenuProps={{
                        classes: { paper: classes.dropDownStyle },
                        getContentAnchorEl: null,
                        anchorOrigin: {
                            vertical: "bottom",
                            horizontal: "left"
                        }
                        }}
                        style={{
                            marginTop: 44,
                            width: "12vw"
                        }}
                    >
                    {
                        displayPresetValues.map((value) => (
                            <MenuItem key={value} value={value}>
                                <Typography noWrap={true}>{value}</Typography>
                            </MenuItem>
                        ))
                    }
                    </Select>
                    </FormControl>
                </Grid>
                <Grid item>
                    <IconButton style={{ marginTop: 12 }} onClick={(e) => this.props.handleDeleteRow(index)}>
                        <Close />
                    </IconButton>
                </Grid>
            </Grid>
      );
  }

  handleExpansionPanel = () => {
      this.setState((prevState) => {
          return {
              expanded: !prevState.expanded
          }
      });
  }

  getExpansionSummaryComponent = (isExpanded, classes) => {
    return (
        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />} 
            className={isExpanded ? classes.focused: classes.label}>
            Additional Audio Tracks
        </ExpansionPanelSummary>
    )
  }

  render() {
    const applyCardFocusClass = this.props.errorsEnabled &&
                                this.props.focusErrorHandler(this.props.fieldError, null);
    const defaultFocusClass = applyCardFocusClass ? applyCardFocusClass : 'normal';
    const classes = this.props.classes;
    const isExpanded = this.state.expanded;
    return (
      <Grid container component={ExpandableSection} header={this.props.header}
        defaultExpanded={true} focusErrorCssStyle={defaultFocusClass}>
            <Grid container className="data-pane"
                justify="space-between"
            >
                <Grid item container direction="column" justify="space-between">
                {
                    this.props.dataFormRow.map((item, index) => {
                        let FormRow = this.formRow(item, index);
                        return (<div key={index}>{FormRow}</div>);
                    })
                }
                </Grid>
                <Grid item container
                    justify="space-between">
                    <Grid item>
                        <Button style={{ marginTop: 16 }} color="primary" onClick={this.props.handleAddRow}>
                            <Add style={{ marginRight: 12 }} />
                            ADD NEW ROW
                        </Button>
                    </Grid>
                </Grid>
                {
                    /* Additional Audio Tracks Component */
                    (<Grid item component={ExpandableSection} header="additional audio data" 
                        defaultExpanded={isExpanded} onChange={this.handleExpansionPanel}
                        expansionSummary={this.getExpansionSummaryComponent(isExpanded, classes)}>
                            <TwoSelectDataForm componentName={ this.props.componentName + "additionalaudio" }
                                files={this.props.files}
                                languages={this.props.languages}
                                validateForm={this.props.validateForm}
                                focusErrorHandler={this.props.focusErrorHandler}
                                updateErrors={this.props.updateErrors}
                                errorsEnabled={this.props.errorsEnabled}
                                handleChildChange={this.props.handleChildChange}
                                rowLimit={10}
                                defaultState={{
                                    data: {
                                        filename: '',
                                        language: '',
                                    }
                                }}
                            />
                        </Grid>)
                }
            </Grid>
      </Grid>
    );
  }
}

const styledDataForm = withStyles(styles)(ManifestDataForm);
const componentWithMethods = withHandleMethods(styledDataForm)
export { componentWithMethods as ManifestDataForm };
