import React, { Component } from 'react';
import { Grid, FormControl, InputLabel } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import { ExpandableSection } from '../common';
import { FileEditableDropdown } from '../FileEditableDropdown/FileEditableDropdown';

const style = (theme) => ({
    root: {
        fontSize: 14,
        color: "#5e5edb",
        fontWeight: 700,
    },
    normal: {
        fontSize: 14,
        color: "#333333",
        fontWeight: 700,
    },
    formControl: {
        position: 'relative',
        marginRight: 36,
    }
});

const initialState = {
    seasonBackground: {},
    seriesBackground: {},
    seriesBoxCover: {},
    seriesKeyArt: {}
};

class SeriesArt extends Component {

    constructor(props) {
        super(props);
        let artwork = props.value || {};
        this.state = {
            ...initialState,
            ...artwork,
        }
    }

    handleChange = (label, e) => {
        let data = this.getEbucoreForFilenames(e.target.value) || {};
        this.setState({
            [label]: data,
        }, () => {
            this.props.onChange(this.state);
        });
    }

    getEbucoreForFilenames = (filename) => {
        if(!filename) {
            return null
        }
       let ebucore = _.find(this.props.files, { filename });
       if(!ebucore) {
           return {
               filename: filename || ''
           }
       }
       return {
           ebucore,
           filename
       };
    }

    render() {
        let { classes } = this.props;
        let defaultFocusClass = 'normal';
        return (
            <Grid container component={ExpandableSection} header="series key" 
                defaultExpanded={true} focusErrorCssStyle={defaultFocusClass}>
                    <Grid container direction="row" justify="space-between">
                    <Grid item>
                        <FormControl>
                            <InputLabel className={classes.normal}>Season Background</InputLabel>
                            <FileEditableDropdown
                                onChange={this.handleChange}
                                field={'seasonBackground'}
                                files={this.props.files}
                                value={this.state.seasonBackground.filename}
                                style={{
                                    marginTop: 40,
                                    width: '20vw'
                                }}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item>
                        <FormControl>
                            <InputLabel className={classes.normal}>Series Background</InputLabel>
                            <FileEditableDropdown
                                onChange={this.handleChange}
                                field={'seriesBackground'}
                                files={this.props.files}
                                value={this.state.seriesBackground.filename}
                                style={{
                                    marginTop: 40,
                                    width: '20vw'
                                }}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item>
                        <FormControl>
                            <InputLabel className={classes.normal}>Series Key Art</InputLabel>
                            <FileEditableDropdown
                                onChange={this.handleChange}
                                field={'seriesKeyArt'}
                                files={this.props.files}
                                value={this.state.seriesKeyArt.filename}
                                style={{
                                    marginTop: 40,
                                    width: '20vw'
                                }}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item>
                        <FormControl>
                            <InputLabel className={classes.normal}>Series Box Cover</InputLabel>
                            <FileEditableDropdown
                                onChange={this.handleChange}
                                field={'seriesBoxCover'}
                                files={this.props.files}
                                value={this.state.seriesBoxCover.filename}
                                style={{
                                    marginTop: 40,
                                    width: '20vw'
                                }}
                            />
                        </FormControl>
                    </Grid>
                </Grid>
            </Grid>
        )
    }
}

const styled = withStyles(style)(SeriesArt);
export { styled as SeriesArt }