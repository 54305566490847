import React from 'react';
import { Grid } from '@material-ui/core';
import { TitleDetails } from '../common';
import { clientShow } from '../Clientshow/clientshow';
import { MovielabsFeatureTitlePane, MovielabsTvTitlePane } from './';
import moment from 'moment';
import { getNormalizedProductType } from '../../_helpers/titleHelper';

function displayDate(dateStr) {
    let momentDateObj = moment(dateStr);
    return momentDateObj.isValid() ? momentDateObj.format("MM/DD/YYYY") : dateStr;
}

const MovielabsTitlePane = (props) => {
    let { presentedTitle } = props;
    let productType = presentedTitle ? getNormalizedProductType(presentedTitle.productType) : '';
    return (
        <Grid container component={TitleDetails}
        direction="column" justify="space-between">
            {
                productType.toLowerCase() === 'feature' ? (
                    <MovielabsFeatureTitlePane
                        {...props}
                        presentedTitle={presentedTitle}
                        displayDate={displayDate}
                    />
                ) : (
                    <MovielabsTvTitlePane
                        {...props}
                        presentedTitle={presentedTitle}
                        displayDate={displayDate}
                    />
                )
            }
        </Grid>
    )
}

const clientConnected = clientShow(MovielabsTitlePane, 'MovielabsTitlePane');
export { clientConnected as MovielabsTitlePane };

