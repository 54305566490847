import React from 'react';
import { connect } from 'react-redux';
import { Grid } from '@material-ui/core';
import { TitleDetails, TitleField } from '../common';
import { TitleSearchList } from '../TitleSearchList/TitleSearchList';
import moment from 'moment';
import { clientShow } from '../Clientshow/clientshow';
import { TitleActions } from '../../_actions/title.actions';

function displayDate(dateStr) {
    let momentDateObj = moment(dateStr);
    return momentDateObj.isValid() ? momentDateObj.format("MM/DD/YYYY") : dateStr;
}

const displayTitleDetails = (presentedTitle) => {
    return (
        <Grid container component={TitleDetails}
        direction="column" justify="space-between">

            <TitleField label="Title" field="name"
                title={presentedTitle}
                warning="No Title found"
            />

            <TitleField label="Runtime" field="runtime"
                title={presentedTitle}
                warning="No Runtime found"
            />

            <TitleField label="Release Date" field="initialReleaseDate"
                title={presentedTitle}  formatFunc={displayDate}
                warning="No Release Date found"
            />

            <TitleField label="Release Year" field="releaseYear"
                title={presentedTitle}
                warning="No Release Year found"
            />

            <TitleField label="GPMS Alpha ID" field="alphaId"
                title={presentedTitle}
                warning="No GPMS Alpha ID found"
            />

        </Grid>
    );

}

const DbbBulkTitlePane = (props) => {
    let { presentedTitle, changingTitleInProgress } = props;
    if (!changingTitleInProgress) {
        return displayTitleDetails(presentedTitle);
    } else {
        return (<TitleSearchList
          client={props.client}
          titles={props.titles}
          onTitleSelected={props.selectTitle}
        />);
    }
}

const mapDispatchToProps = (dispatch) => {
    let { selectTitle } = TitleActions;

    return {
        selectTitle: (title, index) => {
            dispatch(selectTitle(title, index));
        }
    }
}

const clientConnected = clientShow(DbbBulkTitlePane, 'DbbBulkTitlePane');
const connected = connect(null, mapDispatchToProps)(clientConnected);
export { connected as DbbBulkTitlePane };