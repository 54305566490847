import React from "react";

const SvgSearch = props => (
  <svg viewBox="0 0 16 16" {...props} width="1em" height="1em">
    <path
      d="M5.1 1.4c0-.3.3-.4.6-.4 2.5.5 3.8 2.1 4.3 3.3.1.3-.1.6-.4.6L9 5c-.2 0-.4-.1-.5-.3-.3-.6-1.2-1.9-3.1-2.2-.3-.1-.4-.3-.4-.6l.1-.5zm5.4 7.8c.6-.8 1-1.8 1.1-2.9.3-3.2-2.1-6-5.3-6.3C3.1-.3.3 2.1 0 5.3c-.3 3.2 2.1 6 5.3 6.3 1.4.1 2.8-.3 3.8-1l5.6 5.4c.2.1.4.1.5-.1l.7-.9c.1-.2.1-.4-.1-.5.1-.1-1.7-1.8-5.3-5.3z"
      fill="currentColor"
    />
  </svg>
);

export default SvgSearch;
