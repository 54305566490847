import React from 'react';
import { ExpansionPanel, ExpansionPanelSummary, ExpansionPanelDetails, 
  Typography, Divider, Grid } from "@material-ui/core";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

export const ExpandableSection = (props) => {
  let { header = '', defaultExpanded, focusErrorCssStyle, disabled=false, 
  expansionSummary = null, testId = '' } = props;
  let isExpanded = defaultExpanded ? defaultExpanded : true;
  let defaultFocusClass = focusErrorCssStyle || '';

  return (
    <ExpansionPanel defaultExpanded={isExpanded} disabled={disabled} data-testid={testId}
    onChange={props.onChange} className={`panel ${defaultFocusClass}`}> 
      {
        expansionSummary ? expansionSummary : 
        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
          <Grid container direction="row" justify="space-between">
            <Grid item>
              <Typography variant="h5" component="h5">
                {header.toUpperCase()}
              </Typography>
            </Grid>
            {
              props.renderButtons ? props.renderButtons() : null
            }
          </Grid>
        </ExpansionPanelSummary>
      }
      <Divider />
      <ExpansionPanelDetails>
        { props.children }
      </ExpansionPanelDetails>
    </ExpansionPanel>
  );
}