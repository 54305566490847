import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { userActions, alertActions } from '../_actions';
import styles from './login.styles.scss';
import { Button, TextField, Grid, Fab } from '@material-ui/core';
import Logo from '../assets/logos/purpleLogo';
import AlertIcon from '../assets/icons/Alert';

class LoginPage extends React.Component {
  constructor(props) {
    super(props);

    // reset login status
    this.props.dispatch(userActions.logout());
    this.props.dispatch(alertActions.clear());

    this.state = {
      username: '',
      password: '',
      submitted: false
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  static getDerivedStateFromProps(props, state) {
    let location = props.location;
    if (!location.state || !location.state.from) {
      return null;
    }

    if (location.state.from !== state.prevPage) {
      return {
        prevPage: props.location.state.from
      };
    }

    return null;
  }

  handleChange(e) {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  }

  handleSubmit(e) {
    e.preventDefault();

    this.setState({ submitted: true });
    const { username, password } = this.state;
    const { dispatch } = this.props;
    if (username && password) {

      let redirectTo = '/';
      if (this.state.prevPage) {
        let { pathname, search } = this.state.prevPage;
        redirectTo = `${[pathname]}${search}`
      }

      dispatch(userActions.login(username, password, redirectTo));
    }
  }

  render() {
    const { loggingIn, alert } = this.props;
    const { username, password, submitted } = this.state;
    const disableButton = !username.length || !password;
    return (
      <Grid container className="login-wrapper" direction="column" wrap="nowrap">
        <section className="login-box">
          <Grid container className="inner">
          <Logo className="logo" color="#0e0ea1"/>

            <form name="form" onSubmit={this.handleSubmit}>
              <TextField className="username" name="username" required={true} value={username} onChange={this.handleChange} className="form-control" placeholder="Username" inputProps={{ 'aria-label': 'Username'}} />
              <TextField className="password" type="password" required={true} name="password" value={password} onChange={this.handleChange} className="form-control" placeholder="Password" inputProps={{ 'aria-label': 'Password'}} />
              <Fab type="submit" variant="extended" disabled={disableButton} className="login-button" color="primary">Log in</Fab>
            </form>
            { alert.message &&
              <section className="info-display error-display">
                <AlertIcon className="icon"/><span className="alert-message">{alert.message}</span>
              </section>
            }
          </Grid>
        </section>

        <Grid container component="section" justify="center">
          <Grid item component={Button} color="primary" href="https://sites.sonypictures.com/corp/privacypolicies/b2b/privacy_ENGLISH.html" target="_blank">Privacy Policy</Grid>
          <Grid item component={Button} color="primary" href="https://www.sonypictures.com/corp/tos.html" target="_blank">Terms of Usage</Grid>
        </Grid>
      </Grid>
    );
  }
}

function mapStateToProps(state) {
  const { alert } = state;
  const { loggingIn } = state.authentication;
  return {
    loggingIn,
    alert
  };
}

const connectedLoginPage = connect(mapStateToProps)(LoginPage);
export { connectedLoginPage as LoginPage };
