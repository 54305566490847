import { fileConstants } from '../_constants';
import { FileService } from '../_services/file.service';

const processingFiles = (files) => ({
  type: fileConstants.PROCESSING_FILES,
  files,
});

const filesAdded = (files) => ({
  type: fileConstants.FILES_ADDED,
  files,
});

export const FileActions = {
  removeFile: (file) => ({
    type: fileConstants.FILE_REMOVED,
    file,
  }),

  addFiles: (files) => {
    return (dispatch) => {
      dispatch(processingFiles(files));
      FileService.processFiles(files).then((resp) => {
        dispatch(filesAdded(resp.data.files));
      });
    };
  },
};
