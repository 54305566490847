import React from 'react';
import { Grid, MenuItem, Typography } from "@material-ui/core";
import { Dropdown } from "../common";
import _ from 'lodash';
import { withStyles } from '@material-ui/styles';
import PropTypes from 'prop-types';

const subtitleFormats =  ["BDN PNG+XML", "CAP", "DFXP", "FCP PNG+XML", "IMSC1", "ITT", "Netflix STL", "Rhozet PNG+XML", 
"SMPTE-TT XML", "SRT", "STL", "TTML", "SCC", "SMPTE-TT 608", 
"Starz SCC", "Netflix SCC", "None"];

const contentTypes = ["Full", "Forced", "Full w/Forced Removed", "Full - SDH",
"CC", "CC-SFX only", "CC w/Forced Removed"];

const fileLayouts = ["Discrete", "ImageArchive", "TextArchive", "Embedded"];

const style = (theme) => ({
    formControl: {
        top: 2,
        position: 'relative',
    },
    label: {
        fontSize: 14,
        color: "#333333",
        fontWeight: 700,
    }
})

function checkIfComponentHasError(label, props) {
    return props.isFilenameEmpty && _.isEmpty(props[label]);
}

const SubtitleInfo = (props) => {
    let { classes, onChange, isFilenameEmpty, errorsEnabled } = props;
    return (
        <Grid container direction="row" justify="space-between">
                <Grid item component={Dropdown}
                    label="Subtitle Format"
                    required={isFilenameEmpty}
                    field={'format'}
                    onChange={onChange}
                    value={props.format}
                    error={errorsEnabled && checkIfComponentHasError('format', props)}
                >
                {
                    _.map(subtitleFormats, (format, index) => {
                        return (
                            <MenuItem key={index} value={format}>
                                <Typography noWrap={true}>{format}</Typography>
                            </MenuItem>
                        )
                    })
                }
            </Grid>
            <Grid item component={Dropdown}
                    label="Content Type"
                    required={isFilenameEmpty}
                    field={'contentType'}
                    onChange={onChange}
                    value={props.contentType}
                    error={errorsEnabled && checkIfComponentHasError('contentType', props)}
                >
                {
                    _.map(contentTypes, (type, index) => {
                        return (
                            <MenuItem key={index} value={type}>
                                <Typography noWrap={true}>{type}</Typography>
                            </MenuItem>
                        )
                    })
                }
            </Grid>
            <Grid item component={Dropdown}
                    label="Subtitle File Layout"
                    required={isFilenameEmpty}
                    field={'fileLayout'}
                    onChange={onChange}
                    value={props.fileLayout}
                    error={errorsEnabled && checkIfComponentHasError('fileLayout', props)}
                >
                {
                    _.map(fileLayouts, (layout, index) => {
                        return (
                            <MenuItem key={index} value={layout}>
                                <Typography noWrap={true}>{layout}</Typography>
                            </MenuItem>
                        )
                    })
                }
            </Grid>
        </Grid>
    )
}

const styled = withStyles(style)(SubtitleInfo);
export { styled as SubtitleInfo };