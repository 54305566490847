import React from 'react';
import SeasonDownload from './SeasonDownload';
import { clientShow } from '../Clientshow/clientshow';


const RokuCablelabsDownload = (props) => {
  if (props.isSeason) {
    return (<SeasonDownload {...props} />);
  }

  return props.children;
}

let clientConnected = clientShow(RokuCablelabsDownload, 'RokuCablelabsDownload');
export { clientConnected as RokuCablelabsDownload }