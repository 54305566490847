export default [
  "English",
  "None",
  "Abkhazian",
  "Afghan/Pashto",
  "Afrikaans",
  "Albanian",
  "Amharic",
  "Arabic",
  "Bangla / Bengali",
  "Belgium Dual",
  "Bhojpuri",
  "Bokmål, Norwegian; Norwegian Bokmål",
  "Bosnian",
  "BPort-LAS",
  "Bulgarian",
  "Burmese",
  "Catalan",
  "Chinese - Simplified",
  "Chinese - Traditional",
  "Chinese (Cantonese)",
  "Chinese (Mandarin Simplified) [Text]",
  "Chinese (Mandarin Traditional) [Text]",
  "Chinese (Mandarin, PRC)",
  "Chinese (Mandarin, Singapore)",
  "Chinese (Mandarin, Taiwan)",
  "Chinese (Mandarin, Taiwanese)",
  "Chinese (Taiwanese)",
  "Corsican",
  "Croatian",
  "Czech",
  "Dari - Afghan Persian",
  "Danish",
  "Dutch (Belgium)",
  "Dutch (Flemish)",
  "Dutch (Netherlands)",
  "Dzongkha",
  "English",
  "English (UK)",
  "Esperanto",
  "Estonian",
  "Faroese",
  "Finnish",
  "Flemish",
  "French (Belgium)",
  "French - Continental",
  "French (Canadian)",
  "French (Parisian)",
  "Gaelic (Irish)",
  "Gaelic (Scots)",
  "Georgian",
  "German (Austrian)",
  "German (Germany)",
  "German (Swiss)",
  "Greek",
  "Haitian; Haitian Creole",
  "Hebrew",
  "Hindi",
  "Hungarian",
  "Icelandic",
  "Indonesian / Bahasa",
  "Inuktitut",
  "Italian",
  "Japanese",
  "Kannada",
  "Kazakh",
  "Khmer",
  "Korean",
  "Kurdish",
  "Lao",
  "Latvia Dual",
  "Latvian",
  "Lebanon Dual",
  "Lithuanian",
  "Macedonian",
  "Malay",
  "Malayalam",
  "Maori",
  "Marathi",
  "Mongolian",
  "Nauvhal",
  "Nepali",
  "Northern Sotho",
  "Norwegian",
  "Not Texted",
  "Persian / Farsi",
  "Polish",
  "Portuguese (Brazil)",
  "Portuguese (Portugal)",
  "Punjabi",
  "Romanian",
  "Romany",
  "Russian (Russia)",
  "Russian (Ukraine)",
  "Samoan",
  "Serbian",
  "Serbo-Croatian",
  "Sindhi",
  "Sinhalese",
  "Slovak",
  "Slovene",
  "Slovenian",
  "Somali",
  "Sotho, Southern",
  "Spanish (Argentinean)",
  "Spanish (Castilian)",
  "Spanish (Chilean)",
  "Spanish (Colombian)",
  "Spanish (Cuban)",
  "Spanish (Latin Am)",
  "Spanish (Mexican)",
  "Spanish (Puerto Rican)",
  "Swahili",
  "Swedish",
  "Tagalog",
  "Tamil",
  "Telugu",
  "Thai",
  "Tibetan",
  "Turkish",
  "Ukrainian",
  "Urdu",
  "Vietnamese",
  "Welsh",
  "Yiddish",
  "Yugoslavian",
  "Zulu",
];
