import React from 'react';
import { Grid } from '@material-ui/core';
import { TitleDetails, TitleField } from '../common';
import { clientShow } from '../Clientshow/clientshow';
import { TitleLanguage } from '../TitleDisplay';
import { getNormalizedProductType } from '../../_helpers/titleHelper';

const DirectTvTitlePane = (props) => {
    let { presentedTitle:title } = props;
    let productType = title ? getNormalizedProductType(title.productType) : '';
    return (
        <Grid item>

            <Grid item component={TitleLanguage}
                client={props.client}
                language={props.language}
                onLanguageChange={props.onLanguageChange}
            />

            <Grid container component={TitleDetails}
                direction="column" justify="space-between">

                <TitleField label="Title" field="name"
                    title={title}
                    warning="No Title found"
                />

                <TitleField label="Runtime" field="runtime"
                    title={title}
                    warning="No Runtime found"
                />
                {
                    productType.toLowerCase() === 'feature' ? (
                        <TitleField label="MPAA Rating" field="rating"
                            title={title}
                            warning="No Rating found"
                        />
                    ) : (
                        <TitleField label="TV Rating" field="tvRatingInitialAiring"
                            title={title}
                            warning="No Rating found"
                        />
                    )
                }

                <TitleField label="Director" field="director"
                    subfield="name"
                    title={title}
                    warning="No Directors found"
                />

                <TitleField label="Actors" field="actors"
                    subfield="name"
                    title={title}
                    warning="No Actors found"
                />

                <TitleField label="Synopsis" field="synopsis"
                    subfield="text"
                    title={title}
                    warning="No Synopsis found"
                />

                <TitleField label="Genres" field="genres"
                    title={title}
                    warning="No Genres found"
                />

            </Grid>
        </Grid>
    )
}

let clientConnected = clientShow(DirectTvTitlePane, 'DirectTvTitlePane')
export { clientConnected as DirectTvTitlePane };