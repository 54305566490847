import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import { TitleField } from '../common';

const MovielabsTvTitlePane = (props) => {
    let { presentedTitle, displayDate } = props;
    let season = null, series = null;
    if (presentedTitle) {
        season = presentedTitle.season;
        series = presentedTitle.series;
    }

    return (
        <Grid container>
            <TitleField label="Title" field="name"
                title={presentedTitle}
                warning="No Title found"
            />

            <TitleField label="Runtime" field="runtime"
                title={presentedTitle}
                warning="No Runtime found"
            />

            <TitleField label="Ratings" field="rating"
                title={presentedTitle}
                warning="No Ratings found"
            />

            <TitleField label="Alpha EIDR" field="alphaEidr"
                title={presentedTitle}
                warning="No Alpha EIDR found"
            />

            <TitleField label="Product EIDR" field="productEidr"
                title={presentedTitle}
                warning="No Product EIDR found"
            />

            <TitleField label="Release Date" field="initialReleaseDate"
                title={presentedTitle}
                formatFunc={displayDate}
                warning="No Release Date found for this title"
            />

            <TitleField label="Release Year" field="releaseYear"
                title={presentedTitle}
                warning="Release Year not found"
            />

            <TitleField label="Copyright Line" field="copyrightLine"
                title={presentedTitle}
            />

            <TitleField label="Country of Origin" field="countryOfOrigin"
                title={presentedTitle}
            />

            <TitleField label="Genres" field="genres"
                title={presentedTitle}
                warning="No Genres found for this title"
            />

            <TitleField label="Actors" field="actors"
                subfield="name"
                title={presentedTitle}
                warning="No Actors found for this title"
            />

            <TitleField label="Director" field="directors"
                subfield="name"
                title={presentedTitle}
                warning="No Directors found for this title"
            />

            <TitleField label="Producers" field="producers"
                subfield="name"
                title={presentedTitle}
                warning="No Producers found for this title"
            />

            <TitleField label="Screenplay/Writers" field="screenplay"
                subfield="name"
                title={presentedTitle}
                warning="No Screenplay/Writers found for this title"
            />

            <TitleField label="Composer" field="composers"
                subfield="name"
                title={presentedTitle}
                warning="No Composer found for this title"
            />

            <TitleField label="Original Language" field="originalLanguage"
                title={presentedTitle}
                warning="No Original Language found for this title"
            />

            <TitleField label="Synopsis (190)" field="shortSynopsis"
                subfield="text"
                title={presentedTitle}
                warning="No Synopsis (190) found for this title"
            />

            <TitleField label="Synopsis (400)" field="moderateSynopsis"
                subfield="text"
                title={presentedTitle}
                warning="No Synopsis (400) found for this title"
            />

            <TitleField label="Synopsis (4000)" field="longSynopsis"
                subfield="text"
                title={presentedTitle}
                warning="No Synopsis (4000) found for this title"
            />

            {/* Season Data */}

            <Grid item>
                <Typography variant="h5" component="h5"
                className="season-data-header">SEASON DATA</Typography>

                <TitleField label="Season Name" field="season"
                    subfield="name"
                    title={presentedTitle}
                    warning="Season Name not found for this season"
                />

                <TitleField label="Season EIDR" field="season"
                    subfield="seasonEidr"
                    title={presentedTitle}
                    warning="Season EIDR not found for this season"
                />

                <TitleField label="Release Date" field="season"
                    subfield="initialReleaseDate"
                    title={presentedTitle}
                    formatFunc={displayDate}
                    warning="Theatrical Release Date not found for this season"
                />

                <TitleField label="Release Year" field="season"
                    subfield="releaseYear"
                    title={presentedTitle}
                    warning="Release Year not found for this season"
                />

                <TitleField label="Copyright Line" field="season"
                    subfield="copyrightLine"
                    title={presentedTitle}
                />

                <TitleField label="Country of Origin" field="season"
                    subfield="countryOfOrigin"
                    title={presentedTitle}
                />

                <TitleField label="Genres" field="season"
                    subfield="genres"
                    title={presentedTitle}
                    warning="No Genres found for this season"
                />

                <TitleField label="Actors" field="actors"
                    subfield="name"
                    title={season}
                    warning="No Actors found for this season"
                />

                <TitleField label="Guest Stars" field="guestStars"
                    subfield="name"
                    title={season}
                    warning="No Guest Stars found for this season"
                />

                <TitleField label="Creators" field="creators"
                    subfield="name"
                    title={season}
                    warning="No Creators found for this season"
                />

                <TitleField label="Directors" field="directors"
                    subfield="name"
                    title={season}
                    warning="No Directors found for this season"
                />

                <TitleField label="Producers" field="producers"
                    subfield="name"
                    title={season}
                    warning="No Producers found for this season"
                />

                <TitleField label="Composer" field="composers"
                    subfield="name"
                    title={season}
                    warning="No Composer found for this season"
                />

                <TitleField label="Screenplay/Writers" field="screenplay"
                    subfield="name"
                    title={season}
                    warning="No Screenplay/Writers found for this season"
                />

                <TitleField label="Original Language" field="originalLanguage"
                    title={presentedTitle}
                    warning="No Original Language found for this season"
                />

                <TitleField label="Synopsis (190)" field="shortSynopsis"
                    title={season}
                    warning="No Synopsis (190) found for this season"
                />

                <TitleField label="Synopsis (400)" field="moderateSynopsis"
                    title={season}
                    warning="No Synopsis (400) found for this season"
                />

                <TitleField label="Synopsis (4000)" field="longSynopsis"
                    title={season}
                    warning="No Synopsis (4000) found for this season"
                />

                {/* Series Data */}

            
                <Typography variant="h5" component="h5"
                 className="season-data-header">SERIES DATA</Typography>
 
                <TitleField label="Series Name" field="series"
                     subfield="name"
                     title={presentedTitle}
                     warning="Series Name not found for this series"
                />

                <TitleField label="Series EIDR" field="series"
                    subfield="seriesEidr"
                    title={presentedTitle}
                    warning="Series EIDR not found for this series"
                />

                <TitleField label="Release Date" field="series"
                    subfield="initialReleaseDate"
                    title={presentedTitle}
                    formatFunc={displayDate}
                    warning="Theatrical Release Date not found for this series"
                />

                <TitleField label="Release Year" field="series"
                    subfield="releaseYear"
                    title={presentedTitle}
                    warning="Release Year not found for this series"
                />

                <TitleField label="Country of Origin" field="series"
                    subfield="countryOfOrigin"
                    title={presentedTitle}
                />

                <TitleField label="Genres" field="series"
                    subfield="genres"
                    title={presentedTitle}
                    warning="No Genres found for this series"
                />

                <TitleField label="Actors" field="actors"
                    subfield="name"
                    title={series}
                    warning="No Actors found for this series"
                />

                <TitleField label="Guest Stars" field="guestStars"
                    subfield="name"
                    title={series}
                    warning="No Guest Stars found for this series"
                />

                <TitleField label="Creators" field="creators"
                    subfield="name"
                    title={series}
                    warning="No Creators found for this series"
                />

                <TitleField label="Directors" field="directors"
                    subfield="name"
                    title={series}
                    warning="No Directors found for this series"
                />

                <TitleField label="Producers" field="producers"
                    subfield="name"
                    title={series}
                    warning="No Producers found for this series"
                />

                <TitleField label="Composer" field="composers"
                    subfield="name"
                    title={series}
                    warning="No Composer found for this series"
                />

                <TitleField label="Screenplay/Writers" field="screenplay"
                    subfield="name"
                    title={series}
                    warning="No Screenplay/Writers found for this series"
                />

                <TitleField label="Original Language" field="originalLanguage"
                    title={presentedTitle}
                    warning="No Original Language found for this series"
                />

                <TitleField label="Synopsis (190)" field="shortSynopsis"
                    title={series}
                    warning="No Synopsis (190) found for this series"
                />

                <TitleField label="Synopsis (400)" field="moderateSynopsis"
                    title={series}
                    warning="No Synopsis (400) found for this series"
                />

                <TitleField label="Synopsis (4000)" field="longSynopsis"
                    title={series}
                    warning="No Synopsis (4000) found for this series"
                />

            </Grid>
        </Grid>
    )
}

export { MovielabsTvTitlePane };