import _ from "lodash";
import { clientspecConstants } from "../_constants/clientspec.constants";
import { ClientspecService } from "../_services/clientspec.service";
import { FileService } from "../_services/file.service";
import { history } from "../_helpers/history";
let {
  FETCHING_SPEC,
  SPEC_FETCHED,
  SUBMITTING_PACKAGE,
  PACKAGE_SUBMITTED,
} = clientspecConstants;

const redirectTo = (pathname) => {
  let location = { pathname };
  history.push(location);
};

const fetchSpec = (title, client, dataFilter, files = [], redirect = false) => {
  return (dispatch) => {
    dispatch(fetchingSpec(title, client, dataFilter, files));
    let promisesToKeep = [
      ClientspecService.getSpec(title.externalId, client.id, dataFilter),
    ];

    if (files && files.length) {
      promisesToKeep.push(FileService.processFiles(files));
    }

    Promise.all(promisesToKeep).then((promises) => {
      let dataArray = _.map(promises, "data");
      let payload = Object.assign({}, ...dataArray);

      dispatch(specFetched(payload));
      if (redirect) {
        redirectTo("/package-data");
      }
    });
  };
};

const submitSpec = (packageData, client, titleIds) => {
  return (dispatch) => {
    dispatch(submittingPackageData(packageData));
    console.log("package data: ", packageData);
    ClientspecService.submitSpec(packageData, client, titleIds).then((resp) => {
      console.log("Resp:  ", resp);
      dispatch(specSubmitted(resp.data));
      redirectTo("/download");
    });
  };
};

const fetchingSpec = (title, client, dataFilter, files) => {
  let payload = {
    title,
    client,
    dataFilter,
    files,
  };
  return {
    type: FETCHING_SPEC,
    payload,
  };
};

const specFetched = (spec) => ({
  type: SPEC_FETCHED,
  spec,
});

const submittingPackageData = (specPackage) => ({
  type: SUBMITTING_PACKAGE,
  specPackage,
});

const specSubmitted = (payload) => ({
  type: PACKAGE_SUBMITTED,
  payload,
});

export const ClientSpecActions = {
  fetchSpec,
  submitSpec,
};
