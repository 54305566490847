import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import NotifierAction from '../_actions/notifier.actions';
import { history } from '../_helpers/history';
import _ from 'lodash';
import update from 'immutability-helper';
import { withStyles } from '@material-ui/styles';
import { Grid, Paper, Typography, MenuItem, Tabs, Tab, Fab } from '@material-ui/core';
import { ErrorSnackBar } from '../components/SnackBar/ErrorSnackBar';
import { Dropdown, MultiSelect, RadioButton, TabPanel, ConfirmationDialog } from '../components/common';
import { TitleSearch } from '../components/TitleSearch';
import { ProfileService } from '../_services';
import { DataEntryPanel, DataComparisonPanel } from './';


const clientsList = ['Amazon', 'Hulu', 'Microsoft', 'Roku'];
const workflowList = ['AVOD', 'MOD', 'SVOD', 'TVOD'];
const salesTypes = ["Bonus", "w/o Bonus"];

const style = (theme) => ({
    label: {
        fontSize: 14,
        color: '#333333',
        fontWeight: 700,
    },
    labelRequired: {
        fontSize: 14,
        color: "#5e5edb",
        fontWeight: 700,
    },
    radio: {
        paddingLeft: 16,
    },
    paper: {
        marginTop: 24,
    },
    dialogText: {
        color: '#333333',
        fontSize: 14,
    },
    selectionHighlight: {
        color: "#ffffff !important",
        backgroundColor: "#5e5edb"
    }
})

let initState = {
    language: "",
    territories: [],
    title: [{
            source: "",
            type: "",
        }],
    eidr: "yes",
    alphaId: "yes",
    mpmWalkerId: "yes",
    isan: "yes",
    copyrightLine: "yes",
    mpaaRatingReason: "yes",
    synopses: {},
}


class ClientProfileEdit extends Component {
    constructor(props) {
        super(props);
        let id = props.location.state ? props.location.state.id : null;
        this.state = {
            client: "",
            workflows: [],
            salesType: "",
            preorder: false,
            title: null,
            tabIndex: 0,
            languageData: [{...initState}],
            verified: false,
            showModal: false,
            languagesToCompare: [],
            territoriesToCompare: [],
            comparisonData: [],
            id,
        }
    }

    componentDidMount() {
        if(this.state.id) {
            ProfileService.get(this.state.id).then((resp) => {
                this.setState(resp.data);
            })
        }
    }

    areRequiredFieldsEmpty = () => {
        if (!this.state.languageData) {
            return true;
        }

        let { client, workflows, languageData } = this.state;

        return _.isEmpty(client) || !workflows.length ||
               _.some(languageData, (territory) => _.isEmpty(territory.language));
    }

    handleChange = (label, value) => {
        let verified = this.state.verified;
        let newState = Object.assign({}, this.state);

        newState[label] = value;

        if(label.toLowerCase() === 'compare') {
            let { languagesToCompare, comparisonData, territoriesToCompare } = value;
            newState['languagesToCompare'] = languagesToCompare;
            newState['territoriesToCompare'] = territoriesToCompare;
            newState['comparisonData'] = comparisonData;
        }

        switch(label.toLowerCase()) {
            case "client":
            case "workflows":
            case "salesType":
            case "preorder":
            case "title":
                verified = false;
                this.verify(newState).then((exists) => {
                    if(exists) {
                        let msg = 'This Client Profile already exists';
                        this.props.showNotification(true, msg);
                    }
                });
                break;
        }

        this.setState(Object.assign({}, newState, { verified }), () => {
            // console.log(this.state);
        });
    }

    sayYes = () => {
        this.setState({
            verified: true,
            showModal: false,
        });
        this.save(this.state);
    }

    sayNo = () => {
        this.setState({
            showModal: false,
        })
    }

    onCancel = () => {
        // cancel -> goto Client list page
    }

    onSave = () => {
        if (!this.state.verified) {
            this.verify(this.state).then((exists) => {
                if (!exists) {
                    this.save(this.state);
                } else {
                    this.setState({
                        showModal: true,
                    })
                }
            });
        } else {
            this.save(this.state);
        }
    }

    save = (profile) => {
        ProfileService.save(profile).then(()=> {
            this.setState({verified: true, saved: true}, () => {
                setTimeout(() => { // profile might not be available for search just yet
                    history.push({pathname: 'client-profiles'});
                }, 650);
            });
        }).catch((ex) => {
            console.log('couldnt save profile');
        });
    }

    verify = (profile) => {
        return new Promise((resolve, reject) => {
            ProfileService.verify(profile).then(resp => {
                let { exists } = resp.data;
                resolve(exists);
            }).catch(reject);
        });
    }

    setSubmitButtonClassName = () => {
        let classname = "submit-button";
        classname = this.areRequiredFieldsEmpty() ? classname.concat(" disabled") : classname;
        return classname;
    }

    onLanguageDataChange = (data, index) => {
        let languageData = update(this.state.languageData, {
            $splice: [
                [index, 1, data],
            ]
        });

        this.setState({ languageData });
    }

    render() {
        let { client, workflows, salesType, preorder, title, languageData, 
             languagesToCompare, comparisonData, territoriesToCompare } = this.state;

        let selectedLanguages = !_.isEmpty(languageData) ? languageData.map((data) => {
            return data.language;
        }) : [];

        let selectedTerritories = !_.isEmpty(languageData) ? _.uniq(_.flattenDeep(languageData.map(
            (data) => data.territories))) : [];

        let { classes } = this.props;
        return (
            <div className="content package-data">
                <Grid container direction="column" justify="space-between"
                className="card-padding" component={Paper} elevation={8}>
                    <ErrorSnackBar />
                    <Grid item>
                        <Typography variant="h5" component="h5">NEW CLIENT</Typography>
                    </Grid>

                    <Grid container direction="row" justify="space-between">
                        <Grid item component={Dropdown}
                            label="Client"
                            required={true}
                            field={'client'}
                            onChange={this.handleChange}
                            value={client}
                            
                        >
                        {
                            _.map(clientsList, (client, index) => {
                                return (
                                    <MenuItem key={index} value={client}>
                                        <Typography>{client}</Typography>
                                    </MenuItem>
                                )
                            })
                        }
                        </Grid>

                        <Grid item component={Dropdown}
                            label="Workflows"
                            field={'workflows'}
                            value={workflows}
                            onChange={this.handleChange}
                            required={true}
                        >
                        {
                            _.map(workflowList, (workflow, index) => {
                                return (
                                    <MenuItem key={index} value={workflow}>
                                        <Typography>{workflow}</Typography>
                                    </MenuItem>
                                )
                            })
                        }
                        </Grid>

                        <Grid item component={Dropdown}
                            label="Sales Type"
                            field={'salesType'}
                            value={salesType}
                            onChange={this.handleChange}
                            required={true}
                        >
                        {
                            _.map(salesTypes, (type, index) => {
                                return (
                                    <MenuItem key={index} value={type}>
                                        <Typography>{type}</Typography>
                                    </MenuItem>
                                )
                            })
                        }
                        </Grid>

                        <Grid item component={RadioButton}
                            label="PREORDER"
                            value={preorder}
                            handleChange={(checked) => (this.handleChange('preorder', checked))}
                            className={classes.radio}
                        />
                    </Grid>

                    <Grid container component={TitleSearch}
                        onTitleSuggestionSelected={(title) => this.handleChange('title', title)}
                        fullWidth={true}
                        title={title}
                    />

                    <Grid container component={Paper} className={classes.paper}>
                        <Tabs
                            value={this.state.tabIndex}
                            onChange={(e, tabIndex) => this.handleChange('tabIndex', tabIndex)}
                            indicatorColor="primary"
                            textColor="primary"
                        >
                            <Tab label="Enter Data" classes={{
                                selected: classes.selectionHighlight,
                                root: classes.label
                            }}/>
                            <Tab label="Compare Data" classes={{
                                selected: classes.selectionHighlight,
                                root: classes.label
                            }}/>
                        </Tabs>
                        <TabPanel component={DataEntryPanel}
                            currentIndex={this.state.tabIndex}
                            index={0}
                            initState={initState}
                            languageData={languageData}
                            onChange={this.onLanguageDataChange}
                        />
                        <TabPanel component={DataComparisonPanel} 
                            currentIndex={this.state.tabIndex} 
                            index={1}
                            languageData={languageData}
                            selectedLanguages={selectedLanguages}
                            selectedTerritories={selectedTerritories}
                            languagesToCompare={languagesToCompare}
                            territoriesToCompare={territoriesToCompare}
                            comparisonData={comparisonData}
                            onChange={this.handleChange}
                            />
                    </Grid>
                </Grid>

                <React.Fragment>
                    <ConfirmationDialog
                        open={this.state.showModal}
                        onClose={this.sayNo}
                        dialogText="Client Profile already exists. Do you wish to overwrite
                        previous configuration ?"
                        acceptButtonHandler={this.sayYes}
                        cancelButtonHandler={this.sayNo}
                    />
                </React.Fragment>



                <Grid container
                justify="flex-end"
                component="footer" className="footer">
                    <Grid item>
                        <Link to="/client-profiles">
                            <Fab variant="extended" style={{ marginRight: 10 }}  className="previous-button" color="secondary">Previous</Fab>
                        </Link>
                    </Grid>
                    <Grid item>
                        <Fab variant="extended" onClick={this.onSave} disabled={this.areRequiredFieldsEmpty()}
                            className={this.setSubmitButtonClassName()} color="primary">Submit</Fab>
                    </Grid>
                </Grid>
            </div>
        )
    }
}

let mapDispatchToProps = (dispatch) => {
    let showNotification = NotifierAction.showNotification;
    return {
        showNotification: (errorStatus, msg) => {
            dispatch(showNotification(errorStatus, msg, 'warning'));
        }
    }
}

const styledComponent = withStyles(style)(ClientProfileEdit);
const clientConnected = connect(null, mapDispatchToProps)(styledComponent)
export { clientConnected as ClientProfileEdit };