export default {
  "Archive with Text Files": {
    encodingProfile: "Archive with Text Files",
    format: "",
    contentType: "Full",
    fileLayout: "TextArchive",
  },
  "BDN PNG+XML": {
    encodingProfile: "BDN PNG+XML",
    format: "BDN PNG+XML",
    contentType: "Full",
    fileLayout: "ImageArchive",
  },
  "Discrete Subtitle File - CAP": {
    encodingProfile: "Discrete Subtitle File - CAP",
    format: "CAP",
    contentType: "",
    fileLayout: "Discrete",
  },
  "Discrete Subtitle File - ITT": {
    encodingProfile: "Discrete Subtitle File - ITT",
    format: "ITT",
    contentType: "",
    fileLayout: "Discrete",
  },
  "Discrete Subtitle File - SMPTE-TT XML": {
    encodingProfile: "Discrete Subtitle File - SMPTE-TT XML",
    format: "SMPTE-TT XML",
    contentType: "",
    fileLayout: "Discrete",
  },
  "Discrete Subtitle File - SRT": {
    encodingProfile: "Discrete Subtitle File - SRT",
    format: "SRT",
    contentType: "",
    fileLayout: "Discrete",
  },
  "Discrete Subtitle File - STL": {
    encodingProfile: "Discrete Subtitle File - STL",
    format: "STL",
    contentType: "",
    fileLayout: "Discrete",
  },
  "FCP PNG+XML": {
    encodingProfile: "FCP PNG+XML",
    format: "FCP PNG+XML",
    contentType: "Full",
    fileLayout: "ImageArchive",
  },
  "IMF Component Subtitle": {
    encodingProfile: "IMF Component Subtitle",
    format: "",
    contentType: "",
    fileLayout: "",
  },
  "Rhozet HD PNG+XML": {
    encodingProfile: "Rhozet HD PNG+XML",
    format: "",
    contentType: "",
    fileLayout: "",
  },
  "Rhozet NTSC PNG+XML": {
    encodingProfile: "Rhozet NTSC PNG+XML",
    format: "",
    contentType: "",
    fileLayout: "",
  },
  "Rhozet PAL PNG+XML": {
    encodingProfile: "Rhozet PAL PNG+XML",
    format: "",
    contentType: "",
    fileLayout: "",
  },
  "Scanned Ingest Component Subtitle": {
    encodingProfile: "Scanned Ingest Component Subtitle",
    format: "",
    contentType: "",
    fileLayout: "",
  },
  IMF_Sub_Cap_Zip: {
    encodingProfile: "IMF_Sub_Cap_Zip",
    format: "SMPTE-TT 608 V1",
    contentType: "",
    fileLayout: "Discrete",
  },
  "SCC Captions": {
    encodingProfile: "SCC Captions",
    format: "SCC",
    contentType: "CC",
    fileLayout: "Discrete",
  },
  "SMPTE-TT XML Captions": {
    encodingProfile: "SMPTE-TT XML Captions",
    format: "SMPTE-TT 608",
    contentType: "CC",
    fileLayout: "Discrete",
  },
};
