import React, { Component } from 'react';
import { Grid, TextField } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import { CheckCircle } from '@material-ui/icons';
import _ from 'lodash';

const style = (theme) => ({
    focused: {
        backgroundColor: "#5e5edb !important",
    },
    disabled: {
        backgroundColor: "#d3d3d3",
    },
    label: {
        color: "#ffffff !important",
        fontSize: 18,
        fontWeight: 700,
    },
    input: {
        color: "#ffffff !important",
        marginTop: 8,
    },
    textfield: {
        marginTop: 40,
        marginRight: 10,
        marginBottom: 15,
        width: '16vw',
    },
    text: {
        margin: '8px 0',
    }
})

class FilledTextInput extends Component {
    render() {
        let { style, classes, label = '', value = "", disabled, readOnly = false, error = false, helperText } = this.props;
        let showEndAdornment = !_.isEmpty(value) ? <CheckCircle style={{
            fontSize: 16,
            position: 'relative',
            top: -16,
            fill: '#ffffff',
        }}/> : <div></div>;
        return (
            <Grid item>
                <TextField
                    className={classes.textfield}
                    style={style}
                    label={label}
                    value={value}
                    error={error}
                    variant="filled"
                    helperText={error ? helperText : ""}

                    margin="normal"
                    disabled={disabled}
                    FormHelperTextProps={{
                        error: error,
                        classes: { root: classes.text }
                    }}
                    InputLabelProps={{
                        shrink: true,
                        classes: { root: classes.label }
                    }}
                    InputProps={{
                        readOnly,
                        endAdornment: showEndAdornment,
                        classes: {
                            root: disabled ? classes.disabled : classes.focused,
                            focused: classes.focused,
                            input: classes.input,
                        }
                    }}
                />
            </Grid>
        )
    }
}

const styled = withStyles(style)(FilledTextInput);
export { styled as FilledTextInput };