import React, { Component } from 'react';
import _ from 'lodash';
import moment from "moment";

const LICENSING_ERROR_MSG = 'Licensing Window End date must be after Licensing Window Start date';
const EST_ERROR_MSG = 'Est Window End date must be after Est Window Start date';

export const withDateValidation = (ComponentNeedingDateValidation) => {
    return class dateHOC extends Component {
        constructor(props) {
            super(props);

            const defaultState = _.cloneDeep(this.props.defaultState);
            this.state = Object.assign({}, defaultState);
        }

        componentDidMount() {
            if(this.props.validationOnMount) {
                // Do the validation
                let hasErrors = this.hasErrors();
                let validationRequired = this.props.validationRequired === undefined ? true : this.props.validationRequired;
                this.props.updateErrors('license', !hasErrors || !validationRequired);
            }
        }

        hasErrors = () => {
            return this.state.licensingError || this.state.estError;
        }

        validDateRange = (startDate, endDate) => {
            if(startDate === '' || endDate === '') {
              return false;
            }
            let startUTCDate = moment.utc(startDate);
            let endUTCDate = moment.utc(endDate);
            return moment(endUTCDate).isAfter(startUTCDate);
        }

        checkStartAndEndDateExists = (type) => {
            return this.state.data[`${type}StartDate`] !== null &&
                    this.state.data[`${type}EndDate`] !== null &&
                    this.hasErrors();
        }
        
        handleLicenseDateChange = label => date => {
            let newState = Object.assign({}, this.state);
            let data = Object.assign({}, 
                    this.state.data, 
                    { 
                        [label]: date ? new Date(date) : null
                    });
            let msgToDisplay = '';
            let result = false
            let type = (/licensing/).test(label) ? 'licensing' : 'est';
            let startOrEnd = (/Start/).test(label) ? 'EndDate': 'StartDate';
            let dateFieldName = `${type}${startOrEnd}`;
            let dateToCompare = this.state.data[dateFieldName];
            let validationRequired = this.props.validationRequired === undefined ? true : this.props.validationRequired;
        
            if (startOrEnd === 'StartDate') {
              result = this.validDateRange(dateToCompare, date);
            } else {
              result = this.validDateRange(date, dateToCompare);
            }
        
            let isLicensingError = type.toLowerCase() === 'licensing';
            let errorLabel = isLicensingError ? 'licensingError' : 'estError';
    
            newState[errorLabel] = !result;
            newState.data = data;
    
            if (!result) {
                msgToDisplay = isLicensingError ? LICENSING_ERROR_MSG : EST_ERROR_MSG;
            }
    
            this.setState(newState, () => {
                let dataExists = this.checkStartAndEndDateExists(type);
                this.props.showNotification(!result && dataExists, msgToDisplay);
                this.props.updateErrors('license', !this.hasErrors() || !validationRequired);
                this.props.passDateToSeason && this.props.passDateToSeason(this.state.data);
                this.props.onPackageDataChange('license', this.state.data);
            });
        };

        render() {
            const hocProps = {
                licensingStartDate: this.state.data.licensingStartDate,
                licensingEndDate: this.state.data.licensingEndDate,
                estStartDate: this.state.data.estStartDate || null,
                estEndDate: this.state.data.estEndDate || null,
                licensingError: this.state.licensingError,
                estError: this.state.estError,
                hasErrors: this.hasErrors,
                handleLicenseDateChange: this.handleLicenseDateChange,
            }

            const props = Object.assign({}, hocProps, this.props);

            return <ComponentNeedingDateValidation {...props}/>
            
        }
    }
}
