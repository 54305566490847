import React from 'react';
import { Grid } from '@material-ui/core';
import { TitleDetails } from '../common';
import { RokuFeatureTitlePane, RokuTvTitlePane } from './';
import { clientShow } from '../Clientshow/clientshow';
import moment from 'moment';
import { getNormalizedProductType } from '../../_helpers/titleHelper';

function displayDate(dateStr) {
    let momentDateObj = moment(dateStr);
    return momentDateObj.isValid() ? momentDateObj.format("MM/DD/YYYY") : dateStr;
}

const RokuTitlePane = (props) => {
    let { presentedTitle } = props;
    let productType = presentedTitle ? getNormalizedProductType(presentedTitle.productType) : '';
    return (
        <Grid container component={TitleDetails}
        direction="column" justify="space-between">
            {
                productType.toLowerCase() === 'feature' ? (
                    <RokuFeatureTitlePane
                        presentedTitle={presentedTitle}
                        displayDate={displayDate}
                    />
                ) : (
                    <RokuTvTitlePane
                        presentedTitle={presentedTitle}
                        displayDate={displayDate}
                    />
                )
            }
        </Grid>
    )
}

const clientConnected = clientShow(RokuTitlePane, 'RokuTitlePane');
export { clientConnected as RokuTitlePane };