import React from 'react';
import { connect } from 'react-redux';
import config from 'config';
import IdleTimer from 'react-idle-timer';
import { Grid } from "@material-ui/core";
import './DataEntry.styles.scss';
import {
  Dbb,
  DbbBulkIngest,
  DirectTV,
  DistributionMetadata,
  ErrorSnackBar,
  Roku,
  RokuCablelabs,
  CmdgInternational,
  MovielabsManifest
} from "../components";
import SubmitData from './SubmitData';
import { ClientActions } from '../_actions/client.actions';
import { ClientSpecActions } from '../_actions/clientspec.actions';
import { TitleActions } from '../_actions/title.actions';
import NotifierAction from '../_actions/notifier.actions';
import { JobService } from '../_services/job.service';
import { ClientspecService } from '../_services/clientspec.service';
import { TitleService } from '../_services/title.service';

const OMITTED = [
  'titleWarning',
  'errorsEnabled',
  'errors',
  'packageData',
];

class DataEntry extends React.Component {
  constructor(props) {
    super(props);

    let location = props.location;
    let jobId = null;
    this.querystringPresent = false;

    if (location.search || location.query) {
      let querystring = location.search || location.query;
      let params = new URLSearchParams(querystring);
      jobId = params.get('jobid');
      this.querystringPresent = true;
    }

    this.idleTimer = null;
    this.state = {
      titleWarning: false,
      errorsEnabled: false,
      language: 'en',
      territory: {
        name: 'U.S.A. (English)',
        sourceTerritory: 'U.S./English',
        locale: 'en-US',
        alphaRating: 'U.S.A. or U.S.A[excl t\'s & p\'s]'
      },
      locales: ['en-US'],
      errors: {},
      jobId,
      selectedTerritories: [
        {
          name: 'U.S.A. (English)',
          sourceTerritory: 'U.S./English',
          locale: 'en-US',
          alphaRating: 'U.S.A. or U.S.A[excl t\'s & p\'s]'
        }
      ],
      ...props.packageData
    };
  }

  componentDidMount() {
    let { jobId } = this.state;
    let props = this.props;

    if (jobId && this.querystringPresent) {
      JobService.retrieveJob(jobId).then(resp => {
        let { clientId: id, titleIds } = resp.data;
        let packageData = resp.data[`client${id}PackageData`];

        titleIds.forEach((externalId, index) => {
          TitleService.search(externalId).then(resp => {
            resp.data.forEach((title) => {
              props.selectTitle(title, index);
            });
          });
        });

        ClientspecService.getClients().then(resp => {
          let client = _.find(resp.data.clients, { id });
          client && props.changeClient(client);
        });

        this.setState({ ...packageData });
      });
    }
  }

  handleTitleWarning = (missingFields, logicErrors) => {
    this.setState({
      titleWarning: (missingFields || logicErrors),
    });
  }

  isFormInvalid = (errors) => {
    return !(_.isEmpty(errors));
  }

  updateErrors = (key, remove = false) => {
    this.setState((prevState, props) => {
      let newState = Object.assign({}, prevState);

      if (remove) {
        newState.errors = _.omit(newState.errors, key);
      } else {
        newState.errors[key] = true
      }

      return newState;
    });
  }

  jobIdUrl = (jobId) => {
    return `${config.domain}/package-data?jobid=${jobId}`;
  }

  successMessage = (jobId) => {
    return `Current form progress has been saved. To return to this form, use this url: ${this.jobIdUrl(jobId)}`;
  }

  errorMessage = () => {
    let errorMessage = `Please make sure all the highlighted fields have been filled.`;

    if (this.state.jobId) {
      errorMessage = `${errorMessage}` +
        `  If you would like to return to this same page at a later time,` +
        ` please save this URL: ${this.jobIdUrl(this.state.jobId)}`;
    }

    return errorMessage;
  }

  handleSubmit = () => {
    let deliveryModel = this.props.client.deliveryModel;

    switch (deliveryModel) {
      case "distributionMetadata":
        this.props.submitPackage({ taskIds: this.props.taskIds }, this.props.client, []);
        break;
      default:
        let formNotValid = this.isFormInvalid(this.state.errors);
        this.props.showNotification(formNotValid, this.errorMessage());
        this.setState({ errorsEnabled: formNotValid }, () => {
          if (!formNotValid) {
            let data = _.omit(this.state, OMITTED);
            let titleIds = this.props.titles.map((title) => (title.externalId));
            this.props.submitPackage(data, this.props.client, titleIds);
          }
        });
    }
  };

  persistForm = () => {
    let promise = new Promise((resolve, reject) => {
      if ((!this.props.client || !this.props.titles[0].externalId) && !this.state.jobId) {
        reject('client or title empty');
      }

      let userId = ClientspecService.getLoginId();
      let clientId = this.props.client.id;
      let client = this.props.client.name;
      let titleIds = this.props.titles.map(title => {
        return title.externalId;
      });

      let { errors, packageData, ...rest } = this.state;
      let newState = {
        errors,
        ...rest,
      }

      let data = {
        userId,
        clientId,
        client,
        titleIds,
        packageData: newState
      };

      if (!_.isEqual(this.data, data)) {
        this.data = data;
        JobService.postJob(this.state.jobId, data).then(resp => {
          let jobId = resp.data.id;
          if (!this.state.jobId) {
            this.setState({ jobId });
          }
          resolve(jobId);
        });
      }
    });

    promise.catch((ex) => {
      console.error(ex);
    });

    return promise;
  }

  handleChildChange = (fieldname, obj) => {
    let newState = {};

    if (obj) {
      if (_.isArray(obj)) {
        newState[fieldname] = obj;
      } else {
        let { packageData, ...rest } = obj;
        newState[fieldname] = { ...rest };
      }
    } else {
      newState = _.omit(this.state, fieldname);
    }

    this.setState(newState);
  }

  focusErrorHandler = (hasErrors, hasWarnings) => {
    if (hasErrors) {
      return 'red-stripe';
    }

    if (hasWarnings) {
      return 'warning';
    }

    return 'normal';
  }

  render() {
    let disabled = this.isFormInvalid(this.state.errors);
    let { focusErrorHandler, handleChildChange,
      handleTerritoryChange, handleTitleWarning,
      updateErrors } = this;

    let title = this.props.titles[0];

    let allProps = {
      focusErrorHandler,
      handleChildChange,
      handleTerritoryChange,
      handleTitleWarning,
      updateErrors,
      title,
      ...this.state,
      ...this.props
    };

    return (
      <React.Fragment>
        <IdleTimer
          ref={ref => this.idleTimer = ref}
          element={document}
          onIdle={this.persistForm}
          debounce={250}
          timeout={1000 * 2}
        />
        <ErrorSnackBar />
        <div className="content package-data">
          <Dbb {...allProps} clientSpec={this.props.spec} />

          <DirectTV {...allProps} />

          <MovielabsManifest
            {...allProps}
            clientSpec={this.props.spec}
            warning={this.state.titleWarning}
          />

          <Roku {...allProps} clientSpec={this.props.spec} />

          <RokuCablelabs {...allProps} />

          <DbbBulkIngest {...allProps} />

          <CmdgInternational  {...allProps} clientSpec={this.props.spec} />

          <DistributionMetadata {...allProps} />
        </div>
        <Grid container>
          <Grid item component={SubmitData}
            client={this.props.client}
            loadingSpinner={this.props.loadingSpinner}
            disableButton={disabled}
            onSubmit={this.handleSubmit}
            persistForm={this.persistForm}
            successMessage={this.successMessage}
          />
        </Grid>
      </React.Fragment>
    );
  }
}

let mapStateToProps = (state) => {
  let { clients, titles, files: fileReducer, taskIds: taskIdsReducer } = state;
  return {
    titles: titles.titles,
    client: clients.client,
    files: fileReducer.files,
    taskIds: taskIdsReducer.taskIds,
    // loadingSpinner: client.loadingSpinner,
    // packageData: client.packageData,
  }
}

let mapDispatchToProps = (dispatch) => {
  let { changeClient } = ClientActions;
  let { submitSpec } = ClientSpecActions;
  let { selectTitle } = TitleActions;
  let showNotification = NotifierAction.showNotification;
  return {
    changeClient: (client) => {
      dispatch(changeClient(client));
    },
    submitPackage: (packageData, client, title) => {
      dispatch(submitSpec(packageData, client, title));
    },
    selectTitle: (title, index) => {
      dispatch(selectTitle(title, index));
    },
    showNotification: (errorStatus, msg) => {
      dispatch(showNotification(errorStatus, msg))
    }
  }
}

let connected = connect(mapStateToProps, mapDispatchToProps)(DataEntry);
export { connected as DataEntry };