const initState = {
  taskIds: null,
};

export const taskIds = (state = initState, action) => {
  switch (action.type) {
    case 'TASK_IDS_SET':
      return {
        ...state,
        taskIds: action.taskIds,
      };

    default:
      return state;
  };
};
