import React, { Component } from 'react';
import { Grid, FormControl, InputLabel } from '@material-ui/core';
import { ExpandableSection } from '../common';
import { withStyles } from '@material-ui/styles';
import { FileEditableDropdown } from '../FileEditableDropdown/FileEditableDropdown';

const styles = theme => ({
    root: {
      fontSize: 14,
      color: "#5e5edb",
      fontWeight: 700
    },
    dropDownStyle: {
      maxHeight: 234
    },
    formControl: {
      position: "relative",
      marginRight: 36
    },
    label: {
      top: 3,
      fontSize: 14,
      color: "#333333",
      fontWeight: 700,
    }
  });

const initialState = {
    sourceFile: '',
    captionFile: '',
    disabledsourceFile: null,
    disabledcaptionFile: null,
    errors: {
        sourceFileError: true,
        captionFileError: true,
    },
}
class FileData extends Component {
    constructor(props) {
        super(props);

        let { data={}} = props;
        this.state = {
            ...initialState,
            ...data,
        }
    }

    componentDidMount() {
        this.props.updateErrors('fileAssets', !this.hasErrors());
    }

    hasErrors = () => {
        return _.isEmpty(this.state.sourceFile) || _.isEmpty(this.state.captionFile);
    }

    handleChange = (label, event) => {
        let field = label;
        let value = event.target.value;
        let newState = _.cloneDeep(this.state);
        let errorField = `${field}Error`;
        if(_.isEmpty(value)) {
            newState.errors[errorField] = true;
        } else {
            newState.errors[errorField] = false;
        }
        newState[field] = value;

        newState = this.findValueToBeDisabled(label, newState);

        this.setState(newState, () => {
            this.props.updateErrors('fileAssets', !this.hasErrors());
            this.props.handleChildChange('fileAssets', newState);
        });
    }

    findValueToBeDisabled = (label, newState) => {
        switch(label) {
            case 'sourceFile':
                newState.disabledcaptionFile = newState[label] || null;
                break;
            case 'captionFile':
                newState.disabledsourceFile = newState[label] || null;
                break;
            default:
                newState.disabledsourceFile = null;
                newState.disabledcaptionFile = null;
        }
       return newState;
    }

    render() {
        const classes = this.props.classes;
        const errorsEnabled = this.props.errorsEnabled
        const applyFocusClass = errorsEnabled && this.props.focusErrorHandler(this.hasErrors(), null);
        const defaultFocusClass = applyFocusClass ? applyFocusClass : 'normal'
        return (
            <Grid container component={ExpandableSection} header="file data"
                defaultExpanded={true} focusErrorCssStyle={defaultFocusClass}>
                    <Grid item container direction="column"
                            justify="space-between" className="data-pane"
                    >
                    <Grid item container direction="column" justify="space-between">
                            <Grid item container justify="flex-start">
                                <Grid item>
                                <FormControl required={true} className={classes.formControl}>
                                    <InputLabel shrink={false} className={classes.root}>Feature/Episode Filename</InputLabel>
                                    <FileEditableDropdown
                                    value={this.state.sourceFile}
                                    error={errorsEnabled && this.state.errors.sourceFileError}
                                    field={'sourceFile'}
                                    onChange={this.handleChange}
                                    files={this.props.files}
                                    style={{
                                        marginTop: 40,
                                        width: "32vw"
                                    }}
                                    />
                                </FormControl>
                                </Grid>
                                <Grid item>
                                    <FormControl required={true} className={classes.formControl}>
                                        <InputLabel shrink={false} className={classes.root}>Closed Caption Filename</InputLabel>
                                        <FileEditableDropdown
                                        value={this.state.captionFile}
                                        error={errorsEnabled && this.state.errors.captionFileError}
                                        onChange={this.handleChange}
                                        field={'captionFile'}
                                        files={this.props.files}
                                        style={{
                                            marginTop: 40,
                                            width: "28vw"
                                        }}
                                        />
                                    </FormControl>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>)
    }
}

const styledComponent = withStyles(styles)(FileData)
export { styledComponent as FileData };