export default [
  "SPHE Abu Dhabi/English-speaking Title (Packaging)",
  "SPHE Abu Dhabi/French-speaking Title (Local)",
  "SPHE Abu Dhabi/French-speaking Title (Packaging)",
  "SPHE Afghanistan Title (Local)",
  "SPHE Afghanistan Title (Packaging)",
  "SPHE Albania Title (Local)",
  "SPHE Albania Title (Packaging)",
  "SPHE Algeria/English-speaking Title (Local)",
  "SPHE Algeria/English-speaking Title (Packaging)",
  "SPHE Algeria/French-speaking Title (Local)",
  "SPHE Algeria/French-speaking Title (Packaging)",
  "SPHE Andorra/French Title (Local)",
  "SPHE Andorra/French Title (Packaging)",
  "SPHE Andorra/Spanish Title (Local)",
  "SPHE Andorra/Spanish Title (Packaging)",
  "SPHE Angola/English-speaking Title (Local)",
  "SPHE Angola/English-speaking Title (Packaging)",
  "SPHE Angola/Portuguese-speaking Title (Local)",
  "SPHE Angola/Portuguese-speaking Title (Packaging)",
  "SPHE Anguilla Title (Local)",
  "SPHE Anguilla Title (Packaging)",
  "SPHE Antigua & Barbuda Title (Local)",
  "SPHE Antigua & Barbuda Title (Packaging)",
  "SPHE Argentina Title (Local)",
  "SPHE Argentina Title (Packaging)",
  "SPHE Armenia Title (Local)",
  "SPHE Armenia Title (Packaging)",
  "SPHE Aruba Title (Local)",
  "SPHE Aruba Title (Packaging)",
  "SPHE Australia Title (Local)",
  "SPHE Australia Title (Packaging)",
  "SPHE Austria Title (Local)",
  "SPHE Austria Title (Packaging)",
  "SPHE Azerbaijan Title (Local)",
  "SPHE Azerbaijan Title (Packaging)",
  "SPHE Bahamas/Spanish-speaking Title (Local)",
  "SPHE Bahamas/Spanish-speaking Title (Packaging)",
  "SPHE Bahrain Title (Local)",
  "SPHE Bahrain Title (Packaging)",
  "SPHE Bangladesh Title (Local)",
  "SPHE Bangladesh Title (Packaging)",
  "SPHE Barbados Title (Local)",
  "SPHE Barbados Title (Packaging)",
  "SPHE Belarus Title (Local)",
  "SPHE Belarus Title (Packaging)",
  "SPHE Belgium/Dutch-speaking Title (Local)",
  "SPHE Belgium/Dutch-speaking Title (Packaging)",
  "SPHE Belgium/French-speaking Title (Local)",
  "SPHE Belgium/French-speaking Title (Packaging)",
  "SPHE Belize Title (Local)",
  "SPHE Belize Title (Packaging)",
  "SPHE Benin/English-speaking Title (Local)",
  "SPHE Benin/English-speaking Title (Packaging)",
  "SPHE Benin/French-speaking Title (Local)",
  "SPHE Benin/French-speaking Title (Packaging)",
  "SPHE Bermuda Title (Local)",
  "SPHE Bermuda Title (Packaging)",
  "SPHE Bhutan Title (Local)",
  "SPHE Bhutan Title (Packaging)",
  "SPHE Bolivia Title (Local)",
  "SPHE Bolivia Title (Packaging)",
  "SPHE Bonaire Title (Local)",
  "SPHE Bonaire Title (Packaging)",
  "SPHE Bophuthatswana Title (Local)",
  "SPHE Bophuthatswana Title (Packaging)",
  "SPHE Bosnia/Herzegovina Title (Local)",
  "SPHE Bosnia/Herzegovina Title (Packaging)",
  "SPHE Botswana/English-speaking Title (Local)",
  "SPHE Botswana/English-speaking Title (Packaging)",
  "SPHE Brazil Title (Local)",
  "SPHE Brazil Title (Packaging)",
  "SPHE British Virgin Islands Title (Local)",
  "SPHE British Virgin Islands Title (Packaging)",
  "SPHE Brunei Title (Local)",
  "SPHE Brunei Title (Packaging)",
  "SPHE Bulgaria Title (Local)",
  "SPHE Bulgaria Title (Packaging)",
  "SPHE Burkina Faso/English-speaking Title (Local)",
  "SPHE Burkina Faso/English-speaking Title (Packaging)",
  "SPHE Burkina Faso/French-speaking Title (Local)",
  "SPHE Burkina Faso/French-speaking Title (Packaging)",
  "SPHE Burundi Title (Local)",
  "SPHE Burundi Title (Packaging)",
  "SPHE Burundi/English-speaking Title (Local)",
  "SPHE Burundi/English-speaking Title (Packaging)",
  "SPHE Cambodia Title (Local)",
  "SPHE Cambodia Title (Packaging)",
  "SPHE Cameroon/English-speaking Title (Local)",
  "SPHE Cameroon/English-speaking Title (Packaging)",
  "SPHE Canada/English-speaking Title (Local)",
  "SPHE Canada/English-speaking Title (Packaging)",
  "SPHE Canada/French-speaking Title (Local)",
  "SPHE Canada/French-speaking Title (Packaging)",
  "SPHE Canary Islands Title (Local)",
  "SPHE Canary Islands Title (Packaging)",
  "SPHE Cape Verde/English-speaking Title (Local)",
  "SPHE Cape Verde/English-speaking Title (Packaging)",
  "SPHE Cape Verde/Portuguese-speaking Title (Local)",
  "SPHE Cape Verde/Portuguese-speaking Title (Packaging)",
  "SPHE Cayman Islands Title (Local)",
  "SPHE Cayman Islands Title (Packaging)",
  "SPHE Central African Republic Title (Local)",
  "SPHE Central African Republic Title (Packaging)",
  "SPHE Central African Republic/English-speaking Title (Local)",
  "SPHE Central African Republic/English-speaking Title (Packaging)",
  "SPHE Chad/Arabic-speaking Title (Local)",
  "SPHE Chad/Arabic-speaking Title (Packaging)",
  "SPHE Chad/English-speaking Title (Local)",
  "SPHE Chad/English-speaking Title (Packaging)",
  "SPHE Chad/French-speaking Title (Local)",
  "SPHE Chad/French-speaking Title (Packaging)",
  "SPHE Chile Title (Local)",
  "SPHE Chile Title (Packaging)",
  "SPHE China Title (Local)",
  "SPHE China Title (Packaging)",
  "SPHE Ciskei Title (Local)",
  "SPHE Ciskei Title (Packaging)",
  "SPHE Colombia Title (Local)",
  "SPHE Colombia Title (Packaging)",
  "SPHE Comoros/English-speaking Title (Local)",
  "SPHE Comoros/English-speaking Title (Packaging)",
  "SPHE Congo/English-speaking Title (Local)",
  "SPHE Congo/English-speaking Title (Packaging)",
  "SPHE Congo/French-speaking Title (Local)",
  "SPHE Congo/French-speaking Title (Packaging)",
  "SPHE Costa Rica Title (Local)",
  "SPHE Costa Rica Title (Packaging)",
  "SPHE Croatia Title (Local)",
  "SPHE Croatia Title (Packaging)",
  "SPHE Cuba Title (Local)",
  "SPHE Cuba Title (Packaging)",
  "SPHE Curacao Title (Local)",
  "SPHE Curacao Title (Packaging)",
  "SPHE Cyprus/Greek-speaking Title (Local)",
  "SPHE Cyprus/Greek-speaking Title (Packaging)",
  "SPHE Czech Republic Title (Local)",
  "SPHE Czech Republic Title (Packaging)",
  "SPHE Denmark Title (Local)",
  "SPHE Denmark Title (Packaging)",
  "SPHE Djibouti Title (Local)",
  "SPHE Djibouti Title (Packaging)",
  "SPHE Djibouti/English-speaking Title (Local)",
  "SPHE Djibouti/English-speaking Title (Packaging)",
  "SPHE Dominica Title (Local)",
  "SPHE Dominica Title (Packaging)",
  "SPHE Dominican Republic/Spanish-speaking Title (Local)",
  "SPHE Dominican Republic/Spanish-speaking Title (Packaging)",
  "SPHE Dubai/English-speaking Title (Local)",
  "SPHE Dubai/English-speaking Title (Packaging)",
  "SPHE Dubai/French-speaking Title (Local)",
  "SPHE Dubai/French-speaking Title (Packaging)",
  "SPHE East Germany Title (Local)",
  "SPHE East Germany Title (Packaging)",
  "SPHE Ecuador Title (Local)",
  "SPHE Ecuador Title (Packaging)",
  "SPHE Egypt Title (Local)",
  "SPHE Egypt Title (Packaging)",
  "SPHE Egypt/Arabic-speaking Title (Local)",
  "SPHE Egypt/Arabic-speaking Title (Packaging)",
  "SPHE Egypt/English-speaking Title (Local)",
  "SPHE Egypt/English-speaking Title (Packaging)",
  "SPHE Egypt/French-speaking Title (Local)",
  "SPHE Egypt/French-speaking Title (Packaging)",
  "SPHE El Salvador Title (Local)",
  "SPHE El Salvador Title (Packaging)",
  "SPHE Equatorial Guinea Title (Local)",
  "SPHE Equatorial Guinea Title (Packaging)",
  "SPHE Equatorial Guinea/English-speaking Title (Local)",
  "SPHE Equatorial Guinea/English-speaking Title (Packaging)",
  "SPHE Eritrea Title (Local)",
  "SPHE Eritrea Title (Packaging)",
  "SPHE Eritrea/English-speaking Title (Local)",
  "SPHE Eritrea/English-speaking Title (Packaging)",
  "SPHE Estonia Title (Local)",
  "SPHE Estonia Title (Packaging)",
  "SPHE Ethiopia Title (Local)",
  "SPHE Ethiopia Title (Packaging)",
  "SPHE Ethiopia/English-speaking Title (Local)",
  "SPHE Ethiopia/English-speaking Title (Packaging)",
  "SPHE Fiji Title (Local)",
  "SPHE Fiji Title (Packaging)",
  "SPHE Finland Title (Local)",
  "SPHE Finland Title (Packaging)",
  "SPHE France Title (Local)",
  "SPHE France Title (Packaging)",
  "SPHE French Guiana Title (Local)",
  "SPHE French Guiana Title (Packaging)",
  "SPHE French Guiana/French-speaking Title (Local)",
  "SPHE French Guiana/French-speaking Title (Packaging)",
  "SPHE French Guiana/Spanish-speaking Title (Local)",
  "SPHE French Guiana/Spanish-speaking Title (Packaging)",
  "SPHE French Polynesia Title (Local)",
  "SPHE French Polynesia Title (Packaging)",
  "SPHE Futuna Title (Local)",
  "SPHE Futuna Title (Packaging)",
  "SPHE Gabon/English-speaking Title (Local)",
  "SPHE Gabon/English-speaking Title (Packaging)",
  "SPHE Gambia/English-speaking Title (Local)",
  "SPHE Gambia/English-speaking Title (Packaging)",
  "SPHE Georgia Title (Local)",
  "SPHE Georgia Title (Packaging)",
  "SPHE Germany Title (Local)",
  "SPHE Germany Title (Packaging)",
  "SPHE Ghana/English-speaking Title (Local)",
  "SPHE Ghana/English-speaking Title (Packaging)",
  "SPHE Gibraltar Title (Local)",
  "SPHE Gibraltar Title (Packaging)",
  "SPHE Greece Title (Local)",
  "SPHE Greece Title (Packaging)",
  "SPHE Greenland Title (Local)",
  "SPHE Greenland Title (Packaging)",
  "SPHE Grenada Title (Local)",
  "SPHE Grenada Title (Packaging)",
  "SPHE Guadeloupe/French-speaking Title (Local)",
  "SPHE Guadeloupe/French-speaking Title (Packaging)",
  "SPHE Guadeloupe/Spanish-speaking Title (Local)",
  "SPHE Guadeloupe/Spanish-speaking Title (Packaging)",
  "SPHE Guatemala Title (Local)",
  "SPHE Guatemala Title (Packaging)",
  "SPHE Guinea Title (Local)",
  "SPHE Guinea Title (Packaging)",
  "SPHE Guinea-Bissau/English-speaking Title (Local)",
  "SPHE Guinea-Bissau/English-speaking Title (Packaging)",
  "SPHE Guinea-Bissau/Portuguese-speaking Title (Local)",
  "SPHE Guinea-Bissau/Portuguese-speaking Title (Packaging)",
  "SPHE Guinea/English-speaking Title (Local)",
  "SPHE Guinea/English-speaking Title (Packaging)",
  "SPHE Guyana Title (Local)",
  "SPHE Guyana Title (Packaging)",
  "SPHE Haiti/Spanish-speaking Title (Local)",
  "SPHE Haiti/Spanish-speaking Title (Packaging)",
  "SPHE Honduras Title (Local)",
  "SPHE Honduras Title (Packaging)",
  "SPHE Hong Kong Title (Local)",
  "SPHE Hong Kong Title (Packaging)",
  "SPHE Hungary Title (Local)",
  "SPHE Hungary Title (Packaging)",
  "SPHE Iceland Title (Local)",
  "SPHE Iceland Title (Packaging)",
  "SPHE India Title (Local)",
  "SPHE India Title (Packaging)",
  "SPHE India/Assamese-speaking Title (Local)",
  "SPHE India/Assamese-speaking Title (Packaging)",
  "SPHE India/Bengali-speaking Title (Local)",
  "SPHE India/Bengali-speaking Title (Packaging)",
  "SPHE India/Bhojpuri-speaking Title (Local)",
  "SPHE India/Bhojpuri-speaking Title (Packaging)",
  "SPHE India/English-speaking Title (Local)",
  "SPHE India/English-speaking Title (Packaging)",
  "SPHE India/Gujarati-speaking Title (Local)",
  "SPHE India/Gujarati-speaking Title (Packaging)",
  "SPHE India/Hindi-speaking Title (Local)",
  "SPHE India/Hindi-speaking Title (Packaging)",
  "SPHE India/Kannada-speaking Title (Local)",
  "SPHE India/Kannada-speaking Title (Packaging)",
  "SPHE India/Malayalam-speaking Title (Local)",
  "SPHE India/Malayalam-speaking Title (Packaging)",
  "SPHE India/Marathi-speaking Title (Local)",
  "SPHE India/Marathi-speaking Title (Packaging)",
  "SPHE India/Oriya-speaking Title (Local)",
  "SPHE India/Oriya-speaking Title (Packaging)",
  "SPHE India/Punjabi-speaking Title (Local)",
  "SPHE India/Punjabi-speaking Title (Packaging)",
  "SPHE India/Tamil-speaking Title (Local)",
  "SPHE India/Tamil-speaking Title (Packaging)",
  "SPHE India/Telugu-speaking Title (Local)",
  "SPHE India/Telugu-speaking Title (Packaging)",
  "SPHE India/Urdu-speaking Title (Local)",
  "SPHE India/Urdu-speaking Title (Packaging)",
  "SPHE Indonesia Title (Local)",
  "SPHE Indonesia Title (Packaging)",
  "SPHE Iran Title (Local)",
  "SPHE Iran Title (Packaging)",
  "SPHE Iraq Title (Local)",
  "SPHE Iraq Title (Packaging)",
  "SPHE Iraq/English-speaking Title (Local)",
  "SPHE Iraq/English-speaking Title (Packaging)",
  "SPHE Iraq/French-speaking Title (Local)",
  "SPHE Iraq/French-speaking Title (Packaging)",
  "SPHE Ireland Title (Local)",
  "SPHE Ireland Title (Packaging)",
  "SPHE Israel Title (Local)",
  "SPHE Israel Title (Packaging)",
  "SPHE Italy Title (Local)",
  "SPHE Italy Title (Packaging)",
  "SPHE Italy/German Title (Local)",
  "SPHE Italy/German Title (Packaging)",
  "SPHE Ivory Coast Title (Local)",
  "SPHE Ivory Coast Title (Packaging)",
  "SPHE Ivory Coast/English-speaking Title (Local)",
  "SPHE Ivory Coast/English-speaking Title (Packaging)",
  "SPHE Jamaica Title (Local)",
  "SPHE Jamaica Title (Packaging)",
  "SPHE Jamaica/Spanish-speaking Title (Local)",
  "SPHE Jamaica/Spanish-speaking Title (Packaging)",
  "SPHE Japan Title (Local)",
  "SPHE Japan Title (Packaging)",
  "SPHE Japan-Osaka Title (Local)",
  "SPHE Japan-Osaka Title (Packaging)",
  "SPHE Jordan Title (Local)",
  "SPHE Jordan Title (Packaging)",
  "SPHE Jordan/Arabic-speaking Title (Local)",
  "SPHE Jordan/Arabic-speaking Title (Packaging)",
  "SPHE Jordan/English-speaking Title (Local)",
  "SPHE Jordan/English-speaking Title (Packaging)",
  "SPHE Jordan/French-speaking Title (Local)",
  "SPHE Jordan/French-speaking Title (Packaging)",
  "SPHE Kazakhstan Title (Local)",
  "SPHE Kazakhstan Title (Packaging)",
  "SPHE Kenya Title (Local)",
  "SPHE Kenya Title (Packaging)",
  "SPHE Kenya/English-speaking Title (Local)",
  "SPHE Kenya/English-speaking Title (Packaging)",
  "SPHE Kiribati Title (Local)",
  "SPHE Kiribati Title (Packaging)",
  "SPHE Kosovo Title (Local)",
  "SPHE Kosovo Title (Packaging)",
  "SPHE Kuwait Title (Local)",
  "SPHE Kuwait Title (Packaging)",
  "SPHE Kyrgyzstan Title (Local)",
  "SPHE Kyrgyzstan Title (Packaging)",
  "SPHE Laos Title (Local)",
  "SPHE Laos Title (Packaging)",
  "SPHE Latvia Title (Local)",
  "SPHE Latvia Title (Packaging)",
  "SPHE Lebanon Title (Local)",
  "SPHE Lebanon Title (Packaging)",
  "SPHE Lebanon/Arabic-speaking Title (Local)",
  "SPHE Lebanon/Arabic-speaking Title (Packaging)",
  "SPHE Lebanon/English-speaking Title (Local)",
  "SPHE Lebanon/English-speaking Title (Packaging)",
  "SPHE Lesotho Title (Local)",
  "SPHE Lesotho Title (Packaging)",
  "SPHE Liberia/English-speaking Title (Local)",
  "SPHE Liberia/English-speaking Title (Packaging)",
  "SPHE Libya Title (Local)",
  "SPHE Libya Title (Packaging)",
  "SPHE Libya/English-speaking Title (Local)",
  "SPHE Libya/English-speaking Title (Packaging)",
  "SPHE Liechtenstein Title (Local)",
  "SPHE Liechtenstein Title (Packaging)",
  "SPHE Lithuania Title (Local)",
  "SPHE Lithuania Title (Packaging)",
  "SPHE Luxembourg/Dutch-speaking Title (Local)",
  "SPHE Luxembourg/Dutch-speaking Title (Packaging)",
  "SPHE Luxembourg/French Title (Local)",
  "SPHE Luxembourg/French Title (Packaging)",
  "SPHE Luxembourg/German Title (Local)",
  "SPHE Luxembourg/German Title (Packaging)",
  "SPHE Macau Title (Local)",
  "SPHE Macau Title (Packaging)",
  "SPHE Macedonia Title (Local)",
  "SPHE Macedonia Title (Packaging)",
  "SPHE Madagascar/English-speaking Title (Local)",
  "SPHE Madagascar/English-speaking Title (Packaging)",
  "SPHE Madagascar/French-speaking Title (Local)",
  "SPHE Madagascar/French-speaking Title (Packaging)",
  "SPHE Madeira Title (Local)",
  "SPHE Madeira Title (Packaging)",
  "SPHE Malawi/English-speaking Title (Local)",
  "SPHE Malawi/English-speaking Title (Packaging)",
  "SPHE Malaysia Title (Local)",
  "SPHE Malaysia Title (Packaging)",
  "SPHE Maldives Title (Local)",
  "SPHE Maldives Title (Packaging)",
  "SPHE Mali/English-speaking Title (Local)",
  "SPHE Mali/English-speaking Title (Packaging)",
  "SPHE Mali/French-speaking Title (Local)",
  "SPHE Mali/French-speaking Title (Packaging)",
  "SPHE Malta/English-speaking Title (Local)",
  "SPHE Malta/English-speaking Title (Packaging)",
  "SPHE Malta/Italian-speaking Title (Local)",
  "SPHE Malta/Italian-speaking Title (Packaging)",
  "SPHE Martinique/French-speaking Title (Local)",
  "SPHE Martinique/French-speaking Title (Packaging)",
  "SPHE Martinique/Spanish-speaking Title (Local)",
  "SPHE Martinique/Spanish-speaking Title (Packaging)",
  "SPHE Mauritania/English-speaking Title (Local)",
  "SPHE Mauritania/English-speaking Title (Packaging)",
  "SPHE Mauritius/English-speaking Title (Local)",
  "SPHE Mauritius/English-speaking Title (Packaging)",
  "SPHE Mauritius/French-speaking Title (Local)",
  "SPHE Mauritius/French-speaking Title (Packaging)",
  "SPHE Mayotte Title (Local)",
  "SPHE Mayotte Title (Packaging)",
  "SPHE Mexico Title (Local)",
  "SPHE Mexico Title (Packaging)",
  "SPHE Moldova Title (Local)",
  "SPHE Moldova Title (Packaging)",
  "SPHE Monaco/French-speaking Title (Local)",
  "SPHE Monaco/French-speaking Title (Packaging)",
  "SPHE Monaco/Italian-speaking Title (Local)",
  "SPHE Monaco/Italian-speaking Title (Packaging)",
  "SPHE Mongolia Title (Local)",
  "SPHE Mongolia Title (Packaging)",
  "SPHE Montenegro Title (Local)",
  "SPHE Montenegro Title (Packaging)",
  "SPHE Montserrat Title (Local)",
  "SPHE Montserrat Title (Packaging)",
  "SPHE Morocco/English-speaking Title (Local)",
  "SPHE Morocco/English-speaking Title (Packaging)",
  "SPHE Morocco/French-speaking Title (Local)",
  "SPHE Morocco/French-speaking Title (Packaging)",
  "SPHE Mozambique/English-speaking Title (Local)",
  "SPHE Mozambique/English-speaking Title (Packaging)",
  "SPHE Mozambique/Portuguese-speaking Title (Local)",
  "SPHE Mozambique/Portuguese-speaking Title (Packaging)",
  "SPHE Myanmar Title (Local)",
  "SPHE Myanmar Title (Packaging)",
  "SPHE Namibia/English-speaking Title (Local)",
  "SPHE Namibia/English-speaking Title (Packaging)",
  "SPHE Nauru Title (Local)",
  "SPHE Nauru Title (Packaging)",
  "SPHE Nepal Title (Local)",
  "SPHE Nepal Title (Packaging)",
  "SPHE Netherlands Title (Local)",
  "SPHE Netherlands Title (Packaging)",
  "SPHE New Caledonia Title (Local)",
  "SPHE New Caledonia Title (Packaging)",
  "SPHE New Zealand Title (Local)",
  "SPHE New Zealand Title (Packaging)",
  "SPHE Nicaragua Title (Local)",
  "SPHE Nicaragua Title (Packaging)",
  "SPHE Niger/English-speaking Title (Local)",
  "SPHE Niger/English-speaking Title (Packaging)",
  "SPHE Niger/French-speaking Title (Local)",
  "SPHE Niger/French-speaking Title (Packaging)",
  "SPHE Nigeria Title (Local)",
  "SPHE Nigeria Title (Packaging)",
  "SPHE Nigeria/English-speaking Title (Local)",
  "SPHE Nigeria/English-speaking Title (Packaging)",
  "SPHE North Korea Title (Local)",
  "SPHE North Korea Title (Packaging)",
  "SPHE Northern Ireland Title (Local)",
  "SPHE Northern Ireland Title (Packaging)",
  "SPHE Norway Title (Local)",
  "SPHE Norway Title (Packaging)",
  "SPHE Oman Title (Local)",
  "SPHE Oman Title (Packaging)",
  "SPHE Pakistan Title (Local)",
  "SPHE Pakistan Title (Packaging)",
  "SPHE Palau Title (Local)",
  "SPHE Palau Title (Packaging)",
  "SPHE Panama Title (Local)",
  "SPHE Panama Title (Packaging)",
  "SPHE Papua New Guinea Title (Local)",
  "SPHE Papua New Guinea Title (Packaging)",
  "SPHE Paraguay Title (Local)",
  "SPHE Paraguay Title (Packaging)",
  "SPHE Peru Title (Local)",
  "SPHE Peru Title (Packaging)",
  "SPHE Philippines Title (Local)",
  "SPHE Philippines Title (Packaging)",
  "SPHE Poland Title (Local)",
  "SPHE Poland Title (Packaging)",
  "SPHE Portugal Title (Local)",
  "SPHE Portugal Title (Packaging)",
  "SPHE Portugal/English-speaking Title (Local)",
  "SPHE Portugal/English-speaking Title (Packaging)",
  "SPHE Portugal/Portuguese-speaking Title (Local)",
  "SPHE Portugal/Portuguese-speaking Title (Packaging)",
  "SPHE Puerto Rico/Spanish-speaking Title (Local)",
  "SPHE Puerto Rico/Spanish-speaking Title (Packaging)",
  "SPHE Qatar Title (Local)",
  "SPHE Qatar Title (Packaging)",
  "SPHE Quebec/English-speaking Title (Local)",
  "SPHE Quebec/English-speaking Title (Packaging)",
  "SPHE Quebec/French-speaking Title (Local)",
  "SPHE Quebec/French-speaking Title (Packaging)",
  "SPHE Reunion Title (Local)",
  "SPHE Reunion Title (Packaging)",
  "SPHE Reunion/English-speaking Title (Local)",
  "SPHE Reunion/English-speaking Title (Packaging)",
  "SPHE Reunion/French-speaking Title (Local)",
  "SPHE Reunion/French-speaking Title (Packaging)",
  "SPHE Romania Title (Local)",
  "SPHE Romania Title (Packaging)",
  "SPHE Russia Title (Local)",
  "SPHE Russia Title (Packaging)",
  "SPHE Rwanda/English-speaking Title (Local)",
  "SPHE Rwanda/English-speaking Title (Packaging)",
  "SPHE Saba Title (Local)",
  "SPHE Saba Title (Packaging)",
  "SPHE San Marino Title (Local)",
  "SPHE San Marino Title (Packaging)",
  "SPHE Sao Tome & Principe/English-speaking Title (Local)",
  "SPHE Sao Tome & Principe/English-speaking Title (Packaging)",
  "SPHE Sao Tome & Principe/Portuguese-speaking Title (Local)",
  "SPHE Sao Tome & Principe/Portuguese-speaking Title (Packaging)",
  "SPHE Saudi Arabia Title (Local)",
  "SPHE Saudi Arabia Title (Packaging)",
  "SPHE Saudi Arabia/English-speaking Title (Local)",
  "SPHE Saudi Arabia/English-speaking Title (Packaging)",
  "SPHE Saudi Arabia/French-speaking Title (Local)",
  "SPHE Saudi Arabia/French-speaking Title (Packaging)",
  "SPHE Senegal/English-speaking Title (Local)",
  "SPHE Senegal/English-speaking Title (Packaging)",
  "SPHE Senegal/French-speaking Title (Local)",
  "SPHE Senegal/French-speaking Title (Packaging)",
  "SPHE Serbia & Montenegro Title (Local)",
  "SPHE Serbia & Montenegro Title (Packaging)",
  "SPHE Serbia Title (Local)",
  "SPHE Serbia Title (Packaging)",
  "SPHE Serbia/English-speaking Title (Local)",
  "SPHE Serbia/English-speaking Title (Packaging)",
  "SPHE Seychelles/English-speaking Title (Local)",
  "SPHE Seychelles/English-speaking Title (Packaging)",
  "SPHE Seychelles/French-speaking Title (Local)",
  "SPHE Seychelles/French-speaking Title (Packaging)",
  "SPHE Sierra Leone/English-speaking Title (Local)",
  "SPHE Sierra Leone/English-speaking Title (Packaging)",
  "SPHE Singapore Title (Local)",
  "SPHE Singapore Title (Packaging)",
  "SPHE Slovakia Title (Local)",
  "SPHE Slovakia Title (Packaging)",
  "SPHE Slovenia Title (Local)",
  "SPHE Slovenia Title (Packaging)",
  "SPHE Solomon Islands Title (Local)",
  "SPHE Solomon Islands Title (Packaging)",
  "SPHE Somalia Title (Local)",
  "SPHE Somalia Title (Packaging)",
  "SPHE Somalia/English-speaking Title (Local)",
  "SPHE Somalia/English-speaking Title (Packaging)",
  "SPHE South Africa Title (Local)",
  "SPHE South Africa Title (Packaging)",
  "SPHE South Korea Title (Local)",
  "SPHE South Korea Title (Packaging)",
  "SPHE Spain Title (Local)",
  "SPHE Spain Title (Packaging)",
  "SPHE Spain [including Canary Islands, Andorra] Title (Local)",
  "SPHE Spain [including Canary Islands, Andorra] Title (Packaging)",
  "SPHE Sri Lanka Title (Local)",
  "SPHE Sri Lanka Title (Packaging)",
  "SPHE St. Croix Title (Local)",
  "SPHE St. Croix Title (Packaging)",
  "SPHE St. Eustatius Title (Local)",
  "SPHE St. Eustatius Title (Packaging)",
  "SPHE St. Helena Title (Local)",
  "SPHE St. Helena Title (Packaging)",
  "SPHE St. John Title (Local)",
  "SPHE St. John Title (Packaging)",
  "SPHE St. Kitts-Nevis/English-speaking Title (Local)",
  "SPHE St. Kitts-Nevis/English-speaking Title (Packaging)",
  "SPHE St. Kitts-Nevis/French-speaking Title (Local)",
  "SPHE St. Kitts-Nevis/French-speaking Title (Packaging)",
  "SPHE St. Kitts-Nevis/Spanish-speaking Title (Local)",
  "SPHE St. Kitts-Nevis/Spanish-speaking Title (Packaging)",
  "SPHE St. Lucia Title (Local)",
  "SPHE St. Lucia Title (Packaging)",
  "SPHE St. Maarten Title (Local)",
  "SPHE St. Maarten Title (Packaging)",
  "SPHE St. Martin Title (Local)",
  "SPHE St. Martin Title (Packaging)",
  "SPHE St. Martin/French-speaking Title (Local)",
  "SPHE St. Martin/French-speaking Title (Packaging)",
  "SPHE St. Martin/Spanish-speaking Title (Local)",
  "SPHE St. Martin/Spanish-speaking Title (Packaging)",
  "SPHE St. Pierre & Miquelon Title (Local)",
  "SPHE St. Pierre & Miquelon Title (Packaging)",
  "SPHE St. Thomas Title (Local)",
  "SPHE St. Thomas Title (Packaging)",
  "SPHE St. Vincent & Grenadines Title (Local)",
  "SPHE St. Vincent & Grenadines Title (Packaging)",
  "SPHE Sudan Title (Local)",
  "SPHE Sudan Title (Packaging)",
  "SPHE Sudan/English-speaking Title (Local)",
  "SPHE Sudan/English-speaking Title (Packaging)",
  "SPHE Surinam/Spanish-speaking Title (Local)",
  "SPHE Surinam/Spanish-speaking Title (Packaging)",
  "SPHE Swaziland/English-speaking Title (Local)",
  "SPHE Swaziland/English-speaking Title (Packaging)",
  "SPHE Sweden Title (Local)",
  "SPHE Sweden Title (Packaging)",
  "SPHE Switzerland/French Title (Local)",
  "SPHE Switzerland/French Title (Packaging)",
  "SPHE Switzerland/German Title (Local)",
  "SPHE Switzerland/German Title (Packaging)",
  "SPHE Switzerland/Italian Title (Local)",
  "SPHE Switzerland/Italian Title (Packaging)",
  "SPHE Syria/Arabic-speaking Title (Local)",
  "SPHE Syria/Arabic-speaking Title (Packaging)",
  "SPHE Syria/English-speaking Title (Local)",
  "SPHE Syria/English-speaking Title (Packaging)",
  "SPHE Syria/French-speaking Title (Local)",
  "SPHE Syria/French-speaking Title (Packaging)",
  "SPHE Tahiti Title (Local)",
  "SPHE Tahiti Title (Packaging)",
  "SPHE Tahiti/French-speaking Title (Local)",
  "SPHE Tahiti/French-speaking Title (Packaging)",
  "SPHE Taiwan Title (Local)",
  "SPHE Taiwan Title (Packaging)",
  "SPHE Tajikistan Title (Local)",
  "SPHE Tajikistan Title (Packaging)",
  "SPHE Tanzania Title (Local)",
  "SPHE Tanzania Title (Packaging)",
  "SPHE Thailand Title (Local)",
  "SPHE Thailand Title (Packaging)",
  "SPHE Togo/English-speaking Title (Local)",
  "SPHE Togo/English-speaking Title (Packaging)",
  "SPHE Tonga Title (Local)",
  "SPHE Tonga Title (Packaging)",
  "SPHE Transkei Title (Local)",
  "SPHE Transkei Title (Packaging)",
  "SPHE Trinidad & Tobago Title (Local)",
  "SPHE Trinidad & Tobago Title (Packaging)",
  "SPHE Tunisia/English-speaking Title (Local)",
  "SPHE Tunisia/English-speaking Title (Packaging)",
  "SPHE Tunisia/French-speaking Title (Local)",
  "SPHE Tunisia/French-speaking Title (Packaging)",
  "SPHE Turkey Title (Local)",
  "SPHE Turkey Title (Packaging)",
  "SPHE Turkmenistan Title (Local)",
  "SPHE Turkmenistan Title (Packaging)",
  "SPHE Turks & Caicos Title (Local)",
  "SPHE Turks & Caicos Title (Packaging)",
  "SPHE Tuvalu Title (Local)",
  "SPHE Tuvalu Title (Packaging)",
  "SPHE U.S. Virgin Islands Title (Local)",
  "SPHE U.S. Virgin Islands Title (Packaging)",
  "SPHE U.S./English Title (Local)",
  "SPHE U.S./English Title (Packaging)",
  "SPHE U.S./Spanish Title (Local)",
  "SPHE U.S./Spanish Title (Packaging)",
  "SPHE Uganda/English-speaking Title (Local)",
  "SPHE Uganda/English-speaking Title (Packaging)",
  "SPHE Ukraine Title (Local)",
  "SPHE Ukraine Title (Packaging)",
  "SPHE United Arab Emirates Title (Local)",
  "SPHE United Arab Emirates Title (Packaging)",
  "SPHE United Kingdom Title (Local)",
  "SPHE United Kingdom Title (Packaging)",
  "SPHE Uruguay Title (Local)",
  "SPHE Uruguay Title (Packaging)",
  "SPHE Uzbekistan Title (Local)",
  "SPHE Uzbekistan Title (Packaging)",
  "SPHE Vanuatu Title (Local)",
  "SPHE Vanuatu Title (Packaging)",
  "SPHE Vatican City Title (Local)",
  "SPHE Vatican City Title (Packaging)",
  "SPHE Venda Title (Local)",
  "SPHE Venda Title (Packaging)",
  "SPHE Venezuela Title (Local)",
  "SPHE Venezuela Title (Packaging)",
  "SPHE Vietnam Title (Local)",
  "SPHE Vietnam Title (Packaging)",
  "SPHE Wallis & Futuna Title (Local)",
  "SPHE Wallis & Futuna Title (Packaging)",
  "SPHE Wallis Title (Local)",
  "SPHE Wallis Title (Packaging)",
  "SPHE Western Sahara Title (Local)",
  "SPHE Western Sahara Title (Packaging)",
  "SPHE Western Samoa Title (Local)",
  "SPHE Western Samoa Title (Packaging)",
  "SPHE Yemen/Arabic-speaking Title (Local)",
  "SPHE Yemen/Arabic-speaking Title (Packaging)",
  "SPHE Yemen/English-speaking Title (Local)",
  "SPHE Yemen/English-speaking Title (Packaging)",
  "SPHE Yemen/French-speaking Title (Local)",
  "SPHE Yemen/French-speaking Title (Packaging)",
  "SPHE Zaire/English-speaking Title (Local)",
  "SPHE Zaire/English-speaking Title (Packaging)",
  "SPHE Zambia/English-speaking Title (Local)",
  "SPHE Zambia/English-speaking Title (Packaging)",
  "SPHE Zanzibar/English-speaking Title (Local)",
  "SPHE Zanzibar/English-speaking Title (Packaging)",
  "SPHE Zimbabwe/English-speaking Title (Local)",
  "SPHE Zimbabwe/English-speaking Title (Packaging)",
  "SPT Networks Asia - English",
  "SPT Networks Asia - Traditional Chinese",
  "SPT Networks Japan",
  "SPT Networks Korea",
];
