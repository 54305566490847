import React from 'react';
import _ from 'lodash';
import { Grid, Paper, Typography } from "@material-ui/core";
import { TitleData } from "../TitleDisplay/TitleData";
import { ProviderData, LicensingData, BillingData, BillingId, ClosedCaption, ServiceCategory,
  DataForm, ArtworkData } from '.';
import { clientShow } from '../Clientshow/clientshow';
import config from 'config';

class DirectTV extends React.Component {

  shouldFieldDisplay = (fieldName, fields) => {
    if(!fieldName || !fields) {
      return true;
    }
    return fields.includes(fieldName);
  }

  render() {
    return (
        <Grid container
            direction="column"
            justify="space-between"
            className="card-padding"
            component={Paper}
            elevation={8}>
                <Grid item>
                  <Typography variant="h5" component="h5">PACKAGE DATA</Typography>
                </Grid>
                <Grid item component={ProviderData}
                  {...this.props}
                  onPackageDataChange={this.props.handleChildChange}
                  shouldFieldDisplay={this.shouldFieldDisplay}
                  />
                <Grid item component={TitleData}
                  {...this.props}
                  warning={this.props.titleWarning}
                  hasErrors={this.props.errors.billing}
                  >
                  <BillingData
                    {...this.props}
                    onPackageDataChange={this.props.handleChildChange}
                    shouldFieldDisplay={this.shouldFieldDisplay}
                    serviceCategoryError={true}>
                      <BillingId />
                      <ClosedCaption />
                      <ServiceCategory />
                  </BillingData>
                </Grid>

                <Grid item component={LicensingData}
                  {...this.props}
                  licensingData={this.props.license}
                  onPackageDataChange={this.props.handleChildChange}
                  validationOnMount={true}
                  defaultState={{
                    data: {
                      licensingStartDate: null,
                      licensingEndDate: null,
                      estStartDate: null,
                      estEndDate: null,
                    },
                    licensingError: true,
                    estError: true,
                  }}
                />

                <Grid item component={DataForm}
                  {...this.props}
                  data={this.props.feature}
                  header={"feature/episode data"}
                  fieldName="feature"
                  onPackageDataChange={this.props.handleChildChange}
                  languages={config.languages}
                  shouldFieldDisplay={this.shouldFieldDisplay}
                  validate={{
                    filename: true,
                    audioType: true,
                    audioLanguage: true,
                  }}
                  />

                <Grid item component={DataForm}
                  {...this.props}
                  data={this.props.preview}
                  header={"preview data"}
                  fieldName="preview"
                  onPackageDataChange={this.props.handleChildChange}
                  languages={config.languages}
                  shouldFieldDisplay={this.shouldFieldDisplay}
                  validate={{
                    filename: false,
                    audioType: true,
                    audioLanguage: true,
                  }}
                  />

                <Grid item component={ArtworkData}
                  {...this.props}
                  data={this.props.artwork}
                  onPackageDataChange={this.props.handleChildChange}
                  setByProductType={false}
                  disableFields={false}
                  disableAddRowButton={false}
                  defaultTypes={[
                    "Poster",
                    "Box Cover"
                  ]}
                  />
         </Grid>
    );
  }
}

let clientConnected = clientShow(DirectTV, 'DirectTV');
export { clientConnected as DirectTV };