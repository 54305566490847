export default [
  "Afghan/Pashtu",
  "Afrikaans",
  "Albanian",
  "All Languages",
  "Arabic",
  "Armenian",
  "Assamese",
  "Azerbaijani",
  "Bangla",
  "Bengali",
  "Bhojpuri",
  "Bosnian",
  "Bulgarian",
  "Burmese",
  "Cantonese",
  "Castilian Spanish",
  "Catalan",
  "Chinese",
  "Chinese (Mandarin Simp)",
  "Chinese (Mandarin Trad)",
  "Chinese (Mandarin)",
  "Chinese (Taiwanese Mand.)",
  "Chinese (Taiwanese)",
  "Chinese(Mandarin PRC)",
  "Corsican",
  "Croatian",
  "Czech",
  "Danish",
  "Dutch",
  "Dutch (Netherlands)",
  "English",
  "English (Australian)",
  "English (Scottish)",
  "English (UK)",
  "English (US)",
  "Estonian",
  "Ewe",
  "Farsi",
  "Finnish",
  "Flemish",
  "French",
  "French (Canadian)",
  "French (Parisian)",
  "Gaelic (Irish)",
  "Gaelic (Scots)",
  "Georgian",
  "German",
  "German (Austrian)",
  "German (Germany)",
  "German (Swiss)",
  "Greek",
  "Gujarati",
  "Hebrew",
  "Hindi",
  "Hungarian",
  "Icelandic",
  "Indonesian",
  "Inuktitut",
  "Italian",
  "Japanese",
  "Kannada",
  "Kazakh",
  "Khmer",
  "Korean",
  "Kurdish",
  "Lao",
  "Latin American Spanish",
  "Latvian",
  "Lebanese",
  "Lithuanian",
  "Macedonian",
  "Malay",
  "Malayalam",
  "Mandarin",
  "Maori",
  "Marathi",
  "Mongolian",
  "Nauvhal",
  "Nepali",
  "Northern Sotho",
  "Norwegian",
  "Not Available",
  "Oriya",
  "Other",
  "Polish",
  "Portuguese",
  "Portuguese (Brazil)",
  "Portuguese (Portugal)",
  "Punjabi",
  "Romanian",
  "Romany",
  "Russian",
  "Serbian",
  "Serbo-Croatian",
  "Sindi",
  "Sinhala",
  "Slovak",
  "Slovenian",
  "Spanish",
  "Spanish (Argentinean)",
  "Spanish (Chilean)",
  "Spanish (Cuban)",
  "Spanish (Mexican)",
  "Spanish (Puerto Rican)",
  "Swahili",
  "Swedish",
  "Tagalog",
  "Tamil",
  "Telugu",
  "Thai",
  "Tibetan",
  "Turkish",
  "Ukranian",
  "Undefined",
  "Unknown",
  "Urdu",
  "Vietnamese",
  "Welsh",
  "Wolof",
  "Yiddish",
  "Yugoslavian",
  "Zulu",
];
