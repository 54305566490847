import { userConstants } from '../_constants';
import { userService } from '../_services';
import { alertActions } from './';
import { history } from '../_helpers';

export const userActions = {
  login,
  logout,
};

function login(username, password, redirectPath = null) {
  return dispatch => {
    dispatch(request({ username }));

    userService.login(username, password)
      .then(
        user => {
          let path = redirectPath || '/';
          dispatch(success(user));
          history.push(path);
        },
        error => {
          // error.message
          error.message = "Invalid username or password";
          let msg = error.message;
          dispatch(failure(msg));
          dispatch(alertActions.error(msg));
        },
      );
  };

  function request(user) { return { type: userConstants.LOGIN_REQUEST, user }; }
  function success(user) { return { type: userConstants.LOGIN_SUCCESS, user }; }
  function failure(error) {
    return { type: userConstants.LOGIN_FAILURE, error };
  }
}

function logout() {
  userService.logout();
  return { type: userConstants.LOGOUT };
}
