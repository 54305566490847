import React, { Component } from "react";
import {
  Grid, Select, FormControl, InputLabel,
  MenuItem, Button, Typography, IconButton
} from "@material-ui/core";
import { ExpandableSection } from '../common';
import { Add, Close } from "@material-ui/icons";
import { withStyles } from "@material-ui/styles";
import { withHandleMethods } from "./withHandleMethods";
import { FileEditableDropdown } from '../FileEditableDropdown/FileEditableDropdown';

const styles = theme => ({
  root: {
    fontSize: 14,
    color: "#5e5edb",
    fontWeight: 700
  },
  dropDownStyle: {
    maxHeight: 234
  },
  formControl: {
    position: "relative"
  },
  label: {
    top: 3,
    fontSize: 14,
    color: "#333333"
  }
});

class SubtitleData extends Component {

  isFileNameNotEmpty = (form) => {
    return form && form.data.filename !== '';
  }

  formRow = (form, index) => {
      const classes = this.props.classes;
      const errorsEnabled = this.props.errorsEnabled;
      const isFileNameNotEmpty = this.isFileNameNotEmpty(form);
      return (
        <Grid item container justify="space-between">
            <Grid item>
              <FormControl className={classes.formControl}>
                <InputLabel shrink={false} className={classes.label}>
                  Filename
                </InputLabel>
                <FileEditableDropdown
                  value={form ? form.data.filename : ''}
                  onChange={this.props.handleChange}
                  field={'filename'}
                  index={index}
                  files={this.props.files}
                  style={{
                    marginTop: 40,
                    marginBottom: 12,
                    width: "32vw"
                  }}
                />
              </FormControl>
            </Grid>
            <Grid item>
              <FormControl required={isFileNameNotEmpty} className={classes.formControl}>
                <InputLabel shrink={false} className={isFileNameNotEmpty ? classes.root : classes.label}>
                  Subtitle Language
                </InputLabel>
                <Select
                  value={form ? form.data.language : ''}
                  error={errorsEnabled && form.errors.languageError}
                  onChange={(e) => this.props.handleChange('language', e, index)}
                  MenuProps={{
                    classes: { paper: classes.dropDownStyle },
                    getContentAnchorEl: null,
                    anchorOrigin: {
                      vertical: "bottom",
                      horizontal: "left"
                    }
                  }}
                  style={{
                    marginTop: 40,
                    width: "12vw"
                  }}
                > {
                    this.props.languages.map((language, index) => (
                       <MenuItem key={`${language.iso} - ${index}`} value={language.iso}>
                           <Typography noWrap={true}>{language.name}</Typography>
                       </MenuItem>
                    ))
                }
                </Select>
              </FormControl>
            </Grid>
            <Grid item>
              <FormControl required={isFileNameNotEmpty} className={classes.formControl}>
                <InputLabel shrink={false} className={isFileNameNotEmpty ? classes.root : classes.label}>
                    Subtitle Type
                </InputLabel>
                <Select
                  value={form ? form.data.type : ''}
                  error={errorsEnabled && form.errors.typeError}
                  onChange={(e) => this.props.handleChange('type', e, index)}
                  MenuProps={{
                    classes: { paper: classes.dropDownStyle },
                    getContentAnchorEl: null,
                    anchorOrigin: {
                      vertical: "bottom",
                      horizontal: "left"
                    }
                  }}
                  style={{
                    marginTop: 40,
                    width: "24vw"
                  }}
                >
                  <MenuItem value="Forced">Forced</MenuItem>
                  <MenuItem value="Normal">Normal</MenuItem>
                  <MenuItem value="SDH">SDH</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item>
                <IconButton style={{ marginTop: 12 }} onClick={(e) => this.props.handleDeleteRow(index, e)}>
                    <Close />
                </IconButton>
            </Grid>
          </Grid>
      );
  }

  render() {
    const applyCardFocusClass = this.props.errorsEnabled &&
                                this.props.focusErrorHandler(this.props.fieldError, null);
    const defaultFocusClass = applyCardFocusClass ? applyCardFocusClass : 'normal';
    return (
      <Grid container component={ExpandableSection} header="subtitle data" 
        defaultExpanded={true} focusErrorCssStyle={defaultFocusClass}>
          <Grid container className="data-pane"
          justify="space-between">
            <Grid item container direction="column" justify="space-between">
              {
                  this.props.dataFormRow.map((item, index) => {
                      let FormRow = this.formRow(item, index);
                      return (<div key={index}>{FormRow}</div>);
                  })
              }
            </Grid>
            <Grid item container
                justify="space-between">
                  <Grid item>
                    <Button style={{ marginTop: 16 }} color="primary" onClick={this.props.handleAddRow}>
                        <Add style={{ marginRight: 12 }} />
                        ADD NEW ROW
                    </Button>
                  </Grid>
          </Grid>
          </Grid>
      </Grid>
    );
  }
}

const styledDataForm = withStyles(styles)(SubtitleData);
const componentWithMethods = withHandleMethods(styledDataForm)
export { componentWithMethods as SubtitleData };
