import React, { Component } from 'react';
import { Grid, FormControl, FormLabel, RadioGroup,
    FormControlLabel, Radio } from '@material-ui/core';
import { CheckCircle } from '@material-ui/icons';
import { withStyles } from '@material-ui/styles';
import { ExpandableSection, TextInput } from '../common';
import _ from 'lodash';

const style = (theme) => ({
    formControl: {
        marginTop: 22
    },
    formLabel: {
        fontSize: 14,
        color: "#5e5edb",
        fontWeight: 700
    },
    radioGroup: {
        display: "inline"
    }
});

const defaultState = {
    isKitted: 'no',
    kitId: ''
}

class KitData extends Component {
    constructor(props) {
        super(props);
        let kitdata = props.kitData || {};
        this.state = Object.assign({}, defaultState, kitdata);
        this.handleChange = _.debounce(this.handleChange, 550);
    }

    componentDidMount() {
        this.postChange();
    }

    isKitIdRequired = () => {
        return this.state.isKitted === 'yes';
    }

    hasErrors = () => {
        return this.isKitIdRequired() && !this.state.kitId;
    }

    postChange = () => {
        this.props.updateErrors('kitData', !this.hasErrors());
        this.props.onChange('kitData', this.state);
    }

    handleRadioSelection = (label) => (e) => {
        let value = e.target.value;

        this.setState({
            [label]: value,
        }, () => {
            this.postChange();
        })
    }

    handleChange = (label, value) => {
        this.setState({
            [label]: value
        }, () => {
            this.postChange();
        })
    }

    render() {
        let { classes, errorsEnabled, focusErrorHandler } = this.props;
        let { isKitted, kitId } = this.state;
        let error = this.hasErrors();
        let errorsFocusClass = errorsEnabled && focusErrorHandler(this.hasErrors());
        let defaultFocusClass = errorsFocusClass ? errorsFocusClass : 'normal';
        return (
            <Grid item header="Kit Data" component={ExpandableSection} defaultExpanded={true}
                focusErrorCssStyle={defaultFocusClass}>
                <Grid container direction="column">
                    <Grid item>
                        <FormControl component="fieldset" required={true} className={classes.formControl}>
                            <FormLabel component="label" className={classes.formLabel}>
                                Kitted?
                            </FormLabel>
                            <RadioGroup className={classes.radioGroup}
                                value={isKitted}
                                onChange={this.handleRadioSelection('isKitted')}
                            >
                            <FormControlLabel
                                value="yes"
                                control={<Radio checkedIcon={<CheckCircle />}/>}
                                label="Yes"
                            />
                            <FormControlLabel
                                value="no"
                                control={<Radio checkedIcon={<CheckCircle />}/>}
                                label="No"
                            />
                            </RadioGroup>
                        </FormControl>
                    </Grid>
                    {
                        this.isKitIdRequired() && (
                            <Grid item>
                                <TextInput
                                    label="Kit ID"
                                    type="number"
                                    required={true}
                                    value={kitId}
                                    field="kitId"
                                    error={errorsEnabled && error}
                                    onChange={this.handleChange}
                                />
                            </Grid>
                        )
                    }
                </Grid>
            </Grid>
        )
    }
}

const styled = withStyles(style)(KitData);
export { styled as KitData };