import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/styles';
import { MenuItem, Select, Typography, Grid, InputLabel } from '@material-ui/core';
import './Clientform.styles.scss';

const style = theme => ({
  dropDownStyle: {
      maxHeight: 272,
      width: 272,
  },
});

class ClientSelect extends React.Component {
    render() {
        let { classes, clients } = this.props;
        return (<Grid container
         direction="column"
         justify="space-between"
         component="section"
         className="client-selection">
          <Grid item className="client-label">
            <InputLabel className="client-data-label"> Client Name: </InputLabel>
          </Grid>
          <Grid item component={Select}
          value={this.props.selectedClient ? this.props.selectedClient.id : '' }
          onChange={this.props.handleClientChange}
          className="form-control"
          style={{
            position: 'relative',
          }}
          MenuProps={{
            getContentAnchorEl: null,
            classes: {
              paper: classes.dropDownStyle,
            },
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "left",
            }
          }}
          inputProps={
            {
              'aria-label': 'Client',
            }}>
            { 
              clients.map(item => (
                  <MenuItem key={item.id} className="client-names" value={item.id}>
                    <Typography>{item.name}</Typography>
                  </MenuItem>
              ))
            }
          </Grid>
        </Grid>);
    }
}

ClientSelect.propTypes = {
    handleClientChange: PropTypes.func,
    selectedClient: PropTypes.object,
    clients: PropTypes.array,
}

let styleConnected = withStyles(style)(ClientSelect);
export { styleConnected as ClientSelect };