import React from 'react';
import _ from 'lodash';
import { ClientList } from './ClientList';
import { TitleSearch } from '../components/TitleSearch/TitleSearch';
import { ProfileService } from '../_services';
import { Paper, Grid } from '@material-ui/core';
import './ClientProfileView.styles.scss';
import '../components/ClientForm/Clientform.styles.scss';


export class ClientProfileView extends React.PureComponent {
  state = {
    clientProfiles: [],
    title: null,
  }

  fetchProfiles = () => {
    ProfileService.getAll().then(resp => {
      this.setClientProfiles(resp.data.profiles);
    });
  }

  componentDidMount() {
    this.fetchProfiles();
  }

  onTitleSuggestionSelected = (selectedTitle) => {
    this.setState({
      title: selectedTitle
    })
  }

  setClientProfiles = (clientProfiles) => {
    this.setState({
      clientProfiles
    });
  }

  handleClientDelete = (clientname) => {
    let newset = _.filter(this.state.clientProfiles, (profile) => {
      return (profile.name !== clientname);
    });

    this.setClientProfiles(newset);
  }


  render() {
    let { clientProfiles, title } = this.state;

    return (
      <div className="content">
        <Grid container justify="space-between" direction="column">
          <Grid container component={Paper} elevation={8} style={{
            marginBottom: 24,
          }}>
            <Grid container direction="column">
              <Grid item>
                <ClientList
                  className='client-list'
                  clients={clientProfiles}
                  title={title}
                  onClientDelete={(clientname) => { this.handleClientDelete(clientname)}} />
              </Grid>
            </Grid>
          </Grid>
          <Grid container component={Paper} className="card-padding" elevation={8}>
            <TitleSearch onTitleSuggestionSelected={this.onTitleSuggestionSelected} />
          </Grid>
        </Grid>
      </div>
    )
  }
}