import React, { Component } from 'react';
import { Grid, MenuItem, Typography, Fab, FormControl, InputLabel } from '@material-ui/core';
import { Dropdown, InputEditableSelect } from "../common";
import _ from 'lodash';
import profiles from './audioEncodingProfiles';
import { withStyles } from '@material-ui/styles';
import { AudioChannelList } from './';

const audioContents = ["Composite", "Dialogue", "M&E", "Stems", "Music", "Effects", "Laugh Track", "Partial M&E", "Narration",
"Sync", "MOS", "Audio Description", "DVD Audio Commentary", "Voiceover", "Production"];

const style = (theme) => ({
    formControl: {
      top: 2,
    },
    label: {
      fontSize: 14,
      color: '#333333',
      fontWeight: 700,
    },
    input: {
      marginTop: 40,
      width: '12vw',
    },
    labelRequired: {
        fontSize: 14,
        color: "#5e5edb",
        fontWeight: 700,
    },
  });

class AudioProfileInfo extends Component {

    constructor(props) {
        super(props);
        this.state = {
            encodingProfile: '',
            audioContent: '',
            audioLanguage: '',
            profileData: props.profileData,
            showProfile: false,
        }
    }

    handleClearAll = () => {
        // Set show profile to false
        // clear the profiles and send back to audio data
        this.setState({
            encodingProfile: '',
            audioContent: '',
            audioLanguage: '',
            showProfile: false,
            profileData: [],
        }, () => {
            this.postChange();
        })
    }

    handleSet = () => {
        // We create profile name and set channels details
        // Set show profile to true
        // Pass the data back to audio data
        let profileName = '';
        let channels = {};
        let audioConfiguration = '';
        let channelLimitExceeded = false;
        let { encodingProfile, audioContent, audioLanguage } = this.state;
        if(encodingProfile && audioContent && audioLanguage) {
            profileName = `${audioContent}, ${audioLanguage}, ${encodingProfile}`;
            let profile = _.find(profiles, { encodingProfile });
            if(!_.isEmpty(profile)) {
                let channelData = this.getChannels(profile);
                channels = channelData.channels;
                audioConfiguration = profile.audioConfiguration;
                channelLimitExceeded = channelData.hasError;
            }
        }
        if(!channelLimitExceeded) {
            let newState = Object.assign({}, this.state);
            let profileData = newState.profileData.concat({
                profileName,
                channels,
                audioConfiguration,
                encodingProfile,
                audioContent,
                audioLanguage,
            });
            this.setState({
                encodingProfile: '',
                audioContent: '',
                audioLanguage: '',
                profileData,
                showProfile: true,
            }, () => {
                this.postChange();
            })
        } else {
            this.props.showNotification(channelLimitExceeded,
                'Max number of channels have been reached');
        }
    }

    getChannels = (profile) => {
        let channels = {};
        let profileData = this.state.profileData;
        let hasError = false;
        channels = profile.channels;
        if(profileData.length) {
            let lastProfile = profileData[profileData.length - 1];
            let channelData = this.getNewOrderForChannels(lastProfile.channels, profile.channels);
            channels = channelData.channels;
            hasError = channelData.hasError;
        }
        return  {
            channels,
            hasError,
        };
    }

    getNewOrderForChannels = (oldChannels, newChannels) => {
        let lastProfileChannelList = Object.keys(oldChannels); // [channel1, channel2, ..... ]
        let lastChannelNumber = this.getChannelNumber(lastProfileChannelList[lastProfileChannelList.length - 1]); // 5
        let newOrderOfChannels = {};
        for(let value in newChannels) {
            newOrderOfChannels[`channel${++lastChannelNumber}`] = newChannels[value];
        }
        let hasError = this.checkIfChannelLimitExceeds(newOrderOfChannels);
        return  {
            channels: newOrderOfChannels,
            hasError,
        }
    }

    getChannelNumber = (channelStr) => {
        return Number(channelStr.split(/(\d+)/)[1]);
    }

    checkIfChannelLimitExceeds = (channels) => {
        let channelList = Object.keys(channels);
        let lastChannelNumber = this.getChannelNumber(channelList[channelList.length - 1]);
        if(lastChannelNumber > 32) {
            return true;
        }
        return false;
    }

    postChange = () => {
        this.props.onChange('profileData', this.state.profileData);
    }

    handleChannelChange = (newValue, index) => {
        let newState = Object.assign({}, this.state);
        let currentProfile = newState.profileData[index];
        currentProfile.channels = newValue;
        this.setState(newState,() => {
            this.postChange();
        })
    }

    isRequiredFieldsEmpty = () => {
        let { encodingProfile, audioContent, audioLanguage } = this.state;
        return !(encodingProfile && audioContent && audioLanguage)
    }

    renderSetAndClearButtons = () => {
        return (
            <React.Fragment>
                <Fab
                    variant="extended"
                    size="small"
                    onClick={e => this.handleClearAll()}
                    style={{
                        border: "solid 2px #5e5edb",
                        marginRight: 8,
                        backgroundColor: "white",
                    }}>
                    <Typography style={{
                        color: "#5e5edb",
                        padding: "0 8px",
                    }}>Clear All</Typography>
                </Fab>
                <Fab
                    variant="extended"
                    size="small"
                    color="primary"
                    disabled={this.isRequiredFieldsEmpty()}
                    onClick={e => this.handleSet()}
                    style={{
                        marginRight: 8,
                        padding: "0 16px"
                    }}>
                    <Typography style={{
                        color: '#ffffff',
                        padding: "0 8px",
                    }}>Set</Typography>
                </Fab>
            </React.Fragment>
        )
    }

    handleInputSelectChange = (label) => (selectedItem) => {
        this.setState({
            [label]: selectedItem
        });
    }

    handleDropDownChange = (label, value) => {
        this.setState({
            [label]: value
        });
    }

    ignoreIfFileNotSet = () => {
        if (this.props.isFileSet()) {
            return !!this.state.profileData.length;
        } else {
            return true;
        }
    }

    render() {
       let { isFilenameFilled, errorsEnabled,
        classes, languages } = this.props;
        let { showProfile, profileData } = this.state;
        let encodingProfileList = Object.keys(profiles);
       return (
        <Grid container>
            <Grid container direction="row" justify="space-between">
                <Grid item>
                    <Typography variant="h5" component="h5">BATCH SET</Typography>
                </Grid>
                <Grid item>
                    {this.renderSetAndClearButtons()}
                </Grid>
            </Grid>
            <Grid container direction="row" justify="space-between">
                <Grid item>
                    <FormControl className={classes.formControl} required={isFilenameFilled}>
                        <InputLabel shrink={false}
                            className={isFilenameFilled ? classes.labelRequired : classes.label}
                        >
                            Encoding Profile
                        </InputLabel>
                        <InputEditableSelect
                            onChange={this.handleInputSelectChange('encodingProfile')}
                            value={this.state.encodingProfile}
                            error={errorsEnabled && !this.ignoreIfFileNotSet()}
                            collection={encodingProfileList}
                            style={{
                                marginTop: 40,
                                width: '16vw',
                            }}
                        />
                    </FormControl>
                </Grid>

                <Grid item component={Dropdown}
                    label="Audio Content"
                    required={isFilenameFilled}
                    field={'audioContent'}
                    onChange={this.handleDropDownChange}
                    value={this.state.audioContent}
                    error={errorsEnabled && !this.ignoreIfFileNotSet()}
                >
                    {
                        _.map(audioContents, (content, index) => {
                            return (
                                <MenuItem key={index} value={content}>
                                    <Typography noWrap={true}>{content}</Typography>
                                </MenuItem>
                            )
                        })
                    }
                </Grid>

                <Grid item>
                    <FormControl className={classes.formControl} required={isFilenameFilled}>
                        <InputLabel shrink={false}
                            className={isFilenameFilled ? classes.labelRequired : classes.label}
                        >
                            Audio Language
                        </InputLabel>
                        <InputEditableSelect
                            field={'audioLanguage'}
                            onChange={this.handleInputSelectChange('audioLanguage')}
                            value={this.state.audioLanguage}
                            error={errorsEnabled && !this.ignoreIfFileNotSet()}
                            collection={languages}
                            style={{
                                marginTop: 40,
                                width: '16vw',
                            }}
                        />
                    </FormControl>
                </Grid>

                <Grid container direction="row" justify="space-between">
                {
                    showProfile && profileData.map((profile, index) => (
                        <Grid item component={AudioChannelList}
                            profileName={profile.profileName}
                            channels={profile.channels}
                            onChange={this.handleChannelChange}
                            key={index}
                            index={index}
                        />
                    ))
                }
                </Grid>
            </Grid>
        </Grid>
       )
    }
}

let styleConnected = withStyles(style)(AudioProfileInfo)
export { styleConnected as AudioProfileInfo };
