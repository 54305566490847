import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { AppBar, IconButton, Typography, Grid, Menu, MenuItem } from '@material-ui/core';
import Logo from '../../assets/logos/purpleLogo';
import { Settings as SettingsIcon } from '@material-ui/icons';
import { history } from '../../_helpers/history';
import './SidecarAppBar.styles.scss';
import { ResetStoreActions } from '../../_actions/resetstore.actions';

const xlsxFileName = 'Metadata Superset Documentation - Terms and Definitions.xlsx';
const mailTo = "mailto:sidecar_help@spe.sony.com?subject=Sidecar Feedback";
const API_DOC_URL = 'https://developers.sonypicturessidecar.com/'
const XmlGeneratorLink = 'HOME';

class SidecarAppBar extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            anchorEl: null,
        }
    }

    handleClick = event => {
        this.setState({
            anchorEl: event.currentTarget,
        });
    }

    handleClose = () => {
        this.setState({
            anchorEl: null,
        });
    }

    handleSelected = selected => {
        if(selected === 'logout') {
            let location = {
                pathname: "/login"
            }
            history.push(location);
            this.props.resetStore();
        }
        this.setState({
            anchorEl: null,
        })
    }

    render() {
        let { anchorEl } = this.state;
        return (
            <Grid container
            direction="row"
            alignContent="space-between"
            component={AppBar} id="sidecar-nav">
                <Grid item>
                    <Link to="/"><Logo color="#ffffff" className="logo" /></Link>
                </Grid>

                <Grid item>
                    <Grid container direction="row" justify="space-between">
                        <Grid item component={Link} to="/" >
                            <Typography className="sidecar-link" variant="button">
                                {XmlGeneratorLink}
                            </Typography>
                        </Grid>
                        <Grid item component={IconButton} className="settings-icon" onClick={this.handleClick}>
                            <SettingsIcon className="settings"/>
                        </Grid>
                    </Grid>
                    <Grid item>
                        <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={this.handleClose}
                        getContentAnchorEl={null}
                        anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "left",
                        }}
                        PaperProps={{
                            style: {
                                // width: 160,
                                fontWeight: 100,
                                marginTop: 4,
                                marginLeft: -24,
                            }
                        }}
                        >   
                            <a href={API_DOC_URL} target="_blank"><MenuItem key="api-documentation" className="settings-menu"> API Documentation</MenuItem></a>
                            <a download={xlsxFileName} href="/Superset-doc.xlsx"><MenuItem key="superset-document" className="settings-menu">Exhibition & Distribution Documentation</MenuItem></a>
                            <a href={mailTo}><MenuItem key="feedback" className="settings-menu">Help</MenuItem></a>
                            <MenuItem key="logout"  className="settings-menu" onClick={(e) => (this.handleSelected('logout'))}>Logout</MenuItem>
                        </Menu>
                    </Grid>
                </Grid>
            </Grid>
        );
    }
}

const mapDispatchToProps = (dispatch) => {
  let resetStore = ResetStoreActions.resetStore;
  return {
      resetStore: () => {
          return dispatch(resetStore());
      }
  }
};

const connected = connect(null, mapDispatchToProps)(SidecarAppBar);
export { connected as SidecarAppBar};
