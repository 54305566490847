import React from 'react';
import { FileList } from './FileList';
import UploadIcon from '../../assets/icons/Upload';
import { Grid, Typography } from '@material-ui/core';

export class FileDragDrop extends React.Component {
  constructor(props) {
    super(props);
  }

  onDragOver(e) {
    e.preventDefault();
  }

  onDragLeave(e) {
    e.preventDefault();
  }

  render() {
    let files = this.props.files,
        hasFiles = (files && files.length) > 0;

    return (
      <Grid container item className="file-drag-area"
        onDragOver={this.onDragOver}
        onDragLeave={this.onDragLeave}
        onDrop={(e) => {
          e.preventDefault();
          this.props.selectFiles(e.dataTransfer.files);
        }}>

        { !hasFiles &&
            (<Grid container className="empty-drop-message"
                justify="flex-start"
                alignItems="center"
                direction="column"
                >
            <Grid item className="empty-drop-message-icon">
              <UploadIcon />
            </Grid>
            <Grid item>
              <Typography className="text-display" align="center" gutterBottom={true} variant="h2">DRAG FILES HERE</Typography>
              <Typography className="text-display" align="center" variant="h5">or use the ADD FILES button</Typography>
            </Grid>
          </Grid>)
        }

        { hasFiles && (<Grid container component={FileList} files={this.props.files} removeFile={this.props.removeFile} />) }
      </Grid>
    );
  }
}