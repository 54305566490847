import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import { TitleField } from '../common';

const RokuTvTitlePane = (props) => {
    let { presentedTitle = {}, displayDate } = props;
    let season = null, series = null;
    if (presentedTitle) {
        season = presentedTitle.season;
        series = presentedTitle.series;
    }
    return (
        <Grid container>
            <TitleField label="Title" field="name"
                title={presentedTitle}
                warning="No Title found"
            />

            <TitleField label="Episode Number" field="episodeNumber"
                title={presentedTitle}
                warning="Episode number not found"
            />

            <TitleField label="EIDR" field="eidr"
                title={presentedTitle}
                warning="EIDR not found"
            />

            <TitleField label="Runtime" field="runtime"
                title={presentedTitle}
                warning="No Runtime found"
            />

            <TitleField label="Rating" field="rating"
                title={presentedTitle}
                warning="No Rating found"
            />

            <TitleField label="Release Date" field="initialReleaseDate"
                title={presentedTitle}
                formatFunc={displayDate}
                warning="Release Date not found for this title"
            />

            <TitleField label="Genres" field="genres"
                title={presentedTitle}
                warning="No Genres found for this title"
            />

            <TitleField label="Actors" field="actors"
                subfield="name"
                title={presentedTitle}
                warning="No Actors found for this title"
            />

            <TitleField label="Director" field="directors"
                subfield="name"
                title={presentedTitle}
                warning="No Directors found for this title"
            />

            <TitleField label="Producers" field="producers"
                subfield="name"
                title={presentedTitle}
                warning="No Producers found for this title"
            />

            <TitleField label="Composer" field="composers"
                subfield="name"
                title={presentedTitle}
                warning="No Composer found for this title"
            />

            <TitleField label="Screenplay" field="screenplay"
                subfield="name"
                title={presentedTitle}
                warning="No Screenplay found for this title"
            />

            <TitleField label="Synopsis Short" field="episodeShortSynopsis"
                title={presentedTitle}
                warning="No Synopsis (short) found for this title"
            />

            <TitleField label="Synopsis Long" field="episodeLongSynopsis"
                title={presentedTitle}
                warning="No Synopsis (long) found for this title"
            />

            <Grid item>
                <Typography variant="h5" component="h5" className="season-data-header">
                    SEASON DATA
                </Typography>

                <TitleField label="Season Number" field="seasonNumber"
                    title={season}
                    warning="Season number not found"
                />

                <Typography variant="h5" component="h5" className="season-data-header">
                    SERIES DATA
                </Typography>

                <TitleField label="Series Name" field="name"
                    title={series}
                    warning="Series Name not found for this title"
                />

                <TitleField label="Release Date" field="initialReleaseDate"
                    title={series}
                    warning="Release Date not found"
                />

                <TitleField label="Genres" field="genres"
                    title={series}
                    warning="No Genres found"
                />

                <TitleField label="Actors" field="actors"
                    subfield="name"
                    title={series}
                    warning="No Actors found"
                />

                <TitleField label="Director" field="directors"
                    subfield="name"
                    title={series}
                    warning="No Directors found"
                />

                <TitleField label="Producers" field="producers"
                    subfield="name"
                    title={series}
                    warning="No Producers found"
                />

                <TitleField label="Composer" field="composers"
                    subfield="name"
                    title={series}
                    warning="No Composer found"
                />

                <TitleField label="Screenplay" field="screenplay"
                    subfield="name"
                    title={series}
                    warning="No Screenplay found"
                />

                <TitleField label="Synopsis Short" field="episodeShortSynopsis"
                    title={series}
                    warning="No Synopsis (short) found"
                />

                <TitleField label="Synopsis Long" field="episodeLongSynopsis"
                    title={series}
                    warning="No Synopsis (long) found"
                />

            </Grid>            
        </Grid>
    )
}

export { RokuTvTitlePane };