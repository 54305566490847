import React from 'react';
import { Typography, Checkbox, Grid } from '@material-ui/core';
import { clientShow } from '../Clientshow/clientshow';

const MSG = 'Substitute MPAA rating in output XML if regional rating is not available';
const MPAASubstitute = (props) => {
  return (
    <Grid container direction="row" style={{
      marginTop: '-16px',
      marginBottom: '-16px' }}>
      <Grid item style={{
          fontWeight: 700,
          flexBasis: 160,
      }}>
      </Grid>
      <Grid item style={{ maxWidth: '50%'}}>
        <Typography style={{ display: 'inline', fontStyle: 'italic', fontWeight: 600 }}>{MSG}</Typography>
        <Checkbox checked={props.checked} onChange={props.onChange} />
      </Grid>
    </Grid>
  )
}

let clientConnected = clientShow(MPAASubstitute, 'MPAASubstitute');
export { clientConnected as MPAASubstitute }