import React, { Component } from 'react';
import { Grid, FormControl, FormLabel, RadioGroup, FormControlLabel, 
    Radio, MenuItem, Typography } from "@material-ui/core";
import _ from 'lodash';
import { CheckCircle } from '@material-ui/icons';
import { TextInput, Dropdown } from '../common';

const transferFunctionsList = ['HLG', 'ST2084', 'Dolby Vision'];

class DynamicRange extends Component {

    constructor(props) {
        super(props);
        this.handleChange = _.debounce(this.handleChange, 350);
    }

    checkIfFieldHasError = (label) => {
        let isHdr = this.props.dynamicRangeValues.type.toLowerCase() === 'hdr';
        if(isHdr) {
            return _.isEmpty(this.props.dynamicRangeValues[label]);
        }
        return false;
    }

    handleChange = (label, value) => {
        let newState = Object.assign({}, this.props.dynamicRangeValues);
        newState[label] = value;
        this.props.handleDynamicRangeChange(newState);
    }

    render() {
        let { dynamicRangeValues, errorsEnabled } = this.props;
        let { type, rx, ry, gx, gy, bx, by, transferFunction, maxCll, 
            maxFall, lMin, lMax } = dynamicRangeValues;
        let isHdr = type.toLowerCase() === 'hdr'; 
        return (
            <Grid container direction="row" justify="space-between">
                <Grid container>
                    <FormControl component="fieldset" style={{ marginTop: 22 }}>
                        <FormLabel
                            style={{ fontSize: 14, color: "#333333" }}
                            component="label"
                        >
                            Dynamic Range
                        </FormLabel>
                        <RadioGroup style={{ display: "inline" }}
                            value={type}
                            onChange={e => this.handleChange("type", e.target.value)}
                        >
                            <FormControlLabel
                            value="HDR"
                            control={<Radio checkedIcon={<CheckCircle />}/>}
                            label="HDR"
                            />
                            <FormControlLabel
                            value="SDR"
                            control={<Radio checkedIcon={<CheckCircle />}/>}
                            label="SDR"
                            />
                        </RadioGroup>
                    </FormControl>
                </Grid>

                {
                    isHdr ? (
                        <React.Fragment>
                            <Grid item component={TextInput}
                                label="Max CLL"
                                type="number"
                                required={isHdr}
                                value={maxCll}
                                field="maxCll"
                                onChange={this.handleChange}
                                error={errorsEnabled && this.checkIfFieldHasError("maxCll")}
                            />

                            <Grid item component={TextInput}
                                label="Max FALL"
                                type="number"
                                required={isHdr}
                                value={maxFall}
                                field="maxFall"
                                onChange={this.handleChange}
                                error={errorsEnabled && this.checkIfFieldHasError("maxFall")}
                            />

                            <Grid item component={TextInput}
                                label="ST2086 Bx"
                                type="number"
                                required={isHdr}
                                value={bx}
                                field="bx"
                                onChange={this.handleChange}
                                error={errorsEnabled && this.checkIfFieldHasError("bx")}
                            />

                            <Grid item component={TextInput}
                                label="ST2086 By"
                                type="number"
                                required={isHdr}
                                value={by}
                                field="by"
                                onChange={this.handleChange}
                                error={errorsEnabled && this.checkIfFieldHasError("by")}
                            />

                            <Grid item component={TextInput}
                                label="ST2086 Gx"
                                type="number"
                                required={isHdr}
                                value={gx}
                                field="gx"
                                onChange={this.handleChange}
                                error={errorsEnabled && this.checkIfFieldHasError("gx")}
                            />

                            <Grid item component={TextInput}
                                label="ST2086 Gy"
                                type="number"
                                required={isHdr}
                                value={gy}
                                field="gy"
                                onChange={this.handleChange}
                                error={errorsEnabled && this.checkIfFieldHasError("gy")}
                            />

                            <Grid item component={TextInput}
                                label="ST2086 Rx"
                                type="number"
                                required={isHdr}
                                value={rx}
                                field="rx"
                                onChange={this.handleChange}
                                error={errorsEnabled && this.checkIfFieldHasError("rx")}
                            />

                            <Grid item component={TextInput}
                                label="ST2086 Ry"
                                type="number"
                                required={isHdr}
                                value={ry}
                                field="ry"
                                onChange={this.handleChange}
                                error={errorsEnabled && this.checkIfFieldHasError("ry")}
                            />

                            <Grid item component={TextInput}
                                label="ST2086L MAX"
                                type="number"
                                required={isHdr}
                                value={lMax}
                                field="lMax"
                                onChange={this.handleChange}
                                error={errorsEnabled && this.checkIfFieldHasError("lMax")}
                            />

                            <Grid item component={TextInput}
                                label="ST2086L MIN"
                                type="number"
                                required={isHdr}
                                value={lMin}
                                field="lMin"
                                onChange={this.handleChange}
                                error={errorsEnabled && this.checkIfFieldHasError("lMin")}
                            />

                            <Grid item component={Dropdown}
                                label="Transfer Function"
                                required={isHdr}
                                field={'transferFunction'}
                                onChange={this.handleChange}
                                value={transferFunction}
                                error={errorsEnabled && this.checkIfFieldHasError("transferFunction")}
                            >
                                {
                                    _.map(transferFunctionsList, (value, index) => {
                                        return (
                                            <MenuItem key={index} value={value}>
                                                <Typography noWrap={true}>{value}</Typography>
                                            </MenuItem>
                                        )
                                    })
                                }
                            </Grid>
                        </React.Fragment>
                    ) : null
                }
            </Grid>
        )
    }
}

export { DynamicRange };