import * as React from "react";

function SvgSave(props) {
  return (
    <svg viewBox="0 0 40 36" width="1em" height="1em" {...props}>
      <path
        d="M5 30.5h30v-15H5v15zm0-30v10h30V.5h2.2c1.1 1.2 1.7 1.8 2.8 3v31c0 .6-.4 1-.9 1H.9c-.5 0-.9-.4-.9-1v-33C0 .9.4.5.9.5H5zm24.5 0c.3 0 .5.2.5.5v4c0 .3-.2.5-.5.5h-19c-.3 0-.5-.2-.5-.5V1c0-.3.2-.5.5-.5h19z"
        fillRule="evenodd"
        clipRule="evenodd"
        fill="currentColor"
      />
    </svg>
  );
}

export default SvgSave;

