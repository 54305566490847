import { SidecarService } from './sidecar.service';

const postJob = (transactionId = null, body) => {
  body = Object.assign({}, body, { transactionId });
  return SidecarService.post('/postjob', body);
};

const retrieveJob = (transactionId) => {
  return SidecarService.get(`/retrievejob/${transactionId}`);
};

export const JobService = {
  retrieveJob,
  postJob,
};

