import _ from 'lodash';
import { clientConstants } from '../_constants';
import { ClientService } from '../_services/client.service';

const initialState = {
  client: null,
  clients: [],
};

export const clients = (state = initialState, action) => {
  switch (action.type) {
    case clientConstants.CLIENTS_FETCHED:
      let clientId = ClientService.getClient() || null;
      let client = action.clients
        .find(c => (c.id === clientId)) ||
        _.head(action.clients);

      return { ...state, client, clients: action.clients};
    case clientConstants.CHANGE_CLIENT:
      ClientService.saveClient(action.client.id);
      return { ...state, client: action.client};
    default:
      return state;
  }
};
