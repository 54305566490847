import React from 'react';
import { Grid } from '@material-ui/core';

export const TitleDetails = (props) => {
    return (
      <Grid container direction="column" className="title-pane">
            <Grid container direction="column" className="title-pane-content">
                {
                  React.Children.map(props.children, (child) => {
                      return React.cloneElement(child, {
                          ...props
                      })
                  })
                }
            </Grid>
      </Grid>
    );
}

