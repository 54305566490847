import React from "react";
import { connect } from 'react-redux';
import _ from 'lodash';
import { Button, Fab, Grid, InputLabel } from '@material-ui/core';
import { withStyles } from "@material-ui/core/styles";
import CloseX from '../../assets/icons/CloseX';
import { Add } from "@material-ui/icons";
import Loader from 'react-loaders';
import { TitleModal } from '../TitleDisplay/TitleModal';
import { TitleAutoComplete } from '../TitleAutoComplete';
import { TitleService, UtilityService } from '../../_services';
import { global } from '../../_constants/global.constants';
import NotifierAction from '../../_actions/notifier.actions';
import '../TitleSearch/TitleSearch.styles.scss';

const ADD_NEW_TITLE = 'ADD NEW TITLE';
const styles = (theme) => ({
  input: {
    fontSize: 14,
    height: 26,
    [theme.breakpoints.down('sm')]: {
      width: "calc(100vw - 324px)",
    },
    [theme.breakpoints.up('md')]: {
      width: "calc(100vw - 324px)",
    },
    [theme.breakpoints.up('lg')]: {
      width: "calc(100vw - 324px)",
    },
  },
  deletableInput: {
    fontSize: 14,
    height: 26,
    [theme.breakpoints.down('sm')]: {
      width: "calc(100vw - 356px)",
    },
    [theme.breakpoints.up('md')]: {
      width: "calc(100vw - 356px)",
    },
    [theme.breakpoints.up('lg')]: {
      width: "calc(100vw - 356px)",
    },
  }
})

const defaultLanguage = 'en';

const defaultTerritory = {
  name: "U.S.A (English)",
  sourceTerritory: 'U.S./English',
  locale: 'en-US',
  alphaRating: 'U.S.A. or U.S.A[excl t\'s & p\'s]'
};

class TitleSearchList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showTitleModal: false,
      title: null,
      language: defaultLanguage,
      territory: defaultTerritory,
      spinners: []
    };
  }

  removeSearch = (index) => () => {
    this.props.onTitleRemoved(index);
    this.forceUpdate();
  }

  addNewTitleClick = () => {
    this.props.onAddTitleEntry();
  }

  onTitleSuggestionSelected = (title, index) => {
    this.props.onTitleSelected(title, index);
    UtilityService.performActionForDbbNonAlphaSelected(this.props.client, title, () => {
      this.props.showNotification(true, global.NON_ALPHA_SELECTED);
    });
  }

  handleSetClick = (title) => {
    this.setState({title});
  }

  openTitleModal = (title, index) => {
    let spinner = `loadingSpinner${index}`;
    this.setState({
      [spinner]: true
    });

    TitleService.getTitleForClient(
      this.props.client.id,
      title.externalId,
      { language: defaultLanguage, territory: defaultTerritory }).then((response) => {
        this.setState({
          title: { ...response.data.presentedTitle, externalId: title.externalId },
          [spinner]: false,
          showTitleModal: true,
        });
      });
  }

  closeTitleModal = () => {
    this.setState({
      showTitleModal: false,
      title: null,
      territory: defaultTerritory,
      language: defaultLanguage,
    })
  }

  handleLanguageChange = (territory = null, language) => {
    let title = this.state.title;
    let clientId = this.props.client.id;

    TitleService.getTitleForClient(clientId, title.externalId, { language, territory }).then((resp) => {
      this.setState({
        title: { ...resp.data.presentedTitle, externalId: title.externalId },
        territory,
        language,
      })
    });
  }

  renderTitleAutoSuggestInputs = () => {
    return _.map(this.props.titles, (title, index) => {
      let disabled = (!title) || (title && !title.gpmsId);

      return (<Grid container key={`title-search-${index}`} style={{
        marginTop: index > 0 ? 24 : 0,
      }} direction="row" justify="space-between">
        <Grid item>
          <Grid container direction="row">
            <Grid item>
              <TitleAutoComplete
                onSuggestionSelected={e => { this.onTitleSuggestionSelected(e, index) }}
                placeholder={"Search by Title, MPM, GPMS ID, EIDR, or Alpha ID"}
                title={title}
                fullWidth={true}
                titleIndex={index}
              />
            </Grid>
            <Grid item>
              {
                index !== 0 ? (
                  <Button style={{
                    width: 16,
                    minWidth: 24,
                    margin: 8,
                  }} onClick={this.removeSearch(index)}>
                    <CloseX className="btn-x-remove" />
                  </Button>
                ) : null
              }
            </Grid>
          </Grid>
        </Grid>

        <Grid item className="title-details">
          <Fab variant="extended" color="secondary"
            className={this.state.loadingSpinner ? 'title-details-button loading' : 'title-details-button'} disabled={disabled}
            onClick={(e) => { this.openTitleModal(title, index) }}>
            {this.state[`loadingSpinner${index}`] ? <Loader type="line-spin-fade-loader" active={true} /> : "Title Details"}
          </Fab>
          <TitleModal
            title={this.state.title}
            client={this.props.client}
            clientSpec={this.state.clientSpec}
            handleLanguageChange={this.handleLanguageChange}
            language={this.state.language}
            territory={this.state.territory}
            open={this.state.showTitleModal}
            close={this.closeTitleModal}
            handleSetClick={this.handleSetClick}
          />
        </Grid>
      </Grid>)
    });
  }

  render() {
    let { titles, client } = this.props;
    let showAddButton = UtilityService.usesCmdgTitlePane(client);

    return (
      <Grid container direction="column" justify="space-between" component="section">
        <Grid item className="title-label">
          <InputLabel className="client-data-label">Title Lookup:</InputLabel>
        </Grid>
        <Grid item container justify="space-between">
          {this.renderTitleAutoSuggestInputs()}
        </Grid>
        <Grid item style={{
          marginTop: 16,
        }}>
          {
            showAddButton && titles.length <= 9 ? (
              <Button color="primary" onClick={this.addNewTitleClick}>
                <Add style={{ marginRight: 12 }} />
                {ADD_NEW_TITLE}
              </Button>
            ) : null
          }
        </Grid>
      </Grid>
    )
  }
}

const mapDispatchToProps = (dispatch) => {
  let { showNotification } = NotifierAction;
  return {
    showNotification: (errorStatus, msg) => {
      dispatch(showNotification(errorStatus, msg, 'warning'));
    },
  }

}
const styled = withStyles(styles)(TitleSearchList);
const connected = connect(null, mapDispatchToProps)(styled);
export { connected as TitleSearchList }
