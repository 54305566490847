import React, { Component } from "react";
import { connect } from 'react-redux';
import { Grid, TextField, MenuItem, Typography, FormControl,
  Select, InputLabel } from "@material-ui/core";
import _ from 'lodash';
import { ExpandableSection } from '../common';
import { FileService } from '../../_services/file.service';
import { withStyles } from '@material-ui/styles';
import { clientShow } from '../Clientshow/clientshow';
import { FileEditableDropdown } from '../FileEditableDropdown/FileEditableDropdown';

const styles = (theme) => ({
  root: {
    fontSize: 14,
    color: "#5e5edb",
    fontWeight: 700,
  },
  dropDownStyle: {
    maxHeight: 234,
  },
  formControl: {
    position: 'relative',
  },
  checksumLabel: {
    fontSize: 14,
    color: '#333333',
  },
  label: {
    top: 3,
    fontSize: 14,
    color: '#333333',
  }
})

const DOLBY_DIGITAL = 'Dolby Digital';
const DOLBY_PROLOGIC = 'Dolby ProLogic';
const DOLBY_51 = 'Dolby 5.1';
const STEREO = 'Stereo';
const MONO = 'Mono';

const audioTypes = [
  DOLBY_PROLOGIC,
  DOLBY_DIGITAL,
  STEREO,
  MONO,
  DOLBY_51,
];

let initialState = Object.assign({}, {
  filename: '',
  audioType: '',
  audioLanguage: '',
  subtitleLanguage: '',
  checksum: '',
  closedCaption: null,
});

function setAudioType(file) {
  let audioType = null;
  switch (file.audioChannels) {
    case 2:
      audioType = DOLBY_DIGITAL;
      break;
    case 6:
      audioType = DOLBY_51;
      break;
  }

  return audioType;
}

class DataForm extends Component {

  constructor(props) {
    super(props);
    let data = props.data || {};
    this.state = Object.assign({}, initialState, data);
  }

  componentDidMount() {
    this.postChange();
  }

  postChange = () => {
    this.props.updateErrors(this.props.fieldName, !this.hasErrors());
    this.props.onPackageDataChange(this.props.fieldName, this.state);
  }

  hasErrors = () => {
    let validate = this.props.validate;
    if (!validate || !validate.filename) {
      return false;
    }

    if (this.isEmptyFileName()) {
      return true;
    }

    let { audioType, audioLanguage } = validate;
    return  (audioType && this.isAudioTypeEmpty()) ||
              (audioLanguage && this.isAudioLanguageEmpty());
  }

  isEmptyFileName = () => {
    return this.state.filename === '';
  }

  isAudioTypeEmpty = () => {
    return this.state.audioType === '';
  }

  isAudioLanguageEmpty = () => {
    return this.state.audioLanguage === '';
  }

  isFeature = () => {
    return this.props.fieldName === 'feature';
  }

  getAudioTypeForFilename = (filename) => {
    let file = FileService.findFileFor(filename, this.props.files);
    if (file) {
      return setAudioType(file);
    }
  }

  handleChildElement = (label, data) => {
    let newState = Object.assign({}, this.state);
    newState[label] = data;
    this.setState(newState, () => {
      this.postChange();
    });
  }

  handleChange = (label, event) => {
    let inputValue = event.target.value;
    let isEmptyValue = inputValue === '';
    let isFilename = label.toLowerCase() === 'filename';
    let newState = Object.assign({}, this.state, {
      [label]: inputValue,
    });

    if (isFilename) {
      if (!isEmptyValue) {
        // filename was selected
        // try to set audioType dropdown
        let audioType = this.getAudioTypeForFilename(inputValue);
        if (audioType) {
          newState.audioType = audioType;
        }

        //get file data from collection
        let ebucoreFileData = _.find(this.props.files, { filename: inputValue});
        newState.ebucore = ebucoreFileData;

        // try to set checksum
        let checksum = FileService.getChecksumForFilename(inputValue, this.props.files);
        newState.checksum = checksum ? checksum : '';
      } else {
        // filename was set to blank in dropdown
        // reset back to initialstate
        newState = Object.assign({}, initialState);
      }
    }

    this.setState(newState, () => {
      this.postChange();
    });
  }

  render() {
    let { files, classes, fields, validate={} } = this.props;
    let hasErrors = this.hasErrors();
    let errorsOn = this.props.errorsEnabled;
    let errorsFocusClass = errorsOn && this.props.focusErrorHandler(hasErrors);
    let defaultFocusClass = errorsFocusClass ? errorsFocusClass : 'normal';

    let emptyFile = this.isEmptyFileName();
    let emptyAudioType = this.isAudioTypeEmpty();
    let emptyAudioLanguage = this.isAudioLanguageEmpty();

    let filenameError = validate.filename && emptyFile;
    let audioTypeError = !emptyFile && validate.audioType && emptyAudioType;
    let audioLanguageError = !emptyFile && validate.audioLanguage && emptyAudioLanguage;
    let isExpanded = this.props.expanded === undefined ? true : this.props.expanded;
    return (
      <Grid container component={ExpandableSection} header={this.props.header}
      defaultExpanded={isExpanded} focusErrorCssStyle={defaultFocusClass}>
            <Grid container direction="column"
              justify="space-between"
              className="data-pane">
                <Grid container  direction="column"
                  justify="space-between">
                    <Grid item container
                    justify="space-between">
                      <Grid item>
                        <FormControl required={this.isFeature()} className={classes.formControl}>
                          <InputLabel shrink={false} className={this.isFeature() ? classes.root : classes.label}>Filename</InputLabel>
                          <FileEditableDropdown
                            id="filename"
                            onChange={this.handleChange}
                            field={'filename'}
                            value={this.state.filename}
                            error={errorsOn && filenameError}
                            files={files}
                            style={{
                              marginTop: 40,
                              width: '20vw',
                            }}
                            />
                        </FormControl>
                      </Grid>
                      <Grid item>
                        <FormControl required={this.props.shouldFieldDisplay('audioType', fields) && !emptyFile} className={classes.formControl}>
                          <InputLabel shrink={false}
                          className={this.props.shouldFieldDisplay('audioType', fields) && !emptyFile ? classes.root : classes.label}>
                            Audio Type
                          </InputLabel>
                          <Select
                            id="audio-type"
                            value={this.state.audioType}
                            onChange={(e) => (this.handleChange("audioType", e))}
                            error={errorsOn && audioTypeError}
                            MenuProps={{
                              classes: { paper: classes.dropDownStyle },
                              getContentAnchorEl: null,
                              anchorOrigin: {
                                vertical: "bottom",
                                horizontal: "left",
                              }
                            }}
                            style={{
                              marginTop: 40,
                              width: '12vw',
                            }}
                            >{audioTypes.map(audioType => (
                              <MenuItem key={audioType} value={audioType}>
                                <Typography noWrap={true}>{audioType}</Typography>
                              </MenuItem>
                            ))}</Select>
                        </FormControl>
                      </Grid>
                        <Grid item>
                          <FormControl required={this.props.shouldFieldDisplay('audioLanguage', fields) && !emptyFile}
                          className={classes.formControl}>
                            <InputLabel shrink={false}
                              className={this.props.shouldFieldDisplay('audioLanguage', fields) && !emptyFile ? classes.root : classes.label}>
                              Audio Language
                            </InputLabel>
                            <Select
                              id="audio-lang"
                              value={this.state.audioLanguage}
                              error={errorsOn && audioLanguageError}
                              onChange={(e) => (this.handleChange("audioLanguage", e))}
                              MenuProps={{
                                classes: { paper: classes.dropDownStyle },
                                getContentAnchorEl: null,
                                anchorOrigin: {
                                  vertical: "bottom",
                                  horizontal: "left",
                                }
                              }}
                              style={{
                                marginTop: 40,
                                width: '12vw',
                              }}
                              >{this.props.languages.map((language, index) => (
                                <MenuItem key={`${this.props.header}-audio-${language.value}-${index}`} value={language.value}>
                                  <Typography noWrap={true}>{language.name}</Typography>
                                </MenuItem>
                              ))}</Select>
                          </FormControl>
                        </Grid>
                        <Grid item>
                          <FormControl className={classes.formControl}>
                            <InputLabel shrink={false} className={classes.label}>Subtitle Language</InputLabel>
                            <Select
                              id="subtitle-lang"
                              value={this.state.subtitleLanguage}
                              onChange={(e) => (this.handleChange("subtitleLanguage", e))}
                              MenuProps={{
                                classes: { paper: classes.dropDownStyle },
                                getContentAnchorEl: null,
                                anchorOrigin: {
                                  vertical: "bottom",
                                  horizontal: "left",
                                }
                              }}
                              style={{
                                marginTop: 40,
                                width: '12vw',
                              }}
                              >{this.props.languages.map((language, index) => (
                                <MenuItem key={`${this.props.header}-subtitle-${language.value}-${index}`} value={language.value}>
                                  <Typography noWrap={true}>{language.name}</Typography>
                                </MenuItem>
                              ))}</Select>
                          </FormControl>
                        </Grid>
                        <Grid item>
                          <FormControl className={classes.formControl}>
                            <InputLabel shrink={false} className={classes.checksumLabel}>Checksum</InputLabel>
                            <TextField
                            id="checksum"
                            value={this.state.checksum}
                            onChange={(e) => (this.handleChange("checksum", e))}
                            style={{
                              marginTop: 40,
                              width: '12vw',
                            }}
                          />
                          </FormControl>
                        </Grid>
                      </Grid>
                  </Grid>
                  {
                    React.Children.map(this.props.children, (childElement) => {
                      return React.cloneElement(childElement, {
                        onChange: this.handleChildElement,
                      })
                    })
                  }
            </Grid>
      </Grid>
    );
  }
}

const mapStateToProps = (state) => {
  let { files: filesReducer } = state;
  let newfiles = filesReducer.files.concat([{ filename: ''}]);

  return {
    files: newfiles
  };
}

let clientConnected = clientShow(DataForm, 'DataForm');
let styled = withStyles(styles)(clientConnected);
let connected = connect(mapStateToProps, null)(styled);
export { connected as DataForm };