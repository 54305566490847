import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import { TitleField } from '../common';

const CablelabsTvTitlePane = (props) => {
    let { presentedTitle, displayDate } = props;
    let series = presentedTitle ? presentedTitle.series : null

    let lasClient = props.client.name === "Roku CableLab 1.1 (LAS)" 

    return (
        <Grid container>

            {lasClient ? <>
                <TitleField label="Title (English)" field="name"
                    title={presentedTitle}
                    warning="No Title found"
                />
                <TitleField label="Title (Spanish)" field="titleNameSpanish"
                    title={presentedTitle}
                    warning="No Title found"
                />
            </> : <>
                <TitleField label="Title" field="name"
                    title={presentedTitle}
                    warning="No Title found"
                />
            </>}
            
            <TitleField label="Episode Number" field="episodeNumber"
                title={presentedTitle}
                warning="No Episode Number found"
            />

            <TitleField label="EIDR" field="eidr"
                title={presentedTitle}
                warning="EIDR not found"
            />

            <TitleField label="Runtime" field="runtime"
                title={presentedTitle}
                warning="No Runtime found"
            />

            <TitleField label="TV Rating" field="rating"
                title={presentedTitle}
                warning="No Rating found"
            />

            <TitleField label="Release Date" field="initialReleaseDate"
                title={presentedTitle}
                formatFunc={displayDate}
                warning="Release Date not found for this title"
            />

            <TitleField label="Genres" field="genres"
                title={presentedTitle}
                warning="No Genres found for this title"
            />

            <TitleField label="Actors" field="actors"
                subfield="name"
                title={presentedTitle}
                warning="No Actors found for this title"
            />

            <TitleField label="Director" field="directors"
                subfield="name"
                title={presentedTitle}
                warning="No Directors found for this title"
            />

            {lasClient ? 
            <>
                <TitleField label="Synopsis Short (English)" field="episodeShortSynopsis"
                    title={presentedTitle}
                    warning="No Synopsis (short) found for this title"
                />

                <TitleField label="Synopsis Long (English)" field="episodeLongSynopsis"
                    title={presentedTitle}
                    warning="No Synopsis (long) found for this title"
                />

                <TitleField label="Synopsis Short (Spanish)" field="episodeShortSynopsisSpanish"
                    title={presentedTitle}
                    warning="No Synopsis (short) found for this title"
                />

                <TitleField label="Synopsis Long (Spanish)" field="episodeLongSynopsisSpanish"
                    title={presentedTitle}
                    warning="No Synopsis (long) found for this title"
                />
            </> : 
            <>
                <TitleField label="Synopsis Short" field="episodeShortSynopsis"
                    title={presentedTitle}
                    warning="No Synopsis (short) found for this title"
                />

                <TitleField label="Synopsis Long" field="episodeLongSynopsis"
                    title={presentedTitle}
                    warning="No Synopsis (long) found for this title"
                />
            </>}

            <Grid item container>
                <Typography variant="h5" component="h5" className="season-data-header">
                    SERIES DATA
                </Typography>

                <TitleField label="Series Name" field="name"
                    title={series}
                    warning="Series Name not found"
                />

                <TitleField label="Release Date" field="initialReleaseDate"
                    title={series}
                    formatFunc={displayDate}
                    warning="Release Date not found for this series"
                />

                <TitleField label="Genres" field="genres"
                    title={series}
                    warning="No Genres found for this series"
                />

                <TitleField label="Actors" field="actors"
                    subfield="name"
                    title={series}
                    warning="No Actors found for this series"
                />

                <TitleField label="Director" field="directors"
                    subfield="name"
                    title={series}
                    warning="No Directors found for this series"
                />

                <TitleField label="Creators" field="creators"
                    subfield="name"
                    title={series}
                    warning="No Creators found for this series"
                />

                <TitleField label="Producers" field="producers"
                    subfield="name"
                    title={series}
                    warning="No Producers found for this series"
                />

                <TitleField label="Composer" field="composers"
                    subfield="name"
                    title={series}
                    warning="No Composer found for this series"
                />

                <TitleField label="Screenplay" field="screenplay"
                    subfield="name"
                    title={series}
                    warning="No Screenplay found for this series"
                />

                <TitleField label="Synopsis Short" field="episodeShortSynopsis"
                    title={series}
                    warning="No Synopsis (short) found for this series"
                />

                <TitleField label="Synopsis Long" field="episodeLongSynopsis"
                    title={series}
                    warning="No Synopsis (long) found for this series"
                />

            </Grid>            
        </Grid>
    )
}

export { CablelabsTvTitlePane };