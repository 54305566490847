import React from 'react';
import Loader from 'react-loaders';
import { Grid } from '@material-ui/core';

export const LoadingSpinner = (props) => {
  let { active, color = "5e5edb" } = props;

  return (
    <Grid item container>
        <Loader type="line-spin-fade-loader" active={active} color={color} style={{
            position: 'relative',
            transform: 'scale(0.5)',
        }}/>
    </Grid>
  );
}
