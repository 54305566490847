export const ClientService = {
  getClient: () => {
    const clientId = localStorage.getItem('clientId');
    return clientId;
  },

  saveClient: (clientId) => {
    localStorage.setItem('clientId', clientId);
  },

  clearClient() {
    localStorage.removeItem('clientId');
  },
};
