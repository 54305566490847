import React, { Component } from 'react';
import _ from 'lodash';
import { Grid, Tabs, Tab } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import { TitleDetails, TabPanel, LoadingSpinner } from '../common';
import { CmdgFeatureFields, TerritoriesPanel, CmdgTvFields } from './';
import { PXLFeatureFields, PXLBoxyFeatureFields } from '../PXL';
import { ExhibitionMetadataFeatureFields } from '../ExhibitionMetadata';
import { MovielabsTitlePane } from '../MovielabsManifest/MovielabsTitlePane';
import { clientShow } from '../Clientshow/clientshow';
import { TitleService, UtilityService } from '../../_services';

const style = () => ({
  scrollButtons: {
    width: 32,
    '&[type=button]': {
      width: 32,
      backgroundColor: '#5e5edb'
    },
    '& > svg': {
      color: '#ffffff'
    }
  },
  label: {
    fontSize: 14,
    color: '#333333',
    fontWeight: 700,
  },
  selectionHighlight: {
    color: "#ffffff !important",
    backgroundColor: "#5e5edb"
  }
});

const seasonTitleDetected = (titles) => {
  let seasonTitle = _.find(titles, UtilityService.isSeason);
  return titles.length && seasonTitle;
}

const shouldDisableSeasonSetup = (client) => {
  let { deliveryModel } = client;
  return ['exhibitionMetadata', 'distributionMetadata'].includes(deliveryModel);
}

class CmdgTitlePane extends Component {
  constructor(props) {
    super(props);
    let selectedTerritories = props.selectedTerritories || [];

    this.state = {
      titles: props.titles || [],
      loading: true,
      tabIndex: 0,
      titleTabIndex: 0,
      territoryTabIndex: 0,
      presentedTitles: [],
      titleTabs: [],
      selectedTerritories,
      maxSynopsisLength: this.getMaxSynopsisLength(),
      maxSynopsisLongLength: 1000,
      maxShortSynopsisLocalLength: 1000,
      tvSynopsisLengths: {
        shortSynopsisLength: 250,
        longSynopsisLength: 500,
        shortSynopsisLocalLength: 500,
        seasonSynopsisLength: 500,
        seriesSynopsisLength: 500,
      }
    }
  }

  getMaxSynopsisLength = () => {
    let client = this.props.client;
    if (client.deliveryModel.toLowerCase().indexOf('pxl') >=0 ) {
      return 500;
    }
    return 250;
  }

  handleTitleTabChange = (e, tabIndex) => {
    this.setState({ titleTabIndex: tabIndex });
  }

  handleTerritoryTabChange = (e, tabIndex) => {
    this.setState({ territoryTabIndex: tabIndex });
  }

  getTitlesByTerritory = (externalId) => {
    return new Promise((resolve, reject) => {
      let { client, selectedTerritories = [] } = this.props;
      let { maxSynopsisLength: synopsisCharLength, tvSynopsisLengths, maxSynopsisLongLength, maxShortSynopsisLocalLength } = this.state;
      let clientId = client.id;
      let objMap = {};

      selectedTerritories.forEach((territory) => {
        // let territory = _.find(this.state.selectedTerritories, { locale });
        let promise = this.fetchTitle({ clientId,
          externalId,
          territory,
          synopsisCharLength,
          maxSynopsisLongLength,
          maxShortSynopsisLocalLength,
          tvSynopsisLengths })
          .catch(ex => { reject('failed http get')});

        promise.then((result) => {
          let title = { ...result.data.presentedTitle, externalId };
          let key = territory.territoryId || territory.locale;
          objMap[key] = { territory, title };

          if (Object.keys(objMap).length === selectedTerritories.length) {
            resolve(objMap);
          }
        });
      });
    });
  }

  setSingleTitle = async () => {
    let { presentedTitle: title, client, territory } = this.props;

    let { maxSynopsisLength: synopsisCharLength, maxSynopsisLongLength, tvSynopsisLengths, maxShortSynopsisLocalLength } = this.state;
    let { id: clientId } = client;
    let result = await this.fetchTitle({
      clientId,
      externalId: title.externalId,
      territory,
      synopsisCharLength,
      maxSynopsisLongLength,
      maxShortSynopsisLocalLength,
      tvSynopsisLengths,
    })

    let newTitle = { ...result.data.presentedTitle, externalId: title.externalId };
    this.props.handleSetClick(newTitle);
  }

  setTitleTabs = async () => {
    if (this.props.singleTitle) {
      return this.setSingleTitle();
    }

    let { titles } = this.state;
    let { client, territory } = this.props;
    let { maxSynopsisLength: synopsisCharLength,
      maxSynopsisLongLength,
      maxShortSynopsisLocalLength,
      tvSynopsisLengths } = this.state;
    let { id: clientId, deliveryModel } = client;
    let titleTabs = [];

    let promises = titles.map(async (title) => {
      let result = await this.fetchTitle({
        clientId,
        externalId: title.externalId,
        territory,
        synopsisCharLength,
        maxSynopsisLongLength,
        maxShortSynopsisLocalLength,
        tvSynopsisLengths,
      });

      let titlesByTerritory = await this.getTitlesByTerritory(title.externalId);
      let { titleFullName, name } = result.data.presentedTitle;
      let tabData = {
        titleFullName,
        name,
        externalId: title.externalId,
        titlesByTerritory,
      };

      if (title.episodeNumber) {
        tabData.episodeNumber = title.episodeNumber;
      }

      titleTabs = titleTabs.concat(tabData);
    });

    await Promise.all(promises);

    if (seasonTitleDetected(titles)) {
      titleTabs = _.sortBy(titleTabs, (data) => {
        return data.episodeNumber || '';
      });
    } else {
      titleTabs = _.sortBy(titleTabs, (data) => {
        return data.titleFullName || data.name;
      });
    }

    this.setState({ titleTabs, loading: false }, () => {
      let { maxSynopsisLength: synopsisCharLength,
        maxSynopsisLongLength,
        maxShortSynopsisLocalLength,
        tvSynopsisLengths } = this.state;

      this.props.handleChildChange('synopsisLengths',
      { synopsisCharLength, maxSynopsisLongLength, maxShortSynopsisLocalLength, tvSynopsisLengths});
    });
  }

  fetchTitle = ({ clientId, externalId, territory, synopsisCharLength,
     maxSynopsisLongLength, maxShortSynopsisLocalLength, tvSynopsisLengths}) => {
    return TitleService.getTitleForClient(clientId, externalId, {
      territory,
      synopsisCharLength,
      tvSynopsisLengths,
      maxSynopsisLongLength,
      maxShortSynopsisLocalLength,
    });
  }

  setTitlesForSeason = () => {
    let titles = this.props.titles;
    let seasonTitle = _.find(titles, UtilityService.isSeason);
    TitleService.getTitlesByParent(seasonTitle.externalId).then(resp => {
      let newtitles = [seasonTitle].concat(resp.data);
      this.setState({
        titles: newtitles,
      }, () => {
        this.setTitleTabs();
      })
    });
  }

  setTitlesState = () => {
    this.setState({loading: true});
    if (seasonTitleDetected(this.state.titles) && !shouldDisableSeasonSetup(this.props.client)) {
      this.setTitlesForSeason();
    } else {
      this.setTitleTabs();
    }
  }

  componentDidMount() {
    this.setTitlesState();
  }

  componentDidUpdate(prevProps) {
    let areEqual = _.isEqual(prevProps.selectedTerritories, this.props.selectedTerritories);
    if (!areEqual && !this.props.singleTitle) {
      this.setState({
        selectedTerritories: this.props.selectedTerritories,
        titleTabIndex: 0,
        territoryTabIndex: 0,
      });
      this.setTitlesState();
    }
  }

  setTitletabsWithState = (newstate) => {
    this.setState(newstate, () => {
      this.setTitleTabs();
    });
  }

  handleSynopsisLengthSet = (label) => (value) => {
    let charLength = Number(value);
    this.setTitletabsWithState({[label]: charLength });
  }

  handleTVSetClicks = (label, value) => {
    let charLength = Number(value);
    let newState = Object.assign({}, this.state);
    newState.tvSynopsisLengths[label] = charLength;
    this.setTitletabsWithState(newState);
  }

  isProductTypeFeature = (productType = '') => {
    return productType.toLowerCase() === 'feature';
  }

  render() {
    let { presentedTitle,
        classes,
        singleTitle = false,
        onSubstituteMPAAClick,
        subMPAA
      } = this.props;
    let { productType } = presentedTitle;
    let { titleTabs, selectedTerritories, titleTabIndex, territoryTabIndex, loading } = this.state;
    let scrollButtonsVariant = titleTabs.length > 5 ? 'auto' : 'off';
    let movielabsProps = { onSubstituteMPAAClick, subMPAA };

    return (
      <React.Fragment>
        <LoadingSpinner active={loading} color={'black'} />
        {
          singleTitle ? (
            <Grid item>
              <Grid container component={TitleDetails}
                direction="column" justify="space-between">
                {
                  this.isProductTypeFeature(productType) ? (
                    <React.Fragment>
                      <CmdgFeatureFields
                        {...this.props}
                        handleSetClick={this.handleSynopsisLengthSet('maxSynopsisLength')}
                        presentedTitle={presentedTitle}
                        maxSynopsisLength={this.state.maxSynopsisLength}
                      />
                      <PXLFeatureFields
                        {...this.props}
                        presentedTitle={presentedTitle}
                        maxSynopsisLength={this.state.maxSynopsisLength}
                        handleSetClick={this.handleSynopsisLengthSet('maxSynopsisLength')}
                        maxSynopsisLongLength={this.state.maxSynopsisLongLength}
                        handleLongSynopsisSet={this.handleSynopsisLengthSet('maxSynopsisLongLength')}
                        maxShortSynopsisLocalLength={this.state.maxShortSynopsisLocalLength}
                        handleShortSynopsisLocalSet={this.handleSynopsisLengthSet('maxShortSynopsisLocalLength')}
                      />

                      <PXLBoxyFeatureFields
                        {...this.props}
                        presentedTitle={presentedTitle}
                        maxSynopsisLength={this.state.maxSynopsisLength}
                        handleSetClick={this.handleSynopsisLengthSet('maxSynopsisLength')}
                        maxSynopsisLongLength={this.state.maxSynopsisLongLength}
                        handleLongSynopsisSet={this.handleSynopsisLengthSet('maxSynopsisLongLength')}
                        maxShortSynopsisLocalLength={this.state.maxShortSynopsisLocalLength}
                        handleShortSynopsisLocalSet={this.handleSynopsisLengthSet('maxShortSynopsisLocalLength')}
                      />

                      <ExhibitionMetadataFeatureFields
                        {...this.props}
                        presentedTitle={presentedTitle}
                        maxSynopsisLength={this.state.maxSynopsisLength}
                        handleSetClick={this.handleSynopsisLengthSet('maxSynopsisLength')}
                        maxSynopsisLongLength={this.state.maxSynopsisLongLength}
                        handleLongSynopsisSet={this.handleSynopsisLengthSet('maxSynopsisLongLength')}
                        maxShortSynopsisLocalLength={this.state.maxShortSynopsisLocalLength}
                        handleShortSynopsisLocalSet={this.handleSynopsisLengthSet('maxShortSynopsisLocalLength')}
                      />
                    </React.Fragment>
                  ) : (
                    <CmdgTvFields
                      {...this.props}
                      handleSetClick={this.handleTVSetClicks}
                      presentedTitle={presentedTitle}
                      tvSynopsisLengths={this.state.tvSynopsisLengths}
                    />
                  )
                }
                <MovielabsTitlePane {...this.props} presentedTitle={presentedTitle} />
              </Grid>
            </Grid>
          ) : (
              <Grid container style={{
                marginTop: 32,
                border: "1px solid rgba(117,117,117, 0.3)"
              }}>
                <Tabs
                  value={titleTabIndex}
                  onChange={this.handleTitleTabChange}
                  indicatorColor="primary"
                  variant="scrollable"
                  scrollButtons={scrollButtonsVariant}
                  textColor="primary"
                  classes={{
                    scrollButtons: classes.scrollButtons,
                  }}>
                  {
                    titleTabs.map((title, index) => {
                      return (
                        <Tab label={title.titleFullName || title.name} key={index}
                          classes={{
                            selected: classes.selectionHighlight,
                            root: classes.label
                          }}
                        />
                      )
                    })
                  }
                </Tabs>
                {
                  titleTabs.map((titleTab, index) => {
                    return (
                      <TabPanel currentIndex={titleTabIndex} index={index} key={index}>
                        <Grid container direction="column" justify="space-between"
                          style={{
                            backgroundColor: "#ffffff",
                            marginBottom: 0,
                            borderTop: "1px solid rgba(117, 117, 117, 0.3)"
                          }}>
                          <Grid item>
                            <TerritoriesPanel
                              {...movielabsProps}
                              tabIndex={territoryTabIndex}
                              presentedTitle={presentedTitle}
                              maxSynopsisLength={this.state.maxSynopsisLength}
                              handleSetClick={this.handleSynopsisLengthSet('maxSynopsisLength')}
                              maxSynopsisLongLength={this.state.maxSynopsisLongLength}
                              handleLongSynopsisSet={this.handleSynopsisLengthSet('maxSynopsisLongLength')}
                              maxShortSynopsisLocalLength={this.state.maxShortSynopsisLocalLength}
                              handleShortSynopsisLocalSet={this.handleSynopsisLengthSet('maxShortSynopsisLocalLength')}
                              handleTVSetClicks={this.handleTVSetClicks}
                              handleTabChange={this.handleTerritoryTabChange}
                              tvSynopsisLengths={this.state.tvSynopsisLengths}
                              selectedTerritories={selectedTerritories}
                              titlesByTerritory={titleTab.titlesByTerritory}
                              client={this.props.client}
                              locales={this.props.locales}
                              isProductTypeFeature={this.isProductTypeFeature}
                            />
                          </Grid>
                          <Grid item>
                            {
                              this.props.children
                            }
                          </Grid>
                        </Grid>
                      </TabPanel>
                    )
                  })
                }
              </Grid>
            )
        }
      </React.Fragment>
    )
  }
}

const clientConnected = clientShow(CmdgTitlePane, "CmdgTitlePane")
const styledComponent = withStyles(style)(clientConnected);
export { styledComponent as CmdgTitlePane };
