import React, { Component } from 'react';
import { Grid, Typography, TextField } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import _ from 'lodash';
import MaskedInput from 'react-text-mask';
import { checkHours } from '../../_helpers/timecodeInputHelper';

const style = (theme) => ({
    label: {
        fontSize: 14,
        color: "#333333",
        fontWeight: 700,
        width: 'fit-content',
        flexBasis: 184,
    },
    textfield: {
        marginRight: 10,
        marginBottom: 15,
        width: '16vw',
    },
    item: {
        display: 'flex',
    },
    input: {
        position: 'relative',
        height: '28px !important',
    }
});

const NdfMaskInput = (props) => {
    const { inputRef, ...other } = props;
    return (
        <MaskedInput
        {...other}
        ref={ref => {
          inputRef(ref ? ref.inputElement : null);
        }}
        mask={[/[0-2]/, checkHours(other.value), ":", /[0-5]/, /\d/, ":", /[0-5]/, /\d/, ":", /[0-5]/, /\d/]}
        placeholder='HH:MM:SS:FRAMES'
      />
    )
}

const DfMaskInput = (props) => {
    const { inputRef, ...other } = props;
    return (
        <MaskedInput
        {...other}
        ref={ref => {
          inputRef(ref ? ref.inputElement : null);
        }}
        mask={[/[0-2]/, checkHours(other.value), ":", /[0-5]/, /\d/, ":", /[0-5]/, /\d/, ";", /[0-5]/, /\d/]}
        placeholder='HH:MM:SS;FRAMES'
      />
    )
}

class TimecodeProgramField extends Component {
    constructor(props) {
        super(props);
        let value = props.value[props.field];
        this.state = value || { in: '', out: '', error: false };
    }

    componentDidUpdate(prevProps) {
        let field = this.props.field;
        if(!_.isEqual(prevProps.value[field],this.props.value[field])) {
            let newValue = this.props.value[field];
            let newState = Object.assign({}, newValue);
            this.setState(newState);
        }
    }

    checkIfFrameRateExceeds = (value, isNonDropFrame) => {
        let frameRate = isNonDropFrame === 'yes' ? value.split(":")[3] : value.split(";")[1]
        return frameRate > this.props.frameRateLimit;
    }

    handleChange = (label, e, isNonDropFrame) => {
        let value = e.target.value;
        let field = this.props.field;
        let regex = isNonDropFrame === 'yes' ? new RegExp(/\d\d:\d\d:\d\d:\d\d/g) : new RegExp(/\d\d:\d\d:\d\d;\d\d/g)
        let index = Number(this.props.index);
        let error = this.props.value[field].error || false;

        let doesRegexPass = regex.test(value);

        if(doesRegexPass) {
            error = this.props.frameRateLimit !== 0 ? this.checkIfFrameRateExceeds(value, isNonDropFrame) : false;
            this.props.showNotification(error, "'Timecode Data' frame rate cannot exceed 'Video Data' frame rate");
        }

        this.setState({
            [label]: value,
            out: value,
            error,
        }, () => {
            // call parent onChange and do postchange
            if(doesRegexPass || value === "") {
                this.props.onChange(field, this.state, index);
            }
        })
    }

    render() {
        const { classes, field, isNonDropFrame } = this.props;
        return (
            <Grid container direction="row" style={{
                marginTop: 16,
            }}>
                <Grid item component={Typography}
                    className={classes.label}>
                    {_.startCase(field)}
                </Grid>
                <Grid item className={classes.item}>
                    <TextField
                        className={classes.textfield}
                        value={this.state.in}
                        onChange={e => this.handleChange('in', e, isNonDropFrame)}
                        variant="outlined"
                        error={this.state.error || false}
                        InputProps={{
                            inputComponent: isNonDropFrame === 'yes' ? NdfMaskInput : DfMaskInput,
                            classes : { root: classes.input }
                        }}
                    />
                </Grid>
            </Grid>
        )
    }
}

const styled = withStyles(style)(TimecodeProgramField);
export { styled as TimecodeProgramField }