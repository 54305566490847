import React from 'react';
import { RadioButton, DatePicker} from '../common';
import { Grid } from "@material-ui/core";

export class SeasonDatePicker extends React.Component {
  constructor(props) {
    super(props);
    let { startDate=null, endDate=null, applyAll=false } = props;
    this.state = {
      startDate,
      endDate,
      applyAll,
    }
  }

  handleCheckBox = (checked) => {
    this.setState(() => {
        return {
            applyAll: checked,
        };
    }, () => {
      this.props.onChange(this.state);
    });
  }

  handleDateChange = (data) => {
      let {startDate , endDate } = data;
      this.setState(() => {
          return {
              startDate,
              endDate,
          };
      }, () => {
        this.props.onChange(this.state);
      });
  }

  disableApplyAll = () => {
    return !(this.state.startDate && this.state.endDate);
  }

  render() {
    return (
      <Grid container direction="row">
        <Grid item component={DatePicker}
            label="Licensing Window"
            startDate={this.state.startDate}
            endDate={this.state.endDate}
            onChange={this.handleDateChange}
            showNotification={this.props.showNotification}
        />

        <Grid item component={RadioButton}
            label="Apply to all episodes"
            value={this.state.applyAll}
            handleChange={this.handleCheckBox}
            disabled={this.disableApplyAll()}
        />
      </Grid>
    );
  }
}