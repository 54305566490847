import React, { Component } from "react";
import { Grid, MenuItem, Typography } from "@material-ui/core"
import _ from 'lodash';
import { Dropdown, FilledTextInput } from '../common';
import { profiles } from './videoEncodingProfiles';

const types = ['Archival', 'Archival_CHD', 'DubCard', 'P2P', 'P2P_CHD', 'Slate', 'Textless'];
const threeDTypes = ['N/A', 'Left Eye', 'Right Eye', 'Left Eye/Right Eye', 'Over Under', 'Side By Side'];
const scanTypes = ['Progressive', 'Interlaced'];
const chromaSubsamplings = ['4:2:2', '4:4:4'];
const colourPrimariesList = ['BT.709', 'BT.2020', 'BT.601 NTSC', 'BT.601 PAL'];
const videoColorSpaces = ['YUV', 'RGB'];
const videoFormats = ['JPEG 2000', 'ProRes'];
const screenAspectRatios = ['16x9', '4x3'];
const horizontalResolutions = ["3840", "1920", "720"];
const verticalResolutions = ["2160", "1080", "728", "576", "486", "480"];
const frameRates = ["23.976", "25.00", "29.97", "59.94"];

class ProfileInfo extends Component {

    handleChange = (label, value) => {
        let newState = Object.assign({}, this.props.profile);
        newState[label] = value;
        let { type, type3d, scanningFormat, chromaSubsampling, videoFormat, screenAspectRatio, horizontalResolution,
            verticalResolution, frameRate } = newState;
        let profile = profiles.filter((profile) => {
            return (type === profile.type &&
                type3d === profile.type3d &&
                scanningFormat === profile.scanningFormat &&
                chromaSubsampling === profile.chromaSubsampling &&
                videoFormat === profile.videoFormat &&
                screenAspectRatio === profile.screenAspectRatio &&
                horizontalResolution.toString() === profile.horizontalResolution.toString() &&
                verticalResolution.toString() === profile.verticalResolution.toString() &&
                frameRate === profile.frameRate);
        });
        if (!_.isEmpty(profile)) {
            newState.encodingProfile = profile[0].encodingProfile;
            newState.videoStandard = profile[0].videoStandard;
        } else {
            newState.encodingProfile = "";
            newState.videoStandard = "";
        }
        this.props.handleProfileChange(newState);
    }

    checkIfPropertyHasError = (name) => {
        let hasError = _.isEmpty(this.props.profile[name]);
        return this.props.isFilenameFilled && hasError;
    }

    render() {
        let { errorsEnabled } = this.props;
        let { type, type3d, scanningFormat, chromaSubsampling, screenAspectRatio, horizontalResolution, colorPrimaries,
            videoColorSpace, videoFormat, verticalResolution, frameRate, encodingProfile, videoStandard } = this.props.profile;
        return (
            <Grid container direction="row" justify="space-between">

                <Grid item component={Dropdown}
                    label="Type"
                    field={'type'}
                    onChange={this.handleChange}
                    value={type}
                >
                    {
                        _.map(types, (type, index) => {
                            return (
                                <MenuItem key={index} value={type}>
                                    <Typography noWrap={true}>{type}</Typography>
                                </MenuItem>
                            )
                        })
                    }
                </Grid>

                <Grid item component={Dropdown}
                    label="3D Type"
                    field={'type3d'}
                    onChange={this.handleChange}
                    value={type3d}
                >
                    {
                        _.map(threeDTypes, (type, index) => {
                            return (
                                <MenuItem key={index} value={type}>
                                    <Typography noWrap={true}>{type}</Typography>
                                </MenuItem>
                            )
                        })
                    }
                </Grid>

                <Grid item component={Dropdown}
                    label="Scan Type"
                    field={'scanningFormat'}
                    onChange={this.handleChange}
                    value={scanningFormat}
                >
                    {
                        _.map(scanTypes, (type, index) => {
                            return (
                                <MenuItem key={index} value={type}>
                                    <Typography noWrap={true}>{type}</Typography>
                                </MenuItem>
                            )
                        })
                    }
                </Grid>

                <Grid item component={Dropdown}
                    label="Chroma Subsampling"
                    field={'chromaSubsampling'}
                    onChange={this.handleChange}
                    value={chromaSubsampling}
                >
                    {
                        _.map(chromaSubsamplings, (sampling, index) => {
                            return (
                                <MenuItem key={index} value={sampling}>
                                    <Typography noWrap={true}>{sampling}</Typography>
                                </MenuItem>
                            )
                        })
                    }
                </Grid>

                <Grid item component={Dropdown}
                    label="Colour Primaries"
                    field={'colorPrimaries'}
                    onChange={this.handleChange}
                    value={colorPrimaries}
                >
                    {
                        _.map(colourPrimariesList, (primary, index) => {
                            return (
                                <MenuItem key={index} value={primary}>
                                    <Typography noWrap={true}>{primary}</Typography>
                                </MenuItem>
                            )
                        })
                    }
                </Grid>

                <Grid item component={Dropdown}
                    label="Video Color Space"
                    field={'videoColorSpace'}
                    onChange={this.handleChange}
                    value={videoColorSpace}
                >
                    {
                        _.map(videoColorSpaces, (colorSpace, index) => {
                            return (
                                <MenuItem key={index} value={colorSpace}>
                                    <Typography noWrap={true}>{colorSpace}</Typography>
                                </MenuItem>
                            )
                        })
                    }
                </Grid>

                <Grid item component={Dropdown}
                    label="Video Format"
                    field={'videoFormat'}
                    onChange={this.handleChange}
                    value={videoFormat}
                >
                    {
                        _.map(videoFormats, (format, index) => {
                            return (
                                <MenuItem key={index} value={format}>
                                    <Typography noWrap={true}>{format}</Typography>
                                </MenuItem>
                            )
                        })
                    }
                </Grid>

                <Grid item component={Dropdown}
                    label="Screen Aspect Ratio"
                    field={'screenAspectRatio'}
                    onChange={this.handleChange}
                    value={screenAspectRatio}
                >
                    {
                        _.map(screenAspectRatios, (ratio, index) => {
                            return (
                                <MenuItem key={index} value={ratio}>
                                    <Typography noWrap={true}>{ratio}</Typography>
                                </MenuItem>
                            )
                        })
                    }
                </Grid>

                <Grid item component={Dropdown}
                    label="Horizontal Resolution"
                    field={'horizontalResolution'}
                    onChange={this.handleChange}
                    value={horizontalResolution}
                >
                    {
                        _.map(horizontalResolutions, (resolution, index) => {
                            return (
                                <MenuItem key={index} value={resolution}>
                                    <Typography noWrap={true}>{resolution}</Typography>
                                </MenuItem>
                            )
                        })
                    }
                </Grid>

                <Grid item component={Dropdown}
                    label="Vertical Resolution"
                    field={'verticalResolution'}
                    onChange={this.handleChange}
                    value={verticalResolution}
                >
                    {
                        _.map(verticalResolutions, (resolution, index) => {
                            return (
                                <MenuItem key={index} value={resolution}>
                                    <Typography noWrap={true}>{resolution}</Typography>
                                </MenuItem>
                            )
                        })
                    }
                </Grid>

                <Grid item component={Dropdown}
                    label="Frame Rate"
                    field={'frameRate'}
                    onChange={this.handleChange}
                    value={frameRate}
                >
                    {
                        _.map(frameRates, (rate, index) => {
                            return (
                                <MenuItem key={index} value={rate}>
                                    <Typography noWrap={true}>{rate}</Typography>
                                </MenuItem>
                            )
                        })
                    }
                </Grid>

                <Grid item container>

                    <Grid item component={FilledTextInput}
                        label="Encoding Profile"
                        disabled={_.isEmpty(encodingProfile)}
                        error={errorsEnabled && this.checkIfPropertyHasError('encodingProfile')}
                        readOnly={true}
                        helperText="Not a supported encoding profile"
                        style={{
                            width: '32vw',
                        }}
                        value={encodingProfile}
                    />

                    <Grid item component={FilledTextInput}
                        label="Video Standard"
                        disabled={_.isEmpty(videoStandard)}
                        helperText="Not a supported video standard"
                        error={errorsEnabled && this.checkIfPropertyHasError('videoStandard')}
                        readOnly={true}
                        value={videoStandard}
                    />
                </Grid>

            </Grid>
        )
    }
}

export { ProfileInfo };