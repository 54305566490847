import axios from 'axios';
import config from 'config';
import { history } from '../_helpers/history';

const endpoint = (path) => {
  return `${config.apiUrl}${path}`;
};

const get = (path) => {
  return axios.get(endpoint(path), authHeaders()).then(null, redirectOn401);
};

const post = (path, data) => {
  return axios.post(endpoint(path), data, authHeaders())
    .then(null, redirectOn401);
};

const httpDelete = (path) => {
  return axios.delete(endpoint(path), authHeaders()).then(null, redirectOn401);
};

const getJwtToken = () => {
  let user = localStorage.getItem('user');
  return user && JSON.parse(user).token;
};

const redirectOn401 = (err) => {
  if (err.response.status === 401) {
    let location = {
      pathname: '/login',
    };
    history.push(location);
  }
};

const authHeaders = () => {
  return {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${getJwtToken()}`,
    },
  };
};

export const SidecarService = {
  get,
  post,
  delete: httpDelete,
};
