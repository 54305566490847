import React, { Component } from "react";
import PropTypes from 'prop-types';
import { withStyles } from "@material-ui/core/styles";
import { Modal, Grid, Fab, IconButton, MenuItem, InputLabel, Select, Typography } from "@material-ui/core";
import SvgCloseX from "../assets/icons/CloseX";
import { TitleHeader } from '../components/TitleDisplay/TitleHeader';
import { TitlePriorityList } from './';
import _ from 'lodash';

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
    alignItems: "stretch",
    borderRadius: 16,
    backgroundColor: '#f5f5f5',
  };
}

const styles = theme => ({
    dropDownStyle: {
        maxHeight: 272,
        width: 272,
    },
    select: {
        width: 272,
        marginBottom: 16,
    },
    label: {
        top: 8,
        fontSize: 14,
        color: '#333333',
        fontWeight: 700,
        marginRight: 12,
        position: 'relative'
    },
    paper: {
        position: "absolute",
        width: 704,
        height: 590,
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: '8px 16px 8px 16px',
        outline: "none",
    }
});

class TitleProfileModal extends Component {

  constructor(props) {
    super(props);
    let defaultState = this.getState(props.priorityData);
    this.state = Object.assign({}, defaultState);
  }

  getState = (priorityData, territory = "") => {
    let { title, eidr, alphaId, mpmWalkerId, isan, copyrightLine, 
      mpaaRatingReason } = priorityData
    let territories = title.map((titleInfo) => {
      return titleInfo.territoryName;
    });

    territory = territory || territories[0];
    
    let titleData = this.getTerritoryData(title, territory, 'data');
    let eidrTD = this.getTerritoryData(eidr, territory, 'eidr') 
    let alphaIdTD = this.getTerritoryData(alphaId, territory, 'alphaId') 
    let mpmWalkerIdTD = this.getTerritoryData(mpmWalkerId, territory, 'mpmWalkerId') 
    let isanTD = this.getTerritoryData(isan, territory, 'isan') 
    let copyrightLineTD = this.getTerritoryData(copyrightLine, territory, 'copyrightLine') 
    let mpaaRatingsReasonTD = this.getTerritoryData(mpaaRatingReason, territory, 'mpaaRatingReason') 
                

    return {
      territories,
      selectedTerritory: territory || '',
      titleData,
      eidrTD,
      alphaIdTD,
      mpmWalkerIdTD,
      isanTD,
      copyrightLineTD,
      mpaaRatingsReasonTD
    }
  }

  getTerritoryData = (collection, predicate, label) => {
    if(_.isEmpty(collection)) {
      return null;
    }
    let result = _.find(collection, { territoryName: predicate});
    if (!result) {
      return null;
    } 
    return result[label] || "";
  }

  componentDidUpdate() {
    let { selectedTerritory } = this.state;
    let newState = Object.assign({}, this.state,
                    this.getState(this.props.priorityData, selectedTerritory));
    if(!_.isEqual(newState.titleData, this.state.titleData)) {
      this.setState(newState);
    }
  }

  onTerritoryChange = (e) => {
    let selectedTerritory = e.target.value;
    let newState = Object.assign({}, this.state,
                    this.getState(this.props.priorityData, selectedTerritory));
    this.setState(newState);
  }

  renderField = (label, value) => {
    return (
      !_.isNull(value) ? (
        <Grid container key={label} direction="row" style={{
          margin: '4px auto'
        }}>
            <Grid item component={Typography} style={{
                fontWeight: 700,
                flexBasis: 160,
                color: '#333333'
            }}>
                {label}:
            </Grid>
            <Grid item component={Typography} style={{
                maxWidth: '60%'
            }}>
              {value || 
              <span className="no-data-found">No Data Found</span>}
            </Grid>
        </Grid>
      ) : (
        <Grid container key={label} direction="row" style={{
          margin: '4px auto'
        }}>
            <Grid item component={Typography} style={{
                fontWeight: 700,
                flexBasis: 160,
                color: '#333333'
            }}>
                {label}:
            </Grid>
            <Grid item component={Typography} style={{
                maxWidth: '60%'
            }}>
              <span className="no-data-found">Not Configured</span>
            </Grid>
        </Grid>
      ) 
    )
  }


  render() {
    const { classes, open, close } = this.props;
    let { selectedTerritory, territories, titleData, eidrTD,
      alphaIdTD, mpmWalkerIdTD, isanTD,
      copyrightLineTD, mpaaRatingsReasonTD } = this.state;
    return (
        <Modal
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
          open={open}
          onClose={close}
          className="title-modal"
        >
          <Grid container direction="column" justify="flex-start" wrap="nowrap" 
            style={getModalStyle()} className={classes.paper}>
            <Grid item style={{ alignSelf: 'flex-end' }}>
              <IconButton onClick={close}>
                <SvgCloseX style={{ height: 15, width: 16, color: '#333333' }}/>
              </IconButton>
            </Grid>
            <Grid item style={{ height: "fit-content" }}>
                <TitleHeader />
                <Grid container direction="row">
                    <Grid item>
                        <InputLabel className={classes.label}>Territory/Region:</InputLabel>
                    </Grid>
                    <Grid item>
                        <Select 
                            value={selectedTerritory}
                            onChange={this.onTerritoryChange}
                            className={classes.select}
                            MenuProps={{
                                classes: { paper: classes.dropDownStyle },
                                getContentAnchorEl: null,
                                anchorOrigin: {
                                    vertical: "bottom",
                                    horizontal: "left",
                                }
                            }}
                        >
                            {
                                territories.map((territory, index) => {
                                    return (
                                        <MenuItem key={index} value={territory}>
                                            <Typography>{territory}</Typography>
                                        </MenuItem>
                                    )
                                })
                            }
                        </Select>
                    </Grid>
                </Grid>
                
                <Grid container direction="column" className="title-pane">
                  <Grid container direction="row" className="title-pane-content">
                    <TitlePriorityList territoryData={titleData}/>
                    {
                        this.renderField('EIDR', eidrTD)
                    }
                    {
                        this.renderField('Alpha ID', alphaIdTD)
                    }
                    {
                        this.renderField('MPM', mpmWalkerIdTD)
                    }
                    {
                        this.renderField('ISAN', isanTD)
                    }
                    {
                        this.renderField('Copyright Line', copyrightLineTD)
                    }
                    {
                        this.renderField('Ratings Reason', mpaaRatingsReasonTD)
                    }
                  </Grid>
                </Grid>
                
            </Grid>
            <Grid item style={{ alignSelf: 'center', marginTop: 24 }}>
              <Fab variant="extended" color="primary" style={{ height: 40, width: 168 }} 
              onClick={close}>Close</Fab>
            </Grid>
          </Grid>
        </Modal>
    );
  }
}

TitleProfileModal.propTypes = {
  open: PropTypes.bool,
  close: PropTypes.func,
}

const modalWithStyles = withStyles(styles)(TitleProfileModal);
export { modalWithStyles as TitleProfileModal};