export default [
  "Afghanistan",
  "Africa",
  "Albania",
  "Algeria",
  "American Samoa",
  "Andorra",
  "Angola",
  "Anguilla",
  "Antigua & Barbuda",
  "Argentina",
  "Armenia",
  "Aruba",
  "Asian CIS Republics",
  "Australia",
  "Australia [excl islands]",
  "Australia Adj Islands",
  "Austria",
  "Azerbaijan",
  "Bahamas",
  "Bahrain",
  "Bangladesh",
  "Barbados",
  "Belarus",
  "Belgium",
  "Belize",
  "Benelux",
  "Benin",
  "Bermuda",
  "Bhutan",
  "Bolivia",
  "Bonaire",
  "Bosnia/Herzegovina",
  "Botswana",
  "Brazil",
  "British Virgin Islands",
  "Brunei",
  "Bulgaria",
  "Burkina Faso",
  "Burundi",
  "C.I.S.",
  "Cambodia",
  "Cameroon",
  "Canada",
  "Canary Islands",
  "Cape Verde",
  "Caribbean Islands",
  "Cayman Islands",
  "Central African Republic",
  "Central America",
  "Chad",
  "Chile",
  "China",
  "Colombia",
  "Comoros",
  "Congo - Republic of",
  "Congo -Dem Rep of (Zaire)",
  "Cook Islands",
  "Costa Rica",
  "Croatia",
  "Cuba",
  "Curacao",
  "Cyprus",
  "Czech Republic",
  "Czechoslovakia (Former)",
  "Denmark",
  "Denmark [excl Greenland]",
  "Djibouti",
  "Dominica",
  "Dominican Republic",
  "East Timor",
  "Eastern Europe",
  "Ecuador",
  "Egypt",
  "El Salvador",
  "Equatorial Guinea",
  "Eritrea",
  "Estonia",
  "Ethiopia",
  "Far East",
  "Fiji",
  "Finland",
  "France",
  "France [Metropolitan]",
  "French Antilles",
  "French DOM-TOM",
  "French Guiana",
  "French Polynesia",
  "Gabon",
  "Gambia",
  "Georgia",
  "Germany",
  "Ghana",
  "Gibraltar",
  "Greece",
  "Greenland",
  "Grenada",
  "Guadeloupe",
  "Guam",
  "Guatemala",
  "Guinea",
  "Guinea-Bissau",
  "Guyana",
  "Haiti",
  "Honduras",
  "Hong Kong",
  "Hungary",
  "Iceland",
  "India",
  "Indonesia",
  "Iran",
  "Iraq",
  "Ireland",
  "Israel",
  "Italy",
  "Ivory Coast",
  "Jamaica",
  "Japan",
  "Jordan",
  "Kazakhstan",
  "Kenya",
  "Kiribati",
  "Kosovo",
  "Kuwait",
  "Kyrgyzstan",
  "Laos",
  "Latin America",
  "Latvia",
  "Lebanon",
  "Lesotho",
  "Liberia",
  "Libya",
  "Liechtenstein",
  "Lithuania",
  "Luxembourg",
  "Macau",
  "Macedonia",
  "Madagascar",
  "Madeira",
  "Malawi",
  "Malaysia",
  "Maldives",
  "Mali",
  "Malta",
  "Marshall Islands",
  "Martinique",
  "Mauritania",
  "Mauritius",
  "Mayotte",
  "Mexico",
  "Micronesia",
  "Middle East",
  "Moldova",
  "Monaco",
  "Mongolia",
  "Montenegro",
  "Montserrat",
  "Morocco",
  "Mozambique",
  "Myanmar",
  "Namibia",
  "Nauru",
  "Nepal",
  "Netherlands",
  "Netherlands Antilles",
  "New Caledonia",
  "New Zealand",
  "New Zealand [excl islnds]",
  "New Zealand Adj Islands",
  "Nicaragua",
  "Niger",
  "Nigeria",
  "Niue Island",
  "North Korea",
  "Northern Mariana Islands",
  "Norway",
  "Oman",
  "Pakistan",
  "Palau",
  "Panama",
  "Papua New Guinea",
  "Paraguay",
  "Peru",
  "Philippines",
  "Poland",
  "Portugal",
  "Puerto Rico",
  "Qatar",
  "Quebec",
  "Republic of Yemen",
  "Reunion",
  "Romania",
  "Russia",
  "Rwanda",
  "Saba",
  "Sao Tome & Principe",
  "Saudi Arabia",
  "Scandinavia",
  "Senegal",
  "Serbia",
  "Serbia & Montenegro",
  "Seychelles",
  "Sierra Leone",
  "Singapore",
  "Slovakia",
  "Slovenia",
  "Solomon Islands",
  "Somalia",
  "South Africa",
  "South America",
  "South Korea",
  "Spain",
  "Spain [excl Canary/Andor]",
  "Sri Lanka",
  "St. Eustatius",
  "St. Helena",
  "St. Kitts-Nevis",
  "St. Lucia",
  "St. Maarten",
  "St. Martin",
  "St. Pierre & Miquelon",
  "St. Vincent & Grenadines",
  "Sudan",
  "Surinam",
  "Swaziland",
  "Sweden",
  "Switzerland",
  "Syria",
  "Tahiti",
  "Taiwan",
  "Tajikistan",
  "Tanzania",
  "Thailand",
  "Togo",
  "Tonga",
  "Trinidad & Tobago",
  "Tunisia",
  "Turkey",
  "Turkmenistan",
  "Turks & Caicos",
  "Tuvalu",
  "U.S. t's & p's",
  "U.S. Virgin Islands",
  "U.S.A.",
  "U.S.A.[excl t's & p's]",
  "U.S.S.R. (Former)",
  "Uganda",
  "Ukraine",
  "United Arab Emirates",
  "United Kingdom",
  "Universe",
  "Uruguay",
  "Uzbekistan",
  "Vanuatu",
  "Venezuela",
  "Vietnam",
  "Wallis & Futuna",
  "West Germany",
  "West Indies (Fmr British)",
  "Western Europe",
  "Western Sahara",
  "Western Samoa",
  "World",
  "Yemen Arab Republic",
  "Yemen People's Republic",
  "Yugoslavia (Former)",
  "Zambia",
  "Zimbabwe",
];
