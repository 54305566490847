import React, { useState } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import { TextField, InputAdornment, InputLabel, Fab, Grid } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { clientShow } from '../Clientshow/clientshow';
import SvgSearch from "../../assets/icons/Search";
import { TitleService } from '../../_services';
import { TitleActions } from '../../_actions/title.actions';
import NotifierAction from '../../_actions/notifier.actions';

const placeholderText = 'Search by MPM, GPMS ID, EIDR, or Alpha ID';
const fetchTitlesButtonText = 'Fetch Titles';

const styles = theme => ({
  input: {
    fontSize: 14,
    height: 42,
    [theme.breakpoints.down('sm')]: {
      width: "calc(100vw - 304px)",
    },
    [theme.breakpoints.up('md')]: {
      width: "calc(100vw - 304px)",
    },
    [theme.breakpoints.up('lg')]: {
      width: "calc(100vw - 304px)",
    },
  }
})

const FetchTitlesButton = (props) => {
    let { loadingSpinner, disabled } = props;

    return (
      <div>
        <Fab variant="extended" color="secondary"
        className={loadingSpinner ? 'title-details-button loading' : 'title-details-button'} disabled={disabled}
        onClick={props.onClick}
      >
          {loadingSpinner ? <Loader type="line-spin-fade-loader" active={true} /> : fetchTitlesButtonText}
        </Fab>
      </div>
    )
}

const TitleSearchMultiInput = (props) => {
    let [inputIds, setInputIds] = useState('')
    let [errors, setErrors] = useState({});

    let showMissingIdsError = (missingIds) => {  
        let msg = `These Input ID(s): ${missingIds.join(', ')} were not found.`;
         props.showNotification && props.showNotification(true, msg);
    }
    
    let handleErrors = (missingIds) => {
        setTimeout(() => {
            showMissingIdsError(missingIds);
        }, 0);
    }

    const deboucedMultiInput = _.debounce((e) => {
        if(!e.target.value.length){
            props.reset()
        }
        setInputIds(e.target.value);
      }, 550);

    const handleClick = async () => {
        let ids = inputIds.split(',').map(id => id.trim())
        console.log({ids})

        let result = []
        result = await TitleService.search(ids).catch(ex => {
            console.log(ex)
            throw new Error("Error from title service")
        })

        if (ids[0] !== '' && ids.length !== result.data.length){
            let missingIds = []

            let mapped = result.data.map(title => {
                return `${title.mpmWalkerId}|${title.eidr_s}|${title.alphaId}|${title.externalId}`
            })

            ids.forEach(id => {
                if(!mapped.join().includes(id)){
                    missingIds.push(id)
                }
            })
            if (missingIds.length > 0){
                setErrors(missingIds)
                handleErrors(missingIds);
                console.log("There are Ids that did not match any of the titles: ", missingIds)
            }
        }

        if (result.data.length){
            props.addTitles(result.data)
        }      
    }
    
    const handleMultiInput = (e) => {
        props.reset()
        e.persist();
        deboucedMultiInput(e)
    }

    let { placeholder = placeholderText, classes} = props;
    return (
        <Grid container direction="row" justify="space-between">
        <Grid item component={TextField}
            placeholder={placeholder}
            InputProps={{
            startAdornment: <InputAdornment position="start"><SvgSearch></SvgSearch></InputAdornment>,
            className: classes.input,
            }}
            onChange={handleMultiInput}  
        />
        <Grid item className="title-details">
            <FetchTitlesButton {...props} onClick={handleClick}/>
        </Grid>
        <Grid item style={{marginTop: 16}}>
        </Grid>
        </Grid>
    );
}

const TitleMultiContainer = (props) => {
    return (
      <Grid container direction="column" justify="space-between" component="section">
        <Grid item className="title-label">
          <InputLabel className="client-data-label">Title Lookup:</InputLabel>
        </Grid>
        <Grid item container justify="space-between">
          <TitleSearchMultiInput {...props} />
        </Grid>
      </Grid>
    )
  }

function mapDispatchToProps(dispatch) {
    let { addTitles, reset } = TitleActions;
    let { showNotification } = NotifierAction;

    return {
        addTitles: (titles) => {
            dispatch(addTitles(titles));
        },
        reset: () => {
            dispatch(reset())
        },
        showNotification: (errorStatus, msg) => {
            dispatch(showNotification(errorStatus, msg, 'warning'));
        },
    }
}
  
const connected = connect(null, mapDispatchToProps)(TitleMultiContainer);
let clientConnected = clientShow(connected, 'TitleSearchMultiInput');
let styled = withStyles(styles)(clientConnected);
export { styled as TitleSearchMultiInput };



  