import React, { Component } from 'react';
import _ from 'lodash';
import { Grid, Typography } from '@material-ui/core';
import { TextInputWithButton } from './';

class TitleField extends Component {

    getValue = ({field, title, subfield, formatFunc}) => {
        if (!title) {
            return '';
        }

        let value = title[field];

        if(_.isArray(value)) {
            value = this.getStringFromArray(value, subfield)
        } else if(value && subfield) {
            value = value[subfield];
        }

        if(!value) {
            return null;
        }

        return formatFunc ? formatFunc(value) : value;
    }

    getStringFromArray = (array, subfield) => {
        if(!subfield) {
            return array.map(item => item).join(', ');
        }
        return array.map(item => {
            return item[subfield]
        }).join(', ');
    }

    render() {
        let value = this.getValue(this.props);
        let warning = this.props.warning || '';
        let warningCssClass = value ? '' : 'no-data-found';
        let { maxSynopsisLength=250 } = this.props;
        return (
            <Grid container key={this.props.label} direction="row" style={{
                margin: '4px auto'
            }}>
                <Grid item style={{
                    fontWeight: 700,
                    flexBasis: 160,
                }}>
                    <Typography style={{ color: "#333333" }}>{this.props.label}:</Typography>
                    <TextInputWithButton client={this.props.client} {...this.props}
                        value={maxSynopsisLength} label="(Max Character Length)" buttonText="Set"
                    />
                </Grid>
                <Grid item component={Typography} className={warningCssClass} style={{
                    maxWidth: '50%'
                }}>{value || warning}</Grid>
            </Grid>
        )
    }
}

export { TitleField };