import React, { Component } from 'react';
import { Grid, InputLabel, Select, TextField, InputAdornment, MenuItem, Typography } from '@material-ui/core';
import config from 'config';
import { withStyles } from '@material-ui/styles';
import SvgSearch from "../../assets/icons/Search";
import { clientShow } from '../Clientshow/clientshow';

const styles = (theme) => ({
    root: {
      width: 272,
    },
    dropDownStyle: {
      maxHeight: 272,
      width: 272,
    },
    label: {
      top: 3,
      fontSize: 14,
      color: '#333333',
      fontWeight: 700,
      marginLeft: 32,
      marginRight: 12,
      position: 'relative'
    }
});

class ModalTerritorySelect extends Component {

    constructor(props) {
        super(props);
        this.state = {
            selectedTerritory: props.chipValue ? props.chipValue.name : 'U.S.A. (English)',
            defaultTerritories: props.selectedTerritories || config.territories,
            territories: props.selectedTerritories || config.territories,
            open: false,
        }
    }

    handleSelect = (event) => {
        if(!event.target.value) {
            event.preventDefault();
        } else {
            /* Get the spec by using ClientspecService in the callback
            of below setState */
            this.setState({
                selectedTerritory: event.target.value,
                open: false,
            }, () => {
                this.fetchClientSpec(event.target.value);
            });
        }
    }

    fetchClientSpec = (territoryName) => {
        let territoryValue = territoryName || this.state.selectedTerritory;
        let territoryObj = _.find(config.territories, { name: territoryValue });
        this.props.handleLanguageChange(territoryObj, null);
    }

    onOpen = () => {
        this.setState({
            territories: this.state.territories,
            open: true,
        });
    }

    onClose = () => {
        event.preventDefault();
        if(event.target.tagName === 'DIV') {
            this.setState({
                open: false,
                territories: this.state.territories,
                selectedTerritory: 'U.S.A. (English)',
            });
        }
    }

    inputChange = (event) => {
        event.stopPropagation();
        this.setState({
            selectedTerritory: 'U.S.A. (English)',
            territories: this.state.defaultTerritories.filter((territory) => {
                return territory.name.toLowerCase().includes(event.target.value.toLowerCase());
            }),
        });
    }

    render() {
        let classes = this.props.classes;
        return (
            <Grid container direction="row" justify="flex-start">
                <Grid item >
                    <InputLabel shrink={false} className={classes.label}>Territory/Region:</InputLabel>
                </Grid>
                <Grid item style={{ position: 'relative', bottom: 8 }}>
                    <Select autoWidth={true}
                    open={this.state.open}
                    value={this.state.selectedTerritory}
                    onOpen={this.onOpen}
                    onClose={this.onClose}
                    className={classes.root}
                    onChange={this.handleSelect}
                    MenuProps={{
                        classes: { paper: classes.dropDownStyle },
                        getContentAnchorEl: null,
                        anchorOrigin: {
                            vertical: "bottom",
                            horizontal: "left",
                        }
                    }}>
                    <TextField placeholder="Search" id="input" onChange={this.inputChange}
                            style={{ marginBottom: 16, marginRight: 16, marginLeft: 16, width: 228 }}
                            InputProps={{
                                startAdornment: <InputAdornment position="start"><SvgSearch></SvgSearch></InputAdornment>,
                                autoComplete: "off"
                            }}
                    />
                    {this.state.territories.map(territory => (
                        <MenuItem key={territory.sourceTerritory} value={territory.name}>
                            <Typography className="territory-list" noWrap={true}>{territory.name}</Typography>
                        </MenuItem>
                    ))}
                    </Select>
                </Grid>
            </Grid>
        );
    }
}

let clientConnected = clientShow(ModalTerritorySelect, 'ModalTerritorySelect');
let styledSelect = withStyles(styles)(clientConnected);
export { styledSelect as ModalTerritorySelect };