import React, { Component } from 'react';
import { Grid, ExpansionPanel, ExpansionPanelDetails,
    ExpansionPanelSummary, Divider, Typography } from '@material-ui/core';
import { ExpandableSection } from '../common';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

class TitleInfo extends Component {
    render() {
        let header = this.props.header ? 
                    this.props.header.toUpperCase() : 'TITLE PANE';
        let defaultFocusClass = 'normal'
        return (
            <Grid container component={ExpandableSection} header={header} 
                defaultExpanded={true} focusErrorCssStyle={defaultFocusClass}>
        
                <Grid container direction="column" justify="space-between">
                    {
                        this.props.children
                    }
                </Grid>
                    
            </Grid>
        );
    };
};

export { TitleInfo };