import React from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle,
    DialogContentText, Button, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';

const ConfirmationDialog = (props) => {
    let { open, dialogText, acceptButtonText = 'Yes', cancelButtonText = 'No' } = props;
    return (
        <Dialog
            open={open}
            onClose={props.onClose}
            aria-labelledby="alert-dialog-title"
        >
            <DialogTitle>JUST A SECOND</DialogTitle>
            <DialogContent>
                <DialogContentText style={{
                    color: '#333333'
                }}>
                    {dialogText}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={props.cancelButtonHandler} color="primary">{cancelButtonText}</Button>
                <Button onClick={props.acceptButtonHandler} color="primary">{acceptButtonText}</Button>
            </DialogActions>
        </Dialog>
    )
}

ConfirmationDialog.propTypes = {
    open: PropTypes.bool.isRequired,
    dialogText: PropTypes.string.isRequired,
    onClose: PropTypes.func.isRequired,
    acceptButtonHandler: PropTypes.func.isRequired,
    cancelButtonHandler: PropTypes.func.isRequired,
}

export { ConfirmationDialog };