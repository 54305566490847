import React from "react";
import PropTypes from 'prop-types';

const DefaultDownload = (props) => {
  let { client } = props;
  let notRoku = (client && client.specModel !== 'rokuCablelabs' && client.specModel !== 'rokuCablelabsLas');
  let notMovielabsTitleMec = (client && !client.name.match(/Movielabs Title Metadata/));
  return notRoku
    && notMovielabsTitleMec
    && props.children ? props.children : null;
}

DefaultDownload.propTypes = {
  client: PropTypes.object.isRequired,
}
export { DefaultDownload }