import { clientConstants } from '../_constants';
import { ClientspecService } from '../_services/clientspec.service';

export const ClientActions = {
  fetchClients: () => {
    return (dispatch) => {
      ClientspecService.getClients().then((resp) => {
        dispatch(clientsFetched(resp.data.clients));
      }, (err) => {
        throw new Error(`Could not fetch clients: ${err.message}`);
      });
    };
  },

  changeClient: (client) => {
    return {
      type: clientConstants.CHANGE_CLIENT,
      client,
    };
  },
};

const clientsFetched = (clients) => ({
  type: clientConstants.CLIENTS_FETCHED,
  clients,
});
