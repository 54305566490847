export const languages = [
  {
    name: "Afrikaans",
    iso: "af",
  },
  {
    name: "Albanian",
    iso: "sq",
  },
  {
    name: "Arabic",
    iso: "ar",
  },
  {
    name: "Armenian",
    iso: "hy",
  },
  {
    name: "Assamese",
    iso: "as",
  },
  {
    name: "Azerbaijani",
    iso: "az",
  },
  {
    name: "Bengali",
    iso: "bn",
  },
  {
    name: "Bhojpuri",
    iso: "bh",
  },
  {
    name: "Bosnian",
    iso: "bs",
  },
  {
    name: "Bulgarian",
    iso: "bg",
  },
  {
    name: "Burmese",
    iso: "ny",
  },
  {
    name: "Catalan",
    iso: "ca",
  },
  {
    name: "Chinese",
    iso: "zh",
  },
  {
    name: "Corsican",
    iso: "co",
  },
  {
    name: "Croatian",
    iso: "hr",
  },
  {
    name: "Czech",
    iso: "cs",
  },
  {
    name: "Danish",
    iso: "da",
  },
  {
    name: "Dutch",
    iso: "nl",
  },
  {
    name: "Dutch (Netherlands)",
    iso: "nl-NL",
  },
  {
    name: "English",
    iso: "en",
  },
  {
    name: "English (Australian)",
    iso: "en-AU",
  },
  {
    name: "English (UK)",
    iso: "en-UK",
  },
  {
    name: "English (US)",
    iso: "en-US",
  },
  {
    name: "Estonian",
    iso: "et",
  },
  {
    name: "Ewe",
    iso: "ee",
  },
  {
    name: "Farsi",
    iso: "fa",
  },
  {
    name: "Finnish",
    iso: "fi",
  },
  {
    name: "Flemish",
    iso: "fl",
  },
  {
    name: "French",
    iso: "fr",
  },
  {
    name: "French (Canadian)",
    iso: "fr-CA",
  },
  {
    name: "French (Parisian)",
    iso: "fr-FR",
  },
  {
    name: "Gaelic (Irish)",
    iso: "ga-IE",
  },
  {
    name: "Georgian",
    iso: "ka",
  },
  {
    name: "German",
    iso: "de",
  },
  {
    name: "German (Austrian)",
    iso: "de-AT",
  },
  {
    name: "German (Germany)",
    iso: "de-DE",
  },
  {
    name: "German (Swiss)",
    iso: "de-CH",
  },
  {
    name: "Greek",
    iso: "el",
  },
  {
    name: "Gujarati",
    iso: "gu",
  },
  {
    name: "Hebrew",
    iso: "he",
  },
  {
    name: "Hindi",
    iso: "hi",
  },
  {
    name: "Hungarian",
    iso: "hu",
  },
  {
    name: "Icelandic",
    iso: "is",
  },
  {
    name: "Indonesian",
    iso: "id",
  },
  {
    name: "Inuktitut",
    iso: "iu",
  },
  {
    name: "Irish",
    iso: "ga",
  },
  {
    name: "Italian",
    iso: "it",
  },
  {
    name: "Japanese",
    iso: "ja",
  },
  {
    name: "Kannada",
    iso: "kn",
  },
  {
    name: "Kazakh",
    iso: "kk",
  },
  {
    name: "Khmer",
    iso: "km",
  },
  {
    name: "Korean",
    iso: "ko",
  },
  {
    name: "Kurdish",
    iso: "ku",
  },
  {
    name: "Lao",
    iso: "lo",
  },
  {
    name: "Latin American Spanish",
    iso: "es-419",
  },
  {
    name: "Latvian",
    iso: "lv",
  },
  {
    name: "Lebanese",
    iso: "lb",
  },
  {
    name: "Lithuanian",
    iso: "lt",
  },
  {
    name: "Macedonian",
    iso: "mk",
  },
  {
    name: "Malay",
    iso: "ms",
  },
  {
    name: "Malayalam",
    iso: "ml",
  },
  {
    name: "Mandarin",
    iso: "zh",
  },
  {
    name: "Maori",
    iso: "mi",
  },
  {
    name: "Marathi",
    iso: "mr",
  },
  {
    name: "Mongolian",
    iso: "mn",
  },
  {
    name: "Nepali",
    iso: "ne",
  },
  {
    name: "Norwegian",
    iso: "no",
  },
  {
    name: "Oriya",
    iso: "or",
  },
  {
    name: "Polish",
    iso: "pl",
  },
  {
    name: "Portuguese",
    iso: "pt",
  },
  {
    name: "Portuguese (Brazil)",
    iso: "pt-BR",
  },
  {
    name: "Portuguese (Portugal)",
    iso: "pt-PT",
  },
  {
    name: "Punjabi",
    iso: "pa",
  },
  {
    name: "Romanian",
    iso: "ro",
  },
  {
    name: "Russian",
    iso: "ru",
  },
  {
    name: "Serbian",
    iso: "sr",
  },
  {
    name: "Serbo-Croatian",
    iso: "sh",
  },
  {
    name: "Sindi",
    iso: "sd",
  },
  {
    name: "Sinhala",
    iso: "si",
  },
  {
    name: "Slovak",
    iso: "sk",
  },
  {
    name: "Slovene",
    iso: "sl",
  },
  {
    name: "Spanish",
    iso: "es",
  },
  {
    name: "Spanish (Argentinean)",
    iso: "es-AR",
  },
  {
    name: "Spanish (Castilian)",
    iso: "es-ES",
  },
  {
    name: "Spanish (Chilean)",
    iso: "es-CL",
  },
  {
    name: "Spanish (Cuban)",
    iso: "es-CU",
  },
  {
    name: "Spanish (Mexican)",
    iso: "es-MX",
  },
  {
    name: "Spanish (Puerto Rican)",
    iso: "es-PR",
  },
  {
    name: "Swahili",
    iso: "sw",
  },
  {
    name: "Swedish",
    iso: "sv",
  },
  {
    name: "Tagalog",
    iso: "tl",
  },
  {
    name: "Tamil",
    iso: "ta",
  },
  {
    name: "Telugu",
    iso: "te",
  },
  {
    name: "Thai",
    iso: "th",
  },
  {
    name: "Tibetan",
    iso: "bo",
  },
  {
    name: "Turkish",
    iso: "tr",
  },
  {
    name: "Ukranian",
    iso: "uk",
  },
  {
    name: "Urdu",
    iso: "ur",
  },
  {
    name: "Vietnamese",
    iso: "vi",
  },
  {
    name: "Welsh",
    iso: "cy",
  },
  {
    name: "Wolof",
    iso: "wo",
  },
  {
    name: "Yiddish",
    iso: "yi",
  },
  {
    name: "Zulu",
    iso: "zu",
  },
];
