import React from 'react';
import { Grid } from '@material-ui/core';
import { TitleField } from '../common';
import { UtilityService } from '../../_services/utility.service';
import { clientShow } from '../Clientshow/clientshow';
import { ReleaseDateField } from './ReleaseDatesField';

const ExhibitionMetadataTVFields = (props) => {
    let { presentedTitle, client, index, maxSynopsisLength } = props;
    return (
        <Grid container>
            <TitleField label="Title" field="name"
                title={presentedTitle}
                warning="No Title found"
            />

            <TitleField label="Runtime" field="runtime"
                title={presentedTitle}
                warning="No Runtime found"
            />

            <TitleField label="Production Company" field="productions"
                title={presentedTitle}
                warning="Production Company not found"
            />

            <TitleField label="Rating System" field="ratingSystem"
                title={presentedTitle}
                warning="No Rating System found"
            />

            <TitleField label="Rating Reason" field="ratingReason"
                title={presentedTitle}
                warning="No Rating Reason found"
            />

            <TitleField label="Rating" field="rating"
                title={presentedTitle}
                warning="No Rating found"
            />

            <TitleField label="ISAN" field="isan"
                title={presentedTitle}
                warning="ISAN not found"
            />

            <TitleField label="MPM" field="mpmWalkerId"
                title={presentedTitle}
                warning="MPM not found"
            />

            <TitleField label="AlphaId" field="alphaId"
                title={presentedTitle}
                warning="No Alpha ID found for this title"
            />

            <ReleaseDateField releaseDates={presentedTitle.releaseDates}></ReleaseDateField>

            <TitleField label="Initial Release Date" field="initialReleaseDate"
                title={presentedTitle}
                formatFunc={UtilityService.convertDateField}
                warning="Initial Release Date not found for this title"
            />

            <TitleField label="Release Year" field="releaseYear"
                title={presentedTitle}
                warning="Release Year not found"
            />

            <TitleField label="Color" field="color"
                title={presentedTitle}
            />

            <TitleField label="Copyright Line" field="copyrightLine"
                title={presentedTitle}
            />

            <TitleField label="Country of Origin" field="countryOfOrigin"
                title={presentedTitle}
            />

            <TitleField label="Genres" field="genres"
                title={presentedTitle}
                warning="No Genres found for this title"
            />

            <TitleField label="Actors" field="actors"
                subfield="name"
                title={presentedTitle}
                warning="No Actors found for this title"
            />

            <TitleField label="Voice" field="voice"
                subfield="name"
                title={presentedTitle}
                warning="No Voices found for this title"
            />

            <TitleField label="Director" field="directors"
                subfield="name"
                title={presentedTitle}
                warning="No Directors found for this title"
            />

            <TitleField label="Producers" field="producers"
                subfield="name"
                title={presentedTitle}
                warning="No Producers found for this title"
            />

            <TitleField label="Teleplay" field="teleplay"
                subfield="name"
                title={presentedTitle}
                warning="No Teleplay found for this title"
            />

            <TitleField label="Composer" field="composers"
                subfield="name"
                title={presentedTitle}
                warning="No Composers found for this title"
            />

            <TitleField label="Writer" field="writer"
                subfield="name"
                title={presentedTitle}
                warning="No Writers found for this title"
            />

            <TitleField label="Screenplay" field="screenplay"
                subfield="name"
                title={presentedTitle}
                warning="No Screenplay found for this title"
            />

            <TitleField label="Synopsis Logline" field="synopsisLogline"
                subfield="text"
                title={presentedTitle}
                handleSetClick={props.handleSetClick}
                index={index}
                client={client}
                maxSynopsisLength={maxSynopsisLength}
                warning="No synopsis found for this title"
            />

            <TitleField label="Synopsis Long" field="synopsisLong"
                subfield="text"
                title={presentedTitle}
                index={index}
                client={client}
                warning="No synopsis found for this title"
            />

            <TitleField label="Synopsis Broadcast" field="synopsisBroadcast"
                subfield="text"
                title={presentedTitle}
                index={index}
                client={client}
                warning="No synopsis found for this title"
            />

            <TitleField label="Synopsis Alternate" field="synopsisAlternate"
                subfield="text"
                title={presentedTitle}
                index={index}
                client={client}
                warning="No synopsis found for this title"
            />

            <TitleField label="Synopsis Packaging" field="synopsisPackaging"
                subfield="text"
                title={presentedTitle}
                index={index}
                client={client}
                warning="No synopsis found for this title"
            />

            <TitleField label="Synopsis Pre-Order" field="synopsisPreOrder"
                subfield="text"
                title={presentedTitle}
                index={index}
                client={client}
                warning="No synopsis found for this title"
            />

            <TitleField label="Synopsis Medium" field="synopsisMedium"
                subfield="text"
                title={presentedTitle}
                index={index}
                client={client}
                warning="No synopsis found for this title"
            />

        </Grid>
    )
}

let clientConnected = clientShow(ExhibitionMetadataTVFields, "ExhibitionMetadataTVFields");
export { clientConnected as ExhibitionMetadataTVFields };