import React, { Component } from 'react';
import { Grid, Typography, TextField, Select, MenuItem, IconButton } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import { Close } from "@material-ui/icons";
import _ from 'lodash';
import MaskedInput from 'react-text-mask';
import { checkHours } from '../../_helpers/timecodeInputHelper';


const style = (theme) => ({
    label: {
        fontSize: 14,
        marginTop: 4,
        color: "#333333",
        fontWeight: 700,
        width: 'fit-content',
        flexBasis: 120,
    },
    subfield: {
        fontSize: 14,
        color: "#333333",
        fontWeight: 700,
        marginRight: 8,
        marginLeft: 16,
        marginTop: 4,
        width: 'fit-content',
    },
    textfield: {
        marginRight: 10,
        marginBottom: 15,
        width: '16vw',
    },
    item: {
        display: 'flex',
    },
    input: {
        position: 'relative',
        height: '28px !important',
    }
});

const timecodeValues = ['Additional Elements', 'Bars And Tones', 'Black',
'Blacks Commercial', 'Commercial Break', 'Continued Image Freeze',
'Forced Narrative', 'Program Slate', 'Texted', 'Texted Slate',
'Textless', 'Textless End', 'Textless Slate', 'Textless Start', ''];

const NdfMaskInput = (props) => {
    const { inputRef, ...other } = props; 
    return (
        <MaskedInput
        {...other}
        ref={ref => {
            inputRef(ref ? ref.inputElement : null);
        }}
        mask={[/[0-2]/, checkHours(other.value), ":", /[0-5]/, /\d/, ":", /[0-5]/, /\d/, ":", /[0-5]/, /\d/] }
        placeholder='HH:MM:SS:FRAMES'
        />
    )
    
}

const DfMaskInput = (props) => {
    const { inputRef, ...other } = props; 
    return (
        <MaskedInput
        {...other}
        ref={ref => {
            inputRef(ref ? ref.inputElement : null);
        }}
        mask={[/[0-2]/, checkHours(other.value), ":", /[0-5]/, /\d/, ":", /[0-5]/, /\d/, ";", /[0-5]/, /\d/] }
        placeholder='HH:MM:SS;FRAMES'
        />
    )
}

class TimecodeField extends Component {
    constructor(props) {
        super(props);
        let value = props.value[props.field];
        this.state = value || { in: '', out: '', error: false };
    }

    componentDidUpdate(prevProps, prevState) {
        let field = this.props.field;
        if(!_.isEqual(prevProps.value[field],this.props.value[field])) {
            let newValue = this.props.value[field];
            let newState = Object.assign({}, newValue);
            this.setState(newState);
        }
    }

    handleChange = (label, e, isNonDropFrame) => {
        let value = e.target.value;
        let field = this.props.field;
        let index = Number(this.props.fieldIndex);

        let regex = isNonDropFrame === 'yes' ? new RegExp(/\d\d:\d\d:\d\d:\d\d/g) : new RegExp(/\d\d:\d\d:\d\d;\d\d/g)
        let newState = Object.assign({}, this.state, {
            [label]: value,
        })

        let doesRegexPass = regex.test(value);
        newState.error = false;

        if(doesRegexPass || value === "") {
            if (newState.out !== "") {
                let isDataValid = !this.isInValueGreater(newState.in, newState.out);
                let frameRateError = false;
                let errorMsg = 'Please make sure IN and OUT values are valid in highlighted box';
                if(value !== "") {
                    let frameRate = isNonDropFrame === 'yes' ? value.split(":")[3] : value.split(";")[1]
                    frameRateError = this.checkIfFramesLimitExceeds(frameRate);
                    errorMsg = frameRateError ?
                        "Please verify if your frame rate is exceeded or there is required field" : errorMsg;
                }

                let error = !isDataValid || frameRateError;

                this.props.showNotification(error, errorMsg);

                newState.error = error;
            }
        }

        this.setState(newState, () => {
            if(doesRegexPass || value === "") {
                this.props.onChange(field, this.state, index);
            }
        })
    }

    isInValueGreater = (inValue, outValue) => {
        if (!inValue && !outValue) {
            return false;
        }
        return (inValue > outValue);
    }

    checkIfFramesLimitExceeds = (inValueFrames, outValueFrames) => {
        let frameRateLimit = this.props.frameRateLimit;
        // User didn't fill video data so frame rate will be 0.
        if(frameRateLimit !== 0) {
            let hasFrameRateError = inValueFrames > frameRateLimit || outValueFrames > frameRateLimit;
            return hasFrameRateError;
        }
        return false;
    }

    handleDeleteRow = (deletedIndex) => {
        this.props.handleDeleteRow(deletedIndex);
    }

    render() {
        let { classes, field, fieldIndex, onNewTagSelection, isNonDropFrame } = this.props;
        let fieldName = _.startCase(field) || '';
        return (
            <Grid container direction="row" style={{
                marginTop: 16,
            }}>
                <Grid item>
                    <Select onChange={e => onNewTagSelection(e, fieldIndex)} value={fieldName}
                    MenuProps={{
                        classes: { paper: classes.dropDownStyle },
                        getContentAnchorEl: null,
                        anchorOrigin: {
                            vertical: "bottom",
                            horizontal: "left",
                        },
                    }}
                    style={{
                        width: 135,
                        marginRight: 10,
                        marginBottom: 15,
                    }}>
                    {
                        timecodeValues.map((value, index) => {
                            return (
                                <MenuItem value={value} key={index}>
                                    <Typography noWrap={true}>{value}</Typography>
                                </MenuItem>
                            )
                        })
                    }
                    </Select>
                </Grid>
                <Grid item className={classes.item}>
                    <Typography varient="h5" component="h5" className={classes.subfield}>
                        IN
                    </Typography>
                    <TextField
                        className={classes.textfield}
                        value={this.state.in}
                        error={this.state.error || false }
                        onChange={e => this.handleChange('in', e, isNonDropFrame)}
                        variant="outlined"
                        InputProps={{
                            inputComponent: isNonDropFrame === 'yes' ? NdfMaskInput : DfMaskInput,
                            classes : { root: classes.input },
                        }}
                    />
                </Grid>
                <Grid item className={classes.item}>
                    <Typography varient="h5" component="h5" className={classes.subfield}>
                        OUT
                    </Typography>
                    <TextField
                        className={classes.textfield}
                        value={this.state.out}
                        error={this.state.error || false }
                        onChange={e => this.handleChange('out', e, isNonDropFrame)}
                        variant="outlined"
                        InputProps={{
                            inputComponent: isNonDropFrame === 'yes' ? NdfMaskInput : DfMaskInput,
                            classes : { root: classes.input },
                        }}
                    />
                </Grid>
                <Grid item>
                    <IconButton style={{ marginTop: -8 }} onClick={(e) => this.handleDeleteRow(fieldIndex)}>
                        <Close />
                    </IconButton>
                </Grid>
            </Grid>
        )
    }
}

const styled = withStyles(style)(TimecodeField);
export { styled as TimecodeField }